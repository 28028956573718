import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {UrlFactoryService} from '../factories/url-factory.service';
import {CancelablePromise} from '../common/promises/cancelable-promise';
import {UserEntryPreferenceDTO} from '../models/interfaces/user-entry-preference-dto';
import {EntryDTO} from '../models/interfaces/entry-dto';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {

  userPreferences: UserEntryPreferenceDTO[];

  userPreferencesChanged: Subject<UserEntryPreferenceDTO[]> = new Subject<UserEntryPreferenceDTO[]>();

  constructor(private baseService: BaseService,
              private urlFactory: UrlFactoryService) {
    this.userPreferencesChanged.subscribe((value) => {
      this.userPreferences = value;
    });
  }

  getFavoritesEntries(applicationId: number): CancelablePromise<UserEntryPreferenceDTO[]> {
    return this.baseService.getAll<UserEntryPreferenceDTO>(this.urlFactory.getFavoritesEntriesUrl(applicationId));
  }

  addEntryFavorite(entryId: number): CancelablePromise<EntryDTO> {
    return this.baseService.add<EntryDTO>(this.urlFactory.addFavoriteEntryUrl(entryId), null, true);
  }

  deleteEntryFavorite(entryId: number): CancelablePromise<boolean> {
    return this.baseService.deleteFromUrl(this.urlFactory.deleteFavoriteEntryUrl(entryId));
  }

  loadUserPreferences(): Promise<UserEntryPreferenceDTO[]> {
    return this.getFavoritesEntries(4)
      .then(preferences =>
        this.userPreferences = this.updateUserPreferences(preferences)
      );
  }

  addPreference(entryId: number) {
    this.updateUserPreferences(
      [
        ...this.userPreferences,
        {
          rup_id: null,
          uid: null,
          application_id: 4,
          entry_id: entryId
        }]
    );
  }

  removePreference(entryId: number) {
    this.updateUserPreferences(this.userPreferences.filter(entry => !(entry.entry_id === entryId)));
  }

  private updateUserPreferences(preferences: UserEntryPreferenceDTO[]) {
    this.userPreferences = preferences;
    this.userPreferencesChanged.next(this.userPreferences);
    return preferences;
  }
}
