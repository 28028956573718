import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EntryTypeDTO} from '../../../models/entry-type-dto';
import {EntryDTO} from '../../../models/interfaces/entry-dto';
import {UserPreferencesService} from '../../../services/user-preferences.service';
import {UserEntryPreferenceDTO} from '../../../models/interfaces/user-entry-preference-dto';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-favorite-menu-entries',
  templateUrl: './favorite-menu-entries.component.html',
  styleUrls: ['./favorite-menu-entries.component.scss']
})
export class FavoriteMenuEntriesComponent implements OnInit {

  @Input()
  isMenuCollapsed = false;
  displayFavoriteEntries = false;

  @Input()
  fullEntries: Map<string, EntryTypeDTO[]>;

  favoriteEntries: Map<string, EntryTypeDTO[]>;

  toFilter: EntryDTO[] = [];

  @Input()
  opened = false;

  @Output()
  changeFavoriteStatus = new EventEmitter<EntryDTO>();

  @Output()
  openedEmitter = new EventEmitter<boolean>();

  userPreferencesChanged: Subject<UserEntryPreferenceDTO[]> = this.userPreferenceService.userPreferencesChanged;
  private selfSuppressed = false;


  constructor(private userPreferenceService: UserPreferencesService) {
    this.userPreferencesChanged.subscribe((value) => {
      if (!this.selfSuppressed) {
        this.loadPreferences(this.userPreferenceService.userPreferences);
      } else {
        // Ici, on retire le dernier élément de la liste, car il s'agit forcément de l'élément à ne plus filtrer des préférences (qui ont été update en base à ce moment)
        this.toFilter.shift();
        if (!this.toFilter.length) {
          this.selfSuppressed = false;
        }
      }
    });
  }

  ngOnInit(): void {
    this.loadPreferences(this.userPreferenceService.userPreferences);
  }

  private loadPreferences(preferences: UserEntryPreferenceDTO[]) {
    const favoriteEntries = new Map<string, EntryTypeDTO[]>();

    for (const categoryModule of this.fullEntries.keys()) {
      for (const entryType of this.fullEntries.get(categoryModule)) {
        for (const entry of entryType.items) {
          if (this.isInPreferences(entry, preferences)) {

            if (!favoriteEntries.has(categoryModule)) {
              favoriteEntries.set(categoryModule, []);
            }

            if (!favoriteEntries.get(categoryModule).map(etype => etype.entry_type_name).includes(entryType.entry_type_name)) {
              favoriteEntries.get(categoryModule).push({
                entry_type_id: entryType.entry_type_id,
                entry_type_name: entryType.entry_type_name,
                entry_type_position: entryType.entry_type_position,
                items: [],
              });
            }

            favoriteEntries.get(categoryModule).find(type => type.entry_type_name === entryType.entry_type_name).items.push(entry);
          }
        }
      }
    }

    this.favoriteEntries = favoriteEntries;
  }

  changeDisplayFavoriteEntries(): void {
    this.displayFavoriteEntries = !this.displayFavoriteEntries;
    if (this.displayFavoriteEntries) {
      this.openedEmitter.emit(true);
    }
  }

  updateFavoriteStatus(entryDTO: EntryDTO) {
    // Lorsqu'on change le statut de favoris du pannel de favoris, c'est qu'on la supprime
    // Dans ce cas, on va update la liste envoyée à l'enfant pour en retirer le favoris avant tout le reste
    // On va aussi interdire l'update par événement
    this.toFilter.push(entryDTO);
    this.selfSuppressed = true;
    this.loadPreferences(this.userPreferenceService.userPreferences
      .filter(preference => !(this.toFilter
        .map(entry => entry.entry_id))
        .includes(preference.entry_id)));
    this.changeFavoriteStatus.emit(entryDTO);
  }

  private isInPreferences(entry: EntryDTO, preferences: UserEntryPreferenceDTO[]): boolean {
    return preferences.map(preference => preference.entry_id).includes(entry.entry_id);
  }

  onButtonClicked() {
    this.changeDisplayFavoriteEntries();
    this.opened = this.displayFavoriteEntries;
    this.openedEmitter.emit(this.displayFavoriteEntries);
  }
}
