import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserProfile} from '../../../models/user-profile.model';

@Component({
  selector: 'app-option-menu',
  templateUrl: './option-menu.component.html',
  styleUrls: ['./option-menu.component.scss']
})
export class OptionMenuComponent implements OnInit {

  @Input()
  currentProfile: UserProfile;

  @Output() helpClicked = new EventEmitter<void>();
  @Output() paramsClicked = new EventEmitter<void>();
  @Output() disconnectionClicked = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit(): void {
  }


  getProfilePhoto(): string {
    return this.currentProfile[`_links`] ? this.currentProfile[`_links`][`photo`]?.href : '';
  }

}
