declare const require: any;

export const environment = {
  title: 'Kordis',
  production: true,
  authenticationUrl: 'https://authentication.kordis.fr',
  servicesUrl: 'https://api.kordis.fr',
  client_id: 'kordis',
  version: require('../../package.json').version,
  version_type: require('../../package.json').version_type,

};
