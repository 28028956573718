import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import localeFr from '@angular/common/locales/fr';
import {DatePipe, registerLocaleData} from '@angular/common';

import { MarkdownModule } from 'ngx-markdown';
import { AngularSplitModule } from 'angular-split';

// PrimeNG
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { MegaMenuModule } from 'primeng/megamenu';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { TreeTableModule } from 'primeng/treetable';
import { FieldsetModule } from 'primeng/fieldset';
import {PanelModule} from 'primeng/panel';

// Services
import { TokenInterceptorService } from './services/token-interceptor.service';
import { PagesService } from './services/pages.service';

// Pages
import { LoginSuccessComponent } from './pages/users/login-success/login-success.component';
import { ParametersComponent } from './pages/parameters/parameters.component';
import { RightsComponent } from './pages/administration/rights/rights.component';
import { RightsAdminComponent} from './pages/administration/rights-admin/rights-admin.component';
import { StudentsComponent } from './pages/students/students.component';
import { StudentComponent } from './pages/students/student/student.component';
import { DiplomasComponent } from './pages/administration/diplomas/diplomas.component';
import { DiplomaComponent } from './pages/administration/diplomas/diploma/diploma.component';

// Components
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { BasicGridComponent } from './components/grid/basic-grid/basic-grid.component';
import { ComboBoxComponent } from './components/combo-box/combo-box.component';
import { IconChooserComponent } from './components/icon-chooser/icon-chooser.component';
import { LoaderScreenComponent } from './components/loader-screen/loader-screen.component';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { SchoolYearSemesterSelectorComponent } from './components/school-year-semester-selector/school-year-semester-selector.component';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TabLayoutComponent } from './components/tab-layout/tab-layout.component';
import { TreeModule } from './components/tree/tree.component';
import { TristateCheckboxModule } from './components/tristate-checkbox/tristate-checkbox.component';
import { VerticalMenuComponent } from './components/vertical-menu/vertical-menu.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { FieldLoaderComponent } from './field-loader/field-loader.component';
import { WelcomePageComponent } from './pages/welcome-page/welcome-page.component';
import { MenuSearchFieldComponent } from './components/main-menu/menu-search-field/menu-search-field.component';
import { FavoriteMenuEntriesComponent } from './components/main-menu/favorite-menu-entries/favorite-menu-entries.component';
import { FavoriteStarComponent } from './components/main-menu/favorite-star/favorite-star.component';
import { QueryComponent } from './components/query/query.component';


// Directives
import { TabDirective } from './directives/tab.directive';
import { ApplicationParamsSelectorComponent } from './components/application-params-selector/application-params-selector.component';

// Pipes
import {HighlightPipe} from './common/pipes/highlight-pipe';
import { RemoveCrossComponent } from './remove-cross/remove-cross.component';
import { FailureScreenComponent } from './components/failure-screen/failure-screen.component';
import { FormatedEntriesMenuComponent } from './components/main-menu/formated-entries-menu/formated-entries-menu.component';
import { ApiHeartbeatInterceptor } from './services/api-hearbeat-interceptor.service';
import { DiplomaSkillBlockComponent } from './pages/administration/diplomas/diploma/diploma-skills-block/diploma-skill-block.component';
import { ReportComponent } from './components/report/report.component';
import { DynamicParamsSelectorComponent } from './components/dynamic-params-selector/dynamic-params-selector.component';
import { OptionMenuComponent } from './components/vertical-menu/option-menu/option-menu.component';
import { DiplomaSkillBlockDialog } from './pages/administration/diplomas/diploma/diploma-skills-block/diploma-skill-block-dialog/diploma-skill-block-dialog.component';
import { VersionComponent } from './version/version.component';



// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    // Pages
    LoginSuccessComponent, ParametersComponent,
    // Components
    ErrorDialogComponent, BasicGridComponent, ComboBoxComponent, IconChooserComponent,
    LoaderScreenComponent, MainLayoutComponent, RightsComponent, RightsAdminComponent, SearchBoxComponent, SpinnerComponent,
    TabLayoutComponent, VerticalMenuComponent, WelcomePageComponent,
    // Directives
    TabDirective,
    StudentsComponent,
    StudentComponent,
    SchoolYearSemesterSelectorComponent,
    FieldLoaderComponent,
    MainMenuComponent,
    QueryComponent,
    ApplicationParamsSelectorComponent,
    MenuSearchFieldComponent,
    FormatedEntriesMenuComponent,
    HighlightPipe,
    FavoriteMenuEntriesComponent,
    FavoriteStarComponent,
    RemoveCrossComponent,
    FailureScreenComponent,
    DiplomasComponent,
    DiplomaComponent,
    DiplomaSkillBlockComponent,
    ReportComponent,
    DynamicParamsSelectorComponent,
    OptionMenuComponent,
    DiplomaSkillBlockDialog,
    VersionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    // Third party library
    MarkdownModule.forRoot({loader: HttpClient}),
    AngularSplitModule,
    // PrimeNG
    BrowserAnimationsModule, FormsModule,
    AutoCompleteModule, ButtonModule, CalendarModule, CheckboxModule, ConfirmDialogModule, ConfirmPopupModule, ContextMenuModule,
    DataViewModule, DialogModule, DynamicDialogModule, DropdownModule, FileUploadModule, InputTextModule,
    MegaMenuModule, MessagesModule, MultiSelectModule, OrganizationChartModule, OverlayPanelModule,
    PaginatorModule, ProgressBarModule, ProgressSpinnerModule, RadioButtonModule, RippleModule, SidebarModule, SplitButtonModule,
    TableModule, TabViewModule, ToastModule, TooltipModule, TreeModule, TreeTableModule,
    // Project modules
    TristateCheckboxModule, TreeModule, ReactiveFormsModule, FieldsetModule, PanelModule
  ],
  providers: [
    DatePipe,
    MessageService,
    ConfirmationService,
    PagesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiHeartbeatInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
