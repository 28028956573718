<div class="fullHeight" style="position: relative">
  <div [ngStyle]="{position: 'absolute', top: 0, left: 0, right: 0, bottom: bottomTabsHeight + 'px'}">
    <div *ngIf="selectedTab === 'profile'" class="fullWidth">
<!--      {{ student.civility }}-->
<!--      {{ student.name }}-->
<!--      {{ student.firstname }}-->
      <p-tabView class="fullWidth">
        <p-tabPanel header="Informations">
          test 1
        </p-tabPanel>
        <p-tabPanel header="Observations">
          test 2
        </p-tabPanel>
      </p-tabView>
    </div>
    <div *ngIf="selectedTab === 'formations'">
      Formations
    </div>
    <div *ngIf="selectedTab === 'disciplines'">
      Matières
    </div>
    <div *ngIf="selectedTab === 'proofs'">
      Justificatifs
    </div>
    <div *ngIf="selectedTab === 'compléments'">
      Compléments
    </div>
    <div *ngIf="selectedTab === 'documents'">
      Documents
    </div>
    <div *ngIf="selectedTab === 'gesTracking'">
      Suivi GES
    </div>
    <div *ngIf="selectedTab === 'pikicomTracking'">
      Suivi Pikicom
    </div>
    <div *ngIf="selectedTab === 'accounting'">
      Comptabilité
    </div>
    <div *ngIf="selectedTab === 'billing'">
      Facturation
    </div>
    <div *ngIf="selectedTab === 'sepa'">
      SEPA
    </div>
    <div *ngIf="selectedTab === 'planning'">
      Planning étudiant
    </div>
  </div>

  <div #bottomTabs class="p-d-flex bottomTab">
    <div *ngFor="let subTab of subTabs" (click)="onSubTabClicked(subTab)"
         [ngClass]="{'p-px-3': true, 'p-py-1': true, 'p-mr-1': true, 'noWrap': true, clickable: true,
                     whiteColor: true,
                     backgroundPrimaryColor: subTab.value === selectedTab,
                     backgroundGreyColor3: subTab.value !== selectedTab}">{{ subTab.label }}</div>
  </div>
</div>
