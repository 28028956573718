import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DiplomaBlockDTO} from '../../../../../../models/diplomas/diploma-block-dto';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DiplomasService} from '../../../../../../services/diplomas.service';
import {Canceler} from '../../../../../../common/promises/request-canceler';
import {MessageService} from 'primeng/api';
import {ErrorService} from '../../../../../../services/error.service';
import {AutoComplete} from 'primeng/autocomplete';

@Component({
  selector: 'app-diploma-skill-block-dialog',
  templateUrl: './diploma-skill-block-dialog.component.html',
  styleUrls: ['./diploma-skill-block-dialog.component.scss']
})
// tslint:disable-next-line:component-class-suffix
export class DiplomaSkillBlockDialog implements OnInit {
  dialogAddHeader = 'Création d\'un bloc de compétence';

  canceler: Canceler = new Canceler();
  filteredParents: DiplomaBlockDTO[];

  @Input()
  visible = false;
  isAddMode = true;
  isLoading = false;

  @Input() parents: DiplomaBlockDTO[];

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onSave = new EventEmitter<boolean>();

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onLoading = new EventEmitter<boolean>();

  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  //#region tabs
  @ViewChild('topTabs') bottomTabs: ElementRef;
  subTabs = [
    {value: 'general', label: 'Général'},
    {value: 'skill', label: 'Compétences'},
    {value: 'evaluation', label: 'Modalités d\'évaluation'}
  ];
  selectedTab = 'general';
  bottomTabsHeight = 28;

  @ViewChild('generalTab') generalTab: ElementRef;
  @ViewChild('skillTab') skillTab: ElementRef;
  @ViewChild('evaluationTab') evaluationTab: ElementRef;
  //#endregion

  //#region dialog tabs
  isGeneralTabHidden = false;
  isSkillTabHidden = true;
  isEvaluationTabHidden = true;
  //#endregion

  @ViewChild('parentInput') parentInput: AutoComplete;
  diplomaId: number;
  selectedDiplomaBlockId: number;
  skillBlockForm: FormGroup;
  diplomaBlockDTO: DiplomaBlockDTO;
  filterValue: string;
  selectedParent: DiplomaBlockDTO;

  constructor(private diplomasService: DiplomasService,
              private messageService: MessageService,
              private errorService: ErrorService) {
  }

  ngOnInit(): void {
  }

  cancelRequest(): void {
    this.cancel.emit('cancel');
  }

  //#region gestion des tabs
  onSubTabClicked(subTab: { value: string, label: string }): void {
    this.selectedTab = subTab.value;

    switch (this.selectedTab) {
      case 'general':
        this.loadGeneral();
        break;
      case 'skill':
        this.loadSkill();
        break;
      case 'evaluation':
        this.loadEvaluation();
        break;
    }
  }

  private loadGeneral(): void {
    this.isGeneralTabHidden = false;
    this.isSkillTabHidden = true;
    this.isEvaluationTabHidden = true;
  }

  private loadSkill(): void {
    this.isGeneralTabHidden = true;
    this.isSkillTabHidden = false;
    this.isEvaluationTabHidden = true;
  }

  private loadEvaluation(): void {
    this.isGeneralTabHidden = true;
    this.isSkillTabHidden = true;
    this.isEvaluationTabHidden = false;
  }

  //#endregion

  showDialog(isAddMode: boolean, diplomaId: number, diplomaBlockId: number) {
    this.filteredParents = this.parents;
    this.onLoading.emit(true);
    this.isAddMode = isAddMode;
    this.isLoading = true;
    this.diplomaId = diplomaId;
    this.selectedDiplomaBlockId = diplomaBlockId;
    this.selectedParent = null;
    this.filterValue = null;

    if (diplomaBlockId != null) {
      this.diplomasService.getDiplomaBlockByBlockId(diplomaBlockId)
        .setCancelAction(this.canceler)
        .then(blockDTO => {
          this.diplomaBlockDTO = blockDTO;
          this.initSkillBlockForm();

          this.skillBlockForm.setValue(
            {
              code: this.diplomaBlockDTO.code,
              name: this.diplomaBlockDTO.name,
              parent_id: this.diplomaBlockDTO.parent_id,
              is_active: this.diplomaBlockDTO.is_active,
              skill_description: this.diplomaBlockDTO.skill_description,
              evaluation_description: this.diplomaBlockDTO.evaluation_description
            }
          );
          this.visible = true;
          this.isLoading = false;

          if (blockDTO.parent_id != null) {
            this.diplomasService.getDiplomaBlockByBlockId(blockDTO.parent_id)
              .then(parentDTO => {
                this.selectedParent = parentDTO;
                this.parentInput.value = parentDTO;
                this.parentInput.updateInputField();
              });
          } else {
            this.selectedParent = null;
            this.parentInput.value = null;
            this.parentInput.updateInputField();
          }

          this.onLoading.emit(this.isLoading);
        });
    } else {
      this.diplomaBlockDTO = new DiplomaBlockDTO();
      this.diplomaBlockDTO.is_active = true;
      this.initSkillBlockForm();
      this.visible = true;
      this.isLoading = false;
      this.selectedParent = null;
      this.parentInput.value = null;
      this.parentInput.updateInputField();

      this.onLoading.emit(this.isLoading);
    }
  }

  //region formulaire
  private initSkillBlockForm(): void {
    this.skillBlockForm = new FormGroup({
      code: new FormControl(this.diplomaBlockDTO.code, Validators.required),
      name: new FormControl(this.diplomaBlockDTO.name, Validators.required),
      parent_id: new FormControl(this.diplomaBlockDTO.parent_id),
      is_active: new FormControl(this.diplomaBlockDTO.is_active),
      skill_description: new FormControl(this.diplomaBlockDTO.skill_description),
      evaluation_description: new FormControl(this.diplomaBlockDTO.evaluation_description)
    });
  }

  onCheckboxChange(e) {
    if (e.target.checked) {
      this.skillBlockForm.addControl('is_active', new FormControl(e.target.value));
    }
  }

  formIsValid(): boolean {
    return this.skillBlockForm != null && this.skillBlockForm.valid;
  }

  onSaveBlock(): void {
    if (!this.skillBlockForm.valid) {
      return;
    }
    this.isLoading = true;
    if (this.selectedParent != null) {
      this.skillBlockForm.value.parent_id = this.selectedParent.block_id;
    }

    if (this.isAddMode) {
      this.diplomasService.addDiplomaBlockForDiplomaId(this.diplomaId, this.skillBlockForm.value)
        .setCancelAction(this.canceler)
        .catch(error => this.errorService.showError(error))
        .then(() => {
          this.visible = false;
          this.onSave.emit(true);

          this.messageService.add({
            severity: 'success',
            summary: 'Création effectuée',
            detail: 'Le bloc de compétence a été créé avec succès',
            life: 10000
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.onLoading.emit(false);
        });
    } else {
      this.diplomasService.updateDiplomaBlockByBlockId(this.selectedDiplomaBlockId, this.skillBlockForm.value)
        .setCancelAction(this.canceler)
        .catch(error => this.errorService.showError(error))
        .then(() => {
          this.visible = false;
          this.onSave.emit(true);

          this.messageService.add({
            severity: 'success',
            summary: 'Modification effectuée',
            detail: 'Le bloc de compétence a été modifié avec succès',
            life: 10000
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.onLoading.emit(false);
        });
    }
  }

  //#endregion

  updateSelectedParentBloc(event: any): void {
    if (event != null) {
      this.selectedParent = event;
    }
  }

  filterDiplomaBlocksByName(event: any): void {
    if (event.query != null) {
      this.filterValue = event.query;
    }

    this.filteredParents = this.parents
      .filter(blockDto => blockDto.block_id !== this.selectedDiplomaBlockId)
      .filter(blockDto => blockDto.name.indexOf(this.filterValue) > -1 || blockDto.code.indexOf(this.filterValue) > -1);
  }

  clearParent(): void {
    this.clearAllParentInfo();
  }

  private clearAllParentInfo(): void {
    this.selectedParent = null;
    this.filterValue = '';
    this.skillBlockForm.value.parent_id = null;
  }
}
