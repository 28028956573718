import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Message } from 'primeng/api';
import { AuthenticatedUserService } from '../../../services/authenticated-user.service';

@Component({
  selector: 'app-login-success',
  templateUrl: './login-success.component.html',
  styleUrls: ['./login-success.component.scss']
})
export class LoginSuccessComponent implements OnInit {

  msgs: Message[] = [];

  constructor(
    private router: Router,
    private authenticatedUserService: AuthenticatedUserService
  ) { }

  ngOnInit() {
    this.authenticatedUserService.saveAuthenticatedUser(window.location.href);

    // TODO enregistrer les privilèges
    // this.authenticatedUserService.loadPrivileges().then(
    //   (privileges) => {
    this.authenticatedUserService.loadProfile()
      .then((profile) => {
        this.router.navigate(['/']);
      }
      // }
    ).catch((error) => {
      // TODO affichage du bon écran
      this.msgs.push({
        severity: 'error',
        summary: 'Erreur de connexion',
        detail: 'Erreur de récupération de profil, merci de vous reconnecter'});
      this.authenticatedUserService.removeToken();
      this.authenticatedUserService.revokeToken();
      window.location.href = '/';
    });
  }
}
