import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-failure-screen',
  templateUrl: './failure-screen.component.html',
  styleUrls: ['./failure-screen.component.scss']
})
export class FailureScreenComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  refresh() {
    this.router.navigate(['/']).then(()=>window.location.reload());
  }
}
