import {EventEmitter, Component, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-remove-cross',
  templateUrl: './remove-cross.component.html',
  styleUrls: ['./remove-cross.component.scss']
})
export class RemoveCrossComponent implements OnInit {

  @Output()
  crossPressed = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

}
