<app-basic-grid #diplomasGrid
                id="diplomasGrid"
                dataKey="diploma_id"
                gridTitle="Liste des diplômes/titres"
                gridType="{{gridTypes.DIPLOMA_GRID}}"

                [topColumns]="topColumns"
                [columns]="diplomasColumns"
                [data]="diplomasData"
                [selectedData]="selectedDiplomas"
                [loading]="loadingDiplomas"
                [isTabActive]="isActive()"
                [scrollable]="true"

                [customButtonsTemplate]="diplomasButtons"
                [showRefreshButton]="true"
                [showGlobalSearch]="true"
                [showAddButton]="false"
                [showDeleteButton]="false"
                [showShowButton]="false"

                [sendExportExcelVisible]="true"
                [sendExportPDFVisible]="false"
                [sendPublipostageVisible]="false"
                [sendEmailingVisible]="false"
                [sendSmsVisible]="false"
                [sendNotificationVisible]="false"

                (rowsSelectionChanged)="onRowsSelectionChanged($event)"
                (rowDblClicked)="onShowDiploma($event, false)"
                (refreshRequested)="onRefreshRequested()"
                (cancel)="canceler.cancel()">
  <ng-template #diplomasButtons>
    <div class="p-ml-auto p-text-left preset-button">
      <button *ngIf="!isBTS(selectedDiplomas[0])" pButton type="button" class="p-button-success p-mr-2" icon="pi pi-plus" pTooltip="Créer un titre ou un diplôme"
              (click)="onShowDiploma(null, true)"></button>
      <button *ngIf="!isBTS(selectedDiplomas[0])" pButton type="button" class="p-button-success p-mr-2" icon="pi pi-pencil" pTooltip="Modifier le titre ou le diplôme sélectionné"
              (click)="onShowDiploma(selectedDiplomas[0], false)" [disabled]="selectedDiplomas.length != 1"></button>
      <button *ngIf="!isBTS(selectedDiplomas[0])" pButton type="button" class="p-button-danger p-mr-2" icon="pi pi-trash" pTooltip="Supprimer le titre ou le diplôme sélectionné"
              (click)="onDelete()" [disabled]="selectedDiplomas.length === 0"></button>
    </div>
  </ng-template>
</app-basic-grid>

<p-confirmDialog key="deleteDiplomaDialog" [style]="{width: '40vw'}">
</p-confirmDialog>
