<div #headerDiv></div>

<as-split direction="horizontal">
  <as-split-area [size]="35">
    <p-tabView (onChange)="onUserTypeTabChange($event)">
      <p-tabPanel header="Groupes" [selected]="true">
        <app-tree [value]="rightsGroups"
                  [filterPlaceholder]="'Rechercher un groupe'"
                  [loading]="loadingGroups"
                  [filter]="true"
                  [customButtonsTemplate]="rightsGroupsButtons"
                  [(selection)]="selectedRightsGroup" selectionMode="single"
                  [contextMenu]="rightsGroupsContextMenu"
                  (onNodeSelect)="onRightsGroupNodeSelect($event)"
                  (onNodeUnselect)="onRightsGroupNodeUnselect($event)">
          <ng-template #rightsGroupsButtons>
              <div class="p-ml-auto centerContent">
                <p-confirmPopup [style]="{'width': '50vw'}"></p-confirmPopup>
                <button pButton type="button" class="p-mr-2" icon="pi pi-map" pTooltip="Voir l'organigramme des groupes de droits" (click)="showRightsGroupsOrganizationChart()"></button>
                <button *ngIf="selectedRightsGroup" pButton type="button" class="p-button-success p-mr-2" icon="pi pi-plus" pTooltip="Créer un groupe de droits enfant" (click)="showRightsGroupsSaveOrUpdateDialog(true)"></button>
                <button *ngIf="hasRightToEdit" pButton type="button" class="p-button-success p-mr-2" icon="pi pi-pencil" pTooltip="Modifier le groupe sélectionné" (click)="showRightsGroupsSaveOrUpdateDialog(false)"></button>
                <button *ngIf="hasRightToEdit" pButton type="button" class="p-button-success p-mr-2" icon="pi pi-copy" pTooltip="Dupliquer le groupe sélectionné" (click)="showRightsGroupsDuplicationDialog()"></button>
                <button *ngIf="hasRightToEdit && selectedRightsGroup && selectedRightsGroup.children.length === 0" pButton type="button" class="p-button-danger p-mr-2" icon="pi pi-trash" pTooltip="Supprimer le groupe sélectionné" (click)="showRightsGroupsDeleteDialog()"></button>
             </div>
          </ng-template>
        </app-tree>
      </p-tabPanel>
      <p-tabPanel header="Détails par utilisateur de mes groupes" leftIcon="pi pi-info-circle">

        <div class="p-p-3" style="background: #ddd">
          <button pButton class="p-button-secondary" icon="pi pi-search"></button>
          <input class="p-inputtext" type="text" placeholder="Rechercher un membre" [disabled]="userTypeTabActiveIndex === GROUPS_TAB_INDEX && !selectedRightsGroup"
                 [(ngModel)]="memberSearchInput" (ngModelChange)="filterAdministrativesFromRightsGroupByName($event)">
          <button *ngIf="memberSearchInput" pButton pTooltip="Réinitialiser le filtre"
                  icon="pi pi-times" class="p-button-danger p-ml-2" (click)="onResetRightsGroupMembersSearchInput()"></button>
        </div>

        <p-dataView [value]="filteredMembersFromRightsGroup" layout="list" class="fullHeight"
                    [emptyMessage]="'Aucun membre pour le groupe sélectionné.'">
          <ng-template pTemplate="header">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
              <div class="p-mb-2 p-mb-md-0">
                <span class="softMarginRight">Membres : {{ filteredMembersFromRightsGroup.length }}</span>
              </div>
            </div>
          </ng-template>
          <ng-template let-adm pTemplate="listItem" style="display: flex; flex-wrap: wrap;">
            <div class="middlePadding fullWidth isSelectable" (click)="onUserSelect(adm)"
                 [style]="{'background': selectedUserToDisplay ? selectedUserToDisplay.uid === adm.uid ? '#f4f4f4' : '' : ''}">
              <div class="p-d-flex">
                <div class="p-mr-2">
                  <span class="p-mr-2 rightsGroupMemberHeader">{{adm.civility}} {{adm.name}} {{adm.firstname}}</span><br>
                  <span class="p-mr-2">{{adm.service}} - {{adm.email}}</span>
                </div>
                <div class="p-ml-auto centerContent">
                  <button pButton type="button" icon="pi pi-eye" pTooltip="Voir les droits de l'utilisateur" (click)="showUserRightsDialog(adm)"></button>
                </div>
              </div>
            </div>
          </ng-template>
        </p-dataView>

      </p-tabPanel>
    </p-tabView>

    <p-contextMenu #rightsGroupsContextMenu [model]="contextMenuItems" appendTo="body"></p-contextMenu>
  </as-split-area>

  <as-split-area [size]="65">
    <p-tabView (onChange)="onRightsTabChange($event)">
      <p-tabPanel header="Ecoles" [selected]="true">

        <div class="p-tabpanel-content">

          <app-tree #schoolsTree
                    [ngStyle]="{'height': 'calc(100% - 55px) !important'}"
                    class="leftSideScroll"
                    [value]="getTreeFromSelectedTab()" scrollHeight="flex"
                    [filterPlaceholder]="'Rechercher une école'"
                    [loading]="loadingRightsGroupHasRights"
                    [filter]="true"
                    [customButtonsTemplate]="rightsButton"
                    [(selection)]="selectedRightsGroupHasRight"
                    [selectionMode]="getSelectionModeFromSelectedTab()"
                    [emptyMessage]="getEmptyMessageFromSelectedTab()"
                    (onNodeSelect)="refreshSelectedRightsMultiSelectors()"
                    (onNodeUnselect)="refreshSelectedRightsMultiSelectors()">

            <ng-template let-node pTemplate="family">
              <div>
                <span class="rightsFamilyHeader">{{ node.label }}</span>
              </div>
            </ng-template>

            <ng-template let-node pTemplate="schoolRight">
              <div class="p-d-flex">
                <div class="p-mr-2">{{ node.label }}</div>
                <div class="p-ml-auto">
                  <input type="checkbox" [(ngModel)]="node.data.can_read" [disabled]="node.data.can_read_disabled" pTooltip="Lire" class="myinput large" id="canReadSchool" (change)="refreshRights()" >
                  <label [pTooltip]="node.data.can_read_disabled ? 'Vous n\'avez pas les droits pour mettre à jour ce droit' : 'Lire'" for="canReadSchool"><span [style]="{ 'color': node.data.can_read ? '#107D11' : '#A80000'}" class="pi pi-eye middleMarginRight rightsIcon"></span></label>
                  <input type="checkbox" [(ngModel)]="node.data.can_transmit" [disabled]="node.data.can_transmit_disabled" pTooltip="Lire" class="myinput large" id="canTransmitSchool" (change)="refreshRights()" >
                  <label [pTooltip]="node.data.can_transmit_disabled ? 'Vous n\'avez pas les droits pour mettre à jour ce droit' : 'Transmettre ce droit'" for="canTransmitSchool"><span [style]="{ 'color': node.data.can_transmit ? '#107D11' : '#A80000'}" class="pi pi-directions middleMarginRight rightsIcon"></span></label>
                </div>
              </div>
            </ng-template>

          </app-tree>
        </div>

        <ng-container *ngTemplateOutlet="customFooterTemplate"></ng-container>

      </p-tabPanel>
      <p-tabPanel header="Interfaces">

        <div [style]="{'height': 'calc(100% - 55px)'}">
          <app-tree #interfacesTree
                    [ngStyle]="{'height': 'calc(100% - 55px) !important'}"
                    class="leftSideScroll"
                    [value]="getTreeFromSelectedTab()" scrollHeight="flex"
                    [filterPlaceholder]="'Rechercher une interface'"
                    [loading]="loadingRightsGroupHasRights"
                    [filter]="true"
                    [customButtonsTemplate]="rightsButton"
                    [(selection)]="selectedRightsGroupHasRight"
                    [selectionMode]="getSelectionModeFromSelectedTab()"
                    [emptyMessage]="getEmptyMessageFromSelectedTab()"
                    (onNodeSelect)="refreshSelectedRightsMultiSelectors()"
                    (onNodeUnselect)="refreshSelectedRightsMultiSelectors()">

            <ng-template let-node pTemplate="family">
              <div>
                <span class="rightsFamilyHeader">{{ node.label }}</span>
              </div>
            </ng-template>

            <ng-template let-node pTemplate="right">
              <div class="p-d-flex">
                <div class="p-mr-2">{{ node.label }}</div>
                <div class="p-ml-auto">
                  <input type="checkbox" [(ngModel)]="node.data.can_read" [disabled]="node.data.can_read_disabled" pTooltip="Lire" class="myinput large" id="canRead" (change)="refreshRights()" >
                  <label [pTooltip]="node.data.can_read_disabled ? 'Vous n\'avez pas les droits pour mettre à jour ce droit' : 'Lire'" for="canRead"><span [style]="{ 'color': node.data.can_read ? '#107D11' : '#A80000'}" class="pi pi-eye middleMarginRight rightsIcon"></span></label>
                  <input type="checkbox" [(ngModel)]="node.data.can_update" [disabled]="node.data.can_update_disabled" pTooltip="Lire" class="myinput large" id="canUpdate" (change)="refreshRights()" >
                  <label [pTooltip]="node.data.can_update_disabled ? 'Vous n\'avez pas les droits pour mettre à jour ce droit' : 'Créer / Mettre à jour'" for="canUpdate"><span [style]="{ 'color': node.data.can_update ? '#107D11' : '#A80000'}" class="pi pi-pencil middleMarginRight rightsIcon"></span></label>
                  <input type="checkbox" [(ngModel)]="node.data.can_delete" [disabled]="node.data.can_delete_disabled" pTooltip="Lire" class="myinput large" id="canDelete" (change)="refreshRights()" >
                  <label [pTooltip]="node.data.can_delete_disabled ? 'Vous n\'avez pas les droits pour mettre à jour ce droit' : 'Supprimer'" for="canDelete"><span [style]="{ 'color': node.data.can_delete ? '#107D11' : '#A80000'}" class="pi pi-trash middleMarginRight rightsIcon"></span></label>
                  <input type="checkbox" [(ngModel)]="node.data.can_transmit" [disabled]="node.data.can_transmit_disabled" pTooltip="Lire" class="myinput large" id="canTransmit" (change)="refreshRights()" >
                  <label [pTooltip]="node.data.can_transmit_disabled ? 'Vous n\'avez pas les droits pour mettre à jour ce droit' : 'Transmettre ce droit'" for="canTransmit"><span [style]="{ 'color': node.data.can_transmit ? '#107D11' : '#A80000'}" class="pi pi-directions middleMarginRight rightsIcon"></span></label>
                </div>
              </div>
            </ng-template>

          </app-tree>
        </div>

        <ng-container *ngTemplateOutlet="customFooterTemplate"></ng-container>

      </p-tabPanel>
      <p-tabPanel header="Fonctions">

        <div [style]="{'height': 'calc(100% - 55px)'}">
          <app-tree #functionsTree
                    [ngStyle]="{'height': 'calc(100% - 55px) !important'}"
                    class="leftSideScroll"
                    [value]="getTreeFromSelectedTab()" scrollHeight="flex"
                    [filterPlaceholder]="'Rechercher une fonction'"
                    [loading]="loadingRightsGroupHasRights"
                    [filter]="true"
                    [customButtonsTemplate]="rightsButton"
                    [(selection)]="selectedRightsGroupHasRight"
                    [selectionMode]="getSelectionModeFromSelectedTab()"
                    [emptyMessage]="getEmptyMessageFromSelectedTab()"
                    (onNodeSelect)="refreshSelectedRightsMultiSelectors()"
                    (onNodeUnselect)="refreshSelectedRightsMultiSelectors()">

            <ng-template let-node pTemplate="family">
              <div>
                <span class="rightsFamilyHeader">{{ node.label }}</span>
              </div>
            </ng-template>

            <ng-template let-node pTemplate="right">
              <div class="p-d-flex">
                <div class="p-mr-2">{{ node.label }}</div>
                <div class="p-ml-auto">
                  <input type="checkbox" [(ngModel)]="node.data.can_read" [disabled]="node.data.can_read_disabled" pTooltip="Lire" class="myinput large" (change)="refreshRights()" >
                  <label [pTooltip]="node.data.can_read_disabled ? 'Vous n\'avez pas les droits pour mettre à jour ce droit' : 'Lire'" for="canRead"><span [style]="{ 'color': node.data.can_read ? '#107D11' : '#A80000'}" class="pi pi-eye middleMarginRight rightsIcon"></span></label>
                  <input type="checkbox" [(ngModel)]="node.data.can_update" [disabled]="node.data.can_update_disabled" pTooltip="Lire" class="myinput large" (change)="refreshRights()" >
                  <label [pTooltip]="node.data.can_update_disabled ? 'Vous n\'avez pas les droits pour mettre à jour ce droit' : 'Créer / Mettre à jour'" for="canUpdate"><span [style]="{ 'color': node.data.can_update ? '#107D11' : '#A80000'}" class="pi pi-pencil middleMarginRight rightsIcon"></span></label>
                  <input type="checkbox" [(ngModel)]="node.data.can_delete" [disabled]="node.data.can_delete_disabled" pTooltip="Lire" class="myinput large" (change)="refreshRights()" >
                  <label [pTooltip]="node.data.can_delete_disabled ? 'Vous n\'avez pas les droits pour mettre à jour ce droit' : 'Supprimer'" for="canDelete"><span [style]="{ 'color': node.data.can_delete ? '#107D11' : '#A80000'}" class="pi pi-trash middleMarginRight rightsIcon"></span></label>
                  <input type="checkbox" [(ngModel)]="node.data.can_transmit" [disabled]="node.data.can_transmit_disabled" pTooltip="Lire" class="myinput large" (change)="refreshRights()" >
                  <label [pTooltip]="node.data.can_transmit_disabled ? 'Vous n\'avez pas les droits pour mettre à jour ce droit' : 'Transmettre ce droit'" for="canTransmit"><span [style]="{ 'color': node.data.can_transmit ? '#107D11' : '#A80000'}" class="pi pi-directions middleMarginRight rightsIcon"></span></label>
                </div>
              </div>
            </ng-template>

          </app-tree>
        </div>

        <ng-container *ngTemplateOutlet="customFooterTemplate"></ng-container>

      </p-tabPanel>
      <p-tabPanel header="Membres" *ngIf="userTypeTabActiveIndex === GROUPS_TAB_INDEX">

        <div class="p-tabpanel-content">

          <div class="p-p-3" style="background: #ddd">
            <button pButton class="p-button-secondary" icon="pi pi-search"></button>
            <input class="p-inputtext" type="text" placeholder="Rechercher un membre" [disabled]="!selectedRightsGroup"
                   [(ngModel)]="memberSearchInput" (ngModelChange)="filterAdministrativesFromRightsGroupByName($event)">
            <button *ngIf="memberSearchInput" pButton pTooltip="Réinitialiser le filtre"
                    icon="pi pi-times" class="p-button-danger p-ml-2" (click)="onResetRightsGroupMembersSearchInput()"></button>
          </div>

          <p-dataView [value]="filteredMembersFromRightsGroup" layout="list" class="fullHeight"
                      [emptyMessage]="'Aucun membre pour le groupe sélectionné.'">
            <ng-template pTemplate="header">
              <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                <div class="p-mb-2 p-mb-md-0">
                  <span class="softMarginRight">Membres : {{ filteredMembersFromRightsGroup.length }}</span>
                  <span *ngIf="hasRightToEdit && selectedRightsGroup" style="padding-left: 50px">
                    <p-autoComplete [(ngModel)]="selectedAdministrative"
                                    [suggestions]="filteredAdministratives" [minLength]="1"
                                    placeholder="Ajouter un membre" field="name" appendTo="body"
                                    (onSelect)="selectUser($event)"
                                    (completeMethod)="searchAdministrative($event)">
                      <ng-template let-adm pTemplate="item">
                        <div class="rightsGroupMemberHeader">{{adm.civility}} {{adm.name}} {{adm.firstname}}</div>
                        <div>{{adm.service}} - {{adm.email}}</div>
                      </ng-template>
                    </p-autoComplete>
                  </span>
                </div>
                <div *ngIf="!hasRightToEdit" class="p-ml-auto centerContent isGroupMember">Vous ne pouvez pas modifier les membres de votre groupe</div>
              </div>
            </ng-template>
            <ng-template let-adm pTemplate="listItem" style="display: flex; flex-wrap: wrap;">
              <div *ngIf="hasRightsGroupMembersToSave && (!selectedRightsGroupsCurrentMemberIds.includes(adm.uid) || selectedMemberIdsFromRightsGroupToRemove.includes(adm.uid))"
                   [style]="{'flex-basis':'4%','background-color':selectedMemberIdsFromRightsGroupToRemove.includes(adm.uid) ? '#e91224' : '#34A835'}"></div>
              <div class="middlePadding fullWidth"
                   [style]="{'flex-basis': hasRightsGroupMembersToSave && (!selectedRightsGroupsCurrentMemberIds.includes(adm.uid) || selectedMemberIdsFromRightsGroupToRemove.includes(adm.uid)) ? '96%' : '100%'}">
                <div class="p-d-flex" >
                  <div class="p-mr-2">
                    <span class="p-mr-2 rightsGroupMemberHeader">{{adm.civility}} {{adm.name}} {{adm.firstname}}</span><br>
                    <span class="p-mr-2">{{adm.service}} - {{adm.email}}</span>
                  </div>
                  <div class="p-ml-auto centerContent">
                    <button pButton type="button" icon="pi pi-eye" pTooltip="Voir les droits de l'utilisateur" (click)="showUserRightsDialog(adm)"></button>
                    <button *ngIf="hasRightToEdit" pButton type="button" class="p-button-danger p-ml-2" icon="pi pi-times" pTooltip="Retirer ce membre du groupe de droits" (click)="removeMemberFromRightsGroup(adm)"></button>
                  </div>
                </div>
              </div>
            </ng-template>
          </p-dataView>

        </div>

        <div class="p-d-flex softPaddingTop p-tree-bottom-buttons">
          <div *ngIf="hasRightsGroupMembersToSave && hasRightToEdit" class="p-ml-auto centerContent">
            <button pButton [label]="mainLayout.getRightAsideVisibility() ? '' : 'Réinitialiser'" class="p-button-secondary p-mr-2" type="button" icon="pi pi-times" pTooltip="Annuler les modifications" tooltipPosition="top" (click)="reloadSelectedRightsGroupMembers()"></button>
            <button pButton [label]="mainLayout.getRightAsideVisibility() ? '' : 'Enregistrer'" class="p-button-success" type="button" icon="pi pi-save" pTooltip="Enregistrer ces membres" tooltipPosition="top" (click)="saveMembersFromRightsGroup()"></button>
          </div>
        </div>

        <div *ngIf="areMembersLoading" class="fullHeight centerContent" style="background-color: rgba(100, 100, 100, 0.5)">
          <app-spinner></app-spinner>
        </div>

      </p-tabPanel>
      <p-tabPanel header="Groupes" *ngIf="userTypeTabActiveIndex === USERS_DETAILS_TAB_INDEX">

        <div class="p-tabpanel-content">

          <div class="p-p-3" style="background: #ddd">
            <button pButton class="p-button-secondary" icon="pi pi-search"></button>
            <input class="p-inputtext" type="text" placeholder="Rechercher un groupe" [disabled]="!selectedUserToDisplay"
                   [(ngModel)]="rightsGroupSearchInput" (ngModelChange)="filterRightsGroupsFromMemberByName($event)">
            <button *ngIf="rightsGroupSearchInput" pButton pTooltip="Réinitialiser le filtre"
                    icon="pi pi-times" class="p-button-danger p-ml-2" (click)="onResetRightsGroupSearchInput()"></button>
          </div>

          <p-dataView [value]="filteredRightsGroupsFromMember" layout="list" class="fullHeight"
                      [emptyMessage]="'Aucun groupe pour le membre sélectionné.'">
            <ng-template pTemplate="header">
              <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                <div class="p-mb-2 p-mb-md-0">
                  <span class="softMarginRight">Groupes : {{ filteredRightsGroupsFromMember.length }}</span>
                  <span *ngIf="selectedUserToDisplay" style="padding-left: 50px">
                    <p-autoComplete [(ngModel)]="selectedGroup"
                                    [suggestions]="filteredGroups" [minLength]="1"
                                    placeholder="Ajouter un groupe" field="rights_group_name" appendTo="body"
                                    (onSelect)="selectRightsGroup($event)"
                                    (completeMethod)="searchRightsGroup($event)">
                      <ng-template let-rg pTemplate="item">
                        <div class="rightsGroupMemberHeader">{{rg.rights_group_name}}</div>
                      </ng-template>
                    </p-autoComplete>
                  </span>
                </div>
              </div>
            </ng-template>
            <ng-template let-rg pTemplate="listItem" style="display: flex; flex-wrap: wrap;">
              <div *ngIf="hasMemberRightsGroupsToSave && (!selectedMemberCurrentRightsGroupIds.includes(rg.rights_group_id) || selectedRightsGroupIdsFromMemberToRemove.includes(rg.rights_group_id))"
                   [style]="{'flex-basis':'4%','background-color':selectedRightsGroupIdsFromMemberToRemove.includes(rg.rights_group_id) ? '#e91224' : '#34A835'}"></div>
              <div class="middlePadding fullWidth"
                   [style]="{'flex-basis': hasMemberRightsGroupsToSave && (!selectedMemberCurrentRightsGroupIds.includes(rg.rights_group_id) || selectedRightsGroupIdsFromMemberToRemove.includes(rg.rights_group_id)) ? '96%' : '100%'}">
                <div class="p-d-flex" >
                  <div class="p-mr-2">
                    <span class="p-mr-2 rightsGroupMemberHeader">{{rg.rights_group_name}}</span>
                  </div>
                  <div class="p-ml-auto centerContent">
                    <button *ngIf="rg.has_right_to_edit" pButton type="button" class="p-button-danger p-ml-2" icon="pi pi-times" pTooltip="Retirer ce groupe du membre" (click)="removeRightsGroupFromMember(rg)"></button>
                  </div>
                </div>
              </div>
            </ng-template>
          </p-dataView>

        </div>

        <div class="p-d-flex softPaddingTop p-tree-bottom-buttons">
          <div *ngIf="hasMemberRightsGroupsToSave" class="p-ml-auto centerContent">
            <button pButton [label]="mainLayout.getRightAsideVisibility() ? '' : 'Réinitialiser'" class="p-button-secondary p-mr-2" type="button" icon="pi pi-times" pTooltip="Annuler les modifications" tooltipPosition="top" (click)="reloadSelectedMemberRightsGroups()"></button>
            <button pButton [label]="mainLayout.getRightAsideVisibility() ? '' : 'Enregistrer'" class="p-button-success" type="button" icon="pi pi-save" pTooltip="Enregistrer ces groupes" tooltipPosition="top" (click)="saveRightsGroupsFromMember()"></button>
          </div>
        </div>

        <div *ngIf="areMembersLoading" class="fullHeight centerContent" style="background-color: rgba(100, 100, 100, 0.5)">
          <app-spinner></app-spinner>
        </div>

      </p-tabPanel>
    </p-tabView>
    <span *ngIf="selectedUserToDisplay && userTypeTabActiveIndex === USERS_DETAILS_TAB_INDEX" class="selectedRightsGroup">{{selectedUserToDisplay.civility}} {{selectedUserToDisplay.name}} {{selectedUserToDisplay.firstname}}</span>
    <span *ngIf="selectedRightsGroup && userTypeTabActiveIndex === GROUPS_TAB_INDEX" class="selectedRightsGroup">{{selectedRightsGroup.rights_group_name}}</span>
  </as-split-area>

  <ng-template #rightsButton>
    <div class="p-ml-auto centerContent">
      <div *ngIf="selectedRightsGroupRights" class="p-mr-2 centerContent"><button class="p-button-help" pButton type="button" icon="pi pi-window-maximize" [pTooltip]="'Tout déplier\n(cette opération peut prendre un certain temps)'" (click)="expandAllRightsFamilies()"></button></div>
      <div *ngIf="selectedRightsGroupRights" class="p-mr-2 centerContent"><button class="p-button-help" pButton type="button" icon="pi pi-window-minimize" pTooltip="Tout replier" (click)="collapseAllRightsFamilies()"></button></div>
      <div *ngIf="selectedRightsGroupHasRight && selectedRightsGroupHasRight.length === 1 && selectedRightsGroupHasRight[0].type !== 'family'" class="p-mr-2 centerContent"><button class="p-button-secondary" pButton type="button" icon="pi pi-clock" pTooltip="Consulter l'historique de modification de ce droit" (click)="showRightHistoryDialog(selectedRightsGroupHasRight[0])"></button></div>
      <div *ngIf="!hasRightToEdit && selectedRightsGroup" style="white-space: nowrap" class="p-ml-auto centerContent isGroupMember">Vous ne pouvez pas modifier les droits de votre groupe</div>
    </div>
  </ng-template>

  <ng-template #customFooterTemplate>
    <div class="p-d-flex softPaddingTop p-tree-bottom-buttons">
      <div *ngIf="hasRightToEdit && selectedRightsGroupHasRight && selectedRightsGroupHasRight.length > 0 && userTypeTabActiveIndex === GROUPS_TAB_INDEX" class="centerContent">
        <p class="p-mr-2 p-text-bold">Actions sur la sélection</p>
        <app-tristate-checkbox class="centerContent" pTooltip="Allouer le droit de lecture à tous les droits sélectionnés" tooltipPosition="top" [(ngModel)]="allRights.can_read" id="canAllRead" (click)="affectValueToAllSelectedValues($event, 'can_read')"></app-tristate-checkbox>
        <label pTooltip="Allouer le droit de lecture à tous les droits sélectionnés" tooltipPosition="top" for="canAllRead"><span class="pi pi-eye miniMarginLeft middleMarginRight rightsIcon"></span></label>
        <app-tristate-checkbox *ngIf="rightsTabActiveIndex !== 0" class="centerContent" pTooltip="Allouer le droit de mise à jour à tous les droits sélectionnés" tooltipPosition="top" [(ngModel)]="allRights.can_update" id="canAllUpdate" (click)="affectValueToAllSelectedValues($event, 'can_update')"></app-tristate-checkbox>
        <label *ngIf="rightsTabActiveIndex !== 0" pTooltip="Allouer le droit de mise à jour à tous les droits sélectionnés" tooltipPosition="top" for="canAllUpdate"><span class="pi pi-pencil miniMarginLeft middleMarginRight rightsIcon"></span></label>
        <app-tristate-checkbox *ngIf="rightsTabActiveIndex !== 0" class="centerContent" pTooltip="Allouer le droit de suppression à tous les droits sélectionnés" tooltipPosition="top" [(ngModel)]="allRights.can_delete" id="canAllDelete" (click)="affectValueToAllSelectedValues($event, 'can_delete')"></app-tristate-checkbox>
        <label *ngIf="rightsTabActiveIndex !== 0" pTooltip="Allouer le droit de suppression à tous les droits sélectionnés" tooltipPosition="top" for="canAllDelete"><span class="pi pi-trash miniMarginLeft middleMarginRight rightsIcon"></span></label>
        <app-tristate-checkbox class="centerContent" pTooltip="Allouer le droit de transmission à tous les droits sélectionnés" tooltipPosition="top" [(ngModel)]="allRights.can_transmit" id="canAllTransmit" (click)="affectValueToAllSelectedValues($event, 'can_transmit')"></app-tristate-checkbox>
        <label pTooltip="Allouer le droit de transmission à tous les droits sélectionnés" tooltipPosition="top" for="canAllTransmit"><span class="pi pi-directions miniMarginLeft middleMarginRight rightsIcon"></span></label>
      </div>

      <div *ngIf="hasAnyRightToSave" class="p-ml-auto p-d-flex floatRight">
        <div *ngIf="selectedRightsGroupRights" class="p-mr-2"><button pButton [label]="mainLayout.getRightAsideVisibility() ? '' : 'Réinitialiser'" type="button" icon="pi pi-times" class="p-button-secondary" (click)="resetTreesRights()"></button></div>
        <div *ngIf="hasAnyRightToSave" class="p-mr-2"><button pButton [label]="mainLayout.getRightAsideVisibility() ? '' : 'Enregistrer'" type="button" icon="pi pi-save" class="p-button-success" (click)="saveRights()"></button></div>
      </div>
    </div>
  </ng-template>

</as-split>

<p-dialog header="Organigramme des groupes de droits" [(visible)]="displayRightsGroupOrganizationChartDialog" [modal]="true" [style]="{width: '95%'}" [baseZIndex]="10000"
          [draggable]="false" [resizable]="false">
<!-- Le *ngIf ici est nécessaire afin de forcer le composant p-organizationChart à redessiner ses enfants.
Dans le cas contraire, il n'est pas possible de déplier les noeuds lors de second affichage de la dialog.-->
  <ng-template pTemplate="header">
          <div class="p-ml-auto" style="min-width: 85px">
    <button class="p-mr-2 p-button-help" pButton type="button" icon="pi pi-window-maximize" [pTooltip]="'Tout déplier\n(cette opération peut prendre un certain temps)'" (click)="expandOrganizationChartFamilies()"></button>
    <button class="p-mr-4 p-button-help" pButton type="button" icon="pi pi-window-minimize" pTooltip="Tout replier" (click)="collapseOrganizationChartFamilies()"></button>
          </div>
  </ng-template>
  <p-organizationChart #chart
                       *ngIf="displayRightsGroupOrganizationChart"
                       selectionMode="single" [(selection)]="rightsGroupOrganizationChartSelectedNode"
                       [value]="allRightsGroupsTree" [preserveSpace]="false"
                       (onNodeSelect)="onRightsGroupOrganizationChartNodeSelected($event)">

    <ng-template let-node pTemplate="rightsGroupWithoutRights">
      <div style="color: #999">
        <span class="pi pi-ban miniMarginRight"></span>
        <span>{{node.label}}</span>
      </div>
    </ng-template>

    <ng-template style="background: #007ad9" let-node pTemplate="rightsGroupWithRights">
      <span style="font-weight:bold; min-width: 200px">{{node.label}}</span>
      <div style="color: #107D11">éditable</div>
    </ng-template>

    <ng-template let-node pTemplate="rightsGroupMember">
      <span class="isGroupMember">{{node.label}}</span>
      <div>Membre de ce groupe</div>
    </ng-template>

    <ng-template let-node pTemplate="rightsGroupMemberWithRights">
      <span class="isGroupMember">{{node.label}}</span>
      <div>Membre de ce groupe</div>
      <div style="color: #107D11">éditable</div>
    </ng-template>
  </p-organizationChart>
</p-dialog>

<p-dialog header="Droits de l'utilisateur" [(visible)]="displaySelectedMemberRights" [modal]="true" [style]="{width: '75vw'}" [baseZIndex]="10000"
          [draggable]="false" [resizable]="false" (onHide)="onSelectedMemberRightsDialogHide()">

  <div class="p-d-flex">
    <div class="p-mr-2">
      <div class="p-col-12 rightsGroupMemberHeader">{{selectedUserToDisplay?.civility}} {{selectedUserToDisplay?.name}} {{selectedUserToDisplay?.firstname}}</div>
      <div class="p-col-12">{{selectedUserToDisplay?.service}} - {{selectedUserToDisplay?.function}} - {{selectedUserToDisplay?.location}}</div>
      <div class="p-col-12">Tel : {{selectedUserToDisplay?.telephone}} - {{selectedUserToDisplay?.mobile}} - Email : {{selectedUserToDisplay?.email}}</div>
    </div>
    <div class="p-ml-auto" style="min-width: 85px">
      <button class="p-mr-2 p-button-help" pButton type="button" icon="pi pi-window-maximize" [pTooltip]="'Tout déplier\n(cette opération peut prendre un certain temps)'" (click)="expandSelectedMemberRightsFamilies()"></button>
      <button class="p-button-help" pButton type="button" icon="pi pi-window-minimize" pTooltip="Tout replier" (click)="collapseSelectedMemberRightsFamilies()"></button>
    </div>
  </div>

  <app-tree class="leftSideScroll"
            [value]="selectedMemberRightsLogs" scrollHeight="603.28px"
            [loading]="loadingRightsGroupHasRights"
            [emptyMessage]="selectedRightsGroup && selectedRightsGroup !== null ? 'Aucun droit pour le groupe sélectionné' : 'Veuillez sélectionner un groupe de droits'"
            (onNodeSelect)="refreshSelectedRightsMultiSelectors()"
            (onNodeUnselect)="refreshSelectedRightsMultiSelectors()">
    <ng-template let-node pTemplate="family">
      <div>
        <span class="rightsFamilyHeader">{{ node.label }}</span>
      </div>
    </ng-template>

    <ng-template let-node pTemplate="right">
      <div class="p-d-flex">
        <div class="p-mr-2" style="font-weight: bold; font-size: 1.2em">{{ node.label }}</div>
        <div class="p-ml-auto">
            <span title="Consultation" [style]="{ 'color': node.data.can_read ? '#107D11' : '#A80000', 'text-decoration': node.data.can_read ? '' : 'line-through'}" class="pi pi-eye middleMarginRight rightsIcon"></span>
            <span title="Création / Mise à jour" [style]="{ 'color': node.data.can_update ? '#107D11' : '#A80000', 'text-decoration': node.data.can_update ? '' : 'line-through'}"  class="pi pi-pencil middleMarginRight rightsIcon"></span>
            <span title="Suppression" [style]="{ 'color': node.data.can_delete ? '#107D11' : '#A80000', 'text-decoration': node.data.can_delete ? '' : 'line-through'}"  class="pi pi-trash middleMarginRight rightsIcon"></span>
            <span title="Transmission de ce droit" [style]="{ 'color': node.data.can_transmit ? '#107D11' : '#A80000', 'text-decoration': node.data.can_transmit ? '' : 'line-through'}"  class="pi pi-directions middleMarginRight rightsIcon"></span>
        </div>
      </div>
      <div [style]="{'font-size': '0.9em', 'color' : node.data['textColor'], 'cursor' : 'pointer'}" (click)="onMemberRightGroupSelected(node.data.rights_group_id)">
        Groupe : "{{ node.data.rights_group_name }}"
      </div>
      <hr>
    </ng-template>

    <ng-template let-node pTemplate="schoolRight">
      <div class="p-d-flex">
        <div class="p-mr-2" style="font-weight: bold; font-size: 1.2em">{{ node.label }}</div>
        <div class="p-ml-auto">
          <span title="Consultation" [style]="{ 'color': node.data.can_read ? '#107D11' : '#A80000', 'text-decoration': node.data.can_read ? '' : 'line-through'}" class="pi pi-eye middleMarginRight rightsIcon"></span>
          <span title="Transmission de ce droit" [style]="{ 'color': node.data.can_transmit ? '#107D11' : '#A80000', 'text-decoration': node.data.can_transmit ? '' : 'line-through'}"  class="pi pi-directions middleMarginRight rightsIcon"></span>
        </div>
      </div>
      <div [style]="{'font-size': '0.9em', 'color' : node.data['textColor'], 'cursor' : 'pointer'}" (click)="onMemberRightGroupSelected(node.data.rights_group_id)">
        Groupe : "{{ node.data.rights_group_name }}"
      </div>
      <hr>
    </ng-template>
  </app-tree>
</p-dialog>

<p-dialog [header]="getSaveOrUpdateDialogHeader()" [(visible)]="displayRightsGroupSaveOrUpdateDialog" [modal]="true" [style]="{width: '25vw'}" [baseZIndex]="10000"
          [draggable]="false" [resizable]="false" [closeOnEscape]="true"
          (onHide)="onRightsGroupCreationDialogClose()">

  <div class="p-d-block centerContent">
    <span *ngIf="rightsGroupToSaveOrUpdate" class="p-float-label p-mt-5">
      <input id="float-input-creation" type="text" pInputText class="p-tree-filter p-inputtext p-component p-mr-2"
             [pTooltip]="isCreationRightsGroupNameInvalid() ? 'Le nom du groupe de droits ne peut être vide ou identique au groupe d\'origine' : ''"
             [(ngModel)]="rightsGroupToSaveOrUpdate.rights_group_name"
             [class]="{'ng-invalid': isCreationRightsGroupNameInvalid(), 'ng-dirty': isCreationRightsGroupNameInvalid()}"
             aria-describedby="username2-help">
      <label for="float-input-creation">Nom du groupe de droit</label>
    </span>
  </div>

  <ng-template pTemplate="footer">
    <button pButton type="button" icon="pi pi-save" [label]="(rightsGroupToSaveOrUpdate.rights_group_id ? 'Modifier' : 'Créer').concat(' le groupe')" class="p-button-success" (click)="createOrUpdateRightsGroup(rightsGroupToSaveOrUpdate)"></button>
  </ng-template>

</p-dialog>

<p-dialog header="Duplication d'un groupe de droits" [(visible)]="displayRightsGroupDuplicationDialog" [modal]="true" [style]="{width: '80vw'}" [baseZIndex]="10000"
          [draggable]="false" [resizable]="false" [closeOnEscape]="true"
          (onHide)="onRightsGroupDuplicationDialogClose()">

  <div class="p-d-flex centerContent">
    <span *ngIf="rightsGroupToDuplicate" class="p-float-label p-mt-5">
      <input id="float-input-duplication" type="text" pInputText
             [pTooltip]="isDuplicationRightsGroupNameInvalid() ? 'Le nom du groupe de droits ne peut être vide ou identique au groupe d\'origine' : ''"
             [(ngModel)]="rightsGroupToDuplicate.rights_group_name"
             [class]="{'ng-invalid': isDuplicationRightsGroupNameInvalid(), 'ng-dirty': isDuplicationRightsGroupNameInvalid()}"
             aria-describedby="username2-help">
      <label for="float-input-duplication">Nom du groupe de droit</label>
    </span>
  </div>

  <h4>Veuillez sélectionner les membres à importer</h4>

  <p-table [value]="filteredMembersFromRightsGroup" [(selection)]="selectedRightsGroupsMembersToDuplicate" dataKey="uid">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th>Nom</th>
        <th>Fonctions</th>
        <th>Coordonnées</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-adm>
      <tr>
        <td>
          <p-tableCheckbox [value]="adm"></p-tableCheckbox>
        </td>
        <td class="rightsGroupMemberHeader">{{adm.civility}} {{adm.name}} {{adm.firstname}}</td>
        <td>{{adm.service}} - {{adm.function}} - {{adm.location}}</td>
        <td>Tel : {{adm.telephone}} - {{adm.mobile}} - Email : {{adm.email}}</td>
      </tr>
    </ng-template>
  </p-table>

  <hr/>

  <h4>Veuillez sélectionner les droits à dupliquer</h4>

  <p-table [value]="rightsGroupToDuplicateRights" [(selection)]="selectedRightsGroupsRightsToDuplicate" dataKey="right_id">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th>Nom</th>
        <th>Droits</th>
        <th>Groupe de droits</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-right>
      <tr>
        <td>
          <p-tableCheckbox [value]="right"></p-tableCheckbox>
        </td>
        <td>{{right.right_desc}}</td>
        <td #container>
          <span *ngIf="right.can_read" [appendTo]="container" [pTooltip]="'Peut voir'" class="pi pi-eye softMarginLeft" style="font-size: 1.2rem"></span>
          <span *ngIf="right.can_update" [appendTo]="container" [pTooltip]="'Peut créer / mettre à jour'" class="pi pi-pencil softMarginLeft" style="font-size: 1.2rem"></span>
          <span *ngIf="right.can_delete" [appendTo]="container" [pTooltip]="'Peut supprimer'" class="pi pi-trash softMarginLeft" style="font-size: 1.2rem"></span>
          <span *ngIf="right.can_transmit" [appendTo]="container" [pTooltip]="'Peut transmettre ce droit'" class="pi pi-directions softMarginLeft" style="font-size: 1.2rem"></span>
        </td>
        <td>{{right.rights_group_name}}</td>
      </tr>
    </ng-template>
  </p-table>

  <ng-template pTemplate="footer">
    <button pButton type="button" icon="pi pi-save" label="Créer le groupe" class="p-button-success"
            [disabled]="isDuplicationRightsGroupNameInvalid()"
            (click)="duplicateRightsGroup(rightsGroupToDuplicate)"></button>
  </ng-template>

</p-dialog>

<p-dialog header="Suppression d'un groupe de droits" [(visible)]="displayRightsGroupDeleteDialog" [modal]="true" [style]="{width: '40vw'}" [baseZIndex]="10000"
          [draggable]="false" [resizable]="false" [closeOnEscape]="true"
          (onHide)="onRightsGroupDeleteDialogClose()">

  <div>
    <div *ngIf="rightsGroupToSaveOrUpdate" class="p-p-3">Confirmez-vous la suppression du groupe de droits <span class="p-text-bold">"{{rightsGroupToSaveOrUpdate.rights_group_name}}"</span> ?</div>
    <div class="p-p-3 p-text-bold">Cette action va restreindre les droits de ses membres, qui ne pourraient ne plus avoir accès à certaines fonctionnalités.</div>
    <div class="p-p-3">Cette opération ne peut pas être annulée.</div>
  </div>

  <ng-template pTemplate="footer">
    <button pButton type="button" icon="pi pi-check" label="Confirmer" (click)="deactivateRightsGroup(rightsGroupToSaveOrUpdate)"></button>
    <button pButton type="button" icon="pi pi-times" label="Annuler" (click)="onRightsGroupDeleteDialogClose()"></button>
  </ng-template>

</p-dialog>

<p-dialog header="Historique des modifications d'un droit" #rightsHistoryDialog
          [(visible)]="displayLogsDialog" [modal]="true" [style]="{width: '75vw'}" [baseZIndex]="10000"
          [draggable]="false" [resizable]="false" [closeOnEscape]="true">

  <div *ngIf="selectedRightsGroupHasRight && selectedRightsGroupHasRight.length > 0" class="p-mb-3">
    <div *ngIf="selectedRightsGroup" class="rightsFamilyHeader p-mb-2">Groupe de droits : {{ selectedRightsGroupHasRight[0].data.rights_group_name }}</div>
    <div [class]="selectedUserToDisplay ? 'rightsFamilyHeader p-mb-2' : ''">Droit concerné : {{ selectedRightsGroupHasRight[0].data.right_name }}</div>
    <div *ngIf="rightsGroupHasRightHistories.length > 0 && selectedUserToDisplay">
      <button pButton class="p-button-secondary" icon="pi pi-search"></button>
      <input class="p-inputtext" type="text" placeholder="Rechercher un groupe"
             [(ngModel)]="rightsGroupLogsSearchInput" (ngModelChange)="filterRightsGroupFromLogsByName($event)">
      <button *ngIf="rightsGroupLogsSearchInput" pButton pTooltip="Réinitialiser le filtre"
              icon="pi pi-times" class="p-button-danger p-ml-2" (click)="onResetRightsGroupLogsSearchInput()"></button>
    </div>
  </div>

  <div *ngIf="rightsGroupHasRightHistories && rightsGroupHasRightHistories.length > 0; else noRightHistories" class="p-d-flex centerContent">
    <p-table [value]="filteredRightsGroupHasRightHistories">
      <ng-template pTemplate="header">
        <tr>
          <th>Date</th>
          <th>Mis à jour par</th>
          <th *ngIf="selectedUserToDisplay">Groupe de droits</th>
          <th>Ancienne valeur</th>
          <th>Nouvelle valeur</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-right>
        <tr>
          <td>{{right.update_date | date : 'medium'}}</td>
          <td>{{right.civility}} {{right.firstname}} {{right.name}}</td>
          <td *ngIf="selectedUserToDisplay">{{right.rights_group_name}}</td>
          <td *ngIf="right.previousValue">
            <div class="centerContent">
              <span title="Consultation" [style]="{ 'color': right.previousValue.can_read ? '#107D11' : '#A80000', 'text-decoration': right.previousValue.can_read ? '' : 'line-through'}" class="pi pi-eye middleMarginRight rightsIcon"></span>
              <span title="Création / Mise à jour" [style]="{ 'color': right.previousValue.can_update ? '#107D11' : '#A80000', 'text-decoration': right.previousValue.can_update ? '' : 'line-through'}"  class="pi pi-pencil middleMarginRight rightsIcon"></span>
              <span title="Suppression" [style]="{ 'color': right.previousValue.can_delete ? '#107D11' : '#A80000', 'text-decoration': right.previousValue.can_delete ? '' : 'line-through'}"  class="pi pi-trash middleMarginRight rightsIcon"></span>
              <span title="Transmission de ce droit" [style]="{ 'color': right.previousValue.can_transmit ? '#107D11' : '#A80000', 'text-decoration': right.previousValue.can_transmit ? '' : 'line-through'}"  class="pi pi-directions middleMarginRight rightsIcon"></span>
            </div>
          </td>
          <td *ngIf="!right.previousValue"></td>
          <td *ngIf="right.newValue">
            <div class="centerContent">
              <span title="Consultation" [style]="{ 'color': right.newValue.can_read ? '#107D11' : '#A80000', 'text-decoration': right.newValue.can_read ? '' : 'line-through'}" class="pi pi-eye middleMarginRight rightsIcon"></span>
              <span title="Création / Mise à jour" [style]="{ 'color': right.newValue.can_update ? '#107D11' : '#A80000', 'text-decoration': right.newValue.can_update ? '' : 'line-through'}"  class="pi pi-pencil middleMarginRight rightsIcon"></span>
              <span title="Suppression" [style]="{ 'color': right.newValue.can_delete ? '#107D11' : '#A80000', 'text-decoration': right.newValue.can_delete ? '' : 'line-through'}"  class="pi pi-trash middleMarginRight rightsIcon"></span>
              <span title="Transmission de ce droit" [style]="{ 'color': right.newValue.can_transmit ? '#107D11' : '#A80000', 'text-decoration': right.newValue.can_transmit ? '' : 'line-through'}"  class="pi pi-directions middleMarginRight rightsIcon"></span>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <ng-template #noRightHistories>
    <div class="centerContent p-m-3">
      Pas d'historique pour ce droit au sein de ce groupe de droits
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton label="OK" (click)="rightsHistoryDialog.close($event)"></button>
  </ng-template>

</p-dialog>

<p-dialog header="Historique des membres d'un groupe de droits" #membersHistoryDialog
          [(visible)]="displayMembersHistoryDialog" [modal]="true" [style]="{width: '75vw'}" [baseZIndex]="10000"
          [draggable]="false" [resizable]="false" [closeOnEscape]="true">

  <div *ngIf="selectedRightsGroup" class="p-mb-3">
    <div class="rightsFamilyHeader p-mb-2">Groupe de droits : {{ selectedRightsGroup.data.rights_group_name }}</div>
  </div>

  <div *ngIf="rightsGroupMemberLogs && rightsGroupMemberLogs.length > 0; else noMemberHistories" class="p-d-flex centerContent">
    <p-table [value]="rightsGroupMemberLogs">
      <ng-template pTemplate="header">
        <tr>
          <th>Date</th>
          <th>Membre</th>
          <th>Action</th>
          <th>Mis à jour par</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-member>
        <tr>
          <td>{{member.upd_date | date : 'medium'}}</td>
          <td>{{member.updated_user_civility}} {{member.updated_user_firstname}} {{member.updated_user_name}}</td>
          <td>{{member.log_action === 'ADD' ? 'Ajout' : 'Suppression'}}</td>
          <td>{{member.civility}} {{member.firstname}} {{member.name}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <ng-template #noMemberHistories>
    <div class="centerContent p-m-3">
      Pas d'historique de membres pour ce groupe de droits
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton label="OK" (click)="membersHistoryDialog.close($event)"></button>
  </ng-template>

</p-dialog>

<div *ngIf="loading" class="centerContent" style="position: absolute; background: rgba(100, 100, 100, 0.5); top: 0; bottom: 0; left: 0; right: 0">
  <app-spinner></app-spinner>
</div>
