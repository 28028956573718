import {Component, OnInit, Output, Input, EventEmitter, ViewChild, TemplateRef} from '@angular/core';
import {AutoComplete} from 'primeng/autocomplete';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit {

  //#region child
  @ViewChild('autoComplete') autoComplete: AutoComplete;
  //#endregion

  //#region template
  @Input() rowTemplate: TemplateRef<any>;
  //#endregion

  //#region emitters
  @Output() dataSelected = new EventEmitter<any>();
  @Output() filterChanged = new EventEmitter<string>();
  //#endregion

  dataValues: any[] = [];
  //#region input
  @Input() width = 300;
  @Input() fieldName = 'title';
  @Input() placeHolder = '';
  @Input()
  get data() { return this.dataValues; }
  set data(val) {
    this.dataValues = val;
    this.suggestions = [...this.suggestions];
  }
  //#endregion

  mainDataInput: any;
  textFilter = '';
  dropdownEnable = false;
  suggestions = [];

  constructor() { }

  ngOnInit() {
  }

  onSearchData() {
    this.updateDropdownVisibility(true);
    this.filterChanged.emit(this.textFilter);
  }

  onSelectData(selectedData: any) {
    this.updateDropdownVisibility(false);
    this.dataSelected.emit(selectedData);
    this.textFilter = selectedData[this.fieldName];
  }

  displayDropdown() {
    return this.textFilter.length > 1 && this.dropdownEnable;
  }

  updateDropdownVisibility(state: boolean) {
    this.dropdownEnable = state;
  }
}
