import { Component, OnInit, Input, EventEmitter, Output, ViewChild, AfterViewInit } from '@angular/core';
import { DropdownOption } from 'src/app/models/dropdown-option';
import { Dropdown } from 'primeng/dropdown/dropdown';

@Component({
  selector: 'app-combo-box',
  templateUrl: './combo-box.component.html',
  styleUrls: ['./combo-box.component.scss']
})
export class ComboBoxComponent implements OnInit, AfterViewInit {

  @ViewChild('dropdown') dropdown: Dropdown;

  //#region properties
  labelClasses = 'p-col-4';
  dropdownClasses = 'p-col-7';
  //#endregion

  //#region emitters
  @Output() selectedOptionChanged = new EventEmitter<any>();
  @Output() selectedOptionCleared = new EventEmitter<void>();
  //#endregion

  @Input() showClear = true;

  //#region label
  private mLabel: string;
  public get label(): string {
    return this.mLabel;
  }
  @Input('label')
  public set label(value: string) {
    this.mLabel = value;
  }
  //#endregion

  //#region labelWeight
  private mLabelWeight: number;
  public get labelWeight(): number {
    return this.mLabelWeight;
  }
  @Input('labelWeight')
  public set labelWeight(value: number) {
    this.mLabelWeight = (value < 1 || value > 10) ? 4 : value;

    this.labelClasses = 'p-col-' + this.mLabelWeight;
    this.dropdownClasses = 'p-col-' + (12 - this.mLabelWeight - 1);
  }
  //#endregion

  //#region width
  private mWidth: number;
  public get width(): number {
    return this.mWidth;
  }
  @Input('width')
  public set width(value: number) {
    this.mWidth = value;
  }
  //#endregion

  //#region options
  private mOptions: any[] = [];
  public get options(): any[] {
    return this.mOptions;
  }
  @Input('options')
  public set options(value: any[]) {
    this.mOptions = value;
    if (this.mSelectedOption !== undefined && this.mSelectedOption !== null) {
      this.selectOption(this.mSelectedOption);
    }
  }
  //#endregion

  //#region selectedOption
  private mSelectedOption: DropdownOption = null;

  @Output() selectedOptionChange = new EventEmitter<any>();

  public get selectedOption(): DropdownOption {
    return this.mSelectedOption;
  }
  @Input('selectedOption')
  public set selectedOption(value: DropdownOption) {
    this.mSelectedOption = value;

    if (this.dropdown !== undefined) {
      this.selectOption(value);
      this.selectedOptionChange.emit(value);
    }
  }
  //#endregion

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.selectOption(this.selectedOption);
    }, 0);
  }

  //#region methods
  selectOption(newValue: DropdownOption) {
    if (this.dropdown !== undefined && this.dropdown !== null) {
      this.dropdown.selectedOption = this.options.find(o => o.value === newValue);
    }
  }

  clearSelectedOption() {
    this.selectOption(null);
    this.selectedOptionCleared.emit();
  }
  //#endregion

  //#region callbacks
  onSelectionChanged(selection: any) {
    this.selectedOption = selection.value;
    if (selection !== null) {
      this.selectedOptionChanged.emit(selection.value);
    } else {
      this.selectedOptionCleared.emit();
    }
  }
  //#endregion

}
