import {AbstractPageComponent} from './abstract-page.component';
import {SelectedParams} from '../models/query/selected-params';
import {RequestData} from '../models/query/request-data';
import {environment} from '../../environments/environment';
import {Canceler} from '../common/promises/request-canceler';
import {ParamsData} from '../models/query/params-data';
import {BaseService} from '../services/base.service';
import {MessageService} from 'primeng/api';
import {GridTypes} from '../components/grid/basic-grid/basic-grid.component';
import {SchoolYearSemesterValues} from '../models/school-year-semester-values';
import {PromotionDTO} from '../models/schools/promotion-dto';
import {OptionDTO} from '../models/schools/option-dto';
import {ClassDTO} from '../models/schools/class-dto';
import {SpecializationDTO} from '../models/schools/specialization-dto';
import {QueryData} from '../models/query/query-data';

export abstract class AbstractRequestComponent extends AbstractPageComponent {

  YEAR = 'year';
  SCHOOL = 'school';
  SEMESTER = 'semester';
  PROMOTION = 'promotion';
  SESSION = 'session';
  OPTION = 'option';
  CLASS = 'class';
  SPECIALIZATION = 'specialization';

  ROW_INDEX = 'rowIndex';

  gridTypes = GridTypes;
  canceler: Canceler = new Canceler();

  staticFieldName: Map<string, string> = new Map([
    [this.YEAR, 'Année scolaire'],
    [this.SCHOOL, 'École'],
    [this.SEMESTER, 'Semestre'],
    [this.PROMOTION, 'Promotion'],
    [this.OPTION, 'Option / Filière'],
    [this.CLASS, 'Classe / Groupe'],
    [this.SPECIALIZATION, 'Spécialisation']]);
  requestData: RequestData;
  selectedParams: SelectedParams[] = [];

  responseData: any;
  inputsList: Map<string, string> = new Map([]);

  optionsList: Map<string, any> = new Map([]);

  isLoading = false;
  isReady = false;

  mandatoryParamsHaveBeenModified = false;

  selectedData: any[] = [];

  constructor(public baseService: BaseService,
              public messageService: MessageService) {
    super();

  }

  abstract initQuery();

  orderQueries() {
    return this.requestData.queries.sort((q1, q2) => q1.priority - q2.priority); // on trie par ordre de priorité
  }

  executeQuery() {
    let query: string;
    if (this.requestData.query != null) {
      query = this.OldformatUrl(this.requestData.query);
    } else {
      query = this.getAvailableQuery(this.requestData.queries);
    }
    if (query != null) {

      this.isLoading = true;

      this.baseService.getAll(environment.servicesUrl + query)
        .setCancelAction(this.canceler)
        .then(data => {
          this.selectedData = [];
          this.responseData = data;
          if (this.requestData.dataKey === this.ROW_INDEX) {
            this.setDataIndex();
          }
          this.mandatoryParamsHaveBeenModified = false;
        })
        .catch(console.error) // TODO
        .finally(() => this.isLoading = false);
    }
  }

  private setDataIndex() {
    this.responseData.forEach((data, idx) => data[this.ROW_INDEX] = idx );
  }

  private getAvailableQuery(queries: QueryData[]) {
    let queryIsValid: boolean;
    let url: string;
    let requiredParams: SelectedParams[];
    let errorField: string;
    queries = queries.sort((q1, q2) => q1.priority - q2.priority); // on trie par ordre de priorité

    queries.some(query => { // on boucle sur chaque query

      errorField = null;
      url = query.url;
      requiredParams = [];
      queryIsValid = true;

      query.fields.some(field => { // on verifie que toutes les parametres de la requête courante sont présent
        const param = this.selectedParams.find(selectedParam => selectedParam.field === field.field);

        if (field.is_mandatory && (param == null || param.value == null || param.value.length <= 0) ) {
          errorField = field.field;
          queryIsValid = false;
        }
        if (param != null && param.value != null && param.value.length > 0) {
          requiredParams.push(new SelectedParams(field.field, field.is_mandatory, field.is_request_param, param.value));
        }
        return !queryIsValid; // Si un parametres n'est pas valide on arrete de boucle
      });

      return queryIsValid; // si la requête courante est valide on quitte la boucle pour la garder
    });

    if (queryIsValid) {
      return this.formatUrl(url, requiredParams);
    } else {
      this.displayErrorField(errorField);
      return null;
    }
  }

  protected displayErrorField(fieldError: string) {
    if (fieldError.length > 0) {
      let errorParam = this.staticFieldName.get(fieldError);

      if (errorParam == null) {
        const paramData = this.requestData.params.find(param => param.field === fieldError);
        if (paramData != null) {
          errorParam = paramData.name;
        }
      }

      this.messageService.add({
        severity: 'error',
        summary: 'Le champ "' + errorParam + '" n\'est pas renseigné',
        life: 2000
      });
      return null;
    }
  }

  formatUrl(url: string, requiredParams: SelectedParams[]) {
    let requestIndex = 0;
    requiredParams.forEach(param => {

      if (param.is_request_param) {
        if (requestIndex === 0) {
          url += `?${param.field}=${param.value}`;
        } else {
          url += `&${param.field}=${param.value}`;
        }
        requestIndex++;
      } else {
        url = url.replace(`{${param.field}}`, param.value);
      }
    });

    return url;
  }

  OldformatUrl(url: string) {
    let fieldError = '';
    const requiredParams = this.selectedParams.filter(param => param.is_mandatory || (!param.is_mandatory && param.value !== null));
    let requestIndex = 0;

    requiredParams.forEach(param => {

      if (param.is_mandatory && param.value === null) {
        fieldError = param.field;
        return;
      }

      if (param.is_request_param) {
        if (requestIndex === 0) {
          url += `?${param.field}=${param.value}`;
        } else {
          url += `&${param.field}=${param.value}`;
        }
        requestIndex++;
      } else {
        url = url.replace(`{${param.field}}`, param.value);
      }
    });

    if (fieldError.length > 0) {
      let errorParam = this.staticFieldName.get(fieldError);

      if (errorParam == null) {
        const paramData = this.requestData.params.find(param => param.field === fieldError);
        if (paramData != null) {
          errorParam = paramData.name;
        }
      }

      this.messageService.add({
        severity: 'error',
        summary: 'Le champ "' + errorParam + '" n\'est pas renseigné',
        life: 2000
      });
      return null;
    }
    return url;
  }

  initSelectedParams() {
    this.requestData.staticParams.forEach(param => {
      this.selectedParams.push(
        new SelectedParams(param.field, param.is_mandatory != null ? param.is_mandatory : true, param.is_request_param != null ? param.is_request_param : true, null));
    });

    this.requestData.params.forEach(param => {
      if (param.type === 'boolean') {
        this.selectedParams.push(
          new SelectedParams(param.field, param.is_mandatory != null ? param.is_mandatory : true, param.is_request_param != null ? param.is_request_param : true, 'false', param.staticParams));
      } else {
        this.selectedParams.push(
          new SelectedParams(param.field, param.is_mandatory != null ? param.is_mandatory : true, param.is_request_param != null ? param.is_request_param : true, null, param.staticParams));
      }
    });
  }

  checkIfAllOptionsIsVisible(param: string) {
    if (this.requestData && this.requestData.staticParams) {
      let isVisible = false;
      this.requestData.staticParams.forEach(p => {
        if (p.field.includes(param)) {
          isVisible = p.is_all_options_visible ? p.is_all_options_visible : false;
        }
      });
      return isVisible;
    }
    return false;
  }

  checkIfStaticParamIsPresent(param: string) {
    if (this.requestData && this.requestData.staticParams) {
      let  paramIsPresent = false;
      this.requestData.staticParams.forEach(o => {
        if (o.field.includes(param)) {
          paramIsPresent = true;
        }
      });
      return paramIsPresent;
    }
    return false;
  }

  updateSelectedValueParam(paramField: string, paramValue: string, isStatic: boolean) {
    const selectedValueParam = this.selectedParams.find(param => param.field === paramField);
    if (selectedValueParam != null) {
      selectedValueParam.value = paramValue;
    }
    if (isStatic) {
      this.refreshDynamicParams();
    }

    if (this.getMinimumRequirementsFields().indexOf(paramField) !== -1) {
      if (this.responseData != null && this.responseData.length > 0) {
        this.mandatoryParamsHaveBeenModified = true;
      }
      this.selectedData = [];
    }
  }

  refreshDynamicParams() {
    this.requestData.params.forEach(param => {
      if (param.staticData != null) {
        this.optionsList[param.field] = param.staticData;
      }
      else {
        if (param.type === 'dropdown' && this.isDynamicParamReady(param)) {
          this.resetParamSelectedValue(param);

          const query = this.formatSourceUrl(param);

          if (query != null) {
            this.baseService.getAll(environment.servicesUrl + query)
              .setCancelAction(this.canceler)
              .then(data => {
                this.optionsList[param.field] = data;
              })
              .catch(console.error) // TODO
              .finally(() => this.isLoading = false);
          }
        }
      }

      if (param.type === 'suggestbox' && this.isDynamicParamReady(param)) {
        this.inputsList[param.field] = null;
        this.resetParamSelectedValue(param);
      }
    });
  }

  isDynamicParamReady(param: ParamsData) {
    let isDynamicParamReady = true;

    if (param.staticParams != null) {

      param.staticParams.forEach(staticParam => {
        const optionalSelectedParam = this.selectedParams.find(selectedParam => selectedParam.field === staticParam.field);
        if (optionalSelectedParam == null) {
          isDynamicParamReady = false;
        }
        if (optionalSelectedParam.is_mandatory && optionalSelectedParam.value == null) {
          isDynamicParamReady = false;
        }
        if (!optionalSelectedParam.is_request_param && optionalSelectedParam.value == null) {
          isDynamicParamReady = false;
        }
      });
    }

    return isDynamicParamReady;
  }

  resetParamSelectedValue(param: ParamsData) {
    const optionalSelectedParam = this.selectedParams.find(selectedParam => selectedParam.field === param.field);

    if (optionalSelectedParam != null) {
      optionalSelectedParam.value = null;
    }
  }

  formatSourceUrl(param: ParamsData) {
    let fieldError = '';
    let requestIndex = 0;
    let url = param.source;

    if (param.staticParams != null) {
      param.staticParams.forEach(staticParam => {

        const optionalSelectedParam = this.selectedParams.find(selectedParam => selectedParam.field === staticParam.field);

        if (optionalSelectedParam == null) {
          fieldError = staticParam.field;
          return;
        }

        if (staticParam.is_request_param == null || staticParam.is_request_param) {
          if (requestIndex === 0) {
            url += `?${staticParam.field}=${optionalSelectedParam.value}`;
          } else {
            url += `&${staticParam.field}=${optionalSelectedParam.value}`;
          }
          requestIndex++;
        } else {
          url = url.replace(`{${staticParam.field}}`, optionalSelectedParam.value);
        }
      });
    }

    if (fieldError.length > 0) {
      return null;
    }

    return url;
  }

  onYearSelected(values: SchoolYearSemesterValues) {
    this.updateSelectedValueParam(this.YEAR, values.selectedYear.toString(), true);
  }

  onSchoolSelected(values: SchoolYearSemesterValues) {
    this.updateSelectedValueParam(this.SCHOOL, values.selectedSchoolId.toString(), true);
    if (values.selectedSchoolId === 0) {
      const semesterParam = this.selectedParams.find(param => param.field === this.SEMESTER);
      if (semesterParam != null) {
        semesterParam.value = null;
      }
    }
  }

  onSemesterSelected(values: SchoolYearSemesterValues) {
    if (values.selectedSchoolId !== 0) {
      this.updateSelectedValueParam(this.SEMESTER, values.selectedSemesterId.toString(), true);
    }
  }

  areRequiredParamsPresents() {
    if (this.requestData.queries != null && this.requestData.queries.length > 0) {
      return this.canExecuteQuery();
    } else {
      const requiredParams = this.selectedParams.filter(param => (param.is_mandatory)  || (!param.is_request_param));
      return requiredParams.filter(param => param.value == null).length === 0;
    }
  }

  onPromoSelected(promotionDTO: PromotionDTO) {
    this.updateSelectedValueParam(this.PROMOTION, promotionDTO ? promotionDTO.puid.toString() : null, true);
  }

  onSessionSelected(session: string) {
    this.updateSelectedValueParam(this.SESSION, session, true);
  }

  onOptionSelected(optionDTO: OptionDTO) {
    this.updateSelectedValueParam(this.OPTION, optionDTO ? optionDTO.puid.toString() : null , true);
  }

  onClassSelected(classDTO: ClassDTO) {
    this.updateSelectedValueParam(this.CLASS, classDTO ? classDTO.puid.toString() : null, true);
  }

  onSpecializationSelected(specializationDTO: SpecializationDTO) {
    this.updateSelectedValueParam(this.SPECIALIZATION, specializationDTO ? specializationDTO.puid.toString() : null, true);
  }

  getMinimumRequirementsFields() {
    if (this.requestData != null && this.requestData.queries != null && this.requestData.queries.length > 0) {
      const queries = this.orderQueries();
      const query = queries[this.requestData.queries.length - 1];
      if (query.fields != null) {
        return query.fields
          .filter(field => field.is_mandatory)
          .map(mandatoryField => mandatoryField.field);
      }
    }
    return [];
  }

  canExecuteQuery() {
    const mandatoryFields = this.getMinimumRequirementsFields();
    for (const mandatoryField of mandatoryFields) {
      const mandatoryFieldIsPresent = this.selectedParams.some(selectedParam => selectedParam.field === mandatoryField
        && selectedParam.value != null && selectedParam.value.length > 0);
      if (!mandatoryFieldIsPresent) {
        return false;
      }
    }
    return true;
  }

  onRowSelectionChanged(newSelectedData: any[]) {
    this.selectedData = newSelectedData;
  }
}
