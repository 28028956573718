<div *ngIf="!isMenuCollapsed" class="fullHeight">

  <div class="mainTitle p-pt-2" style="height: 100px">
    <div class="p-mr-3" style="transform: translateY(-15%)">
      <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
           viewBox="0 0 326 326.2" style="width: 2.4em; height: 1.1em; transform: translateY(15%)" xml:space="preserve">
        <style type="text/css">.st0 {
          fill: #FFFFFF;
        }</style>
        <g>
          <path class="st0"
                d="M262.1,61.9c-46.8,33.8-92.8,67-139.6,100.8c46.6,33.7,92.7,66.9,139.6,100.8 C262.1,196.1,262.1,129.7,262.1,61.9z"/>
          <path class="st0"
                d="M177.8,61.1c-38.1,0-74.6,0-111.5,0c0,26.5,0,52.7,0,80.3C103.4,114.6,139.9,88.4,177.8,61.1z"/>
          <path class="st0"
                d="M176.8,263.5c-37.4-26.9-73.6-53-110.7-79.7c0,27.2,0,53.2,0,79.7C102.5,263.5,138.7,263.5,176.8,263.5z"/>
        </g>
        <g><path class="st0" d="M0.3,0l325.5,0v325.5H0.3L0.3,0z M316.3,9.5H9.8V316h306.5V9.5z"/></g>
      </svg>
      <span>KORDIS</span>
    </div>
  </div>

  <app-mainmenu id="megamenu" [style]="{'width': 'auto'}" [model]="menuElements" orientation="vertical"
                [isMenuCollapsed]="isMenuCollapsed" (menuCollapseClicked)="changeMenuLayout()">
    <div class="p-flex-column" [style]="{'position': 'absolute', 'bottom' : '10px', 'margin-left':'1.8em'}">
      <div class="centerContent">
        <button pButton type="button"
                class="displayBlock middleMarginBottom p-button-rounded p-button-secondary p-button-outlined"
                style="background-color: white"
                icon="pi pi-cog" (click)="op.toggle($event)"></button>
        <label class="bottomButtonText"></label>
      </div>
    </div>
  </app-mainmenu>
</div>

<div *ngIf="isMenuCollapsed" class="fullHeight">
  <div class="mainTitle">
    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 0 326 326.2" style="width: 60px; height: auto" xml:space="preserve">
        <style type="text/css">.st0 {
          fill: #FFFFFF;
        }</style>
      <g>
          <path class="st0"
                d="M262.1,61.9c-46.8,33.8-92.8,67-139.6,100.8c46.6,33.7,92.7,66.9,139.6,100.8 C262.1,196.1,262.1,129.7,262.1,61.9z"/>
        <path class="st0"
              d="M177.8,61.1c-38.1,0-74.6,0-111.5,0c0,26.5,0,52.7,0,80.3C103.4,114.6,139.9,88.4,177.8,61.1z"/>
        <path class="st0"
              d="M176.8,263.5c-37.4-26.9-73.6-53-110.7-79.7c0,27.2,0,53.2,0,79.7C102.5,263.5,138.7,263.5,176.8,263.5z"/>
        </g>
      <g><path class="st0" d="M0.3,0l325.5,0v325.5H0.3L0.3,0z M316.3,9.5H9.8V316h306.5V9.5z"/></g>
      </svg>
  </div>

  <app-mainmenu id="megamenuCollapsed" [model]="itemsCollapsed" orientation="vertical"
                [isMenuCollapsed]="isMenuCollapsed" (menuCollapseClicked)="changeMenuLayout()">
    <div class="p-flex-column" [style]="{'position': 'absolute', 'bottom' : '10px', 'margin-left':'1.7em'}">
      <button pButton type="button"
              class="displayBlock middleMarginBottom p-button-rounded p-button-secondary p-button-outlined"
              style="background-color: white"
              icon="pi pi-cog" (click)="op.toggle($event)"></button>
    </div>
  </app-mainmenu>

</div>

<p-overlayPanel #op  [showCloseIcon]="false" [style]="{width: '300px'}">
  <app-option-menu *ngIf="this.currentProfile" [currentProfile]="this.currentProfile"
                   (disconnectionClicked)="disconnectionClicked.emit()"
                   (helpClicked)="helpClicked.emit()"
                   (paramsClicked)="paramsClicked.emit()">


  </app-option-menu>
</p-overlayPanel>

