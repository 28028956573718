import {Component, OnInit} from '@angular/core';
import {Page} from '../../annotations/page.annotation';
import {PagesService} from '../../services/pages.service';
import {BaseService} from '../../services/base.service';
import {MessageService} from 'primeng/api';
import {EntryDTO} from '../../models/interfaces/entry-dto';
import {environment} from '../../../environments/environment';
import {AbstractRequestComponent} from '../../interfaces/abstract-request.component';
import {FieldValue} from '../../models/query/field-value';
import {SelectedParams} from '../../models/query/selected-params';
import {TableColumn} from '../../models/table-column';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
@Page(PagesService.REPORT, 'Composant des etats', undefined, { icon: 'pi pi-users' })
export class ReportComponent extends AbstractRequestComponent implements OnInit {

  exportUrl: string;
  fileName: string;


  constructor(public baseService: BaseService,
              public messageService: MessageService) {
    super(baseService, messageService);
  }

  private entryValue: EntryDTO;

  get entry() {
    return this.entryValue;
  }

  set entry(value) {
    this.entryValue = value;
    this.initQuery();
  }

  ngOnInit(): void {
    this.entry = this.extraData;

  }

  initQuery() {
    this.isReady = false;
    this.baseService.getJson(`../assets/documents/reports/${this.entry.entry_id}.json`)
      .then(data => {
        this.exportUrl = data.export.url;
        this.fileName = data.export.filename;
        if (this.exportUrl == null) {
          this.messageService.add({severity: 'error', summary: 'L\'état ne possède pas de lien pour générer les pdf '});
          this.tabLayout.closeCurrentTab();
        }
        this.requestData = data;
        this.requestData.columns = this.requestData.columns.map(column => new TableColumn(column.field, column.header, column.width, column.type, column.readonly, column.sortable, column.filterType) );
        this.initSelectedParams();
        this.isReady = true;
      }).catch(() => {
      this.messageService.add({severity: 'error', summary: 'Cet état n\'est pas disponible.'});
      if (environment.production) {
        this.tabLayout.closeCurrentTab();
      } else {
        this.baseService.getJson(`../assets/documents/reports/report_sample.json`)
          .then(data => {
            this.exportUrl = data.export.url;
            this.fileName = data.export.filename;
            if (this.exportUrl == null) {
              this.messageService.add({severity: 'error', summary: 'L\'état ne possède pas de lien pour générer les pdf '});
            }
            this.requestData = data;
            this.initSelectedParams();
            this.isReady = true;
          });
      }
    });
  }

  getHelp(): string {
    return 'Cette interface permet l\'édition d\'un document à partir des données de notre système (état).' +
    '\n \n' +
    'Après paramètrage (si nécessaire), il vous suffit de cliquer sur le bouton "Exécuter" pour afficher les données.' +
      '\n\n' +
      'Vous pouvez ensuite sélectionner la ou les lignes qui vous intéréssent et cliquer sur le bouton "Générer" pour télécharger le ou les documents.';
  }

  canGenerateReport() {
    return this.exportUrl != null && this.exportUrl.length > 0 && this.selectedData != null && this.selectedData.length > 0;
  }

  executeExport() {
    this.isLoading = true;
    const exportIds = this.selectedData.map(data => data[this.requestData.dataKey]);
    if (exportIds == null || exportIds.length <= 0) {
      this.messageService.add({severity: 'error', summary: 'Aucun élément sélectionné.'});
    }
    const urlWithFields = this.getUrlWithExportFields();
    if (urlWithFields != null) {
      const finalUrl = this.formatExportUrl(urlWithFields, exportIds);
      if (finalUrl != null) {
        const fileNameWithFields = this.getFilenameWithExportFields();
        if (fileNameWithFields == null) {
          return;
        }

        this.baseService.getFile(environment.servicesUrl + finalUrl, 'application/pdf', fileNameWithFields)
          .then(() => {
            this.messageService.add({severity: 'success', summary: 'Le fichier a été généré.'});
          }).catch(() => {
          this.messageService.add({severity: 'error', summary: 'Le fichier a pas été généré.'});
        }).finally(() => this.isLoading = false);
      }
    }
  }

  getUrlWithExportFields() {

    let errorField = null;
    const url = this.exportUrl;
    const requiredParams = [];
    let queryIsValid = true;

    this.requestData.export.fields.some(field => { // on verifie que toutes les parametres de la requête courante sont présent
      const param = this.selectedParams.find(selectedParam => selectedParam.field === field.field);

      if (field.is_mandatory && (param == null || param.value == null || param.value.length <= 0) ) {
        errorField = field.field;
        queryIsValid = false;
      }
      if (param != null && param.value != null && param.value.length > 0) {
        requiredParams.push(new SelectedParams(field.field, field.is_mandatory, field.is_request_param, param.value));
      }
      return !queryIsValid; // Si un parametres n'est pas valide on arrete de boucle
    });

    if (queryIsValid) {
      return this.formatUrl(url, requiredParams);
    } else {
      this.displayErrorField(errorField);
      return null;
    }
  }

  formatExportUrl(url, exportIds) {
    if (url.indexOf('?') !== -1) {
      url += `&ids=${exportIds}`;
    } else {
      url += `?ids=${exportIds}`;
    }
    return url;
  }

  onUpdateDynamicParam(fieldValue: FieldValue) {
    this.updateSelectedValueParam(fieldValue.field, fieldValue.value, false);
  }

  private getFilenameWithExportFields() {

    let errorField = null;
    let url = this.fileName;
    const requiredParams = [];
    let queryIsValid = true;

    this.requestData.export.fields.some(field => { // on verifie que toutes les parametres de la requête courante sont présent
      const param = this.selectedParams.find(selectedParam => selectedParam.field === field.field);

      if (field.is_mandatory && (param == null || param.value == null || param.value.length <= 0) ) {
        errorField = field.field;
        queryIsValid = false;
      }
      if (param != null && param.value != null && param.value.length > 0) {
        requiredParams.push(new SelectedParams(field.field, field.is_mandatory, field.is_request_param, param.value));
      }
      return !queryIsValid; // Si un parametres n'est pas valide on arrete de boucle
    });

    if (errorField != null) {
      this.displayErrorField(errorField);
      return null;
    } else {
      requiredParams.forEach(param => {
        url = url.replace(`{${param.field}}`, param.value);
      });
      return url;
    }
  }
}
