import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {environment} from '../../environments/environment';
import {CancelablePromise} from '../common/promises/cancelable-promise';

@Injectable({
  providedIn: 'root'
})
export class YearsService {

  constructor(private baseService: BaseService) { }

  getAllYears(): CancelablePromise<number[]> {
    return this.baseService.getAll<number>(`${environment.servicesUrl}/me/years`);
  }
}
