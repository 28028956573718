<div class="p-d-flex">
  <div class="p-mr-3" *ngFor="let param of params">
    <p-checkbox *ngIf="param.type === 'boolean'" class="booleanStyle"
                [disabled]="!isDynamicParamReady(param)"
                inputId="{{param.name}}"
                [label]="param.name" (onChange)="updateSelectedValueParam(param.field, $event.checked.toString())" binary="true"></p-checkbox>
    <span *ngIf="param.type === 'boolean' && isElementMandatory(param.field)" style="color: red"> * </span>
    <div *ngIf="param.type === 'date'">
          <span class="p-float-label">
            <p-calendar styleClass="dateStyle" [disabled]="!isDynamicParamReady(param)" (onSelect)="updateDateParam(param.field, $event)" dateFormat="dd-mm-yy" id="dateformat" [readonlyInput]="true"></p-calendar>
            <label class="middleMarginRight" for="dateformat">{{param.name}}<span *ngIf="isElementMandatory(param.field)" style="color: red"> * </span></label>
          </span>
    </div>
    <div *ngIf="param.type === 'input'">
          <span class="p-float-label">
            <input pInputText id="input" class="p-inputtext" type="text" style="height: 35px"
                   [(ngModel)]="inputsList[param.field]"
                   [disabled]="!isDynamicParamReady(param)"

                   (ngModelChange)="updateSelectedValueParam(param.field, $event)">
            <label for="input" class="middleMarginRight">{{param.name}}<span *ngIf="isElementMandatory(param.field)" style="color: red"> * </span></label>
          </span>
    </div>
    <div *ngIf="param.type === 'dropdown'">
          <span class="p-float-label">
            <p-dropdown id="optionDropdown" autoWidth="false" appendTo="body"
                        styleClass="dropdownStyle"
                        [autoDisplayFirst]="false"
                        [disabled]="!isDynamicParamReady(param)"
                        [optionLabel]="param.display_field"
                        [options]="optionsList[param.field]"
                        [(ngModel)]="inputsList[param.field]"
                        [showClear]="!isElementMandatory(param.field)"
                        (onChange)="updateSelectedValueParam(param.field,
                          param.field_value != null ? $event.value[param.field_value].toString()
                          : $event.value)">
            </p-dropdown>
            <label for="optionDropdown">{{param.name}}<span *ngIf="isElementMandatory(param.field)" style="color: red"> * </span></label>
          </span>
    </div>
    <div *ngIf="param.type === 'suggestbox'">
          <span class="p-float-label">
            <p-autoComplete styleClass="dropdownStyle"
                            [field]="param.display_field"
                            [(ngModel)]="inputsList[param.field]"
                            [suggestions]="optionsList[param.field]" [minLength]="1"
                            (onSelect)="updateSelectedValueParam(param.field, $event[param.field_value].toString())"
                            (completeMethod)="getSuggestBoxList(param, $event.query.toString())">
            </p-autoComplete>
            <label for="optionDropdown">{{param.name}}<span *ngIf="isElementMandatory(param.field)" style="color: red"> * </span></label>
          </span>
    </div>
  </div>
</div>
