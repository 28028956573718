<div class="softPaddingTop" ><p-button class="miniMarginLeft" *ngFor="let ico of icons900" [icon]="'pi ' + ico" [pTooltip]="ico" ></p-button></div>
<div class="softPaddingTop" ><p-button class="miniMarginLeft" *ngFor="let ico of icons910" [icon]="'pi ' + ico" [pTooltip]="ico" ></p-button></div>
<div class="softPaddingTop" ><p-button class="miniMarginLeft" *ngFor="let ico of icons920" [icon]="'pi ' + ico" [pTooltip]="ico" ></p-button></div>
<div class="softPaddingTop" ><p-button class="miniMarginLeft" *ngFor="let ico of icons930" [icon]="'pi ' + ico" [pTooltip]="ico" ></p-button></div>
<div class="softPaddingTop" ><p-button class="miniMarginLeft" *ngFor="let ico of icons940" [icon]="'pi ' + ico" [pTooltip]="ico" ></p-button></div>
<div class="softPaddingTop" ><p-button class="miniMarginLeft" *ngFor="let ico of icons950" [icon]="'pi ' + ico" [pTooltip]="ico" ></p-button></div>
<div class="softPaddingTop" ><p-button class="miniMarginLeft" *ngFor="let ico of icons960" [icon]="'pi ' + ico" [pTooltip]="ico" ></p-button></div>
<div class="softPaddingTop" ><p-button class="miniMarginLeft" *ngFor="let ico of icons970" [icon]="'pi ' + ico" [pTooltip]="ico" ></p-button></div>
<div class="softPaddingTop" ><p-button class="miniMarginLeft" *ngFor="let ico of icons980" [icon]="'pi ' + ico" [pTooltip]="ico" ></p-button></div>
<div class="softPaddingTop" ><p-button class="miniMarginLeft" *ngFor="let ico of icons990" [icon]="'pi ' + ico" [pTooltip]="ico" ></p-button></div>
<div class="softPaddingTop" ><p-button class="miniMarginLeft" *ngFor="let ico of icons9a0" [icon]="'pi ' + ico" [pTooltip]="ico" ></p-button></div>
<div class="softPaddingTop" ><p-button class="miniMarginLeft" *ngFor="let ico of icons9b0" [icon]="'pi ' + ico" [pTooltip]="ico" ></p-button></div>
<div class="softPaddingTop" ><p-button class="miniMarginLeft" *ngFor="let ico of icons9c0" [icon]="'pi ' + ico" [pTooltip]="ico" ></p-button></div>
<div class="softPaddingTop" ><p-button class="miniMarginLeft" *ngFor="let ico of iconsOthers" [icon]="'pi ' + ico" [label]="ico" ></p-button></div>
