import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateUtilsService {
  FRENCH_DAYS = [
    'Dimanche',
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
  ];
  ONE_YEAR = 31536000000;
  ONE_DAY = 86400000;
  ONE_HOUR = 3600000;
  ONE_MINUTE = 60000;
  ONE_SECOND = 1000;
  ONE_MONTH = this.ONE_YEAR / 12;
  SEVEN_DAYS = 7 * this.ONE_DAY;
  NINETY_MINUTES = 90 * this.ONE_MINUTE;
  FIFTEEN_MINUTES = 15 * this.ONE_MINUTE;
  FIVE_MINUTES = 5 * this.ONE_MINUTE;
  JANUARY = 0;
  FEBRUARY = 1;
  MARCH = 2;
  APRIL = 3;
  MAY = 4;
  JUNE = 5;
  JULY = 6;
  AUGUST = 7;
  SEPTEMBER = 8;
  OCTOBER = 9;
  NOVEMBER = 10;
  DECEMBER = 11;

  getFrenchDayNameFromTimeStamp(timestamp: number): string | null {
    if (timestamp <= 0) {
      return null;
    }
    return this.FRENCH_DAYS[new Date(timestamp).getDay() % 7];
  }

  toTwoDigitsDate(n: number): string {
    return (n < 10 && n >= 0) ? '0' + n : n.toString();
  }

  formatDateToString(date: Date): string {
    return date.getUTCFullYear() + '-' + this.toTwoDigitsDate(date.getUTCMonth() + 1) + '-' + this.toTwoDigitsDate(date.getUTCDate()) + 'T'
      + this.toTwoDigitsDate(date.getUTCHours()) + ':' + this.toTwoDigitsDate(date.getUTCMinutes())
      + ':' + this.toTwoDigitsDate(date.getUTCSeconds());
  }

  getDisplayableFrenchDateWithoutTime(date: Date): string {
    return this.toTwoDigitsDate(date.getDate()) + '/' + this.toTwoDigitsDate(date.getMonth() + 1) + '/' + date.getFullYear();
  }

  getDisplayableFrenchDayAndMonthInText(date: Date): string {
    return date.getDate() + ' ' + this.shortenFrenchMonthName(this.getDisplayableFrenchMonth(date.getMonth()));
  }

  getDisplayableFrenchDayMonthAndYearInText(date: Date): string {
    return date.getDate()
      + ' ' + this.shortenFrenchMonthName(this.getDisplayableFrenchMonth(date.getMonth()))
      + ' ' + date.getUTCFullYear();
  }

  getDisplayableFrenchMonth(n: number): string {
    switch (n) {
      case 0:
        return 'janvier';
      case 1:
        return 'février';
      case 2:
        return 'mars';
      case 3:
        return 'avril';
      case 4:
        return 'mai';
      case 5:
        return 'juin';
      case 6:
        return 'juillet';
      case 7:
        return 'août';
      case 8:
        return 'septembre';
      case 9:
        return 'octobre';
      case 10:
        return 'novembre';
      case 11:
        return 'décembre';
      default:
        return '';
    }
  }

  shortenFrenchMonthName(name: string): string {
    let shortName = '';
    if (name.length < 5) {
      shortName = name;
    } else if (name === 'juillet') {
      shortName = 'juill.';
    } else if (name === 'février' || name === 'janvier' || name === 'septembre') {
      shortName = name.slice(0, 4) + '.';
    } else {
      shortName = name.slice(0, 3) + '.';
    }
    return shortName;
  }

  adaptTimestampToDisplayableFrenchDateWithoutTime(timestamp: number): string {
    return this.getDisplayableFrenchDateWithoutTime(new Date(timestamp));
  }

  getMinutesBetweenTwoTimestamps(start: number, end: number): number {
    return Math.floor((end - start) / 60000);
  }

  getHoursBeetweenTwoTimestamps(start: number, end: number): number {
    return Math.floor(Math.floor((end - start) / 1000) / 3600) % 24;
  }

  areSameSlot(timestamp1: number, timestamp2: number): boolean {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);

    return date1.getHours() === date2.getHours() && date1.getMinutes() === date2.getMinutes();
  }

  /**
   * Retourne le nombre de semaine de l'année passée en paramètre
   * @param year: année
   */
  getWeekCountForYear(year: number): number {
    const when = new Date(year, 0, 1);
    const testedYear = when.getFullYear();
    const dayNumberOfFirstDay = this.getDayNumberOfDate(year, 0, 1);

    if (dayNumberOfFirstDay === 4) { // l'année commence un jeudi
      return 53;
    } else {
      if (dayNumberOfFirstDay === 3) { // l'année commence un mercredi
        if ((testedYear / 400) - (Math.floor(testedYear / 400)) === 0) { // test du 1er cas d'année bissextile
          return 53;
        } else {
          if ((testedYear / 4) - (Math.floor(testedYear / 4)) === 0) { // test du 2eme cas d'année bissextile
            if ((testedYear / 100) - (Math.floor(testedYear / 100)) === 0) { // test du 2eme cas d'année bissextile
              return 52;
            } else {
              return 53;
            }
          } else {
            return 52;
          }
        }
      } else {
        return 52;
      }
    }
  }

  /**
   * Retourne pour une année et un numéro de semaine donné, la date du mercredi.
   * @param week => { year, weekNumber }
   */
  adaptWeekToWednesdayDate(week: any): Date {
    // On calcul le total de jours entre le 1er janvier et le lundi de la semaine
    const totalDaysFor1stJanuaryWeek = this.getTotalDaysFromCurrentYearInAWeek(week.year, 0, 1);
    let totalDaysGones;
    if (totalDaysFor1stJanuaryWeek >= 4) {
      totalDaysGones = (1 + (week.weekNumber - 1) * 7); // 1st of January + 7 days for each week
    } else {
      totalDaysGones = (1 + (week.weekNumber - 1) * 7) + totalDaysFor1stJanuaryWeek; // 1st of January + 7 days for each week
    }
    const weekBeginDate = new Date(Date.UTC(week.year, 0, totalDaysGones));

    // Calcul du nombre de jour d'écart en entre le jour de la semaine de la date de début et mercredi
    const wednesdayOffset = 3 - weekBeginDate.getDay();
    return new Date(Date.UTC(week.year, 0, totalDaysGones + wednesdayOffset));
  }

  /**
   * Retourne pour la date passée en paramètre, le nombre de jour de la semaine appartenant à l'année de la semaine.
   * @param year => année
   * @param month => mois
   * @param day => jour
   */
  getTotalDaysFromCurrentYearInAWeek(year: number, month: number, day: number): number {
    const dayNumberOfSearchDate = this.getDayNumberOfDate(year, month, day);
    const daysGonesForCurrentYear = this.getDaysGonesForCurrentYear(year, month, day);
    const when = new Date(year, month, day);

    const daysLeftForCurrentYear = ((Date.UTC(year + 1, 0, 1, 0, 0, 0) - Date.UTC(year, when.getMonth(), when.getDate(), 0, 0, 0))
      / 1000 / 60 / 60 / 24
    ) - 1;

    if (daysGonesForCurrentYear < (dayNumberOfSearchDate + 1)) {
      return daysGonesForCurrentYear + (7 - (dayNumberOfSearchDate));
    } else {
      if (daysLeftForCurrentYear > (7 - (dayNumberOfSearchDate))) {
        return 7;
      } else {
        return (dayNumberOfSearchDate) + daysLeftForCurrentYear;
      }
    }
  }

  getWeek(year: number, month: number, day: number): number {
    const daysGonesForCurrentYear = this.getDaysGonesForCurrentYear(year, month, day);
    const totalDaysFromCurrentYearInTheWeek = this.getTotalDaysFromCurrentYearInAWeek(year, month, day);

    if (daysGonesForCurrentYear > 362) {
      if (totalDaysFromCurrentYearInTheWeek >= 4) {
        return this.getWeekCountForYear(year);
      } else {
        return 1;
      }
    } else {
      if (daysGonesForCurrentYear >= 4) {
        const totalDaysFromCurrentYearInTheFirstWeek = this.getTotalDaysFromCurrentYearInAWeek(year, 0, 1);
        if (totalDaysFromCurrentYearInTheFirstWeek >= 4) {
          return Math.floor((daysGonesForCurrentYear - totalDaysFromCurrentYearInTheFirstWeek) / 7) + 2;
        } else {
          return Math.floor((daysGonesForCurrentYear - totalDaysFromCurrentYearInTheFirstWeek) / 7) + 1;
        }
      } else {
        if (totalDaysFromCurrentYearInTheWeek >= 4) {
          return 1;
        } else {
          return this.getWeekCountForYear(year - 1);
        }
      }
    }
  }

  getWeekFromDate(date: Date): number {
    return this.getWeek(date.getFullYear(), date.getMonth(), date.getDate());
  }

  getWeekFromTimestamp(timestamp: number): number {
    return this.getWeekFromDate(new Date(timestamp));
  }

  isCurrentWeek(weekNumber: number, year: number): boolean {
    const now = new Date(Date.now());
    return weekNumber === this.getWeekFromDate(now) && year === now.getFullYear();
  }

  addDays(date: string | number | Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  convertMinutesToHHmm(totalMinutes: number): string {
    const minutesPart = Math.abs(totalMinutes) % 60;
    const firstMinuteDigit = (minutesPart < 10) ? '0' : '';
    if (totalMinutes >= 0) {
      return Math.floor(totalMinutes / 60) + ' h ' + firstMinuteDigit + minutesPart;
    } else if (totalMinutes / 60 > -1) {
      return '-0 h ' + firstMinuteDigit + minutesPart;
    } else {
      return Math.ceil(totalMinutes / 60) + ' h ' + firstMinuteDigit + minutesPart;
    }
  }

  offsetStartDate(startDate: number): number {
    return startDate + 28800000;  // + 8 heures
  }

  offsetEndDate(endDate: number): number {
    return endDate + 75600000;    // +21 heures
  }

  areSameDay(timestamp1: number | string | Date, timestamp2: number | string | Date): boolean {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);

    return date1.getFullYear() === date2.getFullYear()
      && date1.getMonth() === date2.getMonth()
      && date1.getDate() === date2.getDate();
  }

  isSameDayOrAfter(timestamp1: string | number | Date, timestamp2: string | number | Date): boolean {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);

    return date2.getFullYear() > date1.getFullYear()    // si l'année est plus grande
      || date2.getFullYear() === date1.getFullYear()  // si l'année est le meme mais le mois plus grand
      && date2.getMonth() > date1.getMonth()
      || date2.getFullYear() === date1.getFullYear()  // si le mois est le meme mais le jour plus 'grand'
      && date2.getMonth() === date1.getMonth()
      && date2.getDate() >= date1.getDate();
  }

  getMondayOf(timestamp: number): Date {
    let newTimestamp: number;
    if (timestamp % this.SEVEN_DAYS <= (3 * this.ONE_DAY) || new Date(timestamp).getDay() === 0) {
      newTimestamp = timestamp - (timestamp % this.SEVEN_DAYS) - 3 * this.ONE_DAY;
    } else {
      newTimestamp = timestamp - (timestamp % this.SEVEN_DAYS) + 4 * this.ONE_DAY;
    }
    return new Date(newTimestamp);
  }

  createDate(timestamp: number, hours: number, minutes: number): number {
    const originalDate = new Date(timestamp);
    originalDate.setHours(hours);
    originalDate.setMinutes(minutes);
    originalDate.setSeconds(0);
    originalDate.setMilliseconds(0);
    return originalDate.getTime();
  }

  convertMiliSecondsToHHMM(miliseconds: number): string {
    const minutes = miliseconds / 60000;

    return this.convertMinutesToHHmm(minutes);
  }

  mergeDayAndTime(dayDate: Date, timeDate: Date): Date {
    dayDate = new Date(dayDate);
    timeDate = new Date(timeDate);

    return new Date(dayDate.getFullYear(),
      dayDate.getMonth(),
      dayDate.getDate(),
      timeDate.getHours(),
      timeDate.getMinutes(),
      timeDate.getSeconds(),
      0);
  }

  /**
   * Retourne le nombre de jour écoulés entre le 1er jour de l'année et la date passée en paramètre
   * @param year => année
   * @param month => mois
   * @param day => jour
   */
  getDaysGonesForCurrentYear(year: number, month: number, day: number): number {
    const when = new Date(year, month, day);

    const now = Date.UTC(year, when.getMonth(), when.getDate(), 0, 0, 0);
    const beginYear = Date.UTC(year, 0, 1, 0, 0, 0);
    if (now === beginYear) {
      return 1;
    } else {
      return ((Date.UTC(year, when.getMonth(), when.getDate(), 0, 0, 0) - Date.UTC(year, 0, 1, 0, 0, 0))
        / 1000 / 60 / 60 / 24
      ) + 1;
    }
  }

  /**
   * Retourne une valeur de 1 à 7 correspondant au jour de la semaine passé en paramètre.
   * lundi = 1, mardi = 2, mercredi = 3, jeudi = 4, vendredi = 5, samedi = 6, dimanche = 7
   * @param year => année
   * @param month => mois
   * @param day => jour
   */
  getDayNumberOfDate(year: number, month: number, day: number): number {
    const when = new Date(year, month, day);

    let dayNumberOfDate = when.getDay();
    if (dayNumberOfDate === 0) {
      dayNumberOfDate = 7;
    }
    return dayNumberOfDate;
  }

  convertMinutesToMiliSeconds(minutes: number): number {
    return minutes * this.ONE_MINUTE;
  }

  getDaysLeftForCurrentYear(year: number, month: number, day: number): number {
    const when = new Date(year, month, day);
    const now = Date.UTC(year, when.getMonth(), when.getDate(), 0, 0, 0);
    return ((Date.UTC(year + 1, 0, 1, 0, 0, 0) - now)
      / 1000 / 60 / 60 / 24
    );
  }

  getYearOfWeek(date: Date): number {
    const monday = this.getMondayOf(date.getTime());
    const sunday = this.addDays(monday, 6);
    if (monday.getFullYear() === sunday.getFullYear()) {
      return monday.getFullYear();
    } else {
      const daysLeft = this.getDaysLeftForCurrentYear(monday.getFullYear(), monday.getMonth(), monday.getDate());
      if (daysLeft >= 4) {
        return monday.getFullYear();
      } else {
        return sunday.getFullYear();
      }
    }
  }

  getStartOfDay(timestamp: number): number {
    return timestamp - (timestamp % this.ONE_DAY);
  }

  getEndOfDay(timestamp: number): number {
    return this.getStartOfDay(timestamp) + this.ONE_DAY - 1;
  }

  getTimestampHoursBeforeTimestamp(timestamp: number, hours: number): number {
    return timestamp - (hours * 3600000);
  }

  convertDateToTimestamp(date: Date): number {
    return (date.getTime() / 1000);
  }
}
