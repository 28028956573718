import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SchoolsService} from '../../services/schools.service';
import {YearsService} from '../../services/years.service';
import {CacheService} from '../../services/cache.service';
import {SchoolYearSemesterValues} from '../../models/school-year-semester-values';
import {DateUtilsService} from '../../services/date-utils.service';
import {ErrorService} from '../../services/error.service';

@Component({
  selector: 'app-school-year-semester-selector',
  templateUrl: './school-year-semester-selector.component.html',
  styleUrls: ['./school-year-semester-selector.component.scss']
})
export class SchoolYearSemesterSelectorComponent implements OnInit {
  yearOptions: any[];
  selectedYearOption: any;
  loadingYears = false;

  schoolOptions: any[];
  selectedSchoolOption: any;
  loadingSchools = false;

  semesterOptions: any[];
  selectedSemesterOption: any;
  loadingSemesters = false;

  selectedValues = {
    selectedYear: -1,
    selectedSchoolId: -1,
    selectedSemesterId: -1,
  };

  @Input() yearIsVisible = true;
  @Input() schoolIsVisible = true;
  @Input() allSchoolsIsVisible = true;
  @Input() semesterIsVisible = true;
  @Input() mandatoryFields: string[] = [];

  //region emitters
  @Output() yearSelected        = new EventEmitter<SchoolYearSemesterValues>();
  @Output() schoolSelected      = new EventEmitter<SchoolYearSemesterValues>();
  @Output() allSchoolsSelected  = new EventEmitter<SchoolYearSemesterValues>();
  @Output() semesterSelected    = new EventEmitter<SchoolYearSemesterValues>();
  //endregion
  YEAR = 'year';
  SCHOOL = 'school';
  SEMESTER = 'semester';


  constructor(private schoolsService: SchoolsService,
              private yearsService: YearsService,
              private dateUtils: DateUtilsService,
              private cacheService: CacheService,
              private errorService: ErrorService) { }

  ngOnInit(): void {
    this.cacheService.getMainFilters()
      .then(mainFilters => {
        this.selectedValues = mainFilters;
        this.loadYears();
        this.loadSchools();
      });
  }

  onSelectedSchoolChanged(event: any): void {

    this.resetSemesters();

    this.loadingSemesters = true;

    this.selectedValues.selectedSchoolId = event.value;
    this.cacheService.setMainFilters(this.selectedValues);
    this.schoolSelected.emit(this.selectedValues);

    if (this.selectedValues.selectedSchoolId > 0) {
      this.schoolsService.getSchoolSemesters(event.value)
        .then((semesters) => {
          this.semesterOptions = semesters
            .sort((a, b) => a.tri_describe > b.tri_describe ? 1 : -1)
            .map((s) => (({label: s.tri_describe, value: s.tri_id})));

          if (this.selectedValues.selectedSemesterId > -1) {
            this.selectSemester(this.selectedValues.selectedSemesterId);
          } else {
            this.selectFirstSemester();
          }
        })
        .catch(error => this.errorService.showError(error))
        .finally(() => this.loadingSemesters = false);
    } else {
      this.selectedSemesterOption = null;
      this.loadingSemesters = false;
      this.allSchoolsSelected.emit(this.selectedValues);
    }

  }

  onSelectedYearChanged(event: any): void {
    this.selectedValues.selectedYear = event.value;
    this.cacheService.setMainFilters(this.selectedValues);
    this.yearSelected.emit(this.selectedValues);
  }

  onSelectedSemesterChanged(event: any): void {
    this.selectedValues.selectedSemesterId = event.value;
    this.cacheService.setMainFilters(this.selectedValues);
    this.semesterSelected.emit(this.selectedValues);
  }

  private loadSchools(): void {
    this.loadingSchools = true;

    this.cacheService.getUserSchools().then(schools => {
      if (schools.length > 0) {
        this.schoolOptions = schools.map(school => ({label: school.name, value: school.puid}));
        this.loadingSchools = false;
        if (this.selectedValues.selectedSchoolId > -1) {
          this.selectedSchoolOption = this.selectedValues.selectedSchoolId;
        }

        this.onSelectedSchoolChanged({value: this.selectedValues.selectedSchoolId});
      }

      // dans tous les cas, on rafraîchit la liste des écoles en fonction des données fournies par l'API
      this.schoolsService.getAll()
        .then((fetchedSchools) => {

          if (this.allSchoolsIsVisible) {
            fetchedSchools = [{puid: 0, name: 'Toutes mes écoles (chargement long)', description: 'Toutes mes écoles (chargement long)'}, ...fetchedSchools];
          }

          this.cacheService.setUserSchools(fetchedSchools);
          this.schoolOptions = fetchedSchools.map(school => ({label: school.name, value: school.puid}));
        })
        .catch(error => this.errorService.showError(error))
        .finally(() => this.loadingSchools = false);
    });
  }

  private loadYears(): void {
    this.loadingYears = true;

    this.cacheService.getUserYears().then(years => {

      if (years.length > 0) {
        this.yearOptions = years.map(year => ({label: `${year}-${year + 1}`, value: year}));
        this.loadingYears = false;

        this.affectCurrentYearIfNoneSelected();

        this.selectedYearOption = this.selectedValues.selectedYear;
        this.onSelectedYearChanged({value: this.selectedValues.selectedYear});
      }

      // dans tous les cas, on rafraîchit la liste des années en fonction des données fournies par l'API
      this.yearsService.getAllYears()
        .then((ys) => {
          this.cacheService.setUserYears(ys);
          this.yearOptions = ys.map(year => ({label: `${year}-${year + 1}`, value: year}));

          this.affectCurrentYearIfNoneSelected();

          this.selectedYearOption = this.selectedValues.selectedYear;
          this.onSelectedYearChanged({value: this.selectedValues.selectedYear});
        })
        .catch(error => this.errorService.showError(error))
        .finally(() => this.loadingYears = false);
    });
  }

  private affectCurrentYearIfNoneSelected(): void {
    if (this.selectedValues.selectedYear === -1) {
      const now = new Date();
      this.selectedValues.selectedYear = now.getMonth() > this.dateUtils.AUGUST ? now.getUTCFullYear() : now.getUTCFullYear() - 1;
      this.cacheService.setMainFilters(this.selectedValues);
    }
  }
  private resetSemesters(): void {
    if (this.semesterOptions) {
      this.semesterOptions.splice(0);
    }
  }

  private selectFirstSemester(): void {
    if (this.semesterOptions.length > 0) {
      this.selectedSemesterOption = this.semesterOptions[0].value;
      this.selectedValues.selectedSemesterId = this.selectedSemesterOption;
      this.cacheService.setMainFilters(this.selectedValues);
      this.semesterSelected.emit(this.selectedValues);
    }
  }

  private selectSemester(selectedSemesterId: number): void {
    if (this.semesterOptions.length > 0) {
      this.selectedSemesterOption = this.semesterOptions.find(o => o.value === selectedSemesterId) ? selectedSemesterId : this.semesterOptions[0].value;
      this.selectedValues.selectedSemesterId = this.selectedSemesterOption;
      this.cacheService.setMainFilters(this.selectedValues);
      this.semesterSelected.emit(this.selectedValues);
    }
  }

  public getSelectedSchoolName(): string {
    if (!this.schoolOptions) {
      return null;
    }
    const schools = this.schoolOptions.filter(s => s.value === this.selectedValues.selectedSchoolId);
    return schools && schools.length > 0 ? schools[0].label : null;
  }

  public getSelectedSemesterName(): string {
    if (!this.semesterOptions) {
      return null;
    }
    const schools = this.semesterOptions.filter(s => s.value === this.selectedValues.selectedSemesterId);
    return schools && schools.length > 0 ? schools[0].label : null;
  }

  public getSelectedYearName(): string {
    if (!this.yearOptions) {
      return null;
    }
    const schools = this.yearOptions.filter(s => s.value === this.selectedValues.selectedYear);
    return schools && schools.length > 0 ? schools[0].label : null;
  }

  isElementMandatory(currentField: string) {
    return this.mandatoryFields.some(field => field === currentField);
  }
}
