<app-basic-grid #grid id="grid" dataKey="block_id" gridTitle="{{title}}"
                gridType="{{gridTypes.MAIN_GRID}}"
                [data]="diplomaBlockData"
                [columns]="columns" [topColumns]="blockTopColumns" [loading]="loadingData"
                (cancel)="canceler.cancel()"
                [exportTitle]="exportTitle"
                [isTabActive]="isActive()"

                [selectedData]="selectedBlocks"
                [showRefreshButton]="true"
                [customButtonsTemplate]="rightsGroupsButtons"
                [showGlobalSearch]="true"

                [sendPublipostageVisible]="true"
                [sendEmailingVisible]="false"
                [sendNotificationVisible]="false"

                (rowDblClicked)="onDetailsRequested($event)"
                (refreshRequested)="onRefreshRequested()"
                (rowsSelectionChanged)="onRowsSelectionChanged($event)"
>
  <ng-template #rightsGroupsButtons>
    <div class="p-ml-auto p-text-left preset-button">
      <button pButton type="button" class="p-button-success p-mr-2" icon="pi pi-plus" pTooltip="Ajouter un bloc de compétence" (click)="onAddRequest()"></button>
      <button pButton type="button" class="p-button-success p-mr-2" icon="pi pi-pencil" pTooltip="Modifier le bloc de compétence sélectionné"
              (click)="onDetailsRequested(this.selectedBlocks[0])" [disabled]="this.selectedBlocks.length != 1"></button>
      <button pButton type="button" class="p-button-danger p-mr-2" icon="far fa-square" pTooltip="Désactiver le(s) blocs de compétence sélectionné(s)"
              (click)="this.onDelete()" [disabled]="this.selectedBlocks.length === 0"></button>
    </div>
  </ng-template>
</app-basic-grid>
<p-confirmDialog key="deleteBlockDialog" [style]="{width: '40vw'}">
</p-confirmDialog>

<app-diploma-skill-block-dialog #blockDialog [visible]="displaySkillBlockDetails"
                                [parents] = "diplomaBlockData"
                                (onSave)="onRefreshRequested()"
                                (onLoading)="showLoading($event)"
                                (close)="this.showLoading(false)">
</app-diploma-skill-block-dialog>
