import {Injectable} from '@angular/core';
import {UrlFactoryService} from '../factories/url-factory.service';
import {BaseService} from './base.service';
import {CancelablePromise} from '../common/promises/cancelable-promise';
import {DiplomaDTO} from '../models/diplomas/diploma-dto';
import {DiplomaTypeDTO} from '../models/diplomas/diploma-type-dto';
import {GradeDTO} from '../models/diplomas/grade-dto';
import {Observable, Subject} from 'rxjs';
import {DiplomaWithCertifierDto} from '../models/diplomas/diploma-with-certifier-dto';
import {DiplomaBlockDTO} from '../models/diplomas/diploma-block-dto';
import {CertifierDTO} from '../models/diplomas/certifierDTO';

@Injectable({
  providedIn: 'root'
})
export class DiplomasService {

  dataChanged = new Subject<any>();

  constructor(private baseService: BaseService,
              private urlFactory: UrlFactoryService) {
  }

  listen(): Observable<any> {
    return this.dataChanged.asObservable();
  }

  refreshData(): void {
    this.dataChanged.next();
  }

  getSchoolsDiplomas(): CancelablePromise<DiplomaDTO[]> {
    return this.baseService.getAll<DiplomaDTO>(this.urlFactory.getSchoolsDiplomasUrl());
  }


  getSchoolsDiplomasTypes(): CancelablePromise<DiplomaTypeDTO[]> {
    return this.baseService.getAll<DiplomaTypeDTO>(this.urlFactory.getSchoolsDiplomasTypesUrl());
  }

  getGrades(): CancelablePromise<GradeDTO[]> {
    return this.baseService.getAll<GradeDTO>(this.urlFactory.getGradesUrl());
  }

  saveOrUpdateSchoolsDiplomas(diplomaToSave: DiplomaWithCertifierDto): CancelablePromise<DiplomaWithCertifierDto> {
    return this.baseService.save(this.urlFactory.getSchoolsDiplomasUrl(), diplomaToSave, false);
  }

  deleteSchoolsDiplomas(diplomasToDelete: DiplomaWithCertifierDto[]): CancelablePromise<boolean> {
    return this.baseService.deleteAll(this.urlFactory.getSchoolsDiplomasUrl(), diplomasToDelete);
  }

  getDiplomaBlocksByDiplomaId(diplomaId: number): CancelablePromise<DiplomaBlockDTO[]> {
    return this.baseService.getAll<DiplomaBlockDTO>(this.urlFactory.getSchoolsDiplomasUrl() + '/' + diplomaId + '/blocks');
  }

  deleteDiplomaBlock(blockIdsToDelete: number[]): CancelablePromise<boolean> {
    return this.baseService.deleteAll(this.urlFactory.getSchoolsDiplomasUrl() + '/blocks', blockIdsToDelete);
  }

  addDiplomaBlockForDiplomaId(diplomaId: number, toAddDto: DiplomaBlockDTO): CancelablePromise<DiplomaBlockDTO> {
    return this.baseService.save(this.urlFactory.getSchoolsDiplomasUrl() + '/' + diplomaId + '/blocks', toAddDto, false);
  }

  getDiplomaBlockByBlockId(blockId: number): CancelablePromise<DiplomaBlockDTO> {
    return this.baseService.get<DiplomaBlockDTO>(this.urlFactory.getStructureDiplomaBlocksUrl() + '/' + blockId);
  }

  updateDiplomaBlockByBlockId(blockId: number, dto: DiplomaBlockDTO): CancelablePromise<boolean> {
    return this.baseService.patchOne<DiplomaBlockDTO>(this.urlFactory.getStructureDiplomaBlocksUrl() + '/' + blockId, dto);
  }

  getAllDiplomaBlocksByNameLike(name: string): CancelablePromise<DiplomaBlockDTO[]> {
    return this.baseService.getAll<DiplomaBlockDTO>(this.urlFactory.getStructureDiplomaBlocksUrl() + '/byName/' + name);
  }

  getStructuresDiplomasWithStructure(): CancelablePromise<DiplomaWithCertifierDto[]> {
    return this.baseService.getAll<DiplomaWithCertifierDto>(this.urlFactory.getSchoolsDiplomasWithCertifierUrl());
  }

  getCertifiers(): CancelablePromise<CertifierDTO[]> {
    return this.baseService.getAll<CertifierDTO>(this.urlFactory.getSchoolsDiplomasUrl() + '/certifiers');
  }
}
