import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {Table} from 'primeng/table/table';
import {TableColumn} from 'src/app/models/table-column';
import {CustomToolbarButton} from 'src/app/models/custom-toolbar-button';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {FilterMatchMode, FilterService, MenuItem, MessageService, PrimeNGConfig, SelectItem} from 'primeng/api';
import {CustomToolbarSplitButton} from '../../../models/custom-toolbar-split-button';
import {DatePipe} from '@angular/common';
import {FileService} from '../../../services/file.service';
import {StringUtils} from '../../../common/utils/string-utils';
import {FilterTypeEnum} from '../../../utils/filterTypeEnum';

export enum GridTypes {
  MAIN_GRID = ('main-grid'),
  REGULAR_GRID = ('regular-grid'),
  QUERY_GRID = ('query-grid'),
  RIGHT_GRID = ('right-grid'),
  DIPLOMA_GRID = ('diploma-grid')
}

@Component({
  selector: 'app-basic-grid',
  templateUrl: './basic-grid.component.html',
  styleUrls: ['./basic-grid.component.scss']
})
export class BasicGridComponent implements OnInit, AfterViewInit {

  @ViewChild('dt') table: Table;
  @Input() gridType: GridTypes = GridTypes.REGULAR_GRID;

  @Input() isDisabled = false;

  @Input() customButtonsTemplate: TemplateRef<any>;

  @Input() gridTitle = '';
  private baseExportTitle = 'Export';
  @Input() exportTitle = this.baseExportTitle;

  isTabActiveValue = false;
  @Input() heightToKeep = 0;
  @Input() rowToRemove = 0;

  @Input()
  get isTabActive() {
    return this.isTabActiveValue;
  }

  set isTabActive(val) {
    this.isTabActiveValue = val;
    if (this.isTabActiveValue === true) {
      setTimeout(() => this.calculateRows(), 0);
    }
  }

  @Input() selectedData: any[];

  //#region outputs
  @Output() customToolbarButtonClicked = new EventEmitter<string>();
  @Output() rowsSelectionChanged = new EventEmitter<any[]>();
  @Output() refreshRequested = new EventEmitter<any>();
  @Output() addRequested = new EventEmitter<any>();
  @Output() deleteSelectedRequested = new EventEmitter<any[]>();
  @Output() showRequested = new EventEmitter<any>();
  @Output() sendPublipostageClicked = new EventEmitter<any>();
  @Output() sendEmailingClicked = new EventEmitter<any>();
  @Output() sendSmsClicked = new EventEmitter<any>();
  @Output() sendNotificationClicked = new EventEmitter<any>();
  @Output() rowDblClicked = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  //#endregion

  //#region customToolbarButtons
  private mCustomToolbarButtons: CustomToolbarButton[];

  public get customToolbarButtons() {
    return this.mCustomToolbarButtons;
  }

  @Input()
  public set customToolbarButtons(value) {
    this.mCustomToolbarButtons = value;
  }

  //#endregion

  //#region customToolbarButtons
  private mCustomToolbarSplitButtons: CustomToolbarSplitButton[];

  public get customToolbarSplitButtons() {
    return this.mCustomToolbarSplitButtons;
  }

  @Input()
  public set customToolbarSplitButtons(value) {
    this.mCustomToolbarSplitButtons = value;
  }

  //#endregion

  //#region data
  private mAllData: any[];
  mData: any[];

  get data(): any[] {
    return this.mData;
  }

  @Input()
  set data(value: any[]) {
    this.mAllData = value;
    this.mData = value;

    this.selectedOptions = {};

    if (this.columns && this.columns.length > 0) {

      const options = this.getColumnsWithDropdownFilter();

      options.forEach(option => this.options[option] = this.mData
        .map(d => ({label: d[option], value: d[option]}))
        .filter((v, i, array) => array.findIndex(t => t.value === v.value) === i)
        .sort((a, b) => a.value > b.value ? 1 : -1));
    }
    this.calculateRows();

    this.dataChanged.emit(this.mData);
  }

  @Output() dataChanged = new EventEmitter<any[]>();
  //#endregion

  //#region columns
  mColumns: TableColumn[];

  get columns(): TableColumn[] {
    return this.mColumns;
  }

  @Input('columns')
  set columns(value: TableColumn[]) {
    this.mColumns = value;
    this.columnsChanged.emit(this.mColumns);
  }

  @Output() columnsChanged = new EventEmitter<TableColumn[]>();

  @Input() globalFilterFields: any[];

  //#endregion

  parentDivWidth = 0;

  //region publipostage
  displayPublipostageDialog = false;
  displayPublipostageDownloadDialog = false;
  publipostageMode = 'existingDocument';
  publipostageSelectedFiles: any[];
  //endregion

  customFilterName = 'strict-equals';
  matchModeOptions: SelectItem[] = [
    {label: 'Valeur exacte', value: this.customFilterName},
    {label: 'Commence par...', value: FilterMatchMode.STARTS_WITH},
    {label: 'Contient...', value: FilterMatchMode.CONTAINS},
  ];

  options: { [key: string]: any[]; } = {};
  selectedOptions: { [key: string]: string[]; } = {};
  rows = 0;

  @Input() loading = true;
  @Input() gridWidth = 200;

  @Input() dataKey = 'id';
  @Input() topColumns: any[];
  @Input() showRefreshButton = true;
  @Input() showAddButton = false;
  @Input() showDeleteButton = false;
  @Input() showShowButton = false;
  @Input() totalHeight = 0;

  @Input() sendExportExcelVisible = true;
  @Input() sendExportPDFVisible = true;
  @Input() sendPublipostageVisible = true;
  @Input() sendEmailingVisible = true;
  @Input() sendSmsVisible = true;
  @Input() sendNotificationVisible = true;

  @Input() scrollable = true;

  //region scrollSizeInPx
  mScrollSizeInPx = 0;
  @Output() scrollSizeInPxChanged = new EventEmitter<number>();

  @Input()
  get scrollSizeInPx(): number {
    return this.mScrollSizeInPx;
  }

  set scrollSizeInPx(val: number) {
    this.mScrollSizeInPx = val;
    this.scrollSizeInPxChanged.emit(val);
  }

  //endregion

  //region bottomSize
  mBottomSize = 0;
  @Output() bottomSizeChanged = new EventEmitter<number>();

  @Input()
  get bottomSize(): number {
    return this.mBottomSize;
  }

  set bottomSize(val: number) {
    this.mBottomSize = val;
    this.bottomSizeChanged.emit(val);
  }

  //endregion

  //region globalSearch
  @Input() showGlobalSearch = false;
  searchInput = '';
  //region exportSplitButton
  exportItems: MenuItem[];
  //endregion
  //region moreActionsButton
  moreActionItems: MenuItem[] = [];

  //endregion

  private formatString(stringToConvert: string): string {
    return stringToConvert.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
  }

  onResetSearchInput(): void {
    if (this.searchInput) {
      this.searchInput = '';
      this.mData = this.mAllData;
    }
  }

  searchGlobal(event: any): void {
    if (event != null) {
      const stringToSearch = this.formatString(event);
      const filteredData: any[] = [];
      this.mAllData.forEach(element => {
        for (const column of this.columns) {
          if (element[column.field] != null && this.formatString(element[column.field].toString()).indexOf(stringToSearch) >= 0) {
            filteredData.push(element);
            break;
          }
        }
      });

      this.mData = filteredData;
    }
  }

  updateDataAfterChange(newData: any[]): void {
    this.mData = newData;
    this.mAllData = newData;
    this.handleGridBorder();
    this.searchGlobal(this.searchInput);
  }

  //#endregion

  constructor(private filterService: FilterService,
              private config: PrimeNGConfig,
              private fileService: FileService,
              private messageService: MessageService,
              public datePipe: DatePipe) {
  }

  ngOnInit(): void {
    this.filterService.register(this.customFilterName, (value, filter): boolean => {
      if (filter === undefined || filter === null || filter.trim() === '') {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      return value.toString() === filter.toString();
    });

    this.config.setTranslation({
      accept: 'Accepter',
      reject: 'Annuler',
      apply: 'Appliquer',
      addRule: 'Ajouter une règle',
      cancel: 'Annuler',
      clear: 'Effacer',
      removeRule: 'Retirer la règle'
    });
    this.initExportItems();
  }

  ngAfterViewInit(): void {
    this.parentDivWidth = this.table?.el?.nativeElement?.children[0]?.offsetWidth;
    setTimeout(() => this.calculateRows(), 0);
  }

  initExportItems(): void {
    this.exportItems = [
      {
        label: 'Export Excel',
        icon: 'pi pi-file-excel',
        command: () => this.exportExcel(),
        visible: this.sendExportExcelVisible
      },
      {
        label: 'Export PDF',
        icon: 'pi pi-file-pdf',
        command: () => this.exportPdf(),
        visible: this.sendExportPDFVisible
      },
      {
        label: 'Publipostage',
        icon: 'pi pi-file-o',
        command: () => this.displayPublipostageDialog = true,
        visible: this.sendPublipostageVisible
      },
      {
        label: 'E-mailing',
        icon: 'pi pi-envelope',
        command: () => this.sendEmailing(),
        visible: this.sendEmailingVisible
      },
      {label: 'Envoi SMS', icon: 'pi pi-phone', command: () => this.sendSms(), visible: this.sendSmsVisible},
      {
        label: 'Notification',
        icon: 'pi pi-exclamation-circle',
        command: () => this.sendNotification(),
        visible: this.sendNotificationVisible
      },
    ];
  }

  onCustomToolbarButtonClicked(buttonIndex): void {
    this.customToolbarButtonClicked.emit(this.customToolbarButtons[buttonIndex].buttonId);
  }

  onRowSelect(): void {
    this.rowsSelectionChanged.emit(this.selectedData);
  }

  onRowUnselect(): void {
    this.rowsSelectionChanged.emit(this.selectedData);
  }

  onHeaderCheckboxToggle(): void {
    this.rowsSelectionChanged.emit(this.selectedData);
  }

  exportPdf(): void {
    // noinspection JSPotentiallyInvalidConstructorUsage
    const doc = new jsPDF(this.columns.length > 6 ? 'l' : 'p');

    const fields = this.columns.map(o => o.field);
    const headers = [this.columns.map(o => o.header)];

    autoTable(doc, {
      head: headers,
      body: this.data.map(d => fields.map(k => {
        const dataType = this.columns.find(o => o.field === k);

        if (dataType?.type === 'boolean') {
          return d[k] ? 'oui' : 'non';
        } else if (dataType?.type === 'date') {
          return new Date(d[k]).toLocaleDateString();
        }
        return d[k];
      })),
      didDrawPage: (dataArg) => {
        doc.text(this.exportTitle.length > 0 ? this.exportTitle : this.baseExportTitle, dataArg.settings.margin.left, 10);
      }
    });

    doc.save(`${this.exportTitle.length > 0 ? this.exportTitle : this.baseExportTitle}.pdf`);
  }

  exportExcel(): void {
    const excelRawData = JSON.parse(JSON.stringify(this.selectedData && this.selectedData.length > 0 ? this.selectedData : this.data));

    const excelBuffer: Blob = this.fileService.createFileBlobFromRawDataAndColumns(excelRawData, this.columns, this.topColumns);
    this.fileService.saveBlobAsExcelFile(excelBuffer, this.exportTitle.length > 0 ? this.exportTitle : this.baseExportTitle);
  }

  refresh(): void {
    this.table.selectionKeys = {};
    this.selectedData = [];
    this.refreshRequested.emit(true);
  }

  add(): void {
    this.addRequested.emit();
  }

  deleteSelected(): void {
    this.deleteSelectedRequested.emit();
  }

  showSelectedElement(): void {
    if (this.selectedData && this.selectedData.length === 1) {
      this.showRequested.emit(this.selectedData[0]);
    }
  }

  filterValues(): void {
    const options = this.getColumnsWithDropdownFilter();
    let data = [...this.mAllData];

    options.forEach(opt => {
      if (this.selectedOptions[opt] && this.selectedOptions[opt].length > 0) {
        data = data.filter(d => this.selectedOptions[opt].findIndex(o => o === d[opt]) !== -1);
      }
    });

    this.mData = [...data];
  }

  sendEmailing(): void {
    this.sendEmailingClicked.emit();
  }

  sendSms(): void {
    this.sendSmsClicked.emit();
  }

  sendNotification(): void {
    this.sendNotificationClicked.emit();
  }

  onRowClicked(event, rowData: any): void {
    // si l'on a cliqué sur la checkbox de la ligne, la sélection est déjà gérée et ne doit l'être ici
    if (event.target.classList.contains('p-checkbox-box') || this.isDisabled) {
      return;
    }

    if (!this.selectedData) {
      this.selectedData = [];
    }

    const index = this.selectedData.findIndex(o => o[this.dataKey] === rowData[this.dataKey]);

    if (index > -1) {
      this.selectedData.splice(index, 1);
    }
    // cette condition détermine si la checkbox a servi à décocher la ligne courante ou non
    else if (!event.target.classList.contains('p-checkbox-icon')) {
      this.selectedData.push(rowData);
    }

    this.selectedData = [...this.selectedData];
    this.rowsSelectionChanged.emit(this.selectedData);
  }

  hasAnySendButton(): boolean {
    return this.sendExportExcelVisible || this.sendExportPDFVisible || this.sendPublipostageVisible || this.sendEmailingVisible || this.sendSmsVisible || this.sendNotificationVisible;
  }

  onDblClick(event: any): void {
    this.rowDblClicked.emit(event);
  }

  cancelRequest(): void {
    this.cancel.emit('cancel');
  }

  @HostListener('window:resize', ['$event'])
  calculateRows(): void {
    if (this.isTabActive && this.table != null && this.table.containerViewChild != null && this.table.containerViewChild.nativeElement != null) {
      const lineHeight = 24;
      const titleHeight = 45;
      const buttonsHeight = 58;
      const headerFooterHeights = 200;
      const headerFooterAndColumnFamilyHeights = 243;
      const topPosition = this.table.containerViewChild.nativeElement.getElementsByTagName('tbody')[0].getBoundingClientRect().top;

      // Calcul de la hauteur restante
      let availableHeight = window.innerHeight - topPosition;
      if (this.gridTitle == null || this.gridTitle.trim().length === 0) {
        availableHeight += titleHeight; // Si la grid ne possède pas de titre
      }
      if (this.isButtonsRowNotDisplayed()) {
        availableHeight += buttonsHeight; // Si la grid ne possède pas de bouttons
      }

      if (this.gridType === GridTypes.RIGHT_GRID) {
        const heightUsedByOtherTabPanel = 50;
        availableHeight -= heightUsedByOtherTabPanel; // La grid de la page right est contenue dans un autre tab-panel
      }

      // division par la taille d'une ligne (24px) plus réduction de 2 pour le scroll horizontal et pagination
      if (Math.round((availableHeight / lineHeight)) > 0) {
        this.rows = Math.round((availableHeight / lineHeight) - 2 - this.rowToRemove);
        if (this.gridType === GridTypes.MAIN_GRID) {
          // Mise à jour de la taille de la grid quand elle a des famille de colonne
          this.table.containerViewChild.nativeElement.style.height = lineHeight * this.rows + headerFooterAndColumnFamilyHeights + 'px';
        } else {
          // Mise à jour de la taille de la grid quand elle n'a pas de famille de colonne
          this.table.containerViewChild.nativeElement.style.height = lineHeight * this.rows + headerFooterHeights - this.heightToKeep + 'px';
        }
      }
      this.handleGridBorder();
    }
  }

  private isButtonsRowNotDisplayed(): boolean {
    return this.showAddButton === false && this.showDeleteButton === false && this.showShowButton === false && this.customButtonsTemplate == null;
  }

  private handleGridBorder(): void {
    if (this.mData === undefined || this.mData === null || this.mData.length === 0) {
      this.table.containerViewChild.nativeElement.getElementsByClassName('p-datatable-scrollable-body')[0].style.borderStyle = 'none';
    } else {
      this.table.containerViewChild.nativeElement.getElementsByClassName('p-datatable-scrollable-body')[0].style.borderStyle = 'solid';
    }
  }

  isFilterActivated(col: TableColumn, inputType: string): boolean {
    return col.filterType != null && col.filterType.includes(inputType) && col.type !== 'boolean';
  }

  private getColumnsWithDropdownFilter(): string[] {
    return this.columns.filter(c => c.filterType != null && c.filterType.includes(FilterTypeEnum.DROPDOWN_FILTER)).map(c => c.field);
  }

  onDownloadPublipostageModelFiles(): void {
    this.displayPublipostageDialog = false;
    this.displayPublipostageDownloadDialog = false;

    if (this.publipostageSelectedFiles[0] && this.publipostageSelectedFiles[0].size === 0) {
      this.messageService.add({
        summary: 'Le document existant ne peut pas être complètement vide.',
        severity: 'error',
        life: 10000
      });
      return;
    }

    this.loading = true;
    const excelRawData = JSON.parse(JSON.stringify(this.selectedData && this.selectedData.length > 0 ? this.selectedData : this.data));

    const data: Blob = this.fileService.createFileBlobFromRawDataAndColumns(excelRawData, this.columns, this.topColumns);
    const filename = StringUtils.getRandomString(10);

    if (this.publipostageSelectedFiles[0]) {

      this.fileService.getPublipostedWordFileFromPassedWordFile(
        this.fileService.blobToFile(data, filename),
        this.publipostageSelectedFiles
        && this.publipostageSelectedFiles.length
          ? this.publipostageSelectedFiles[0]
          : null)
        .then(() => {
          this.fileService.saveBlobAsExcelFile(data, filename);
          this.loading = false;
        });
    } else {
      this.fileService.getPublipostedWordFileWithoutWordFile(
        this.fileService.blobToFile(data, filename)
      )
        .then(() => {
          this.fileService.saveBlobAsExcelFile(data, filename);
          this.loading = false;
        });
    }

    this.resetPublipostageFiles();
  }

  importOnClick(event: any): void {
    this.publipostageSelectedFiles = event.currentFiles;
  }

  resetPublipostageSelectedFiles(): void {
    this.publipostageSelectedFiles.splice(0);
  }

  onValidatePublipostage(event: MouseEvent): void {
    this.displayPublipostageDialog = false;
    this.displayPublipostageDownloadDialog = true;
  }

  resetPublipostageFiles(): void {
    this.publipostageSelectedFiles = [];
  }

  removeAllSelection(): void {
    this.selectedData = [];
  }
}
