import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Predicate,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {TabItem} from 'src/app/models/tab-item';
import {TabDirective} from 'src/app/directives/tab.directive';
import {TabComponent} from '../tab.component';
import {TabView} from 'primeng/tabview';
import {PagesService} from '../../services/pages.service';
import {AbstractPageComponent} from '../../interfaces/abstract-page.component';
import {StringUtils} from '../../utils/string-utils';

@Component({
  selector: 'app-tab-layout',
  templateUrl: './tab-layout.component.html',
  styleUrls: ['./tab-layout.component.scss']
})
export class TabLayoutComponent implements OnInit {

  @Input() tab: TabItem;

  currentTabIndex = 0;

  tabs: TabItem[] = [];

  helps: { [id: string]: string; } = { null: 'test' };

  @ViewChild('closableTabView') closableTabView: TabView;
  @ViewChildren(TabDirective) appTab: QueryList<TabDirective>;

  @Output() tabSelected = new EventEmitter<any>();

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private pagesService: PagesService) { }

  ngOnInit(): void {}

  loadComponent() {
    const tabItem = this.tabs[this.tabs.length - 1];

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(tabItem.component);

    const viewContainerRef = this.appTab.last.viewContainerRef;
    viewContainerRef.clear();

    const pageId = StringUtils.getRandomString(10);
    const componentRef = viewContainerRef.createComponent<TabComponent>(componentFactory);

    tabItem.data[`pageId`] = pageId;
    componentRef.instance.data = tabItem.data;
    componentRef.instance[`extraData`] = tabItem.data.extraData;

    if (componentRef.instance[`extraData`] == null) {
      componentRef.instance[`extraData`] = {};
    }
    componentRef.instance[`extraData`][`pageId`] = pageId;
    componentRef.instance[`tabLayout`] = this;

    this.currentTabIndex = 0;
    this.currentTabIndex = this.tabs.length;
    this.onTabChanged({index : this.currentTabIndex });
    this.scrollToActiveTab();

  }

  private scrollToActiveTab() {
    const destinationY = 93 + this.tabs.slice(0, this.currentTabIndex)
      .map(t => t.headerWidth)
      .reduce((accumulator, currentValue) => accumulator + currentValue);

    this.closableTabView.navbar.nativeElement.scrollTo(destinationY, 0);
  }

  onTabChanged(event: any) {
    setTimeout(() => {
      this.currentTabIndex = event.index;
      const key = this.tabs[this.currentTabIndex - 1]?.data?.id;
      this.tabSelected.emit(this.currentTabIndex > 0
        ? (key
          ? this.helps[key]
          : null)
        : null);
    }, 0);
  }

  handleClose(event: any) {
    event.close();
    this.closeTabAtIndex(event.index);
  }

  private closeTabAtIndex(index: number) {
    this.tabs.splice(index - 1, 1);
    this.tabSelected.emit(null);
    this.onClosedTabAtIndex(index);
  }

  private onClosedTabAtIndex(index: number) {
    if (index === this.currentTabIndex) {
      this.onClosedCurrentTab();
      return;
    }
    const closeTabAtLeftOfCurrentTab: boolean = index < this.currentTabIndex;
    const newCurrentIndex: number = closeTabAtLeftOfCurrentTab
      ? this.currentTabIndex - 1
      : this.currentTabIndex;
    this.onTabChanged({index: newCurrentIndex});
  }

  private onClosedCurrentTab() {
    this.currentTabIndex -= 1;
    const closeLastTab: boolean = this.currentTabIndex  >= this.tabs.length;
    const newCurrentIndex = closeLastTab
      ? this.tabs.length
      : this.currentTabIndex + 1;
    this.onTabChanged({index: newCurrentIndex});
  }

  closeCurrentTab() {
    this.closeTabAtIndex(this.currentTabIndex);
  }

  public onAddTab(type: string) {
    this.addTab(type, undefined, o => o.data.id === type && !o.data.extraData);
  }

  public onAddTabWithData(type: string, data: any) {
    this.addTab(type, data, o => o.data.id === type && o.data.extraData.key === data.key);
  }

  public onAddTabWithDataAndHeader(type: string, data: any, header: string) {
    this.addTab(type, data, o => o.data.id === type && o.data.extraData.key === data.key, header);
  }

  private addTab(type: string, data: any, predicate: Predicate<any>, headerName: string = null) {
    const existingTabIndex = this.tabs.findIndex(predicate);

    // on laisse la possibilité à l'utilisateur de dupliquer l'écran des requêtes
    if (existingTabIndex !== -1 && !type.includes(PagesService.QUERY) && !type.includes(PagesService.REPORT)) {
      this.currentTabIndex = existingTabIndex + 1; // on décale pour tenir compte de l'accueil
      this.scrollToActiveTab();
      return;
    }

    // on renomme les requêtes lorsqu'il s'agit de doublons
    if (type.includes(PagesService.QUERY) || type.includes(PagesService.REPORT)) {
      const existingQueries = this.tabs.filter(page => page.header.includes(headerName));
      let finalIndex = 0;
      if (existingQueries.length > 0) {
        for (const query of existingQueries) {
          query.header = headerName + ' (' + (finalIndex + 1) + ')';
          finalIndex++;
        }
        headerName = headerName + ' (' + (finalIndex + 1) + ')';
      }
    }

    const componentsMapElement = this.pagesService.getComponentsMap()[type];

    if (componentsMapElement === undefined) {
      return;
    }

    const createdComponentForHelp = this.instantiateClass(componentsMapElement.type);
    this.helps[type] = createdComponentForHelp.getHelp();

    this.tabs.push(new TabItem(
      componentsMapElement.type,
      headerName ? headerName : componentsMapElement.header,
      data?.headerAddition,
      this.getHeaderWidth(componentsMapElement.header, data?.headerAddition),
      {id: type, extraData: data, ...componentsMapElement.data}));

    setTimeout(() => {
      this.loadComponent();
    }, 0);
  }

  // noinspection JSMethodCanBeStatic
  private instantiateClass<T extends AbstractPageComponent>(Clazz: new() => T ) {
    return new Clazz();
  }

  private getHeaderWidth(header, headerAddition: any) {
    return (header.length * 6.5) + 110 + ((headerAddition) ? (headerAddition.length * 6.5) : 0);
  }
}
