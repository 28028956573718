import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import {AuthenticatedUserService} from './authenticated-user.service';
import {Linked} from '../models/linked';
import {Page} from '../models/page';
import {saveAs} from 'file-saver';
import {CancelablePromise} from '../common/promises/cancelable-promise';
import {Canceler} from '../common/promises/request-canceler';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(private http: HttpClient,
              private authenticatedUserService: AuthenticatedUserService) {
  }

  get<T>(url: string): CancelablePromise<T> {
    const canceler = new Canceler();

    const callback = (resolve, reject) => {
      canceler.cancel = () => {
        reject('Request canceled');
      };
      this.http.get(url,
        {
          headers: {'content-type': 'application/json'},
          responseType: 'json',
          observe: 'response'
        }
      )
        .subscribe(
          (data) => {
            if (data.status === 204) {
              resolve();
            } else if (data.body && data.body[`response_code`] === 0) {
              resolve(data.body[`result`]);
            } else {
              reject(data.body ? data.body[`result`] : '');
            }
          },
          (error) => {
            if (error.status === 401) {
              this.authenticatedUserService.removeToken();
              localStorage.removeItem('kordisToken');
              setTimeout(() => {
                this.authenticatedUserService.redirectToAuthentication();
              }, 1000);
            } else {
              reject(error);
            }
          }
        );
    };

    return new CancelablePromise<T>(callback, canceler);
  }

  getFile(url: string, accept: string, filename: string): CancelablePromise<boolean> {
    const canceler = new Canceler();

    const callback = (resolve, reject) => {
      canceler.cancel = () => {
        reject('Request canceled');
      };
      this.http.get(url,
        {
          headers: {Accept: accept},
          responseType: 'blob',
          observe: 'response'
        }
      )
        .subscribe(
          (data) => {
            if (data.status === 204) {
              resolve();
            } else if (data.body) {
              saveAs(data.body, filename);
              resolve();
            } else {
              reject(data.body ? data.body[`result`] : '');
            }
          },
          (error) => {
            if (error.status === 401) {
              this.authenticatedUserService.removeToken();
              this.authenticatedUserService.redirectToAuthentication();
            } else {
              reject(error);
            }
          }
        );
    };

    return new CancelablePromise<boolean>(callback, canceler);
  }

  getAll<T>(url: string): CancelablePromise<T[]> {
    const canceler = new Canceler();
    const callback = (resolve, reject) => {
      canceler.cancel = () => {
        reject('Request canceled');
      };

      this.http.get(url, {
        headers: {'content-type': 'application/json'},
        responseType: 'json',
        observe: 'response'
      })
        .subscribe(
          (data) => {
            if (data.status === 204) {
              resolve([]);
            } else if (data.body && data.body[`response_code`] === 0) {
              resolve(data.body[`result`]);
            } else {
              reject(data.body ? data.body[`result`] : []);
            }
          },
          (error) => {
            if (error.status === 401) {
              this.authenticatedUserService.removeToken();
              this.authenticatedUserService.redirectToAuthentication();
            } else {
              reject(error);
            }
          }
        );
    };
    return new CancelablePromise<T[]>(callback, canceler);
  }

  getAllFiltered<T>(url: string, filters: any): CancelablePromise<T[]> {
    const canceler = new Canceler();

    const callback = (resolve, reject) => {
      canceler.cancel = () => {
        reject('Request canceled');
      };
      this.http.post(url, JSON.stringify(filters), {
        headers: {'content-type': 'application/json'},
        responseType: 'json',
        observe: 'response'
      })
        .subscribe(
          (data) => {
            if (data.body && data.body[`response_code`] === 0) {
              resolve(data.body[`result`]);
            } else {
              reject(data.body[`result`]);
            }
          },
          (error) => {
            if (error.status === 401) {
              this.authenticatedUserService.removeToken();
              this.authenticatedUserService.redirectToAuthentication();
            } else {
              reject(error);
            }
          }
        );
    };

    return new CancelablePromise<T[]>(callback, canceler);
  }

  getFiltered<T>(url: string, filters: any): CancelablePromise<T> {
    const canceler = new Canceler();
    const callback = (resolve, reject) => {
      canceler.cancel = () => {
        reject('Request canceled');
      };
      this.http.post(url, JSON.stringify(filters), {
        headers: {'content-type': 'application/json'},
        responseType: 'json',
        observe: 'response'
      })
        .subscribe(
          (data) => {
            if (data.body && data.body[`response_code`] === 0) {
              resolve(data.body[`result`]);
            } else {
              reject(data.body[`result`]);
            }
          },
          (error) => {
            if (error.status === 401) {
              this.authenticatedUserService.removeToken();
              this.authenticatedUserService.redirectToAuthentication();
            } else {
              reject(error);
            }
          }
        );
    };

    return new CancelablePromise<T>(callback, canceler);
  }

  getPage<T>(url: string, page: number): CancelablePromise<Page<T>> {
    const canceler = new Canceler();

    const callback = (resolve, reject) => {
      canceler.cancel = () => {
        reject('Request canceled');
      };
      this.http.get(url + '?page=' + page, {
        headers: {'content-type': 'application/json'},
        responseType: 'json',
        observe: 'response'
      })
        .subscribe(
          (data) => {
            if (data.status === 204) {
              resolve(undefined);
            } else if (data.body && data.body[`response_code`] === 0) {
              resolve(data.body[`result`]);
            } else {
              reject(data.body ? data.body[`result`] : []);
            }
          },
          (error) => {
            if (error.status === 401) {
              this.authenticatedUserService.removeToken();
              this.authenticatedUserService.redirectToAuthentication();
            } else {
              reject(error);
            }
          }
        );
    };

    return new CancelablePromise<Page<T>>(callback, canceler);
  }

  add<T>(url: string, element: T, force: boolean): CancelablePromise<T> {
    const canceler = new Canceler();

    let urlToSend = url;
    if (force) {
      urlToSend += urlToSend.includes('?') ? '&' : '?';
      urlToSend += 'force=true';
    }
    const callback = (resolve, reject) => {
      canceler.cancel = () => {
        reject('Request canceled');
      };
      this.http.post(urlToSend, JSON.stringify(element), {
        headers: {'content-type': 'application/json'},
        responseType: 'json',
        observe: 'response'
      })
        .subscribe(
          (data) => {
            if (data.body && data.body[`response_code`] === 0) {
              resolve(data.body[`result`]);
            } else {
              reject(data.body[`result`]);
            }
          },
          (error) => {
            if (error.status === 401) {
              this.authenticatedUserService.removeToken();
              this.authenticatedUserService.redirectToAuthentication();
            } else {
              reject(error);
            }
          }
        );
    };

    return new CancelablePromise<T>(callback, canceler);
  }

  save<T, R>(url: string, element: T, force: boolean): CancelablePromise<R> {
    const canceler = new Canceler();
    let urlToSend = url;
    if (force) {
      urlToSend += urlToSend.includes('?') ? '&' : '?';
      urlToSend += 'force=true';
    }
    const callback = (resolve, reject) => {
      canceler.cancel = () => {
        reject('Request canceled');
      };
      this.http.post(urlToSend, JSON.stringify(element), {
        headers: {'content-type': 'application/json'},
        responseType: 'json',
        observe: 'response'
      })
        .subscribe(
          (data) => {
            if (data.status === 204) {
              resolve();
            } else if (data.body && data.body[`response_code`] === 0) {
              resolve(data.body[`result`]);
            } else {
              reject(data.body[`result`]);
            }
          },
          (error) => {
            if (error.status === 401) {
              this.authenticatedUserService.removeToken();
              this.authenticatedUserService.redirectToAuthentication();
            } else {
              reject(error);
            }
          }
        );
    };

    return new CancelablePromise(callback, canceler);
  }

    callConflicts<T, R>(url: string, element: T): CancelablePromise<R[]> {
    const canceler = new Canceler();

    const callback = (resolve, reject) => {
      canceler.cancel = () => {
        reject('Request canceled');
      };
      this.http.post(url, JSON.stringify(element), {
        headers: {'content-type': 'application/json'},
        responseType: 'json',
        observe: 'response'
      })
        .subscribe(
          (data) => {
            if (data.status === 204) {
              resolve([]);
            } else if (data.body && data.body[`response_code`] === 0) {
              resolve(data.body[`result`]);
            } else {
              reject(data.body[`result`]);
            }
          },
          (error) => {
            if (error.status === 401) {
              this.authenticatedUserService.removeToken();
              this.authenticatedUserService.redirectToAuthentication();
            } else {
              reject(error);
            }
          }
        );
    };

    return new CancelablePromise<R[]>(callback, canceler);
  }

  update<T extends Linked>(element: T, force: boolean): CancelablePromise<T> {
    const canceler = new Canceler();

    let url = element._links[`self`][`href`];
    if (force) {
      url += url.includes('?') ? '&' : '?';
      url += 'force=true';
    }

    delete element._links;

    const callback = (resolve, reject) => {
      canceler.cancel = () => {
        reject('Request canceled');
      };
      this.http.put(url, JSON.stringify(element), {
        headers: {'content-type': 'application/json'},
        responseType: 'json',
        observe: 'response'
      })
        .subscribe(
          (data) => {
            if (data.body[`response_code`] === 0) {
              resolve(data.body[`result`]);
            } else {
              reject(data.body[`result`]);
            }
          },
          (error) => {
            if (error.status === 401) {
              this.authenticatedUserService.removeToken();
              this.authenticatedUserService.redirectToAuthentication();
            } else {
              reject(error);
            }
          }
        );
    };

    return new CancelablePromise(callback, canceler);
  }

  updateUnlinked<T, R>(url: string, element: T, force: boolean = false): CancelablePromise<R> {
    const canceler = new Canceler();

    if (force) {
      url += url.includes('?') ? '&' : '?';
      url += 'force=true';
    }
    const callback = (resolve, reject) => {
      canceler.cancel = () => {
        reject('Request canceled');
      };
      this.http.put(url, JSON.stringify(element), {
        headers: {'content-type': 'application/json'},
        responseType: 'json',
        observe: 'response'
      })
        .subscribe(
          (data) => {
            if (data.body[`response_code`] === 0) {
              resolve(data.body[`result`]);
            } else {
              reject(data.body[`result`]);
            }
          },
          (error) => {
            if (error.status === 401) {
              this.authenticatedUserService.removeToken();
              this.authenticatedUserService.redirectToAuthentication();
            } else {
              reject(error);
            }
          }
        );
    };

    return new CancelablePromise<R>(callback, canceler);
  }

  patch<T>(url: string, bodyElements?: T[]): CancelablePromise<boolean> {
    const canceler = new Canceler();

    const callback = (resolve, reject) => {
      canceler.cancel = () => {
        reject('Request canceled');
      };
      this.http.patch(url, bodyElements, {
        headers: {'content-type': 'application/json'},
        responseType: 'json',
        observe: 'response'
      })
        .subscribe(
          (data) => {
            if (data.ok) {
              resolve();
            } else {
              reject();
            }
          },
          (error) => {
            if (error.status === 401) {
              this.authenticatedUserService.removeToken();
              this.authenticatedUserService.redirectToAuthentication();
            } else {
              reject(error);
            }
          }
        );
    };

    return new CancelablePromise<boolean>(callback, canceler);
  }

  patchOne<T>(url: string, bodyElement?: T): CancelablePromise<boolean> {
    const canceler = new Canceler();

    const callback = (resolve, reject) => {
      canceler.cancel = () => {
        reject('Request canceled');
      };
      this.http.patch(url, bodyElement, {
        headers: {'content-type': 'application/json'},
        responseType: 'json',
        observe: 'response'
      })
        .subscribe(
          (data) => {
            if (data.ok) {
              resolve();
            } else {
              reject();
            }
          },
          (error) => {
            if (error.status === 401) {
              this.authenticatedUserService.removeToken();
              this.authenticatedUserService.redirectToAuthentication();
            } else {
              reject(error);
            }
          }
        );
    };

    return new CancelablePromise<boolean>(callback, canceler);
  }

  deleteFromUrl(url: string): CancelablePromise<boolean> {
    const canceler = new Canceler();

    const callback = (resolve, reject) => {
      canceler.cancel = () => {
        reject('Request canceled');
      };
      this.http.delete(url, {
        headers: {'content-type': 'application/json'},
        responseType: 'json',
        observe: 'response'
      })
        .subscribe(
          (data) => {
            resolve(true);
          },
          (error) => {
            if (error.status === 401) {
              this.authenticatedUserService.removeToken();
              this.authenticatedUserService.redirectToAuthentication();
            } else {
              reject(error);
            }
          }
        );
    };
    return new CancelablePromise<boolean>(callback, canceler);
  }

  deleteAll<T>(url: string, bodyElements: T[]): CancelablePromise<boolean> {
    const canceler = new Canceler();

    const callback = (resolve, reject) => {
      canceler.cancel = () => {
        reject('Request canceled');
      };
      const options = {
        headers: new HttpHeaders({'Content-Type': 'application/json'}),
        body: bodyElements
      };

      return this.http
        .delete(url, options)
        .subscribe((data) => {
            resolve(true);
          },
          (error) => {
            if (error.status === 401) {
              this.authenticatedUserService.removeToken();
              this.authenticatedUserService.redirectToAuthentication();
            } else {
              reject(error);
            }
          }
        );
    };
    return new CancelablePromise<boolean>(callback, canceler);
  }

  delete<T extends Linked>(element: T): CancelablePromise<boolean> {
    return this.deleteFromUrl(element._links[`self`][`href`]);
  }

  downloadByteFile(url: string, element: any, accept: string, filename: string): CancelablePromise<any> {
    const canceler = new Canceler();

    const callback = (resolve, reject) => {
      canceler.cancel = () => {
        reject('Request canceled');
      };
      this.http.post(url, element,
        {
          headers: {Accept: accept},
          responseType: 'blob',
          observe: 'response'
        }
      )
        .subscribe(
          (data) => {
            if (data.status === 204) {
              resolve();
            } else if (data.body) {
              saveAs(data.body, filename);
              resolve();
            } else {
              reject(data.body ? data.body[`result`] : '');
            }
          },
          (error) => {
            if (error.status === 401) {
              this.authenticatedUserService.removeToken();
              this.authenticatedUserService.redirectToAuthentication();
            } else {
              reject(error);
            }
          }
        );
    };

    return new CancelablePromise(callback, canceler);
  }

  getJson(url: string): CancelablePromise<any> {
    const canceler = new Canceler();

    const callback = (resolve, reject) => {
      canceler.cancel = () => {
        reject('Request canceled');
      };

      this.http.get(url).subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
              reject(error);
          }
        );
    };

    return new CancelablePromise(callback, canceler);
  }

  uploadFileToDownloadFile(url: string, formData: FormData, accept: string, filename: string): CancelablePromise<any> {
    const canceler = new Canceler();

    const req = new HttpRequest('POST', url, formData, {
      responseType: 'blob',
    });

    const callback = (resolve, reject) => this.http.request(req).subscribe(
      (data) => {
        canceler.cancel = () => {
          reject('Request canceled');
        };
        if (data instanceof HttpResponse) {
          if (data.status === 204 || data.status === 201) {
            resolve();
          } else if (data.body) {
            saveAs(data.body, filename);
            resolve();
          } else {
            reject(data.body[`result`]);
          }
        }
      },
      (error) => {
        if (error.status === 401) {
          this.authenticatedUserService.removeToken();
          this.authenticatedUserService.redirectToAuthentication();
        } else {
          reject(error);
        }
      });
    return new CancelablePromise(callback, canceler);
  }
}
