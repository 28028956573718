import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {SchoolDTO} from '../models/schools/school-dto';
import {environment} from '../../environments/environment';
import {TrimesterDTO} from '../models/schools/trimester-dto';
import {CancelablePromise} from '../common/promises/cancelable-promise';
import {PromotionDTO} from '../models/schools/promotion-dto';
import {ClassDTO} from '../models/schools/class-dto';
import {OptionDTO} from '../models/schools/option-dto';
import {SpecializationDTO} from '../models/schools/specialization-dto';
import {Session, sessions} from '../utils/sessionsList';

@Injectable({
  providedIn: 'root'
})
export class SchoolsService {

  constructor(private baseService: BaseService) { }

  getAll(): CancelablePromise<SchoolDTO[]> {
    return this.baseService.getAll<SchoolDTO>(`${environment.servicesUrl}/me/schools`);
  }

  getSchoolSemesters(schoolId: number): CancelablePromise<TrimesterDTO[]> {
    return this.baseService.getAll<TrimesterDTO>(`${environment.servicesUrl}/promotions/${schoolId}/trimesters`);
  }

  getAllPromotionsByYearAndSchool(schoolId: number, year: number): CancelablePromise<PromotionDTO[]> {
    return this.baseService.getAll<PromotionDTO>(`${environment.servicesUrl}/schools/${schoolId}/promotions/${year}`);
  }

  getAllSessionsByPromotion(): Session[] {
    return sessions;
  }

  getAllClassesByYearAndPromotion(year: number, promoId: number): CancelablePromise<ClassDTO[]> {
    return this.baseService.getAll<ClassDTO>(`${environment.servicesUrl}/promotions/${promoId}/classes/${year}`);
  }

  getAllOptionsByYearAndPromotion(year: number, promoId: number): CancelablePromise<OptionDTO[]> {
    return this.baseService.getAll<OptionDTO>(`${environment.servicesUrl}/promotions/${promoId}/options/${year}`);
  }

  getAllSpecializationsByOption(optionId: number): CancelablePromise<SpecializationDTO[]> {
    return this.baseService.getAll<SpecializationDTO>(`${environment.servicesUrl}/options/${optionId}/specialisations`);
  }
}
