import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  constructor() {
  }

  static getVersion(): string {
    return environment.version_type + ' ' + environment.version;
  }
}
