import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Page} from '../../../../annotations/page.annotation';
import {PagesService} from '../../../../services/pages.service';
import {AbstractPageComponent} from '../../../../interfaces/abstract-page.component';
import {DiplomasService} from '../../../../services/diplomas.service';
import {MessageService} from 'primeng/api';
import {ErrorService} from '../../../../services/error.service';
import {DiplomaTypeDTO} from '../../../../models/diplomas/diploma-type-dto';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {StructureService} from '../../../../services/structure.service';
import {Structure} from '../../../../models/structure/structure';
import {Dropdown} from 'primeng/dropdown/dropdown';
import {GradeDTO} from '../../../../models/diplomas/grade-dto';
import {Certifier} from '../../../../models/diplomas/certifier';
import {CertifierAdapter} from '../../../../models/diplomas/CertifierAdapter';
import {DiplomaWithCertifierDto} from '../../../../models/diplomas/diploma-with-certifier-dto';

@Component({
  selector: 'app-diploma',
  templateUrl: './diploma.component.html',
  styleUrls: ['./diploma.component.scss']
})
@Page(PagesService.DIPLOMA, 'Création d\'un titre', undefined, {icon: 'pi pi-user'})
export class DiplomaComponent extends AbstractPageComponent implements OnInit, AfterViewInit {
  private readonly certifierAdapter: CertifierAdapter;

  private ESTABLISHMENT_TYPE_ID = 1;
  private RNCP_TYPE_ID = 2;

  diplomaForm: FormGroup;

  @ViewChild('diplomaCertifier') diplomaCertifier: Dropdown;
  @ViewChild('rncpLevel') rncpLevel: Dropdown;
  showRncpFieldset = false;

  //#region data
  diploma: DiplomaWithCertifierDto;
  structures: Structure[] = [];
  certifiers: Certifier[] = [];
  diplomasTypes: DiplomaTypeDTO[] = [];
  grades: GradeDTO[] = [];
  //#endregion

  //#region tabs
  @ViewChild('bottomTabs') bottomTabs: ElementRef;
  subTabs = [{value: 'general', label: 'Général'}];
  selectedTab: string;
  bottomTabsHeight = 28;

  //#endregion

  constructor(private messageService: MessageService,
              private errorService: ErrorService,
              private diplomasService: DiplomasService,
              private structureService: StructureService) {
    super();
  }

  ngOnInit(): void {
    this.diploma = this.extraData;
    this.addTabsIfInEditMode();

    this.loadDiplomasTypes();
    this.loadGrades();
    this.loadCertifiers();

    this.initDiplomaForm();

    this.onSubTabClicked(this.subTabs[0]);
    this.onTypeSelectionChanged();
  }

  getHelp(): string {
    return '';
  }

  onSubTabClicked(subTab: { value: string, label: string }): void {

    this.selectedTab = subTab.value;

    switch (this.selectedTab) {
      case 'general':
        this.loadGeneral();
        break;
      case 'bloc':
        this.loadBlock();
        break;
      case 'log':
        this.loadLog();
        break;
    }
  }

  private loadGeneral() {
    // TODO
  }

  private loadBlock() {
  }

  private loadLog() {
    // TODO
  }

  ngAfterViewInit(): void {
    this.bottomTabsHeight = this.bottomTabs.nativeElement.offsetHeight;
  }

  isAddMode(): boolean {
    return this.diploma.diploma_id == null;
  }

  private addTabsIfInEditMode(): void {
    if (!this.isAddMode()) {
      this.subTabs = [
        {value: 'general', label: 'Général'},
        {value: 'bloc', label: 'Blocs de compétences'},
        {value: 'log', label: 'Historique'}
      ];
    }
  }

  private initDiplomaForm(): void {
    this.diplomaForm = new FormGroup({
      diplomaName: new FormControl(this.diploma.name, Validators.required),
      diplomaCode: new FormControl(this.diploma.code, Validators.required),
      diplomaType: new FormControl(this.isAddMode() ? this.ESTABLISHMENT_TYPE_ID : this.diploma.type_id, Validators.required),
      diplomaCertifier: new FormControl(this.diploma.certifier?.certifier_id, Validators.required),
      diplomaIsActive: new FormControl(this.isAddMode() ? true : this.diploma.is_active),
      diplomaUrl: new FormControl(this.diploma.url, Validators.maxLength(255)),
      diplomaAcronym: new FormControl(this.diploma.acronym, Validators.maxLength(255)),
      diplomaStartDate: new FormControl(this.diploma.start_delivery_date != null
        ? new Date(this.diploma.start_delivery_date)
        : new Date(), Validators.required),
      diplomaEndDate: new FormControl(this.diploma.end_delivery_date != null
        ? new Date(this.diploma.end_delivery_date)
        : null),
      rncpLevel: new FormControl(this.diploma.grade_id),
      codeLearning: new FormControl(this.diploma.code_learning)
    });
  }

  private updateDiplomaWithFormData(): void {
    this.diploma.name = this.diplomaForm.get('diplomaName').value;
    this.diploma.code = this.diplomaForm.get('diplomaCode').value;
    this.diploma.code = this.diploma.code.replace(/ /g, '');
    this.diploma.type_id = this.diplomaForm.get('diplomaType').value;
    this.diploma.struct_id = this.diplomaForm.get('diplomaCertifier').value;
    this.diploma.acronym = this.diplomaForm.get('diplomaAcronym').value;
    this.diploma.url = this.diplomaForm.get('diplomaUrl').value;
    this.diploma.start_delivery_date = this.diplomaForm.get('diplomaStartDate').value;
    this.diploma.end_delivery_date = this.diplomaForm.get('diplomaEndDate').value;
    if (this.diplomaCertifierDropdownHasSelectedOption()) {
      this.diploma.certifier = {
        certifier_id: this.diplomaCertifier.selectedOption.certifier_id,
        certifier_name: this.diplomaCertifier.selectedOption.certifier_name,
        is_structure: this.diplomaCertifier.selectedOption.is_structure
      };
    } else {
      this.resetDiplomaCertifierDTO();
    }
    this.diploma.is_active = this.diplomaForm.get('diplomaIsActive').value;
    if (this.rncpLevel != null) {
      this.diploma.grade_id = this.rncpLevel.value != null ? this.rncpLevel.selectedOption.grade_id : null;
      this.diploma.grade_name = this.rncpLevel.value != null ? this.rncpLevel.selectedOption.grade_name : null;
      this.diploma.grade_ects = this.rncpLevel.value != null ? this.rncpLevel.selectedOption.grade_ects : null;
      this.diploma.eqf_id = this.rncpLevel.value != null ? this.rncpLevel.selectedOption.eqf_id : null;
      this.diploma.eqf_name = this.rncpLevel.value != null ? this.rncpLevel.selectedOption.eqf_name : null;
    }
    this.diploma.code_learning = this.diplomaForm.get('codeLearning').value;
  }

  private updateTabHeader(): void {
    this.tabLayout.tabs[this.tabLayout.currentTabIndex - 1].header = `TITRE : ${this.diploma.code}`;
  }

  private loadDiplomasTypes(): void {
    this.diplomasService.getSchoolsDiplomasTypes()
      .then(types => {
        this.diplomasTypes = types
        .sort((a, b) => (a.name > b.name)
          ? 1 : ((b.name > a.name)
            ? -1 : 0));
        const btsType = this.diplomasTypes.find(diplomaType => diplomaType.name === 'BTS');
        if (btsType) {
          btsType.disabled = true;
        }
      })
      .catch(error => this.errorService.showError(error));
  }

  private loadCertifiers(): void {
    this.diplomasService.getCertifiers()
      .then(certifierDTOs => this.certifiers = certifierDTOs
        .map(dto => CertifierAdapter.adapt(dto)))
      .catch(error => this.errorService.showError(error));
  }


  private loadGrades(): void {
    this.diplomasService.getGrades()
      .then(grades => this.grades = grades)
      .catch(error => this.errorService.showError(error));
  }

  private resetGradeSelection(): void {
    this.diploma.grade_id = null;
    this.diploma.grade_name = null;
    this.diploma.grade_ects = null;
    this.diploma.eqf_id = null;
    this.diploma.eqf_name = null;
  }

  formIsValid(): boolean {
    if (this.diploma.type_id === this.RNCP_TYPE_ID) {
      if (this.diploma.grade_id == null) {
        return false;
      } else {
        return this.diplomaForm.valid;
      }
    } else {
      return this.diplomaForm.valid;
    }
  }

  onSave(): void {
    const addMode = this.isAddMode();
    this.updateDiplomaWithFormData();

    this.diplomasService.saveOrUpdateSchoolsDiplomas(this.diploma)
      .then(savedDiploma => {
        this.onSaveSuccessful(savedDiploma, addMode);
      })
      .catch(error => {
        this.onSaveError(error);
      });

  }

  onSaveSuccessful(savedDiploma: DiplomaWithCertifierDto, addMode: boolean): void {
    this.diploma = savedDiploma;
    this.messageService.add({
      severity: 'success',
      summary: 'Le titre "' + this.diploma.code + '" a bien été ' + (addMode ? 'créé.' : 'modifié.'),
      life: 5000
    });
    this.diplomasService.refreshData();
    this.addTabsIfInEditMode();
    this.updateTabHeader();
  }

  onSaveError(error): void {
    const diplomaAcronymConflict = error.status === 409 && error.error.result.data === this.diploma.acronym;
    if (diplomaAcronymConflict) {
      this.errorService.showCustomErrorToast({
        is_visible: true,
        header: 'Erreur',
        message: `Cet acronyme n'est pas disponible.`
      }, 5000);
      this.diplomaForm.get('diplomaAcronym').setErrors({conflict: true});
    } else {
      this.errorService.showError(error);
    }
  }

  onTypeSelectionChanged(): void {
    this.updateDiplomaWithFormData();
    if (this.diplomaForm.get('diplomaType').value === this.RNCP_TYPE_ID) {
      this.showRncpFieldset = true;
    } else {
      this.showRncpFieldset = false;
      this.rncpLevel = null;
      this.diplomaForm.get('rncpLevel').reset();
      this.onGradeSelectionChanged();
    }
  }

  onGradeSelectionChanged(): void {
    this.updateDiplomaWithFormData();
    if (this.rncpLevel == null) {
      this.resetGradeSelection();
    }
  }

  private diplomaCertifierDropdownHasSelectedOption() {
    return this.diplomaCertifier != null;
  }

  private resetDiplomaCertifierDTO() {
    if (this.diploma.certifier) {
      this.diploma.certifier = {
        certifier_id: null,
        certifier_name: null,
        is_structure: null
      };
    }
  }
}
