import {Component, OnInit, ViewChild} from '@angular/core';
import {Page} from '../../../annotations/page.annotation';
import {PagesService} from '../../../services/pages.service';
import {AbstractPageComponent} from '../../../interfaces/abstract-page.component';
import {BasicGridComponent, GridTypes} from '../../../components/grid/basic-grid/basic-grid.component';
import {TableColumn} from '../../../models/table-column';
import {DiplomasService} from '../../../services/diplomas.service';
import {DiplomaWithCertifierDto} from '../../../models/diplomas/diploma-with-certifier-dto';
import {Canceler} from '../../../common/promises/request-canceler';
import {FilterTypeEnum} from '../../../utils/filterTypeEnum';
import {ColumnSizeEnum} from '../../../utils/columnSizeEnum';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ErrorService} from '../../../services/error.service';

@Component({
  selector: 'app-diplomas',
  templateUrl: './diplomas.component.html',
  styleUrls: ['./diplomas.component.scss']
})
@Page(PagesService.DIPLOMAS_LIST, 'Gestion des dipômes/titres', undefined, {icon: 'pi pi-user'})
export class DiplomasComponent extends AbstractPageComponent implements OnInit {

  canceler: Canceler = new Canceler();

  //#region data
  diplomasData: DiplomaWithCertifierDto[] = [];
  selectedDiplomas: DiplomaWithCertifierDto[] = [];
  //#endregion

  //#region grid
  @ViewChild('diplomasGrid') diplomasGrid: BasicGridComponent;
  gridTypes = GridTypes;
  loadingDiplomas = false;

  topColumns = [
    {span: 1, header: 'Certificateur'},
    {span: 5, header: 'Diplôme/titre'},
    {span: 2, header: 'Enregistrement'},
    {span: 2, header: ''}
  ];

  diplomasColumns: TableColumn[] = [
    new TableColumn('certifier_name',   'Nom', ColumnSizeEnum.SMALL_COLUMN_SIZE,           'string',  true, true, FilterTypeEnum.DROPDOWN_FILTER),
    new TableColumn('type',                'Type', ColumnSizeEnum.EXTRA_SMALL_COLUMN_SIZE,           'string',  true, true, FilterTypeEnum.DROPDOWN_FILTER),
    new TableColumn('code',                'RNCP', ColumnSizeEnum.EXTRA_SMALL_35_COLUMN_SIZE,           'string',  true, true, FilterTypeEnum.INPUT_FILTER),
    new TableColumn('code_learning',       'App.', ColumnSizeEnum.EXTRA_SMALL_30_COLUMN_SIZE,           'string',  true, true, FilterTypeEnum.INPUT_FILTER),
    new TableColumn('name',                'Intitulé', ColumnSizeEnum.VERY_LARGE_300_COLUMN_SIZE,             'string',  true, true, FilterTypeEnum.INPUT_FILTER),
    new TableColumn('acronym',             'Acr.', ColumnSizeEnum.EXTRA_SMALL_30_COLUMN_SIZE,             'string',  true, true, FilterTypeEnum.INPUT_FILTER),
    new TableColumn('start_delivery_date', 'Début', ColumnSizeEnum.DATE_WITHOUT_TIME_COLUMN_SIZE, 'date',    true, true, FilterTypeEnum.INPUT_FILTER),
    new TableColumn('end_delivery_date',   'Fin', ColumnSizeEnum.DATE_WITHOUT_TIME_COLUMN_SIZE,   'date',    true, true, FilterTypeEnum.INPUT_FILTER),
    new TableColumn('diploma_id',          'ID', ColumnSizeEnum.EXTRA_SMALL_30_COLUMN_SIZE,             'number',     true, true, FilterTypeEnum.INPUT_FILTER),
    new TableColumn('is_active',           'Actif', ColumnSizeEnum.EXTRA_SMALL_35_COLUMN_SIZE,          'boolean', true, true, FilterTypeEnum.DROPDOWN_FILTER)
  ];

  //#endregion

  constructor(private confirmationService: ConfirmationService,
              private errorService: ErrorService,
              private messageService: MessageService,
              private diplomasService: DiplomasService) {
    super();
    if (this.diplomasService) {
      this.diplomasService.listen().subscribe(() => this.onDataChanged());
    }
  }

  ngOnInit(): void {
    this.loadDiplomas();
  }

  getHelp(): string {
    return 'Cette interface liste l\'ensemble des titres et diplômes utilisés dans nos kits et nos sessions de formation.\n' +
      '\n' +
      'Pour les titres/diplômes, cette interface permet :\n' +
      '- Lister l\'ensemble des titres/diplômes.\n' +
      '- Rechercher une information sur l\'ensemble des titres/diplômes.\n' +
      '- Filtrer la liste.\n' +
      '- Exporter la liste au format Excel.\n' +
      '\n' +
      'Pour un titre/diplôme, cette interface permet :\n' +
      '- Afficher le détail du titre/diplôme. \n' +
      '- Ajouter un titre/diplôme.\n' +
      '- Modifier les informations du titre/diplôme.\n' +
      '- Désactiver le titre/diplôme.\n' +
      '- Supprimer le titre/diplôme.\n' +
      '- Afficher la liste des blocs de compétences.\n' +
      '- Ajouter un bloc de compétence.\n' +
      '- Modifier un bloc de compétence.\n' +
      '- Supprimer un bloc de compétence.\n' +
      '- Consulter l\'historique des modifications (A venir).\n';
  }

  onRefreshRequested(): void {
    this.loadDiplomas();
  }

  onDataChanged(): void {
    this.loadDiplomas();
  }

  onRowsSelectionChanged(diplomas: DiplomaWithCertifierDto[]): void {
    this.selectedDiplomas = diplomas;
  }

  onShowDiploma(diploma: DiplomaWithCertifierDto, addMode: boolean): void {
    this.tabLayout.onAddTabWithDataAndHeader(PagesService.DIPLOMA, diploma, addMode ? `Création d'un titre/diplôme` : `TITRE/DIPLÔME : ${diploma.code}`);
  }

  onDelete(): void {
    const confirmationMessage = 'Vous êtes sur le point de supprimer ' + this.selectedDiplomas.length + ' titre(s).<br/>Êtes-vous sûr·e ?';

    this.confirmationService.confirm({
      message: confirmationMessage,
      header: 'Suppression du(es) titre(s) diplôme(s) sélectionné(s)',
      icon: 'pi pi-ban',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      key: 'deleteDiplomaDialog',
      closeOnEscape: true,
      accept: () => this.deleteDiploma()
    });
  }

  private loadDiplomas(): void {
    this.loadingDiplomas = true;
    this.diplomasService.getStructuresDiplomasWithStructure()
      .setCancelAction(this.canceler)
      .then(diplomas => {
        diplomas.forEach(d => d.certifier_name = d.certifier.certifier_name);
        this.diplomasData = diplomas.filter(d => d.type !== 'BTS');
      })
      .catch(error => this.errorService.showError(error))
      .finally(() => this.loadingDiplomas = false);
  }


  isBTS(diploma: DiplomaWithCertifierDto): boolean {
    return diploma && diploma.type === 'BTS';
  }


  private deleteDiploma(): void {
    const successMessage = this.selectedDiplomas.length === 1
      ? 'Le titre/diplôme sélectionné a été supprimé.'
      : 'Les ' + this.selectedDiplomas.length + ' titres/diplômes sélectionnés ont été supprimés.';

    this.diplomasService.deleteSchoolsDiplomas(this.selectedDiplomas)
      .then(() => this.messageService.add({
        severity: 'success',
        summary: 'Suppression effectuée',
        detail: successMessage,
        life: 10000
      }))
      .catch((error) => {
        if (error.status === 409) {
          this.errorService.showCustomErrorToast({
            is_visible: true,
            header: 'Erreur',
            message: `Titre(s) ou diplôme(s) encore affecté(s) à un stage.`
          }, 5000);
        } else {
          this.errorService.showError(error);
        }
      })
      .finally(() => {
        this.selectedDiplomas = [];
        this.loadDiplomas();
      });
  }
}
