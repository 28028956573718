import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import {TableColumn} from '../../models/table-column';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss']
})
export class WelcomePageComponent implements OnInit {

  cols: TableColumn[] = [
    { field: 'student_id', header: 'ID', type: 'string', width: '200px', readonly: false },
    { field: 'student_name', header: 'Nom', type: 'string', width: '200px', readonly: false },
    { field: 'student_firstname', header: 'Prénom', type: 'string', width: '200px', readonly: false }
  ];

  testData: any[] = [
    { student_id: 'test1', student_name: 'test nom 1', student_firstname: 'prénom 1' },
    { student_id: 'test2', student_name: 'test nom 2', student_firstname: 'test prénom 2' },
    { student_id: 'test3', student_name: 'test nom 3', student_firstname: 'test prénom 3' },
    { student_id: 'test4', student_name: 'test nom 4', student_firstname: 'test prénom 4' },
    { student_id: 'test5', student_name: 'test nom 5', student_firstname: 'test prénom 5' },
    { student_id: 'test6', student_name: 'test nom 6', student_firstname: 'test prénom 6' },
    { student_id: 'test7', student_name: 'test nom 7', student_firstname: 'test prénom 7' },
    { student_id: 'test8', student_name: 'test nom 8', student_firstname: 'test prénom 8' },
    { student_id: 'test9', student_name: 'test nom 9', student_firstname: 'test prénom 9' },
    { student_id: 'test10', student_name: 'test nom 10', student_firstname: 'test prénom 10' },
    { student_id: 'test11', student_name: 'test nom 11', student_firstname: 'test prénom 11' },
    { student_id: 'test12', student_name: 'test nom 12', student_firstname: 'test prénom 12' },
    { student_id: 'test13', student_name: 'test nom 13', student_firstname: 'test prénom 13' },
    { student_id: 'test14', student_name: 'test nom 14', student_firstname: 'test prénom 14' },
    { student_id: 'test15', student_name: 'test nom 15', student_firstname: 'test prénom 15' },
    { student_id: 'test16', student_name: 'test nom 16', student_firstname: 'test prénom 16' },
    { student_id: 'test17', student_name: 'test nom 17', student_firstname: 'test prénom 17' },
    { student_id: 'test18', student_name: 'test nom 18', student_firstname: 'test prénom 18' },
    { student_id: 'test19', student_name: 'test nom 19', student_firstname: 'test prénom 19' },
    { student_id: 'test20', student_name: 'test nom 20', student_firstname: 'test prénom 20' },
    { student_id: 'test21', student_name: 'test nom 21', student_firstname: 'test prénom 21' },
    { student_id: 'test22', student_name: 'test nom 22', student_firstname: 'test prénom 22' },
    { student_id: 'test23', student_name: 'test nom 23', student_firstname: 'test prénom 23' },
    { student_id: 'test24', student_name: 'test nom 24', student_firstname: 'test prénom 24' },
    { student_id: 'test25', student_name: 'test nom 25', student_firstname: 'test prénom 25' },
    { student_id: 'test26', student_name: 'test nom 26', student_firstname: 'test prénom 26' },
    { student_id: 'test27', student_name: 'test nom 27', student_firstname: 'test prénom 27' },
    { student_id: 'test28', student_name: 'test nom 28', student_firstname: 'test prénom 28' },
    { student_id: 'test29', student_name: 'test nom 29', student_firstname: 'test prénom 29' },
    { student_id: 'test30', student_name: 'test nom 30', student_firstname: 'test prénom 30' },
    { student_id: 'test31', student_name: 'test nom 31', student_firstname: 'test prénom 31' },
    { student_id: 'test32', student_name: 'test nom 32', student_firstname: 'test prénom 32' },
  ];
  filteredStudentData: any[] = [];

  //#region Combobox

  @Output() tabAdded = new EventEmitter<string>();
  //#endregion

  constructor() { }

  ngOnInit(): void {
  }

  addTab(type) {
    this.tabAdded.emit(type);
  }

  onFilterChanged(filter: string) {
    this.filteredStudentData = this.testData.filter(student =>
      student.student_firstname.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
  }

  onDataSelected(selectedData: any) {
    // TODO
  }

}
