import { Component, OnInit, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  @Input() headerTemplate: TemplateRef<any>;
  @Input() menuTemplate: TemplateRef<any>;
  @Input() contentTemplate: TemplateRef<any>;
  @Input() helpTemplate: TemplateRef<any>;
  @Input() footerTemplate: TemplateRef<any>;

  @Input() spaceFromTop = 60;
  @Input() spaceFromLeft = 350;
  @Input() spaceFromRight = 350;
  @Input() spaceFromBottom = 150;
  @Input() noHeader = false;
  @Input() noFooter = false;
  isExpanding = false;

  constructor() { }

  ngOnInit(): void {

    setTimeout(() => {
      const helpDisplay = localStorage.getItem('helpDisplay');
      this.isExpanding = (helpDisplay !== null && JSON.parse(helpDisplay).id === 2);
    }, 0);

    if (this.noHeader) {
      this.spaceFromTop = 0;
    }

    if (this.noFooter) {
      this.spaceFromBottom = 0;
    }
  }

  changeRightAsideVisibility(expanded: boolean) {
    this.isExpanding = expanded;
  }

  getRightAsideVisibility() {
    return this.isExpanding;
  }

}
