import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {environment} from '../environments/environment';

import {TabLayoutComponent} from './components/tab-layout/tab-layout.component';
import {VerticalMenuComponent} from './components/vertical-menu/vertical-menu.component';
import {MainLayoutComponent} from './components/main-layout/main-layout.component';

import {AuthenticatedUserService} from './services/authenticated-user.service';
import {ReloadService} from './services/reload.service';
import {BaseService} from './services/base.service';

import {Linked} from './models/linked';
import {PagesService} from './services/pages.service';
import {LocalStorageService} from './services/local-storage.service';
import {ModuleDTO} from './models/interfaces/module-dto';
import {UserPreferencesService} from './services/user-preferences.service';
import {MenuDTO} from './models/interfaces/menu-dto';
import {UserEntryPreferenceDTO} from './models/interfaces/user-entry-preference-dto';
import {Subject} from 'rxjs';
import {ErrorService} from './services/error.service';
import {UserProfile} from './models/user-profile.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./styles/icons.scss', './app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  QUERY_ENTRY_TYPE_ID = 2;
  REPORT_ENTRY_TYPE_ID = 3;

  @ViewChild('tabLayout') tabLayout: TabLayoutComponent;
  @ViewChild('verticalMenu') verticalMenu: VerticalMenuComponent;
  @ViewChild('mainLayout') mainLayout: MainLayoutComponent;

  loadingApp = true;
  isMenuLoaded = false;
  title = 'Kordis';
  defaultHelp = 'Bienvenue dans le volet d\'aide de Kordis.\n\n' +
    'En fonction de l\'interface affichée, vous trouverez ici des informations pour vous guider dans l\'utilisation de l\'application.';

  //#region errorDialog
  isErrorDialogDisplayed = false;
  mainErrorMessage = '';
  mainErrorTitle = '';
  userProfile: UserProfile;
  //#endregion

  currentHelp = {value: this.defaultHelp};

  //#endregion

  menuElements: ModuleDTO[];
  private userPreferences = [];

  userPreferencesChanged: Subject<UserEntryPreferenceDTO[]> = this.userPreferencesService.userPreferencesChanged;


  constructor(private authenticatedUserService: AuthenticatedUserService,
              private errorService: ErrorService,
              private pagesService: PagesService,
              private baseService: BaseService,
              private reloadService: ReloadService,
              private userPreferencesService: UserPreferencesService) {

    this.userPreferencesChanged.subscribe((value) => {
      if (this.menuElements) {
        this.updateEntriesPreferences();
      }

      this.errorService.isErrorDialogVisible.subscribe(error => {
        this.mainErrorMessage = error.message;
        this.mainErrorTitle = error.header;
        this.isErrorDialogDisplayed = error.is_visible;
      });
    });

    if (this.authenticatedUserService.isAuthenticated()) {
      setTimeout(() => {
        this.baseService.get<Linked>(environment.servicesUrl + '/me')
          .then((user: Linked) => {
            this.baseService.get(`${environment.servicesUrl}/me/profile`)
              .then((profile: UserProfile) => {
                localStorage.setItem('kordisProfile', JSON.stringify(profile));
                this.userProfile = profile;
              })
              .catch(console.error); // TODO : la récupération du profile génère parfois une erreur, dans l'attente, laisser commenter la ligne du dessous.
              // .catch(error => this.errorService.showError(error));
          })
          .catch(() => this.disconnectUser());
      });
    } else {
      this.authenticatedUserService.redirectToAuthentication();
    }

    document.body.style.zoom = '80%';

    this.reloadService.getObservedLayoutsShouldRefresh().subscribe((v) => {
      const helpDisplay = v.find(o => o.id === 'helpDisplay');
      const smallMenuDisplay = v.find(o => o.id === 'smallMenuDisplay');

      if (helpDisplay !== null) {
        this.mainLayout.changeRightAsideVisibility((helpDisplay.value.id === 2));
      }

      if (smallMenuDisplay !== null) {
        this.verticalMenu.isMenuCollapsed = (smallMenuDisplay.value.id === 2);
      }
    });

  }

  ngOnInit(): void {
    this.getApplicationMenu();
    // On vérifie la dernière version de l'app qui s'est exécuté sur le client. Sur une mise à jour a été faite, on vide le cache client.
    // On garde intacte les prefs utilisateur
    LocalStorageService.isAppUpdated()
      .then(updated => {
        if (updated) {
          LocalStorageService.cleanCache();
        }
      }).then(() => {
      LocalStorageService.updateCachedVersion();
    });
  }

  onTabSelected(event: any): void {
    if (event === null) {
      this.currentHelp.value = this.defaultHelp;
    } else {
      this.currentHelp.value = event;
    }
  }

  ngAfterViewInit(): void {
  }

  disconnectUser(): void {
    this.authenticatedUserService.logout();
  }

  private getApplicationMenu(): void {
    this.userPreferencesService.loadUserPreferences()
      .then(() => this.getEntries())
      .catch(error => this.errorService.showError(error));
  }


  private adaptMenu(menu: MenuDTO, userEntryPreference: UserEntryPreferenceDTO[]): void {
    menu.items.forEach(module => {

      module.items.forEach(category => {

        if (category.items) {

          category.items.forEach(entryType => {

            entryType.items.forEach(entry => {
              if (entry.page_id) {
                entry[`pageId`] = entry.page_id;
                entry[`command`] = () => {
                  if (entryType.entry_type_id === this.QUERY_ENTRY_TYPE_ID) {
                    this.tabLayout.onAddTabWithDataAndHeader(PagesService.QUERY, entry, entry.label);
                  } else if (entryType.entry_type_id === this.REPORT_ENTRY_TYPE_ID) {
                    this.tabLayout.onAddTabWithDataAndHeader(PagesService.REPORT, entry, entry.label);
                  } else {
                    this.tabLayout.onAddTab(entry.page_id);
                  }
                  if (userEntryPreference.map(preference => preference.entry_id).includes(entry.entry_id)) {
                    entry.is_favorite = true;
                  }
                };
              }
            });
          });
        }
      });
    });
  }

  private getEntries(): void {
    this.pagesService.getMenuElements()
      .then(menu => {
        this.adaptMenu(menu, this.userPreferencesService.userPreferences);
        this.menuElements = menu.items;
        this.isMenuLoaded = true;
        this.loadingApp = false;
        this.updateEntriesPreferences();
      }).catch(error => this.errorService.showError(error));
  }

  private updateEntriesPreferences(): void {
    this.menuElements.forEach(module => {

      module.items.forEach(category => {

        if (category.items) {

          category.items.forEach(entryType => {

            entryType.items.forEach(entry => {
              if (entry.page_id) {

                entry.is_favorite = this.userPreferencesService.userPreferences.map(preference => preference.entry_id).includes(entry.entry_id);
              }
            });
          });
        }
      });
    });
  }
}
