import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AbstractPageComponent} from '../../../interfaces/abstract-page.component';
import {BasicGridComponent, GridTypes} from '../../../components/grid/basic-grid/basic-grid.component';
import {TableColumn} from '../../../models/table-column';
import {RightsService} from '../../../services/rights.service';
import {Right} from '../../../models/rights/right';
import {RightsFamily} from '../../../models/rights/rights_family';
import {RightsGroupHasRight} from '../../../models/rights/rights-group-has-right';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Page} from '../../../annotations/page.annotation';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Dialog} from 'primeng/dialog';
import {FilterTypeEnum} from '../../../utils/filterTypeEnum';

@Component({
  selector: 'app-rights-admin',
  templateUrl: './rights-admin.component.html',
  styleUrls: ['./rights-admin.component.scss']
})
@Page('rights-admin', 'Gestion des droits', undefined, { icon: 'pi pi-user' })
export class RightsAdminComponent extends AbstractPageComponent implements OnInit {
  @ViewChild('rightsGrid') rightsGrid: BasicGridComponent;
  @ViewChild('familiesGrid') familiesGrid: BasicGridComponent;
  @ViewChild('rightDialog') rightDialog: Dialog;
  @ViewChild('familyDialog') familyDialog: Dialog;
  @ViewChild('mainDiv') mainDiv: ElementRef;
  @ViewChild('bottomPart') bottomPart: ElementRef;

  gridTypes = GridTypes;

  rightsColumns: TableColumn[] = [
    new TableColumn('right_id', 'ID', '90px', 'number', true, true, FilterTypeEnum.INPUT_FILTER),
    new TableColumn('right_type_name', 'Type', '250px', 'string', true, true, FilterTypeEnum.INPUT_FILTER),
    new TableColumn('rights_family_name', 'Parent', '250px', 'string', true, true, FilterTypeEnum.INPUT_FILTER),
    new TableColumn('right_name', 'Nom',  '', 'string', true, true),
    new TableColumn('right_desc', 'Description',  '250', 'string', true, true),
    new TableColumn('is_active', 'Actif',  '90px', 'boolean', true, true, FilterTypeEnum.INPUT_FILTER),
  ];

  familiesColumns: TableColumn[] = [
    new TableColumn('rights_family_id', 'ID', '90px', 'number', true, true, FilterTypeEnum.INPUT_FILTER),
    new TableColumn('rights_family_name', 'Famille', '', 'string', true, true, FilterTypeEnum.INPUT_FILTER),
    new TableColumn('rights_family_parent_name', 'Parent', '', 'string', true, true, FilterTypeEnum.INPUT_FILTER),
  ];

  //#region rights
  rightsTypes: any[] = [];
  rightsData: any[] = [];
  rightsSelectedData: Right[] = [];
  rightUsages: RightsGroupHasRight[] = [];
  loadingData = false;
  loadingUsages = false;
  //#endregion

  //#region family members
  familiesData: RightsFamily[] = [];
  familiesAllData: RightsFamily[] = [];
  familiesSelectedData: RightsFamily[] = [];
  //#endregion

  //#region dialog
  displayRightDialog = false;
  isCreateDialog = false;
  displayFamilyDialog = false;
  selectedRight: Right;
  editedRight: Right;
  rightForm: FormGroup;
  selectedFamily: RightsFamily;
  editedFamily: RightsFamily;
  familyForm: FormGroup;
  //#endregion

  constructor(private rightsService: RightsService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService) {
    super();
  }

  ngOnInit(): void {

    this.refreshRightsList();
    this.refreshFamiliesList();
    this.rightsService.getRightTypes().
    then(types => {
      this.rightsTypes = types;
    });

    this.editedRight = new Right();
    this.editedFamily = new RightsFamily();

    this.rightForm = new FormGroup({
      rightType: new FormControl(this.editedRight.right_type_id, Validators.required),
      rightFamily: new FormControl(this.editedRight.rights_family_id, Validators.required),
      rightName: new FormControl(this.editedRight.right_name, Validators.required),
      rightDesc: new FormControl(this.editedRight.right_desc, Validators.required),
      isActive: new FormControl(this.editedRight.is_active),
      rightId: new FormControl(this.editedRight.right_id)
    });

    this.familyForm = new FormGroup({
      familyId: new FormControl(this.editedFamily.rights_family_id),
      familyName: new FormControl(this.editedFamily.rights_family_name, Validators.required),
      familyParent: new FormControl(this.editedFamily.rights_family_parent_id),
    });
  }

  refreshRightsList(): void {
    this.rightsService.getRights().then(rights => {
      this.rightsGrid.updateDataAfterChange(rights);
    });
  }

  refreshFamiliesList(): void {
    this.rightsService.getAllRightsFamilies().
    then(families => {
      this.familiesAllData = families;
      this.familiesGrid.updateDataAfterChange(families);
    });
  }

  getHelp(): string {
    return 'L\'aide de la gestion des droits';
  }

  onRightsRowsSelectionChanged(data: any[]) {
    this.rightsSelectedData = data;

    if (this.rightsSelectedData.length === 1) {
      this.loadingUsages = true;

      this.selectedRight = this.rightsSelectedData[0];

      this.rightsService.getRightsGroupByRight(this.selectedRight.right_id)
        .then((rightsGroups) => this.rightUsages = rightsGroups)
        .catch(console.error)
        .finally(() => this.loadingUsages = false);
    }
  }

  onFamiliesRowsSelectionChanged(data: any[]) {
    this.familiesSelectedData = data;
    if (this.familiesSelectedData.length === 1) {
      this.selectedFamily = this.familiesSelectedData[0];
    }
  }

  // Right dialog
  showRightDialog(isCreating: boolean) {
    this.isCreateDialog = isCreating;
    if (isCreating) {
      this.editedRight = new Right();
      this.editedRight.is_active = true;
    } else {
      this.editedRight = this.selectedRight;
    }
    this.rightForm.get('rightId').setValue(this.editedRight.right_id);
    this.rightForm.get('rightName').setValue(this.editedRight.right_name);
    this.rightForm.get('rightDesc').setValue(this.editedRight.right_desc);
    this.rightForm.get('rightFamily').setValue(this.editedRight.rights_family_id);
    this.rightForm.get('rightType').setValue(this.editedRight.right_type_id);
    this.rightForm.get('isActive').setValue(this.editedRight.is_active);

    this.displayRightDialog = true;
  }

  reinitEditedRight() {
    this.editedRight = new Right();
  }

  saveRight(event: Event): void {
    this.editedRight.right_name = this.rightForm.get('rightName').value;
    this.editedRight.right_desc = this.rightForm.get('rightDesc').value;
    this.editedRight.rights_family_id = this.rightForm.get('rightFamily').value;
    this.editedRight.right_type_id = this.rightForm.get('rightType').value;
    this.editedRight.is_active = this.rightForm.get('isActive').value;
    this.editedRight.right_id = this.rightForm.get('rightId').value;

    this.rightsService.saveRight(this.editedRight).then(r =>
        this.messageService.add({
          severity: 'success',
          detail: r.right_name + ' sauvegardé' ,
          life: 5000
        }))
      .then(() => this.refreshRightsList())
      .then(() => {
        if (this.isCreateDialog) {
          this.rightsSelectedData.splice(0, this.rightsSelectedData.length);
        }
      })
      .then(() => this.rightDialog.close(event))
      .catch((error) =>
        this.messageService.add({
          severity: 'error',
          detail: 'Erreur lors de la sauvegarde du droit. Veuillez recommencer',
          life: 5000
        }))
      .catch(console.error);
  }

  // Activation dialog
  showRightsActivationDialog(activation: boolean) {
    const detailMessage = this.rightsSelectedData.length === 1 ? 'Le droit sélectionné a été ' + (activation ? 'activé.' : 'désactivé.') :
      'Les ' + this.rightsSelectedData.length + ' droits ont été ' + (activation ? 'activés.' : 'désactivés.');

    this.confirmationService.confirm({
      message: 'Êtes-vous sûr·e de vouloir ' + (activation ? 'activer' : 'désactiver') + ' le(s) droit(s) sélectionné(s)?',
      header: (activation ? 'Activation' : 'Désactivation') + ' des droits sélectionnés',
      icon: 'pi pi-exclamation-triangle',
      key: 'activationDialog',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      closeOnEscape: true,
      accept: () => {
        // désactivation
        this.rightsService.activateRights(this.rightsSelectedData.map(r => r.right_id), activation).then(() =>
          this.messageService.add({
            severity: 'success',
            summary: (activation ? 'Activation' : 'Désactivation') + ' réussie',
            detail: detailMessage,
            life: 5000
          }))
          .then(() => this.refreshRightsList())
          .catch(() => this.messageService.add({
            severity: 'error',
            detail: 'Erreur lors de ' + (activation ? 'l\'activation' : 'la désactivation') + ' des droits.',
            life: 5000
          })).catch(console.error);
      }
    });
  }

  // Rights suppression dialog
  showRightsSuppressionDialog() {
    const confirmationMessage = 'Vous êtes sur le point de supprimer ' + this.rightsSelectedData.length + ' droits.<br/> Cette action va : <br/>* Restreindre définitivement les accès des groupes liés. <br/>* Supprimer définitivement les historiques du droit. <br/>Êtes-vous sûr·e ?';
    this.confirmationService.confirm( {
      message: confirmationMessage,
      header: 'Suppression du(es) droit(s) sélectionné(s)',
      icon: 'pi pi-ban',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      key: 'deleteRightDialog',
      closeOnEscape: true,
      accept: () => {
        const successMessage = this.rightsSelectedData.length === 1 ? 'Le droit sélectionné a été supprimé.' :
          'Les ' + this.rightsSelectedData.length + ' droits sélectionnés ont été supprimés.';

        // suppression
        this.rightsService.deleteRights(this.rightsSelectedData).then(() =>
          this.messageService.add({
            severity: 'success',
            summary: 'Suppression effectuée',
            detail: successMessage,
            life: 5000
          }))
          .then(() => this.refreshRightsList())
          .then(() => this.rightsSelectedData.splice(0, this.rightsSelectedData.length))
          .then(() => this.selectedRight = null)
          .catch((error) => {
            if (error.status === 500) {
              this.messageService.add({
                severity: 'error',
                summary: 'Le ou les droit(s) sélectionné(s) sont encore utilisés par un groupe de droit. \n La suppression n\'est pas possible.',
                life: 5000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Une erreur inattendue est survenue. Si le problème persiste, veuillez contacter le service informatique.',
                life: 5000
              });
            }
          }).catch(console.error);
      }
    });
  }

  // Family dialog
  showFamilyDialog(isCreating: boolean) {
    this.isCreateDialog = isCreating;
    this.editedFamily = isCreating ? new RightsFamily() : this.selectedFamily;
    this.displayFamilyDialog = true;

    this.familyForm.get('familyParent').setValue(this.editedFamily.rights_family_parent_id);
    this.familyForm.get('familyId').setValue(this.editedFamily.rights_family_id);
    this.familyForm.get('familyName').setValue(this.editedFamily.rights_family_name);
  }

  // Family suppression dialog
  showFamilySuppressionDialog() {
    this.confirmationService.confirm({
      message: 'Êtes-vous sûr·e de vouloir supprimer ' + (this.familiesSelectedData.length === 1 ? 'la famille sélectionnée ?' : 'les familles sélectionnées ?'),
      header: 'Suppression de la famille sélectionnée',
      icon: 'pi pi-ban',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      key: 'deleteFamilyDialog',
      closeOnEscape: true,
      accept: () => {
        const successMessage = this.familiesSelectedData.length === 1 ? 'La famille sélectionnée a été supprimée.' :
          'Les ' + this.familiesSelectedData.length + ' familles sélectionnées ont été supprimées.';

        // suppression
        this.rightsService.deleteFamilies(this.familiesSelectedData)
          .then(() =>
            this.messageService.add({
              severity: 'success',
              summary: 'Suppression effectuée',
              detail: successMessage,
              life: 5000
            }))
          .then(() => this.refreshFamiliesList())
          .then(() => this.familiesSelectedData.splice(0, this.familiesSelectedData.length))
          .catch((error) => {
            if (error.status === 500) {
              this.messageService.add({
                severity: 'error',
                summary: 'La ou les famille(s) sélectionnée(s) ont encore un lien à un droit ou à une famille de droit. La suppression n\'est pas possible.',
                life: 20000
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Une erreur inattendue est survenue. Si le problème persiste, veuillez contacter le service informatique.',
                life: 20000
              });
            }
          });
      }
    });
  }

  reinitEditedFamily() {
    this.editedFamily = new RightsFamily();
  }

  saveFamily(event: Event) {
    this.editedFamily.rights_family_parent_id = this.familyForm.get('familyParent').value;
    this.editedFamily.rights_family_name = this.familyForm.get('familyName').value;
    this.rightsService.saveFamily(this.editedFamily).then(f =>
      this.messageService.add({
        severity: 'success',
        detail: f.rights_family_name + ' sauvegardé' ,
        life: 5000
      }))
      .then(() => this.refreshFamiliesList())
      .then(() => this.familyDialog.close(event))
      .then(() => this.familiesSelectedData.splice(0, this.familiesSelectedData.length))
      .then(() => this.reinitEditedFamily())
      .catch(() => this.messageService.add({
        severity: 'error',
        detail: 'Erreur lors de la sauvegarde de la famille de droit. Veuillez recommencer',
        life: 5000
      })).catch(console.error);
  }
}
