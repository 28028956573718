export class DiplomaBlockDTO {
  block_id: number;
  parent_id: number;
  parent_code: string;
  diploma_id: number;
  name: string;
  code: string;
  skill_description: string;
  evaluation_description: string;
  crea_user_id: number;
  crea_user_login: string;
  crea_date: number;
  upd_user_id: number;
  upd_user_login: string;
  upd_date: number;
  is_active: boolean;
}
