import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {StudentCompleteDTO} from '../models/students/student-complete-dto';
import {environment} from '../../environments/environment';
import {StudentYearInfoDTO} from '../models/students/student-year-info-dto';
import {CancelablePromise} from '../common/promises/cancelable-promise';

@Injectable({
  providedIn: 'root'
})
export class StudentsService {

  constructor(private baseService: BaseService) { }

  getRegisteredStudents(schoolId: number, year: number, trimesterId: number): CancelablePromise<StudentCompleteDTO[]> {
    const url = `${environment.servicesUrl}/students/registered?schoolId=${schoolId}&year=${year}&trimesterId=${trimesterId}`;
    return this.baseService.getAll<StudentCompleteDTO>(url);
  }

  getDesistedStudents(schoolId: number, year: number, trimesterId: number): CancelablePromise<StudentCompleteDTO[]> {
    const url = `${environment.servicesUrl}/students/desisted?schoolId=${schoolId}&year=${year}&trimesterId=${trimesterId}`;
    return this.baseService.getAll<StudentCompleteDTO>(url);
  }

  getRegisteredStudentsForAllSchools(year: number): CancelablePromise<StudentCompleteDTO[]> {
    const url = `${environment.servicesUrl}/students/registered/allSchools?year=${year}`;
    return this.baseService.getAll<StudentCompleteDTO>(url);
  }

  getDesistedStudentsForAllSchools(year: number): CancelablePromise<StudentCompleteDTO[]> {
    const url = `${environment.servicesUrl}/students/desisted/allSchools?year=${year}`;
    return this.baseService.getAll<StudentCompleteDTO>(url);
  }

  getStudentYearInfo(year: number, uid: number): CancelablePromise<StudentYearInfoDTO[]> {
    const url = `${environment.servicesUrl}/students/${uid}/info/${year}`;
    return this.baseService.getAll<StudentYearInfoDTO>(url);
  }
}
