import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgModule,
  Output,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MenuItem, PrimeTemplate} from 'primeng/api';
import {RouterModule} from '@angular/router';
import {RippleModule} from 'primeng/ripple';
import {MainMenuItem} from './main-menu-item';
import {EntryDTO} from '../../models/interfaces/entry-dto';
import {ModuleDTO} from '../../models/interfaces/module-dto';
import {EntryTypeDTO} from '../../models/entry-type-dto';
import {UserPreferencesService} from '../../services/user-preferences.service';
import {VersionService} from '../../version.service';

@Component({
  templateUrl: 'main-menu.component.html',
  selector: 'app-mainmenu',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./megamenu.scss']
})
export class MainMenuComponent implements AfterContentInit {

  @Output() menuCollapseClicked = new EventEmitter<void>();

  @Input() model: MainMenuItem[];

  @Input() style: any;

  @Input() styleClass: string;

  @Input() orientation = 'horizontal';

  @Input() autoZIndex = true;

  @Input() baseZIndex = 0;

  @Input() isMenuCollapsed = true;

  @ContentChildren(PrimeTemplate) templates: QueryList<any>;

  flattenedEntries: Map<string, EntryTypeDTO[]> = new Map<string, EntryTypeDTO[]>();

  version = VersionService.getVersion();

  activeItem: any;

  documentClickListener: any;

  startTemplate: TemplateRef<any>;

  endTemplate: TemplateRef<any>;
  hoveredCategoryEntryTypes: any[];
  menuOpened = false;


  constructor(
    public elementRef: ElementRef,
    public renderer: Renderer2,
    public cd: ChangeDetectorRef,
    private userPreferenceService: UserPreferencesService,
  ) {
  }

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'start':
          this.startTemplate = item.template;
          break;

        case 'end':
          this.endTemplate = item.template;
          break;
      }
    });


    this.setFlattenMenu();
  }


  @HostListener('document:click', ['$event.target'])
  public onPageClick(targetElement) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.menuOpened = false;
    }
  }

  onModuleMouseEnter(event, menuitem: MainMenuItem) {
    if (this.menuOpened) {
      if (menuitem.disabled) {
        event.preventDefault();
        return;
      }

      if (this.activeItem) {
        this.activeItem = menuitem;
      }
    }
  }

  onModuleClick(event, item: MenuItem | MainMenuItem) {
    if (item.disabled) {
      event.preventDefault();
      return;
    }

    if (!item.url) {
      event.preventDefault();
    }

    if (item.command) {
      item.command({
        originalEvent: event,
        item
      });
    }

    if (item.items) {
      if (this.activeItem && this.activeItem === item) {
        this.activeItem = null;
        this.menuOpened = false;
        this.unbindDocumentClickListener();
      } else {
        this.activeItem = item;
        this.menuOpened = true;
        this.bindDocumentClickListener();
      }
    }
  }

  itemClick(event, item: MenuItem | MainMenuItem) {
    if (item.disabled) {
      event.preventDefault();
      return;
    }

    if (!item.url) {
      event.preventDefault();
    }

    if (item.command) {
      item.command({
        originalEvent: event,
        item
      });
    }

    this.activeItem = null;
  }

  getColumnClass(menuitem: MainMenuItem) {
    const length = menuitem.items ? menuitem.items.length : 0;
    let columnClass;
    switch (length) {
      case 2:
        columnClass = 'p-megamenu-col-6';
        break;

      case 3:
        columnClass = 'p-megamenu-col-4';
        break;

      case 4:
        columnClass = 'p-megamenu-col-3';
        break;

      case 6:
        columnClass = 'p-megamenu-col-2';
        break;

      default:
        columnClass = 'p-megamenu-col-12';
        break;
    }

    return columnClass;
  }

  bindDocumentClickListener() {
    if (!this.documentClickListener) {
      this.documentClickListener = (event) => {
        if (this.elementRef && !this.elementRef.nativeElement.contains(event.target)) {
          this.activeItem = null;
          this.unbindDocumentClickListener();
          this.cd.markForCheck();
        }
      };

      document.addEventListener('click', this.documentClickListener);
    }
  }

  unbindDocumentClickListener() {
    if (this.documentClickListener) {
      document.removeEventListener('click', this.documentClickListener);
      this.documentClickListener = null;
    }
  }

  onCategoryHover(event: MouseEvent, entryTypes: MenuItem[]) {
    this.hoveredCategoryEntryTypes = entryTypes;
  }

  onModuleCategoriesLeave(event: MouseEvent) {
    this.hoveredCategoryEntryTypes = [];

  }

  onMenuCollapseClicked() {
    this.menuCollapseClicked.emit();
  }

  closeCategories() {
    this.activeItem = null;
    this.menuOpened = false;
  }

  registerFavorite(entry: EntryDTO) {
    entry.is_favorite = !entry.is_favorite;
  }

  private setFlattenMenu() {
    this.model.forEach((module: ModuleDTO) => {
      module.items.forEach(category => {
        this.flattenedEntries.set(`${module.label} > ${category.label}`, category.items);
      });
    });
  }


  updateFavoriteStatus(entryDTO: EntryDTO) {
    // check if the entry is in the favorites list (from ges-api),
    // if yes, remove it from the list
    // if not, it is added to the favorites
    this.userPreferenceService.getFavoritesEntries(entryDTO.application_id).then(userPreference => {
      if (userPreference.filter(u => u.entry_id === entryDTO.entry_id).length > 0) {
        this.userPreferenceService.deleteEntryFavorite(entryDTO.entry_id).then(() => this.userPreferenceService.removePreference(entryDTO.entry_id));
      } else {
        this.userPreferenceService.addEntryFavorite(entryDTO.entry_id).then((entry) => this.userPreferenceService.addPreference(entryDTO.entry_id));
      }
    });
  }

  onModuleMouseLeave() {
    this.activeItem = [];
  }

  onCommandClicked(entry: any) {
    entry.command();
    this.closeCategories();
  }
}

@NgModule({
  imports: [CommonModule, RouterModule, RippleModule],
  exports: [RouterModule]
})
export class MegaMenuModule {
}
