import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Canceler} from '../../../../../common/promises/request-canceler';
import {TableColumn} from '../../../../../models/table-column';
import {ColumnSizeEnum} from '../../../../../utils/columnSizeEnum';
import {FilterTypeEnum} from '../../../../../utils/filterTypeEnum';
import {BasicGridComponent, GridTypes} from '../../../../../components/grid/basic-grid/basic-grid.component';
import {DiplomaWithCertifierDto} from '../../../../../models/diplomas/diploma-with-certifier-dto';
import {DiplomasService} from '../../../../../services/diplomas.service';
import {DiplomaBlockDTO} from '../../../../../models/diplomas/diploma-block-dto';
import {ErrorService} from '../../../../../services/error.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DiplomaSkillBlockDialog} from './diploma-skill-block-dialog/diploma-skill-block-dialog.component';

@Component({
  selector: 'app-diploma-skills-block',
  templateUrl: './diploma-skill-block.component.html',
  styleUrls: ['./diploma-skill-block.component.scss']
})
export class DiplomaSkillBlockComponent implements OnInit {
  title = 'Liste des blocs de compétences rattachés';
  exportTitle = 'Liste des blocs de compétences rattachés';

  canceler: Canceler = new Canceler();
  @Input() selectedDiploma: DiplomaWithCertifierDto;
  @Input() active = false;
  isAddMode = true;

  @ViewChild('grid') grid: BasicGridComponent;
  @ViewChild('blockDialog') dialog: DiplomaSkillBlockDialog;

  loadingData = false;
  gridTypes = GridTypes;

  //#region colonnes
  columns: TableColumn[] = [
    // Bloc
    new TableColumn('code', 'Code', ColumnSizeEnum.MEDIUM_COLUMN_SIZE, 'string', true, true, FilterTypeEnum.DROPDOWN_FILTER),
    new TableColumn('name', 'Intitulé', ColumnSizeEnum.LARGE_COLUMN_SIZE, 'string', true, true, FilterTypeEnum.DROPDOWN_FILTER),
    new TableColumn('skill_description', 'Compétences', ColumnSizeEnum.MEDIUM_COLUMN_SIZE, 'link', true, false),
    new TableColumn('evaluation_description', 'Evaluation', ColumnSizeEnum.MEDIUM_COLUMN_SIZE, 'link', true, false),
    new TableColumn('block_id', 'ID', ColumnSizeEnum.MEDIUM_COLUMN_SIZE, 'string', true, true, FilterTypeEnum.DROPDOWN_FILTER),
    new TableColumn('is_active', 'Actif ', ColumnSizeEnum.MEDIUM_COLUMN_SIZE, 'boolean', true, true, FilterTypeEnum.DROPDOWN_FILTER),

    // Parent
    new TableColumn('parent_code', 'Code', ColumnSizeEnum.MEDIUM_COLUMN_SIZE, 'string', true, true, FilterTypeEnum.DROPDOWN_FILTER),
    new TableColumn('parent_id', 'ID', ColumnSizeEnum.MEDIUM_COLUMN_SIZE, 'string', true, true, FilterTypeEnum.DROPDOWN_FILTER),

    // Historique
    new TableColumn('crea_user_login', 'Crée par', ColumnSizeEnum.MEDIUM_COLUMN_SIZE, 'string', true, true, FilterTypeEnum.DROPDOWN_FILTER),
    new TableColumn('crea_date', 'Le', ColumnSizeEnum.MEDIUM_COLUMN_SIZE, 'date', true, true, FilterTypeEnum.DROPDOWN_FILTER),
    new TableColumn('upd_user_login', 'Modifié par', ColumnSizeEnum.MEDIUM_COLUMN_SIZE, 'string', true, true, FilterTypeEnum.DROPDOWN_FILTER),
    new TableColumn('upd_date', 'le', ColumnSizeEnum.MEDIUM_COLUMN_SIZE, 'date', true, true, FilterTypeEnum.DROPDOWN_FILTER),
  ];

  blockTopColumns = [
    {span: 6, header: 'Bloc'},
    {span: 2, header: 'Parent'},
    {span: 4, header: 'Historique'}
  ];
  //#endregion

  diplomaBlockData: DiplomaBlockDTO[] = [];
  selectedBlocks: DiplomaBlockDTO[] = [];

  selectedData = [];
  displaySkillBlockDetails = false;

  isActive(): boolean {
    return this.active;
  }

  constructor(private diplomasService: DiplomasService,
              private errorService: ErrorService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService) {
  }

  loadDiplomaBlocks(): void {
    this.loadingData = true;

    this.resetSelectedBlocks();

    this.diplomasService.getDiplomaBlocksByDiplomaId(this.selectedDiploma.diploma_id)
      .setCancelAction(this.canceler)
      .then(blocks => this.addBlocks(blocks))
      .catch(error => this.errorService.showError(error))
      .finally(() => this.loadingData = false);
  }

  ngOnInit(): void {
    this.loadDiplomaBlocks();
  }

  onRefreshRequested(): void {
    this.loadDiplomaBlocks();
  }

  private resetSelectedBlocks(): void {
    this.selectedBlocks.splice(0);
  }

  private addBlocks(blocks: DiplomaBlockDTO[]): void {
    blocks.forEach(block => {
      if (block.crea_date != null) {
        const createDate = new Date();
        createDate.setTime(Number(block.crea_date));
        block.upd_date = createDate.getTime();
      }

      if (block.upd_date != null) {
        const updDate = new Date();
        updDate.setTime(Number(block.upd_date));
        block.upd_date = updDate.getTime();
      }
    });
    this.diplomaBlockData = blocks.sort((block1, block2) => block1.code > block2.code ? 1 : -1);
  }

  onDelete(): void {
    const confirmationMessage = 'Vous êtes sur le point de désactiver ' + this.selectedBlocks.length + ' bloc(s) de compétences.<br/>Êtes-vous sûr·e ?';
    this.confirmationService.confirm({
      message: confirmationMessage,
      header: 'Suppression de bloc de compétences',
      icon: 'pi pi-ban',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      key: 'deleteBlockDialog',
      closeOnEscape: true,
      accept: () => this.deleteBlock()
    });
  }

  onRowsSelectionChanged(blocks: DiplomaBlockDTO[]): void {
    this.selectedBlocks = blocks;
  }

  private deleteBlock(): void {
    const successMessage = this.selectedBlocks.length === 1
      ? 'Le bloc de compétences sélectionné a été désactivé.'
      : 'Les ' + this.selectedBlocks.length + ' blocs de compétences sélectionnés ont été désactivés.';

    const blockIdsToDelete: number[] = this.selectedBlocks.map(selectedBlock => selectedBlock.block_id);
    this.diplomasService.deleteDiplomaBlock(blockIdsToDelete)
      .then(() => this.messageService.add({
        severity: 'success',
        summary: 'Suppression effectuée',
        detail: successMessage,
        life: 10000
      }))
      .catch((error) => {
        this.errorService.showError(error);
      })
      .finally(() => {
        this.selectedBlocks = [];
        this.loadDiplomaBlocks();
      });
  }

  onAddRequest(): void {
    this.dialog.showDialog(true, this.selectedDiploma.diploma_id, null);
  }

  onDetailsRequested(selected: DiplomaBlockDTO) {
    const diplomaBlockId = selected.block_id;
    this.dialog.showDialog(false, this.selectedDiploma.diploma_id, diplomaBlockId);
  }

  showLoading(isLoading: boolean) {
    this.loadingData = isLoading;
  }
}
