import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AbstractPageComponent} from '../../interfaces/abstract-page.component';
import {BasicGridComponent, GridTypes} from '../../components/grid/basic-grid/basic-grid.component';
import {StudentsService} from '../../services/students.service';
import {TableColumn} from '../../models/table-column';
import {StudentYearInfoDTO} from '../../models/students/student-year-info-dto';
import {StudentCompleteDTO} from '../../models/students/student-complete-dto';
import {MessageService} from 'primeng/api';
import {SchoolYearSemesterSelectorComponent} from '../../components/school-year-semester-selector/school-year-semester-selector.component';
import {SchoolYearSemesterValues} from '../../models/school-year-semester-values';
import {Canceler} from '../../common/promises/request-canceler';
import {Page} from '../../annotations/page.annotation';
import {PagesService} from '../../services/pages.service';
import {FilterTypeEnum} from '../../utils/filterTypeEnum';
import {ColumnSizeEnum} from '../../utils/columnSizeEnum';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss']
})
@Page('studentsList', 'Liste des étudiants', undefined, { icon: 'pi pi-users' })
export class StudentsComponent extends AbstractPageComponent implements OnInit {

  gridTypes = GridTypes;


  @ViewChild('grid') grid: BasicGridComponent;
  @ViewChild('mainDiv') mainDiv: ElementRef;
  @ViewChild('schoolYearSemesterSelector') schoolYearSemesterSelector: ElementRef;
  @ViewChild('bottomPart') bottomPart: ElementRef;
  @ViewChild('selector') selector: SchoolYearSemesterSelectorComponent;

  columns: TableColumn[] = [
    new TableColumn('student_id',         'ID',                   ColumnSizeEnum.SMALL_COLUMN_SIZE),
    new TableColumn('student_number',     'N° Etudiant',          ColumnSizeEnum.MEDIUM_COLUMN_SIZE),
    new TableColumn('civility',           'Civ.',                 ColumnSizeEnum.MEDIUM_COLUMN_SIZE, 'string', true, true, FilterTypeEnum.DROPDOWN_FILTER),
    new TableColumn('name',               'Nom',                  ColumnSizeEnum.MEDIUM_COLUMN_SIZE, 'string', true, true, FilterTypeEnum.INPUT_FILTER),
    new TableColumn('firstname',          'Prénom',               ColumnSizeEnum.MEDIUM_COLUMN_SIZE),
    new TableColumn('promotion',          'Promotion',            ColumnSizeEnum.LARGE_COLUMN_SIZE,  'string', true, true, FilterTypeEnum.DROPDOWN_FILTER),
    new TableColumn('classe',             'Classe',               ColumnSizeEnum.MEDIUM_COLUMN_SIZE,  'string', true, false, FilterTypeEnum.DROPDOWN_FILTER),
    new TableColumn('code',               'Code',                 ColumnSizeEnum.MEDIUM_COLUMN_SIZE,  'string', true, false, FilterTypeEnum.INPUT_FILTER),
    new TableColumn('option',             'Option',               ColumnSizeEnum.VERY_LARGE_COLUMN_SIZE,  'string', true, false, FilterTypeEnum.DROPDOWN_FILTER),
    new TableColumn('session',            'Session',              ColumnSizeEnum.MEDIUM_COLUMN_SIZE,  'string', true, false, FilterTypeEnum.DROPDOWN_FILTER),
    new TableColumn('campus',             'Campus',               ColumnSizeEnum.MEDIUM_COLUMN_SIZE,  'string', true, false, FilterTypeEnum.DROPDOWN_FILTER),
    new TableColumn('trimester_name',     'Semestre',             ColumnSizeEnum.MEDIUM_COLUMN_SIZE,  'string', true, false, FilterTypeEnum.DROPDOWN_FILTER),
    new TableColumn('email',              'Email',                ColumnSizeEnum.VERY_LARGE_COLUMN_SIZE),
    new TableColumn('email_school',       'Email École',          ColumnSizeEnum.VERY_LARGE_COLUMN_SIZE),
    new TableColumn('mobile',             'Mobile',               ColumnSizeEnum.LARGE_COLUMN_SIZE),
    new TableColumn('telephone',          'Téléphone',            ColumnSizeEnum.MEDIUM_COLUMN_SIZE),
    new TableColumn('address1',           'Adresse',              ColumnSizeEnum.VERY_LARGE_COLUMN_SIZE),
    new TableColumn('address2',           'Adresse suite',        ColumnSizeEnum.VERY_LARGE_COLUMN_SIZE),
    new TableColumn('zip_code',           'Code Postal',          ColumnSizeEnum.MEDIUM_COLUMN_SIZE),
    new TableColumn('city',               'Ville',                ColumnSizeEnum.MEDIUM_COLUMN_SIZE),
    new TableColumn('country',            'Pays',                 ColumnSizeEnum.MEDIUM_COLUMN_SIZE),
    new TableColumn('nationality',        'Nationalité',          ColumnSizeEnum.MEDIUM_COLUMN_SIZE),
    new TableColumn('international',      'International',        ColumnSizeEnum.MEDIUM_COLUMN_SIZE),
    new TableColumn('outside_ue',         'Hors UE',              ColumnSizeEnum.MEDIUM_COLUMN_SIZE,   'boolean'),
    new TableColumn('desist_date',        'Date désistement',     ColumnSizeEnum.MEDIUM_COLUMN_SIZE,  'string', true, true, FilterTypeEnum.INPUT_FILTER),
    new TableColumn('accounting_code',    'Code Comptable',       ColumnSizeEnum.MEDIUM_COLUMN_SIZE),
    new TableColumn('cvec',               'CVEC',                 ColumnSizeEnum.MEDIUM_COLUMN_SIZE),
    new TableColumn('cvec_exoneration',   'Exonéré(e) de CVEC ?', ColumnSizeEnum.MEDIUM_COLUMN_SIZE,  'boolean'),
    new TableColumn('hyphenation_year',   'Année de césure',      ColumnSizeEnum.MEDIUM_COLUMN_SIZE,  'boolean'),
    new TableColumn('csfp',               'CSFP',                 ColumnSizeEnum.MEDIUM_COLUMN_SIZE,   'boolean'),
    new TableColumn('registration_fee',   'Frais d\'inscription', ColumnSizeEnum.MEDIUM_COLUMN_SIZE,  'boolean'),
    new TableColumn('scolar_fee_mod_1',   'Frais de scolarité (Modalité 1)',    ColumnSizeEnum.MEDIUM_COLUMN_SIZE, 'boolean'),
    new TableColumn('scolar_fee_mod_23',  'Frais de scolarité (Modalité 2/3)',  ColumnSizeEnum.MEDIUM_COLUMN_SIZE, 'boolean'),
    new TableColumn('parcoursup',         'Parcoursup',           ColumnSizeEnum.MEDIUM_COLUMN_SIZE,  'boolean'),
    new TableColumn('parcoursup_desist',  'Désisté Parcoursup',   ColumnSizeEnum.MEDIUM_COLUMN_SIZE,  'boolean'),
  ];

  data: any[] = [];
  loadingData = false;
  topColumns = [
    { span: 2, header: '' },
    { span: 3, header: 'Étudiant' },
    { span: 5, header: 'Cursus' },
    { span: 1, header: '' },
    { span: 9, header: 'Coordonnées' },
  ];
  listMode = 'registered';
  selectedData = [];

  publipostageMode = 'existingDocument';

  studentYearInfo: StudentYearInfoDTO[];
  loadingStudentYearInfo = false;
  gridScrollSizeInPx = 0;
  gridBottomSize = 0;
  schoolYearSemesterSelectorSizeInPx = 0;
  private selectedMainFilters: SchoolYearSemesterValues;

  exportTitle = 'Liste des étudiants';

  displayPublipostageDialog = false;
  publipostageSelectedFiles: any[];
  canceler: Canceler = new Canceler();


  constructor(private studentsService: StudentsService,
              private messageService: MessageService) {
    super();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.gridScrollSizeInPx = this.mainDiv.nativeElement.offsetHeight;
      this.schoolYearSemesterSelectorSizeInPx = this.schoolYearSemesterSelector.nativeElement.offsetHeight;
      this.gridBottomSize = this.bottomPart.nativeElement.offsetHeight + 8;
    });
  }

  getHelp(): string {
    return '';
  }

  onDataChanged(event: any[]) { }

  alert(test: string) {
    alert(test);
  }

  loadStudents(schoolId, year, trimesterId) {
    this.loadingData = true;

    this.resetSelectedStudents();

    if (this.listMode === 'registered') {
      (schoolId === 0 // id de "toutes les écoles"
        ? this.studentsService.getRegisteredStudentsForAllSchools(year)
        : this.studentsService.getRegisteredStudents(schoolId, year, trimesterId))
        .setCancelAction(this.canceler)
        .then((students) => this.addStudents(students, year))
        .catch(console.error)
        .finally(() => this.loadingData = false);
    } else {
      (schoolId === 0 // id de "toutes les écoles"
        ? this.studentsService.getDesistedStudentsForAllSchools(year)
        : this.studentsService.getDesistedStudents(schoolId, year, trimesterId))
        .setCancelAction(this.canceler)
        .then((students) => this.addStudents(students, year))
        .catch(console.error)
        .finally(() => this.loadingData = false);
    }
  }

  private addStudents(students: StudentCompleteDTO[], year: number): void {
    students.forEach(s => {
      s[`key`] = s.student_id;
      s[`headerAddition`] = `${s.name} ${s.firstname} [${year}]`;
    });
    this.data = students;
  }

  onListModeChanged() {
    this.loadStudents(this.selectedMainFilters.selectedSchoolId, this.selectedMainFilters.selectedYear, this.selectedMainFilters.selectedSemesterId);
  }

  onRefreshRequested() {
    this.loadStudents(this.selectedMainFilters.selectedSchoolId, this.selectedMainFilters.selectedYear, this.selectedMainFilters.selectedSemesterId);
  }

  onRowsSelectionChanged(data: any[]) {
    this.selectedData = data;

    if (this.selectedData.length === 1) {
      this.loadingStudentYearInfo = true;

      this.studentsService.getStudentYearInfo(2020, this.selectedData[0].student_id)
        .then((info) => this.studentYearInfo = info)
        .catch(console.error)
        .finally(() => this.loadingStudentYearInfo = false);
    }

  }

  onSendPublipostageClicked() {
    // alert('envoi publipostage');
    this.displayPublipostageDialog = true;
  }

  onSendEmailingClicked() {
    alert('envoi Emailing');
  }

  onSendSmsClicked() {
    alert('envoi SMS');
  }

  onSendNotificationClicked() {
    alert('envoi Notification');
  }

  onShowStudent(student: StudentCompleteDTO) {
    this.tabLayout.onAddTabWithData(PagesService.STUDENT, student);
  }

  // noinspection JSUnusedLocalSymbols
  onResize(mainDiv: HTMLDivElement, bottomPart: HTMLDivElement) {

    setTimeout(() => {
      this.gridScrollSizeInPx = this.mainDiv.nativeElement.offsetHeight;
      this.schoolYearSemesterSelectorSizeInPx = this.schoolYearSemesterSelector.nativeElement.offsetHeight;
      this.gridBottomSize = this.bottomPart.nativeElement.offsetHeight;
    });
  }

  onSemesterSelected(event: SchoolYearSemesterValues) {

    this.setExportTitle();

    this.selectedMainFilters = event;
    if (event.selectedYear > -1 && event.selectedSchoolId > -1 && event.selectedSemesterId > -1) {
      this.loadStudents(event.selectedSchoolId, event.selectedYear, event.selectedSemesterId);
    } else if (event.selectedYear === -1) {
      this.messageService.add({ severity: 'warn', summary: 'Information', detail: 'Veuillez sélectionner également une année scolaire', life: 10000 });
    }
  }

  onYearSelected(event: SchoolYearSemesterValues) {

    this.setExportTitle();

    this.selectedMainFilters = event;
    if (event.selectedYear > -1 && event.selectedSchoolId > -1 && event.selectedSemesterId > -1) {
      this.loadStudents(event.selectedSchoolId, event.selectedYear, event.selectedSemesterId);
    }
  }

  private setExportTitle() {
    this.exportTitle =  `[${this.selector.getSelectedYearName()}][${this.selector.getSelectedSchoolName()}][${this.selector.getSelectedSemesterName()}]`;
  }

  private resetSelectedStudents() {
    this.selectedData.splice(0);
  }

  onValidatePublipostage(event: MouseEvent) {
    this.messageService.add({severity: 'success', summary: 'Bientôt', detail: `On validera ici l'envoi du modèle au back pour validation : ${(this.publipostageSelectedFiles && this.publipostageSelectedFiles.length === 1) ? this.publipostageSelectedFiles[0].name.substr(0, 30) : ''}`, sticky: true});
    this.displayPublipostageDialog = false;
  }

  importOnClick(event: any) {
    this.publipostageSelectedFiles = event.currentFiles;
  }

  resetPublipostageSelectedFiles() {
    this.publipostageSelectedFiles.splice(0);
  }

  onAllSchoolsSelected(values: SchoolYearSemesterValues) {
    if (values.selectedYear > -1 && values.selectedSchoolId > -1) {
      this.loadStudents(values.selectedSchoolId, values.selectedYear, values.selectedSemesterId);
    }
  }
}
