export class Right {
  right_id: number;
  right_name: string;
  right_desc: string;
  is_active: boolean;
  rights_family_id: number;
  rights_family_name: string;
  right_type_id: number;
  right_type_name: string;
}
