import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {UrlFactoryService} from '../factories/url-factory.service';
import {Administrative} from '../models/administration/administrative';
import {CancelablePromise} from "../common/promises/cancelable-promise";

@Injectable({
  providedIn: 'root'
})
export class AdministrationService {

  constructor(private baseService: BaseService,
              private urlFactory: UrlFactoryService) { }

  getAdministrativesByName(chunk: string): CancelablePromise<Administrative[]> {
    return this.baseService.getAll<Administrative>(this.urlFactory.getAdministrativesByNameUrl(chunk));
  }

}
