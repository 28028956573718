<p-dialog [(visible)]="errorDialogDisplayed" [modal]="true" [header]="title" [draggable]="false" [closable]="false" [resizable]="false" styleClass="errorDialog">
  <div class="p-p-2 errorMessage">{{errorMessage}}</div>
  <div class="p-p-2">
    Essayez de rafraîchir la page.
  </div>
  <div class="p-px-2 contactInformations">Si le problème persiste veuillez contacter le support à l'adresse suivante: <a style="color: #ff0060" href="mailto:{{ supportAddress }}" class="mailLink">{{supportAddress}}</a></div>
  <ng-template pTemplate="footer">
    <button pButton icon="pi pi-power-off" (click)="disconnect()" label="Déconnexion" class="roundedButton errorBackground"></button>
    <button pButton icon="pi pi-refresh" (click)="refreshPage()" label="Rafraîchir" class="roundedButton"></button>
  </ng-template>
</p-dialog>
