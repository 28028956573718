import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  @Input() strokeWidth = '6';
  @Input() spinnerWidth = '50px';
  @Input() spinnerHeight = '50px';
  @Input() fillColor = 'transparent';

  constructor() { }

  ngOnInit(): void {
  }

}
