import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {EntryTypeDTO} from '../../../models/entry-type-dto';
import {EntryDTO} from '../../../models/interfaces/entry-dto';

@Component({
  selector: 'app-formated-entries-menu',
  templateUrl: './formated-entries-menu.component.html',
  styleUrls: ['./formated-entries-menu.component.scss']
})
export class FormatedEntriesMenuComponent implements OnInit {

  @Input()
  formattedEntries: Map<string, EntryTypeDTO[]>;

  @Output()
  closePannel = new EventEmitter<boolean>();

  @Input()
  filter = '';

  @Input()
  onOpening = true;

  @Output()
  changeFavoriteStatus = new EventEmitter<EntryDTO>();
  
  @Input()
  displayStar = true;

  crossDisplayedEntryId: number;

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
  }

  @HostListener('document:click', ['$event.target'])
  public onPageClick(targetElement) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!(this.onOpening || clickedInside)) {
      this.close(false);
    } else {
      this.onOpening = false;
    }

  }

  close(resetFilter: boolean) {
    this.closePannel.emit(resetFilter);
  }

  getEntryTypeClass(entryTypeName: string) {
    let entryTypeClass;

    switch (entryTypeName) {
      case 'saisie' :
        entryTypeClass = 'saisieTitle';
        break;
      case 'requêtes' :
        entryTypeClass = 'requetesTitle';
        break;
      case 'états' :
        entryTypeClass = 'etatsTitle';
        break;
    }

    return entryTypeClass + ' entryLabel';
  }

  openEntry(entry: EntryDTO) {
    entry[`command`]();
    this.close(true);
  }

  updateEntryFavoriteStatus(entry: EntryDTO) {
    this.onOpening = true;
    this.changeFavoriteStatus.emit(entry);
  }

  displayCross(entryId: number) {
    if (!this.displayStar) {
      this.crossDisplayedEntryId = entryId;
    }
  }

  displayEntryCross(entry_id: number) {
    return entry_id === this.crossDisplayedEntryId;
  }

  removeEntryCross() {
    this.crossDisplayedEntryId = null;
  }
}
