import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserProfile} from '../../models/user-profile.model';
import {MainMenuItem} from '../main-menu/main-menu-item';

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss']
})
export class VerticalMenuComponent implements OnInit {

  itemsCollapsed: MainMenuItem[];
  isMenuCollapsed = false;

  @Input() menuElements: MainMenuItem[];
  @Output() helpClicked = new EventEmitter<void>();
  @Output() paramsClicked = new EventEmitter<void>();
  @Output() disconnectionClicked = new EventEmitter<void>();
  @Input()
  currentProfile: UserProfile;
  currentProfilePhoto = '';

  constructor() { }

  ngOnInit(): void {

    setTimeout(() => {
      const smallMenuDisplay = localStorage.getItem('smallMenuDisplay');
      if (smallMenuDisplay !== null && JSON.parse(smallMenuDisplay).id === 2) {
        this.isMenuCollapsed = true;
      }
    }, 0);


    this.itemsCollapsed = this.menuElements.map(x => Object.assign({}, x));
  }

  changeMenuLayout() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
  }

  private shouldSeeModule(menuId: string) {
    return menuId === 'test';
  }

  loadProfile(event) {
    this.currentProfile = event;

    if (this.currentProfile[`links`]) {
      this.currentProfilePhoto = this.currentProfile[`links`][`photo`]?.href;
    } else {
      this.currentProfilePhoto = '';
    }
  }
}
