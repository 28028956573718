
<div class="p-d-flex p-pt-4">
  <div *ngIf="yearIsVisible" class="p-field p-mr-3" style="position: relative">
    <span class="p-float-label">
      <p-dropdown id="yearDropdown" appendTo="body" [autoDisplayFirst]="false"
                  styleClass="dropdownStyle"
                  [options]="yearOptions" [(ngModel)]="selectedYearOption"
                  [showClear]="!isElementMandatory(YEAR)"
                  (onChange)="onSelectedYearChanged($event)">
        <ng-template let-element pTemplate="selectedItem">
          <div class="ellipsis-text" [pTooltip]="element.label">{{element.label}}</div>
        </ng-template>
      </p-dropdown>
      <label for="yearDropdown">Année scolaire<span *ngIf="isElementMandatory(YEAR)" style="color: red"> * </span></label>
    </span>
    <div *ngIf="loadingYears" class="centerContent spinnerDivBackground"><app-spinner [spinnerWidth]="'25px'"></app-spinner></div>
  </div>
  <div *ngIf="schoolIsVisible" class="p-field p-mr-3" style="position: relative">
    <span class="p-float-label">
      <p-dropdown id="schoolDropdown" appendTo="body" [autoDisplayFirst]="false"
                  styleClass="dropdownStyle"
                  [options]="schoolOptions" [(ngModel)]="selectedSchoolOption"
                  [showClear]="!isElementMandatory(SCHOOL)"
                  (onChange)="onSelectedSchoolChanged($event)">
        <ng-template let-element pTemplate="selectedItem">
          <div class="ellipsis-text" [pTooltip]="element.label">{{element.label}}</div>
        </ng-template>
      </p-dropdown>
      <label for="schoolDropdown">École<span *ngIf="isElementMandatory(SCHOOL)" style="color: red"> * </span></label>
    </span>
    <div *ngIf="loadingSchools" class="centerContent spinnerDivBackground"><app-spinner [spinnerWidth]="'25px'"></app-spinner></div>
  </div>

  <div *ngIf="semesterIsVisible" class="p-field p-mr-3" style="position: relative">
    <span class="p-float-label">
      <p-dropdown [disabled]="!schoolOptions || schoolOptions.length === 0 || !selectedSchoolOption"
                  [pTooltip]="!schoolOptions || schoolOptions.length === 0 || !selectedSchoolOption ? 'Veuillez sélectionner une école' : ''"
                  id="semesterDropdown" appendTo="body" [autoDisplayFirst]="false"
                  styleClass="dropdownStyle"
                  [options]="semesterOptions" [(ngModel)]="selectedSemesterOption"
                  [showClear]="!isElementMandatory(SEMESTER)"
                  (onChange)="onSelectedSemesterChanged($event)">
        <ng-template let-element pTemplate="selectedItem">
          <div class="ellipsis-text" [pTooltip]="element.label">{{element.label}}</div>
        </ng-template>
      </p-dropdown>
      <label for="semesterDropdown">Semestre<span *ngIf="isElementMandatory(SEMESTER)" style="color: red"> * </span></label>
    </span>
    <div *ngIf="loadingSemesters" class="centerContent spinnerDivBackground"><app-spinner [spinnerWidth]="'25px'"></app-spinner></div>
  </div>
</div>
