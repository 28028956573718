import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-favorite-star',
  templateUrl: './favorite-star.component.html',
  styleUrls: ['./favorite-star.component.scss']
})
export class FavoriteStarComponent implements OnInit {
  @Input()
  isFavorite = false;

  @Output()
  changeFavorite = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  registerFavorite() {
    this.isFavorite = !this.isFavorite;
    this.changeFavorite.emit();
  }
}
