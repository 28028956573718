import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-field-loader',
  templateUrl: './field-loader.component.html',
  styleUrls: ['./field-loader.component.scss']
})
export class FieldLoaderComponent implements OnInit {
  @Input()
  loadingCondition = false;

  @Output()
  cancel: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit(): void {
  }

  @Input()
  interruptLoading() {
    this.loadingCondition = false;
    this.cancel.emit('cancel');
  }
}
