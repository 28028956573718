<div style="position: relative; height: 95%">
  <div [ngStyle]="{position: 'absolute', overflow: 'auto', top: 0, left: 0, right: 0, bottom: bottomTabsHeight + 'px'}">

    <form [formGroup]="diplomaForm">
      <div *ngIf="selectedTab === 'general'" class="fullWidth">
        <p-fieldset styleClass="fieldsetForm p-mt-6" legend="Identification" [toggleable]="true">
          <table class="fullWidth">
            <tr>
              <td class="labelForm"><label>Intitulé*:</label></td>
              <td class="inputForm" colspan="5"><input type="text" class="fullWidth" pInputText
                                                       formControlName="diplomaName"></td>
            </tr>
            <tr>
              <td class="labelForm"><label>Code RNCP*:</label></td>
              <td class="inputForm"><input type="text" style="width: 75%" pInputText formControlName="diplomaCode">
              </td>

              <td class="labelForm"><label>Code Apprentissage*:</label></td>
              <td class="inputForm"><input type="text" style="width: 75%" pInputText formControlName="codeLearning">

              <td class="labelForm"><label>Type*:</label></td>
              <td class="inputForm" style="width: 1vw">
                <p-dropdown id="diplomaType" appendTo="body" placeholder="Choisir un type"
                            optionLabel="name" optionValue="diploma_type_id"
                            styleClass="dropdownStyle"
                            formControlName="diplomaType"
                            (onChange)="onTypeSelectionChanged()"
                            [options]="diplomasTypes"
                            [showClear]="true">
                </p-dropdown>
              </td>
            </tr>
            <tr>
              <td class="labelForm"><label>Certificateur*:</label></td>
              <td class="inputForm" colspan="3">
                <p-dropdown #diplomaCertifier id="diplomaCertifier"
                            appendTo="body" placeholder="Choisir un certificateur"
                            optionLabel="certifier_name" optionValue="certifier_id"
                            styleClass="dropdownStyle leftInputForm"
                            formControlName="diplomaCertifier"
                            [options]="certifiers"
                            [showClear]="true">
                </p-dropdown>
              </td>
              <td class="labelForm"><label>Actif:</label></td>
              <td class="inputForm">
                <p-checkbox binary="true" formControlName="diplomaIsActive"></p-checkbox>
              </td>
            </tr>
            <tr>
              <td class="labelForm"><label>Lien web:</label></td>
              <td class="inputForm" colspan="3"><input type="text" class="leftInputForm" pInputText formControlName="diplomaUrl"></td>

              <td class="labelForm"><label>Acronyme:</label></td>
              <td class="inputForm"><input type="text" pInputText formControlName="diplomaAcronym"></td>
            </tr>
          </table>
        </p-fieldset>

        <p-fieldset styleClass="fieldsetForm" legend="Enregistrement" [toggleable]="true">
          <table class="fullWidth">
            <tr>
              <td class="labelForm"><label>Date de début*:</label></td>
              <td class="inputForm">
                <p-calendar styleClass="dateStyle" dateFormat="dd-mm-yy" formControlName="diplomaStartDate"
                            [readonlyInput]="false"></p-calendar>
                <label class="rightLabelForm">Date de fin:</label>
                <p-calendar styleClass="rightInputForm dateStyle" dateFormat="dd-mm-yy" formControlName="diplomaEndDate"
                            [readonlyInput]="false"></p-calendar>
              </td>
            </tr>
          </table>
        </p-fieldset>

        <p-fieldset *ngIf="showRncpFieldset" styleClass="fieldsetForm" legend="Spécificité RNCP" [toggleable]="true">
          <table class="fullWidth">
            <tr>
              <td class="labelForm"><label>Nomenclature du niveau de qualification*:</label></td>
              <td class="inputForm">
                <p-dropdown #rncpLevel id="rncpLevel"
                            appendTo="body" placeholder="Choisir un niveau"
                            optionLabel="grade_name" optionValue="grade_id"
                            styleClass="dropdownStyle p-ml-6"
                            formControlName="rncpLevel"
                            (onChange)="onGradeSelectionChanged()"
                            [options]="grades"
                            [showClear]="true">
                </p-dropdown>
              </td>
            </tr>
            <tr>
              <td class="labelForm"><label>Valeur ECTS:</label></td>
              <td class="inputForm"><input class="p-ml-6" type="text" pInputText [value]="diploma.grade_ects" disabled>
              </td>
            </tr>
            <tr>
              <td class="labelForm"><label>European qualification Framework:</label></td>
              <td class="inputForm"><input class="p-ml-6" type="text" pInputText [value]="diploma.eqf_name" disabled>
              </td>
            </tr>
          </table>
        </p-fieldset>

        <p-fieldset *ngIf="!isAddMode()" styleClass="fieldsetForm" legend="Historique" [toggleable]="true">
          <table class="fullWidth">
            <tr>
              <td class="labelForm"><label>Créé par:</label></td>
              <td class="inputForm">
                <input class="p-ml-6" type="text" pInputText [value]="diploma.create_user" disabled>
                <label class="rightLabelForm">Le:</label>
                <input class="rightInputForm dateStyle" type="text" pInputText
                       [value]="diploma.create_date | date : 'dd-MM-yyyy'" disabled>
              </td>
            </tr>
            <tr>
              <td class="labelForm"><label>Dernière modification par:</label></td>
              <td class="inputForm">
                <input class="p-ml-6" type="text" pInputText [value]="diploma.update_user" disabled>
                <label class="rightLabelForm">Le:</label>
                <input class="rightInputForm dateStyle" type="text" pInputText
                       [value]="diploma.update_date | date : 'dd-MM-yyyy'" disabled>
              </td>
            </tr>
          </table>
        </p-fieldset>
      </div>
    </form>

    <div *ngIf="selectedTab === 'bloc'">
      <app-diploma-skills-block [selectedDiploma]="diploma" [active]="isActive()">
      </app-diploma-skills-block>
    </div>

    <div *ngIf="selectedTab === 'log'">
      //TODO
    </div>
  </div>

  <div #bottomTabs class="p-d-flex bottomTab">
    <div *ngFor="let subTab of subTabs" (click)="onSubTabClicked(subTab)"
         [ngClass]="{'p-px-3': true, 'p-py-1': true, 'p-mr-1': true, 'noWrap': true, clickable: true,
                     whiteColor: true,
                     backgroundPrimaryColor: subTab.value === selectedTab,
                     backgroundGreyColor3: subTab.value !== selectedTab}">{{ subTab.label }}</div>
  </div>
</div>

<div class="p-d-flex softPaddingTop p-justify-end">
  <button pButton class="p-button-success" type="button" icon="pi pi-save"
          [disabled]="!formIsValid()"
          [label]="diploma.diploma_id != null ? 'Enregistrer' : 'Créer le titre ou le diplôme'"
          (click)="onSave()"></button>
</div>
