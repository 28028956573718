import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UrlFactoryService {

  constructor() { }

  //#region Profile
  getProfileUrl(): string {
    return environment.servicesUrl + '/me/profile';
  }

  getPrivilegesUrl(): string {
    return environment.servicesUrl + '/privileges?filter=kordis.';
  }

  getFavoritesEntriesUrl(applicationId: number): string {
    return environment.servicesUrl + '/interfaces/menu/favorites/' + applicationId;
  }


  addFavoriteEntryUrl(entryId: number): string {
    return environment.servicesUrl + '/interfaces/menu/favorites/' + entryId;
  }

  deleteFavoriteEntryUrl(entryId: number): string {
    return environment.servicesUrl + '/interfaces/menu/favorites/' + entryId;
  }
  //#endregion

  //#region Interfaces
  getAllModulesUrl(): string {
    return environment.servicesUrl + '/interfaces/modules';
  }

  getAllCategoriesUrl(): string {
    return environment.servicesUrl + '/interfaces/categories';
  }

  getAllCategoriesByModuleUrl(moduleId: number): string {
    return environment.servicesUrl + '/interfaces/categories/module/' + moduleId;
  }

  getAllEntriesUrl(): string {
    return environment.servicesUrl + '/interfaces/entries';
  }

  getAllEntriesByCategoryUrl(categoryId: number): string {
    return environment.servicesUrl + '/interfaces/entries/category/' + categoryId;
  }

  getMenuUrl(applicationId: number): string {
    return environment.servicesUrl + '/interfaces/menu/' + applicationId;
  }
  //#endregion

  //#region StudentsGroups
  getStudentsGroupsForYearAndSchoolIdsUrl(isClass: boolean, schoolIds: string): string {
    return environment.servicesUrl + '/studentsgroups/{year}?isclass=' + isClass.toString() + '&schools=' + schoolIds + '&includeDesisted=false';
  }
  //#endregion

  //#region Rights
  getRightsUrl(): string {
    return environment.servicesUrl + '/rights';
  }

  getRightsActivationUrl(): string {
    return environment.servicesUrl + '/rights/activate';
  }
  //#endregion

  //#region RightGroups
  getAllRightsGroupsUrl(): string {
    return environment.servicesUrl + '/rightsgroups';
  }

  getCurrentRightsGroupUrl(): string {
    return environment.servicesUrl + '/rightsgroups/mine';
  }

  getMembersFromMyRightsGroupsUrl(): string {
    return environment.servicesUrl + '/rightsgroups/mine/members';
  }

  getRightsGroupChildrenUrl(rightsGroupId: number): string {
    return environment.servicesUrl + '/rightsgroups/' + rightsGroupId + '/children';
  }

  getRightsGroupByRightUrl(rightId: number): string {
    return environment.servicesUrl + '/rights/' + rightId + '/rightsgroups';
  }
  //#endregion

  getRightsGroupRightsUrl(rightsGroupId: number): string {
    return environment.servicesUrl + '/rightsgroups/' + rightsGroupId + '/rights';
  }


  //#region RightGroup members
  getRightsGroupMembersUrl(rightsGroupId: number): string {
    return environment.servicesUrl + '/rightsgroups/' + rightsGroupId + '/members';
  }
  //#endregion

  //#region Rights families
  getRightsFamiliesUrl(): string {
    return environment.servicesUrl + '/rights/families';
  }

  getAllRightsFamiliesUrl(): string {
    return environment.servicesUrl + '/rights/families/all';
  }
  //#endregion

  //#region Rights types
  getRightsTypesUrl(): string {
    return environment.servicesUrl + '/rights/types';
  }
  //#endregion

  //#region users
  getAdministrativesByNameUrl(chunk: string): string {
    return environment.servicesUrl + '/administrations/search/' + chunk;
  }

  getRightsGroupsByNameUrl(chunk: string): string {
    return environment.servicesUrl + '/rightsgroups/search/' + chunk;
  }

  getUserRightsUrl(uid: number): string {
    return environment.servicesUrl + '/administrations/' + uid + '/rights';
  }

  getRightsGroupsFromMemberUrl(uid: number): string {
    return environment.servicesUrl + '/administrations/' + uid + '/rightsgroups';
  }
  //#endregion

  getRightsGroupUrl(rightsGroupId: number): string {
    return environment.servicesUrl + '/rightsgroups/' + rightsGroupId;
  }

  //#region Logs
  getRightsLogsUrl(rightsGroupId: number, rightId: number): string {
    return environment.servicesUrl + '/rightsgroups/' + rightsGroupId + '/rights/' + rightId + '/logs';
  }

  getRightsLogsByUserUrl(rightId: number, userId: number): string {
    return environment.servicesUrl + '/rights/' + rightId + '/member/' + userId + '/logs';
  }

  getRightsGroupMemberLogsUrl(rightsGroupId: number): string {
    return environment.servicesUrl + '/rightsgroups/' + rightsGroupId + '/members/logs';
  }
  //#endregion

  //#region diplomas
  getSchoolsDiplomasUrl(): string {
    return environment.servicesUrl + '/schoolsDiplomas';
  }

  getSchoolsDiplomasWithCertifierUrl() {
    return environment.servicesUrl + '/schoolsDiplomas/withStructures';
  }

  getStructuresDiplomasByUserUrl(): string {
    return environment.servicesUrl + '/schoolsDiplomas/user';
  }

  getSchoolsDiplomasTypesUrl(): string {
    return environment.servicesUrl + '/schoolsDiplomas/types';
  }

  getGradesUrl(): string {
    return environment.servicesUrl + '/schoolsDiplomas/grades';
  }

  getStructureDiplomaBlocksUrl(): string {
    return environment.servicesUrl + '/structuresDiplomaBlocks';
  }
  //#endregion

  //#region structure
  getStructuresUrl(): string {
    return environment.servicesUrl + '/structures';
  }

  getStructuresByUidUrl(): string {
    return environment.servicesUrl + '/structures/user';
  }
  //#endregion

}
