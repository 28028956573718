import { Injectable } from '@angular/core';
import { AuthenticatedUser } from '../models/authenticated-user.model';
import { UserProfile } from '../models/user-profile.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Privilege } from '../models/privilege';
import { UrlFactoryService } from '../factories/url-factory.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedUserService {

  privileges: { [id: string]: Privilege; } = {};

  constructor(private http: HttpClient,
              private urlFactory: UrlFactoryService) { }

  public getToken(): string {
    return localStorage.getItem('kordisToken');
  }

  public setToken(token: string): void {
    return localStorage.setItem('kordisToken', token);
  }

  public removeToken(): void {
    localStorage.removeItem('kordisToken');
  }

  public removePrivileges(): void {
    localStorage.removeItem('kordisPrivileges');
  }

  removeProfile(): void {
    localStorage.removeItem('kordisProfile');
  }

  public getProfile(): UserProfile {
    return JSON.parse(localStorage.getItem('kordisProfile'));
  }

  public saveAuthenticatedUser(fullUrl: string): void {

    const strings = fullUrl.split(new RegExp('#|&'));

    strings.shift();

    const account = new AuthenticatedUser();

    strings.forEach(element => {
      const property = element.split('=');
      account[property[0]] = property[1];
    });

    localStorage.setItem('kordisToken', account.access_token);
  }

  public loadProfile(): Promise<UserProfile> {
    return new Promise((resolve, reject) => this.http.get(this.urlFactory.getProfileUrl())
      .subscribe(
        (data) => {
          if (data[`response_code`] === 0) {
            const profile = data[`result`];
            localStorage.setItem('kordisProfile', JSON.stringify(profile));
            resolve(profile);
          } else {
            reject(data[`result`]);
          }
        },
        reject
      )
    );
  }

  public loadPrivileges(): Promise<Privilege[]> {
    return new Promise((resolve, reject) => this.http.get(this.urlFactory.getPrivilegesUrl(),
      { headers: { 'content-type': 'application/json'},
        responseType: 'json',
        observe: 'response'
      })
      .subscribe(
        (data) => {
          if (data.status === 204) {
            this.redirectToAccessDeniedScreen();
            resolve();
            localStorage.setItem('kordisPrivileges', '');
          } else if (data.body && data.body[`response_code`] === 0) {
            resolve(data.body[`result`]);
            localStorage.setItem('kordisPrivileges', JSON.stringify(data.body[`result`]));
          } else {
            reject(data.body ? data.body[`result`] : '');
          }
        },
        reject
      )
    );
  }

  public getPrivileges(): Promise<{ [id: string]: Privilege; }> {
    return new Promise((resolve, reject) => {
      if (Object.keys(this.privileges).length > 0) {
        resolve(this.privileges);
      } else {
        this.loadPrivileges()
          .then(rawPrivileges => {
            rawPrivileges.forEach(rawPrivilege => {
              this.privileges[rawPrivilege.label] = rawPrivilege;
            });
            resolve(this.privileges);
          }).catch(reject);
      }
    });
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();
    return token !== undefined && token !== null;
  }

  public redirectToAuthentication(): void {
    window.location.href = environment.authenticationUrl + '/oauth/authorize?response_type=token&client_id=' + environment.client_id;
  }

  public redirectToAccessDeniedScreen() {
    // this.router.navigate(['/', 'access-denied']);
    // TODO
  }

  public revokeToken(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const httpHeaders = new HttpHeaders({Authorization: 'Bearer ' + this.getToken()});

      this.http.get(environment.authenticationUrl + '/oauth/revoke',
        {
          observe: 'response',
          headers: httpHeaders
        })
        .subscribe(
          (response) => {
            resolve(response.status === 200);
          },
          (error) => {
            if (error.status === 200 && error.statusText === 'OK') {
              resolve(true);
            } else {
              reject(error);
            }
          });
    });
  }

  public logout(): void {
    this.revokeToken()
      .then(() => this.removeProfile())
      .then(() => this.removeToken())
      .then(() => window.location.href = environment.authenticationUrl + '/logout?client_id=' + environment.client_id);
  }

}
