import {ColumnSizeEnum} from '../utils/columnSizeEnum';
import {Validators} from '@angular/forms';

export class TableColumn {

  private static COLUMN_WIDTH_SIZE = new Map <string, ColumnSizeEnum>([
    ['VERY_SMALL', ColumnSizeEnum.VERY_SMALL_COLUMN_SIZE],
    ['SMALL', ColumnSizeEnum.SMALL_COLUMN_SIZE],
    ['MEDIUM', ColumnSizeEnum.MEDIUM_COLUMN_SIZE],
    ['LARGE', ColumnSizeEnum.LARGE_COLUMN_SIZE],
    ['VERY_LARGE', ColumnSizeEnum.VERY_LARGE_COLUMN_SIZE],
  ]);

  field: string;
  header: string;
  type?: string;
  width?: string;
  readonly?: boolean;
  sortable?: boolean;
  filterType?: string;


  static setColumnSize(width: string) {
    const finalWidth = TableColumn.COLUMN_WIDTH_SIZE.get(width);
    const regex = new RegExp('(\\d+)px$');

    if (finalWidth == null && width.length > 0 && regex.exec(width)) {
      return width;
    } else if (finalWidth == null) {
      return ColumnSizeEnum.MEDIUM_COLUMN_SIZE;
    }
    return  finalWidth;
  }

  constructor(field: string, header: string, width = '90px', type = 'string', readonly= true, sortable = true, filterType = '') {
    this.field = field;
    this.header = header;
    this.type = type;
    this.width = TableColumn.setColumnSize(width);
    this.readonly = readonly;
    this.sortable = sortable;
    this.filterType = filterType;
  }
}
