import {Canceler} from './request-canceler';

export class CancelablePromise<T> {
  promise: Promise<T>;

  canceler: Canceler;

  setCancelAction(a: Canceler): Promise<T> {
    a.cancel = this.canceler.cancel;
    return this.promise;
  }

  catch<TResult = never>(onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null): Promise<T | TResult> {
    return this.promise.catch<TResult>(onrejected);
  }

  then<TResult1 = T, TResult2 = never>(onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null, onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null): Promise<TResult1 | TResult2> {
    return this.promise.then<TResult1, TResult2>(onfulfilled);
  }

  finally(onfinally?: (() => void) | undefined | null): Promise<T> {
    return this.promise.finally(onfinally);
  }


  constructor(callback: (resolve: (value?: T | PromiseLike<T> ) => void, reject: (reason?: any) => void) => void, canceler: Canceler) {
    this.promise = new Promise<T>(callback);
    this.canceler = canceler;
  }
}
