<div #parent class="p-grid p-align-center" [style.width]="width + 'px'">
  <div [class]="labelClasses">
    <div class="box">{{ label }}</div>
  </div>
  <div [class]="dropdownClasses">
    <div class="box">
      <p-dropdown #dropdown
                  autoWidth="false"
                  appendTo="body"
                  [options]="options"
                  [(ngModel)]="selectedOption"
                  [style]="{ 'width': '100%', 'overflow':'hidden', 'min-width': '1em'}"
                  (onChange)="onSelectionChanged($event)">
        <ng-template let-element pTemplate="selectedItem">
          <div [pTooltip]="element.label">{{element.label}}</div>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
  <div *ngIf="showClear" [class]="'p-col-1'">
    <button pButton type="button" icon="pi pi-times" class="ui-button-danger" (click)="clearSelectedOption()" ></button>
  </div>
</div>
