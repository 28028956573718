
<form style="width: 800px">
  <app-combo-box [label]="'Afficher par défaut l\'aide'"
                 [options]="helpOptions"
                 [(selectedOption)]="selectedHelpOption"
                 [showClear]="false"
                 [labelWeight]="8"></app-combo-box>

  <app-combo-box [label]="'Afficher le menu réduit par défaut'"
                 [options]="smallMenuOptions"
                 [(selectedOption)]="selectedSmallMenuOption"
                 [showClear]="false"
                 [labelWeight]="8"></app-combo-box>

  <button pButton type="button" icon="pi pi-save" label="Enregistrer" (click)="onSave()" class="p-button-success"></button>
</form>
