<div *ngIf="isReady">
  <div class="p-p-2 p-d-flex p-justify-between">
    <div>
      <app-application-params-selector
        [allSchoolsIsVisible]="checkIfAllOptionsIsVisible('school')"
        [yearIsVisible]="checkIfStaticParamIsPresent('year')"
        [schoolIsVisible]="checkIfStaticParamIsPresent('school')"
        [semesterIsVisible]="checkIfStaticParamIsPresent('semester')"
        [promoIsVisible]="checkIfStaticParamIsPresent('promotion')"
        [sessionIsVisible]="checkIfStaticParamIsPresent('session')"
        [optionIsVisible]="checkIfStaticParamIsPresent('option')"
        [classIsVisible]="checkIfStaticParamIsPresent('class')"
        [specializationIsVisible]="checkIfStaticParamIsPresent('specialization')"
        [mandatoryFields]="getMinimumRequirementsFields()"

        (yearSelected)="onYearSelected($event)"
        (schoolSelected)="onSchoolSelected($event)"
        (semesterSelected)="onSemesterSelected($event)"
        (promotionSelected)="onPromoSelected($event)"
        (sessionSelected)="onSessionSelected($event)"
        (optionSelected)="onOptionSelected($event)"
        (classSelected)="onClassSelected($event)"
        (specializationSelected)="onSpecializationSelected($event)">
      </app-application-params-selector>
    </div>
    <div class="executeButtonStyle">
      <button *ngIf="requestData.params.length === 0" pButton type="button" class="p-button-success p-mr-2" icon="pi pi-play" label="Exécuter" iconPos="right"
              [disabled]="!areRequiredParamsPresents()"
              (click)="executeQuery()"></button>

      <button *ngIf="requestData.params.length === 0" pButton type="button" class="p-button-success exportButton p-mr-2" icon="pi pi-file-pdf" label="Générer" iconPos="right"
              [disabled]="!canGenerateReport()"
              (click)="executeExport()"></button>
    </div>
  </div>
  <div class="p-p-2 p-d-flex p-justify-between">
    <div>
      <app-dynamic-params-selector
      [mandatoryFields]="getMinimumRequirementsFields()"
      [selectedParams]="selectedParams"
      [params]="requestData.params"
      (dynamicParamSelected)="onUpdateDynamicParam($event)"
      [optionsList]="optionsList">
      </app-dynamic-params-selector>
    </div>
    <div>
      <button *ngIf="requestData.params.length !== 0" pButton type="button" class="p-button-success p-mr-2" icon="pi pi-play" label="Exécuter" iconPos="right"
              [disabled]="!areRequiredParamsPresents()"
              (click)="executeQuery()"></button>
      <button *ngIf="requestData.params.length !== 0" pButton type="button" class="p-button-success exportButton p-mr-2" icon="pi pi-file-pdf" label="Générer" iconPos="right"
              [disabled]="!canGenerateReport()"
              (click)="executeExport()"></button>
    </div>
  </div>
</div>

<app-basic-grid id="grid"
                gridType="{{gridTypes.QUERY_GRID}}"
                [gridTitle]="requestData ? requestData.title : ''"
                [columns]="requestData ? requestData.columns : []"
                [dataKey]="requestData ? requestData.dataKey : ''"
                [data]="responseData"
                [loading]="isLoading"
                [showRefreshButton]="true"
                [isTabActive]="isActive()"
                [heightToKeep]="112"
                (cancel)="canceler.cancel()"
                [selectedData]="selectedData"
                [isDisabled]="mandatoryParamsHaveBeenModified"
                (rowsSelectionChanged)="onRowSelectionChanged($event)"
>
</app-basic-grid>
