import {Injectable} from '@angular/core';
import * as xlsx from 'xlsx';
import {TableColumn} from '../models/table-column';
import * as fileSaver from 'file-saver';
import {CancelablePromise} from "../common/promises/cancelable-promise";
import {BaseService} from "./base.service";
import {environment} from "../../environments/environment";
import {CommonUtilsService} from "../common/utils/common-utils";
import {StringUtils} from "../common/utils/string-utils";

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private baseService: BaseService) {

  }

  createFileBlobFromRawDataAndColumns(excelRawData: any[], columns: TableColumn[], topColumns: any[] = null): Blob {
    const excelData = [];

    const editedColumn = this.prepareColumns(excelRawData, columns, topColumns);

    excelRawData.forEach(d => {
      const dataWithHeader = {};
      // on peuple le nouvel objet (avec les NOMS des colonnes, et non les ids)
      editedColumn.forEach(column => dataWithHeader[column.header] = d[column.field]);
      excelData.push(dataWithHeader);
    });

    const worksheet = xlsx.utils.json_to_sheet(excelData);
    const workbook = {Sheets: {data: worksheet}, SheetNames: ['data']};
    const excelBuffer: any = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});

    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    return new Blob([excelBuffer], {
      type: EXCEL_TYPE
    });
  }

  prepareColumns(excelRawData: any[], columns: TableColumn[], topColumns: any[]): TableColumn[] {
    let dooblons = [];
    for (let i = 0; i < columns.length; i++) {
      for (let j = i + 1; j < columns.length; j++) {
        if (columns[i].header === columns[j].header)
          dooblons.push(i, j)
      }
    }

    const editedColumns: TableColumn[] = [];
    columns.forEach(column => editedColumns.push({...column}));

    if (dooblons.length > 0) {
      dooblons.forEach(index => {
        let topColumnIndex = 0;

        if (topColumns != null) {
          let indexTmp = index;
          while (indexTmp - topColumns[topColumnIndex].span >= 0 && topColumnIndex < topColumns.length) {
            indexTmp -= topColumns[topColumnIndex++].span;
          }
        }

        let nb = 1;
        editedColumns[index].header = editedColumns[index].header + ' - ' + (topColumns != null ? topColumns[topColumnIndex].header : nb++);
      })
    }

    return editedColumns;
  }

  saveBlobAsExcelFile(file: Blob, fileName: string): void {
    const EXCEL_EXTENSION = '.xlsx';
    fileSaver.saveAs(file, fileName + EXCEL_EXTENSION);
  }

  blobToFile(blob: Blob, fileName: string): File {
    const b: any = blob;
    b.lastModifiedDate = new Date();
    b.name = fileName;

    return blob as File;
  }

  getPublipostedWordFileFromPassedWordFile(excelFile: File, wordFile: File): CancelablePromise<any> {
    const formData = new FormData();
    formData.append('excel_file', excelFile, excelFile.name);
    formData.append('word_file', wordFile, wordFile.name);

    return this.baseService.uploadFileToDownloadFile(
      `${environment.servicesUrl}/mailing/uploadPublipostageWithWordFile`,
      formData,
      CommonUtilsService.getMimeTypeFromExtension('.docx'),
      StringUtils.getRandomString(10) + '.docx');
  }

  getPublipostedWordFileWithoutWordFile(excelFile: File): CancelablePromise<any> {
    const formData = new FormData();
    formData.append('excel_file', excelFile, excelFile.name);

    return this.baseService.uploadFileToDownloadFile(
      `${environment.servicesUrl}/mailing/uploadPublipostage`,
      formData,
      CommonUtilsService.getMimeTypeFromExtension('.docx'),
      StringUtils.getRandomString(10) + '.docx');
  }
}
