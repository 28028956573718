import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {UrlFactoryService} from '../factories/url-factory.service';
import {ModuleDTO} from '../models/interfaces/module-dto';
import {CategoryDTO} from '../models/interfaces/category-dto';
import {EntryDTO} from '../models/interfaces/entry-dto';
import {MenuDTO} from '../models/interfaces/menu-dto';
import {CancelablePromise} from '../common/promises/cancelable-promise';

@Injectable({
  providedIn: 'root'
})
export class InterfacesService {

  constructor(private baseService: BaseService,
              private urlFactory: UrlFactoryService) { }

  getAllModules(): CancelablePromise<ModuleDTO[]> {
    return this.baseService.getAll<ModuleDTO>(this.urlFactory.getAllModulesUrl());
  }

  getAllCategories(): CancelablePromise<CategoryDTO[]> {
    return this.baseService.getAll<CategoryDTO>(this.urlFactory.getAllCategoriesUrl());
  }

  getAllCategoriesByModule(moduleId: number): CancelablePromise<CategoryDTO[]> {
    return this.baseService.getAll<CategoryDTO>(this.urlFactory.getAllCategoriesByModuleUrl(moduleId));
  }

  getAllEntries(): CancelablePromise<EntryDTO[]> {
    return this.baseService.getAll<EntryDTO>(this.urlFactory.getAllEntriesUrl());
  }

  getAllEntriesByCategory(categoryId: number): CancelablePromise<EntryDTO[]> {
    return this.baseService.getAll<EntryDTO>(this.urlFactory.getAllEntriesByCategoryUrl(categoryId));
  }

  getMenu(applicationId: number): CancelablePromise<MenuDTO> {
    return this.baseService.get<MenuDTO>(this.urlFactory.getMenuUrl(applicationId));
  }

}
