<style>
  :host {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 8px 0;
  }

  p {
    margin: 0;
  }

  .toolbar img {
    margin: 0 16px;
  }

  .card svg.material-icons path {
    fill: #888;
  }

  a,
  a:visited,
  a:hover {
    color: #1976d2;
    text-decoration: none;
  }

  a:hover {
    color: #125699;
  }

  footer {
    margin-top: 8px;
    display: flex;
    align-items: center;
    line-height: 20px;
  }

  footer a {
    display: flex;
    align-items: center;
  }

  /* Responsive Styles */
  @media screen and (max-width: 767px) {

    .card-container > *:not(.circle-link) {
      width: 100%;
    }
  }
</style>

<div class="content  noPadding" id="gridParent" role="main">

  <app-main-layout #mainLayout noHeader="false" noFooter="false" [menuTemplate]="customMenu"
                   [contentTemplate]="customContent" [helpTemplate]="customHelp"
                   [ngStyle]="{'position': 'absolute', 'width': '100%', 'top':'0', 'bottom':'0', 'left': '0', 'right': '0', 'padding': '0', 'margin': '0'}">
    <ng-template #customMenu>
      <app-vertical-menu *ngIf="isMenuLoaded" #verticalMenu
                         [menuElements]="menuElements"
                         [currentProfile]="this.userProfile"
                         (helpClicked)="mainLayout.changeRightAsideVisibility(!mainLayout.isExpanding)"
                         (paramsClicked)="tabLayout.onAddTab('params')"
                         (disconnectionClicked)="disconnectUser()">
      </app-vertical-menu>
    </ng-template>
    <ng-template #customContent>
      <div class="fullHeight" style="position: relative">
        <app-tab-layout #tabLayout id="tabLayout" (tabSelected)="onTabSelected($event)"></app-tab-layout>
      </div>
    </ng-template>
    <ng-template #customHelp>
      <div class="fullHeight">
        <div class="middlePadding" markdown [data]="currentHelp.value"></div>
<!--                <app-icon-chooser></app-icon-chooser>-->
      </div>
    </ng-template>
  </app-main-layout>

</div>

<app-loader-screen *ngIf="loadingApp"></app-loader-screen>

<router-outlet></router-outlet>

<app-error-dialog [errorDialogDisplayed]="isErrorDialogDisplayed"
                  [title]="mainErrorTitle"
                  [errorMessage]="mainErrorMessage">
</app-error-dialog>

<p-toast position="top-right">
  <ng-template let-message pTemplate="message">
    <div class="p-toast-message-text">
      <div class="p-toast-summary">{{message.summary}}</div>
      <p class="p-toast-detail"  innerHtml="{{message.detail}}"></p>
    </div>
  </ng-template>
</p-toast>
