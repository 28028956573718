<div class="entriesBlock coloredBorder backgroundWhiteColor"
     *ngIf="formattedEntries && formattedEntries.size > 0">
  <div class="entryTypeBlock" *ngFor="let moduleAndCategory of formattedEntries.keys()">
    <div class="moduleCategoryTitle">{{moduleAndCategory}}
      <hr class="solid"/>

    </div>


    <div *ngFor="let entryType of formattedEntries.get(moduleAndCategory)">
      <div *ngFor="let entry of entryType.items">

        <div *ngIf="entryType.items.length"
             [class]="getEntryTypeClass(entryType['entry_type_name'].toLowerCase())">
          <div class="p-pb-1" style="min-width: 250px"
               (mouseenter)="displayCross(entry.entry_id)"
               (mouseleave)="removeEntryCross()">
            <div class="p-grid" style="padding: 0">
              <div class="entryStyle p-col-11" (click)="openEntry(entry)"
                   [innerHTML]=" entry.label | highlight : filter"


              ></div>
              <app-favorite-star *ngIf="displayStar"
                                 (changeFavorite)="updateEntryFavoriteStatus(entry)"
                                 [isFavorite]="entry.is_favorite"></app-favorite-star>
              <app-remove-cross *ngIf="this.displayEntryCross(entry.entry_id)"
                                (crossPressed)="updateEntryFavoriteStatus(entry)"
              ></app-remove-cross>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
