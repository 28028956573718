<div *ngIf="loadingCondition" class="field-modal">
  <div class="fullHeight centerContent">
    <div class="p-d-block">
      <div class="backgroundPrimaryColor whiteColor">
        <div class="centerHorizontal">
          <div class="centerHorizontal p-py-4 p-px-5">Chargement en cours</div>
          <app-spinner class="centerHorizontal"></app-spinner>
        </div>
        <div class="clickable bottom-right" (click)="interruptLoading()">Annuler</div>
      </div>

    </div>
  </div>
</div>

