import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  public static CACHED_APP_VERSION = 'KordisAppVersionTag';

  constructor() { }

  // Nous gardons en cache la dernière version enregistrée de la version de l'APP, et la comparons avec la version d'environnement pour détecter une maj
  static isAppUpdated(): Promise<boolean> {
    return new Promise<boolean>( ((resolve) => {
      const newAppVersion = environment.version;

      if (localStorage.getItem(LocalStorageService.CACHED_APP_VERSION)) {
        const oldAppVersion = localStorage.getItem(LocalStorageService.CACHED_APP_VERSION);

        if (oldAppVersion !== newAppVersion) {
          resolve(true);
        } else {
          resolve(false);
        }
      }
      resolve(false);
    }));
  }

  static cleanCache() {
    // comme window.location.reload(true) est déprécié pour forcer le reload, cette méthode fonctionne
    // noinspection SillyAssignmentJS
    window.location.href = window.location.href;
  }

  static updateCachedVersion() {
    localStorage.setItem(LocalStorageService.CACHED_APP_VERSION, environment.version);
  }
}
