import { Injectable } from '@angular/core';
import {Translation} from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor() { }

  static getCalendarTraduction(): Translation {
    return {
      monthNames: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre'
      ],
      monthNamesShort: [
        'Jan.',
        'Fév.',
        'Mars',
        'Avr.',
        'Mai',
        'Juin',
        'Juil.',
        'Août',
        'Sep.',
        'Oct.',
        'Nov.',
        'Déc.'
      ],
      dayNamesMin: [
        'Lu',
        'Ma',
        'Me',
        'Je',
        'Ve',
        'Sa',
        'Di',
      ],
      today: `Aujourd'hui`,
      clear: 'Vider',
    };
  }
}
