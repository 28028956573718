import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {EntryTypeDTO} from '../../../models/entry-type-dto';
import {EntryDTO} from '../../../models/interfaces/entry-dto';

@Component({
  selector: 'app-menu-search-field',
  templateUrl: './menu-search-field.component.html',
  styleUrls: ['./menu-search-field.component.scss']
})
export class MenuSearchFieldComponent implements OnInit {
  @Input()
  isMenuCollapsed: boolean;

  interfaceFilter = '';

  @Input()
  flattenedEntries: Map<string, EntryTypeDTO[]>;

  displayedEntries: Map<string, EntryTypeDTO[]>;
  displaySearchedEntries = false;
  collapsedSearchFieldDisplayed = false;

  @Output()
  changeFavoriteStatus = new EventEmitter<EntryDTO>();

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
  }

  @HostListener('document:click', ['$event.target'])
  public onPageClick(targetElement) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside && this.isMenuCollapsed) {
      this.hideDisplayedEntries(true, this.isMenuCollapsed);
    }
  }

  searchInterface(newValue: string) {
    if (newValue.length > 0) {
      this.displayedEntries = this.getFilteredFlattenedEntries(newValue);
      this.changeDisplayEntries();
    } else {
      this.hideDisplayedEntries(false, !this.isMenuCollapsed);
    }
  }

  changeDisplayEntries() {
    this.displaySearchedEntries = true;
  }

  hideDisplayedEntries(resetFilter: boolean, hideCollaspedFilter: boolean) {
    this.displaySearchedEntries = false;
    this.interfaceFilter = resetFilter ? '' : this.interfaceFilter;

    if (hideCollaspedFilter) {
      this.collapsedSearchFieldDisplayed = false;
    }
  }

  private getFilteredFlattenedEntries(filter: string): Map<string, EntryTypeDTO[]> {
    const displayedEntries = new Map<string, EntryTypeDTO[]>();

    for (const categoryModule of this.flattenedEntries.keys()) {
      for (const entryType of this.flattenedEntries.get(categoryModule)) {
        for (const entry of entryType.items) {
          if (entry.label.toLowerCase().includes(filter.toLowerCase())) {

            if (!displayedEntries.has(categoryModule)) {
              displayedEntries.set(categoryModule, []);
            }

            if (!displayedEntries.get(categoryModule).includes(entryType)) {
              displayedEntries.get(categoryModule).push({
                entry_type_id: entryType.entry_type_id,
                entry_type_name: entryType.entry_type_name,
                entry_type_position: entryType.entry_type_position,
                items: [],
              });
            }

            displayedEntries.get(categoryModule).find(type => type.entry_type_name === entryType.entry_type_name).items.push(entry);
          }
        }

      }
    }

    return displayedEntries;
  }

  changeCollapsedSearchFieldVisibility() {
    this.collapsedSearchFieldDisplayed = !this.collapsedSearchFieldDisplayed;

    if (!this.collapsedSearchFieldDisplayed) {
      this.hideDisplayedEntries(true, false);
    }
  }

  updateFavoriteStatus(entryDTO: EntryDTO) {
    this.changeFavoriteStatus.emit(entryDTO);
  }
}
