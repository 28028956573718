import {Injectable} from '@angular/core';
import {CancelablePromise} from '../common/promises/cancelable-promise';
import {MenuDTO} from '../models/interfaces/menu-dto';
import {AbstractPageComponent} from '../interfaces/abstract-page.component';
import {InterfacesService} from './interfaces.service';

export type pageData = {type: AbstractPageComponent, header: string, rightId: number, data: any};

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  static componentsList: { [id: string]: pageData }  = {};
  public static KORDIS_ID = 4;
  public static STUDENT = 'student';
  public static QUERY = 'query';
  public static REPORT = 'state';
  // public static DIPLOMAS_LIST = 'diplomasList';
  public static DIPLOMAS_LIST = 'diplomas';
  public static DIPLOMA = 'diploma';

  components: { [id: string]: any; } = {};

  constructor(
    private interfacesService: InterfacesService
  ) {
    this.components = PagesService.componentsList;
  }

  public getComponentsMap(): any {
    return this.components;
  }

  getMenuElements(): CancelablePromise<MenuDTO> {
    return this.interfacesService.getMenu(PagesService.KORDIS_ID);
  }

}
