<p-dialog header="{{isAddMode ? dialogAddHeader : 'Bloc : ' + diplomaBlockDTO.code}}" #skillBlockDialog
          [(visible)]="visible" [contentStyle]="{'overflow':'visible'}"
          [modal]="true" [draggable]="false" [resizable]="false" [closeOnEscape]="true"
          [style]="{width: '50vw'}">
  <div #topTabs class="p-d-flex">
    <div *ngFor="let subTab of subTabs" (click)="onSubTabClicked(subTab)"
         [ngClass]="{'p-px-3': true, 'p-py-1': true, 'p-mr-1': true, 'noWrap': true, clickable: !isAddMode,
                     whiteColor: true,
                     backgroundPrimaryColor: subTab.value === selectedTab,
                     backgroundGreyColor3: subTab.value !== selectedTab}">{{ subTab.label }}</div>
  </div>

  <form *ngIf="diplomaBlockDTO != null" [formGroup]="skillBlockForm">
    <div #generalTab [hidden]="isGeneralTabHidden">
      <div class="p-fluid">
        <p-fieldset styleClass="" legend="Description">
          <table class="fullWidth">
            <tr>
              <td class="labelForm"><label for="blockCode">Code*</label></td>
              <td class="inputForm"><input id="blockCode" formControlName="code" type="text" class="fullWidth"
                                           [required]="true"
                                           pInputText/></td>
            </tr>
            <tr>
              <td class="labelForm"><label for="blockLabel">Intitulé*</label></td>
              <td class="inputForm"><input id="blockLabel" formControlName="name" type="text" class="fullWidth"
                                           [required]="true"
                                           pInputText/></td>
            </tr>
            <tr>
              <td class="labelForm"><label for="parentInput">Parent</label></td>
              <td class="inputForm">
                <p-autoComplete #parentInput (completeMethod)="filterDiplomaBlocksByName($event)"
                                (onClear)="clearParent()"
                                (onSelect)="updateSelectedParentBloc($event)"
                                [dropdown]="true"
                                [suggestions]="filteredParents"
                                [type]="'search'"
                                field="name"
                                id="parentInput"
                                placeholder="Choisir un bloc">
                </p-autoComplete>
              </td>
            </tr>
          </table>
        </p-fieldset>

        <p-fieldset styleClass="" legend="Etat">
          <table class="fullWidth">
            <td class="labelForm"><label for="active">Actif</label></td>
            <td class="inputForm">
              <p-checkbox id="active" name="active" value="is_active"
                          binary="true"
                          formControlName="is_active"
                          [required]="false"></p-checkbox>
            </td>
          </table>
        </p-fieldset>
      </div>
    </div>

    <div #skillTab [hidden]="isSkillTabHidden">
      <div class="p-fluid">
        <p-fieldset legend="Descriptif">
          <textarea id="skillTextArea" formControlName="skill_description"
                    style="min-height: 250px"
                    class="fullWidth fullHeight">
          </textarea>
        </p-fieldset>
      </div>
    </div>

    <div #evaluationTab [hidden]="isEvaluationTabHidden">
      <div class="p-fluid">
        <p-fieldset legend="Descriptif">
          <textarea id="evaluationTextArea" formControlName="evaluation_description"
                    style="min-height: 250px"
                    class="fullWidth fullHeight">
          </textarea>
        </p-fieldset>
      </div>
    </div>
  </form>

  <ng-template pTemplate="footer">
    <button pButton class="p-button-success" type="button" icon="pi pi-save"
            [disabled]="isLoading || !formIsValid()"
            [label]="(isAddMode ? 'Créer' : 'Modifier') + ' le bloc'"
            (click)="onSaveBlock()"></button>
    <button pButton icon="pi pi-ban" label="Annuler" (click)="skillBlockDialog.close($event)"></button>
  </ng-template>

  <app-field-loader [loadingCondition]="isLoading" (cancel)="this.cancelRequest()"></app-field-loader>
</p-dialog>
