import {StaticParamsData} from './static-params-data';

export class SelectedParams {

  field: string;
  is_mandatory: boolean;
  is_request_param: boolean;
  value: string;
  staticParams: StaticParamsData[];

  constructor(field: string, is_mandatory: boolean, is_request_param: boolean, value: string, staticParams: StaticParamsData[] = []) {
    this.field = field;
    this.is_mandatory = is_mandatory;
    this.is_request_param = is_request_param;
    this.value = value;
    this.staticParams = staticParams;
  }
}
