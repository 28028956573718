export enum ColumnSizeEnum {
  EXTRA_SMALL_30_COLUMN_SIZE = '30px',
  EXTRA_SMALL_35_COLUMN_SIZE = '35px',
  EXTRA_SMALL_COLUMN_SIZE = '40px',
  VERY_SMALL_45_COLUMN_SIZE = '45px',
  VERY_SMALL_50_COLUMN_SIZE = '50px',
  VERY_SMALL_60_COLUMN_SIZE = '60px',
  VERY_SMALL_COLUMN_SIZE = '80px',
  SMALL_COLUMN_SIZE = '120px',
  MEDIUM_COLUMN_SIZE = '160px',
  LARGE_COLUMN_SIZE = '200px',
  VERY_LARGE_COLUMN_SIZE = '240px',
  VERY_LARGE_300_COLUMN_SIZE = '300px',
  EXTRA_LARGE_COLUMN_SIZE = '520px',
  DATE_WITHOUT_TIME_COLUMN_SIZE = EXTRA_SMALL_35_COLUMN_SIZE,

}
