
<div class="fullHeight noPadding">
  <div *ngIf="!noHeader" class="header softMargin" [ngStyle]="{'height': spaceFromTop + 'px', 'overflow-y': 'scroll'}">
      <ng-container *ngTemplateOutlet="headerTemplate ? headerTemplate : defaultHeader"></ng-container>
  </div>
  <div class="fullHeight">
    <div class="p-grid fullHeight">
      <div class="fullHeight">
        <ng-container *ngTemplateOutlet="menuTemplate ? menuTemplate : defaultMenu"></ng-container>
      </div>
      <div class="fullHeight p-col softPadding">
        <ng-container *ngTemplateOutlet="contentTemplate ? contentTemplate : defaultContent"></ng-container>
      </div>
      <aside [ngClass]="{'expanding': isExpanding}">
        <button pButton type="button" icon="pi pi-times" class="p-button-rounded p-button-secondary p-button-text softMarginTop miniMarginLeft" label="Fermer l'aide" (click)="changeRightAsideVisibility(false)"></button>
        <ng-container *ngTemplateOutlet="helpTemplate ? helpTemplate : defaultHelp"></ng-container>
      </aside>
    </div>
    <div *ngIf="!noFooter" class="footer softMargin absolute scrollable-y">
      <ng-container *ngTemplateOutlet="footerTemplate ? footerTemplate : defaultFooter"></ng-container>
    </div>
  </div>
</div>

<ng-template #defaultHeader>En-tête de page par défaut</ng-template>
<ng-template #defaultMenu>Menu par défaut</ng-template>
<ng-template #defaultContent>Contenu par défaut</ng-template>
<ng-template #defaultHelp>Aide par défaut</ng-template>
<ng-template #defaultFooter><div [ngStyle]="{'top': 'calc(100% - ' + spaceFromBottom + 'px)', 'left': '0px', 'right': '0px', 'bottom': '0px'}">Pied de page par défaut</div></ng-template>
