import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ParamsData} from '../../models/query/params-data';
import {SelectedParams} from '../../models/query/selected-params';
import {environment} from '../../../environments/environment';
import {Canceler} from '../../common/promises/request-canceler';
import {BaseService} from '../../services/base.service';
import {PrimeNGConfig} from 'primeng/api';
import {CalendarService} from '../../services/calendar.service';
import {SchoolYearSemesterValues} from '../../models/school-year-semester-values';
import {FieldValue} from '../../models/query/field-value';

@Component({
  selector: 'app-dynamic-params-selector',
  templateUrl: './dynamic-params-selector.component.html',
  styleUrls: ['./dynamic-params-selector.component.scss']
})
export class DynamicParamsSelectorComponent implements OnInit {


  constructor(private baseService: BaseService,
              private config: PrimeNGConfig) { }

  @Input()
  params: ParamsData[];

  @Input()
  selectedParams: SelectedParams[] = [];

  @Input()
  mandatoryFields: string[];

  @Output() dynamicParamSelected = new EventEmitter<FieldValue>();

  isLoading = false;

  canceler: Canceler = new Canceler();
  inputsList: Map<string, string> = new Map([]);

  @Input()
  optionsList: Map<string, any> = new Map([]);


  ngOnInit(): void {
    this.config.setTranslation(CalendarService.getCalendarTraduction());
  }

  isDynamicParamReady(param: ParamsData) {
    let isDynamicParamReady = true;

    if (param.staticParams != null) {

      param.staticParams.forEach(staticParam => {
        const optionalSelectedParam = this.selectedParams.find(selectedParam => selectedParam.field === staticParam.field);
        if (optionalSelectedParam == null) {
          isDynamicParamReady = false;
        }
        if (optionalSelectedParam.is_mandatory && optionalSelectedParam.value == null) {
          isDynamicParamReady = false;
        }
        if (!optionalSelectedParam.is_request_param && optionalSelectedParam.value == null) {
          isDynamicParamReady = false;
        }
      });
    }

    return isDynamicParamReady;
  }

  updateSelectedValueParam(field: string, value: any) {
    this.dynamicParamSelected.emit(new FieldValue(field, value));
  }

  updateDateParam(field: string, date: any) {
    this.updateSelectedValueParam(field, (date.getTime()).toString());
  }

  getSuggestBoxList(param: ParamsData, currentSelection: string) {
    if (this.isDynamicParamReady(param)) {
      const query = this.formatSourceUrl(param);

      if (query != null) {
        this.baseService.getAll(environment.servicesUrl + query + '/' + currentSelection)
          .setCancelAction(this.canceler)
          .then(data => {
            this.optionsList[param.field] = data;
          })
          .catch(console.error) // TODO
          .finally(() => this.isLoading = false);
      }
    }
  }

  formatSourceUrl(param: ParamsData) {
    let fieldError = '';
    let requestIndex = 0;
    let url = param.source;

    if (param.staticParams != null) {
      param.staticParams.forEach(staticParam => {

        const optionalSelectedParam = this.selectedParams.find(selectedParam => selectedParam.field === staticParam.field);

        if (optionalSelectedParam == null) {
          fieldError = staticParam.field;
          return;
        }

        if (staticParam.is_request_param == null || staticParam.is_request_param) {
          if (requestIndex === 0) {
            url += `?${staticParam.field}=${optionalSelectedParam.value}`;
          } else {
            url += `&${staticParam.field}=${optionalSelectedParam.value}`;
          }
          requestIndex++;
        } else {
          url = url.replace(`{${staticParam.field}}`, optionalSelectedParam.value);
        }
      });
    }

    if (fieldError.length > 0) {
      return null;
    }

    return url;
  }

  isElementMandatory(currentField: string) {
    return this.mandatoryFields.some(field => field === currentField);
  }
}
