import { Injectable } from '@angular/core';
import {MessageService} from 'primeng/api';
import {BehaviorSubject, Observable} from 'rxjs';
import {ErrorDialogMessage} from '../models/error-dialog-message';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private mIsErrorDialogVisible = new BehaviorSubject<ErrorDialogMessage>({is_visible: false, header: '', message: ''});
  readonly mObservedErrorDialogVisibility = this.mIsErrorDialogVisible.asObservable();
  private errorDialogVisibility: ErrorDialogMessage | undefined;

  get isErrorDialogVisible(): Observable<ErrorDialogMessage> {
    return this.mObservedErrorDialogVisibility;
  }

  constructor(private messageService: MessageService) { }

  showCustomErrorDialog(val: ErrorDialogMessage): void {
    this.errorDialogVisibility = val;
    this.mIsErrorDialogVisible.next(this.errorDialogVisibility);
  }

  showError(error: any): void {
    switch (error.status) {
      case 404: this.showCustomErrorToast({ is_visible: true, header: 'Erreur', message: `La ressource demandée n'a pas été trouvée.`}); break;
      case 405: this.showCustomErrorToast({ is_visible: true, header: 'Erreur', message: `Vous n'êtes pas autorisé à accéder à cette ressource.`}); break;
      case 423: this.showCustomErrorDialog({is_visible: true, header: 'Erreur', message: 'Votre compte a été momentanément bloqué pour la raison suivante : .'}); break;
      case 500: this.showCustomErrorDialog({is_visible: true, header: 'Erreur', message: 'Impossible de contacter le serveur.'}); break;
      default: this.showCustomErrorDialog({is_visible: true, header: 'Erreur', message: 'Une erreur inconnue est survenue.'}); break;
    }
  }

  showCustomErrorToast(errorDialogMessage: ErrorDialogMessage, duration?: number): void {
    this.messageService.add({severity: 'error', summary: errorDialogMessage.header, detail: errorDialogMessage.message, life: duration});
  }

  showCustomWarningToast(errorDialogMessage: ErrorDialogMessage, duration?: number): void {
    this.messageService.add({severity: 'warn', summary: errorDialogMessage.header, detail: errorDialogMessage.message, life: duration});
  }
}
