import { Injectable } from '@angular/core';
import {Observable, Subscriber} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReloadService {

  private layoutsShouldRefreshSubscriber: Subscriber<any>;
  private observedLayoutsShouldRefresh: Observable<any> = Observable.create((consumer) => this.layoutsShouldRefreshSubscriber = consumer);
  private layoutsShouldRefresh = false;

  constructor() { }

  shouldRefreshLayouts(val: any) {
    this.layoutsShouldRefresh = val;
    if (this.layoutsShouldRefreshSubscriber !== undefined) {
      this.layoutsShouldRefreshSubscriber.next(val);
    }
  }

  getObservedLayoutsShouldRefresh(): Observable<any> {
    return this.observedLayoutsShouldRefresh;
  }
}
