import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {UrlFactoryService} from '../factories/url-factory.service';
import {CancelablePromise} from '../common/promises/cancelable-promise';
import {Structure} from '../models/structure/structure';

@Injectable({
  providedIn: 'root'
})
export class StructureService {

  constructor(private baseService: BaseService,
              private urlFactory: UrlFactoryService) { }

  getStructures(): CancelablePromise<Structure[]> {
    return this.baseService.getAll<Structure>(this.urlFactory.getStructuresUrl());
  }

  getStructuresByUid(): CancelablePromise<Structure[]> {
    return this.baseService.getAll<Structure>(this.urlFactory.getStructuresByUidUrl());
  }
}
