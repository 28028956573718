<div [class]="styleClass" [ngStyle]="style"
     [ngClass]="{'p-megamenu p-component':true,'p-megamenu-horizontal': orientation == 'horizontal','p-megamenu-vertical': orientation == 'vertical'}">
  <div class="p-megamenu-start" *ngIf="startTemplate">
    <ng-container *ngTemplateOutlet="startTemplate"></ng-container>
  </div>
  <ul class="p-megamenu-root-list" role="menubar">

    <div [attr.title]="'Replier le menu'" (click)="onMenuCollapseClicked()" class="collapseButton"
         style="position: relative" pRipple>
      <span
        [class]="isMenuCollapsed ? 'p-menuitem-icon pi pi-bars expendButtonIcon' : 'pi pi-angle-left collapseButtonIcon'"></span>
      <span class="p-menuitem-text">{{ isMenuCollapsed ? '' : 'Replier le menu' }}</span>
    </div>

    <app-menu-search-field [isMenuCollapsed]="isMenuCollapsed"
                           [flattenedEntries]="flattenedEntries"
                           (click)="menuOpened = false;"
                           (changeFavoriteStatus)="updateFavoriteStatus($event)"></app-menu-search-field>

    <app-favorite-menu-entries [isMenuCollapsed]="isMenuCollapsed"
                               [fullEntries]="flattenedEntries"
                               [opened]="menuOpened"
                               (changeFavoriteStatus)="updateFavoriteStatus($event)"
                               (openedEmitter)="menuOpened = $event"
    ></app-favorite-menu-entries>


    <ng-template ngFor let-module [ngForOf]="model">
      <li *ngIf="module.separator" class="p-menu-separator" [ngClass]="{'p-hidden': module.visible === false}"></li>
      <li *ngIf="!module.separator"
          [ngClass]="{'p-menuitem':true,'p-menuitem-active':module==activeItem, 'p-hidden': module.visible === false}"
          (mouseenter)="onModuleMouseEnter($event, module)" (mouseleave)="onModuleMouseLeave()">
        <a *ngIf="!module.routerLink" [href]="module.url||'#'" [attr.target]="module.target" [attr.title]="module.title"
           [attr.id]="module.id" (click)="onModuleClick($event, module)"
           [attr.tabindex]="module.tabindex ? module.tabindex : '0'"
           [ngClass]="{'p-menuitem-link':true,'p-disabled':module.disabled}" [ngStyle]="module.style"
           [class]="module.styleClass" pRipple>
          <span class="p-menuitem-icon" *ngIf="module.icon" [ngClass]="module.icon + ' p-mr-5 p-px-4'"></span>
          <span class="p-menuitem-text"
                *ngIf="module.escape !== false; else categoryHtmlLabel">{{!isMenuCollapsed ? module.label : ''}}</span>
          <ng-template #categoryHtmlLabel><span class="p-menuitem-text" [innerHTML]="module.label"></span></ng-template>
          <span *ngIf="module.items" class="p-submenu-icon p-mr-5 pi"
                [ngClass]="{'pi-angle-down':orientation=='horizontal','pi-angle-right':orientation=='vertical'}"></span>
        </a>
        <div class="p-megamenu-panel coloredBorder backgroundTransparent" *ngIf="module.items"
             (mouseleave)="onModuleCategoriesLeave($event)">
          <div class="p-d-flex">
            <div class="p-d-block coloredBorder">
              <div class="noWrap" *ngFor="let category of module.items">
                <div class="menuCategory p-p-3 p-d-flex p-justify-between centerContent backgroundWhiteColor"
                     (mouseenter)="onCategoryHover($event, category.items)">
                  <div class="p-m-auto">{{category.label}}</div>
                  <span *ngIf="category.items" class="pi pi-angle-right p-mt-1 p-ml-3"></span>
                </div>
              </div>
            </div>
            <div class="entriesBlock coloredBorder backgroundWhiteColor"
                 *ngIf="hoveredCategoryEntryTypes && hoveredCategoryEntryTypes.length > 0">
              <div class="entryTypeBlock" *ngFor="let entryType of hoveredCategoryEntryTypes">
                <div *ngIf="entryType['entry_type_name'].toLowerCase() === 'saisie' && entryType.items.length"
                     class="saisieTitle entryTypeTitle">{{entryType['entry_type_name']}}</div>
                <div *ngIf="entryType['entry_type_name'].toLowerCase() === 'requêtes' && entryType.items.length"
                     class="requetesTitle entryTypeTitle">{{entryType['entry_type_name']}}</div>
                <div *ngIf="entryType['entry_type_name'].toLowerCase() === 'états' && entryType.items.length"
                     class="etatsTitle entryTypeTitle">{{entryType['entry_type_name']}}</div>
                <div class="p-pb-1" *ngFor="let entry of entryType.items">
                  <div class="p-d-flex centerVertical p-px-2 p-pb-1" style="min-width: 250px">
                    <div class="p-mr-2"><span [class]="entry.icon"></span></div>
                    <div class="noWrap">
                      <app-favorite-star class="favorite-star" [isFavorite]="entry.is_favorite" (changeFavorite)="updateFavoriteStatus(entry)"></app-favorite-star>
                      <span class="entryStyle p-ml-2"
                            (click)="onCommandClicked(entry)">{{ entry.label }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ng-template>
    <div class="p-megamenu-end" *ngIf="endTemplate; else legacy">
      <ng-container *ngTemplateOutlet="endTemplate"></ng-container>
    </div>
    <ng-template #legacy>
      <div class="p-megamenu-end">
        <ng-content></ng-content>
      </div>
    </ng-template>
  </ul>
    <div [class]="isMenuCollapsed ? 'version' : 'version-deployed'">
      <app-version></app-version>
    </div>
</div>
