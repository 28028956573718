<div [style]="{'position': 'relative', 'width': width + 'px'}">
  <p-autoComplete #autoComplete
                  [(ngModel)]="textFilter"
                  [suggestions]="suggestions"
                  [placeholder]="placeHolder"
                  (onFocus)="updateDropdownVisibility(true)"
                  [style]="{'width':(width - 50) + 'px' }" [inputStyle]="{'width':'100%'}"
                  (completeMethod)="onSearchData()"
                  [multiple]="false">
  </p-autoComplete>

  <p-button icon="pi pi-refresh" [style]="{'width':'50px'}" (onClick)="onSearchData()"></p-button>
  <div class="state-container-dropdown" *ngIf="displayDropdown()">
    <p-table [value]="data"
             [(selection)]="mainDataInput"
             selectionMode="single"
             [paginator]="true"
             [rows]="5"
             [pageLinks]="4"
             [showCurrentPageReport]="true"
             currentPageReportTemplate="Page courante {first} - {last} sur {totalRecords}">
      <ng-template pTemplate="body" let-data>
        <tr [pSelectableRow]="data" (click)="onSelectData(data)">
            <td>
              <ng-container *ngTemplateOutlet="rowTemplate ? rowTemplate : defaultRow; context: {rowData: data }"></ng-container>
            </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<ng-template #defaultRow let-rowData='rowData'>
  {{ rowData[fieldName]}}
</ng-template>
