<style>
  .text-error {
    filter: invert(25%) sepia(64%) saturate(1447%) hue-rotate(332deg) brightness(94%) contrast(99%);
  }
</style>

<div style="position: absolute; top: 0; bottom: 0; left: 0; right: 0; background-color: #343434; z-index: 100000">
  <div class="middleContainer" style="display: flex; align-items: center; justify-content: center">
    <div style="display: block; text-align: center;">
      <img style="height: 268px" src="../../../assets/Kordis_logo_blanc.svg"/>
      <div class="p-mt-6" style="height: 200px; display: block; text-align: center; color:white">
        <div style="height: 50px; font-size: 1.5rem">Echec de connexion.
          Si le problème devait persister, merci de
          contacter le support Applicatif (support@kordis.fr)
        </div>
        <i (click)="refresh()" class="pi pi-refresh pointer" style="font-size: 3rem; margin-top: 5px"></i>
      </div>
    </div>
    <div style="display: block; text-align: center">
    </div>
  </div>
  <div class="fullHeight" style="display: flex; align-items: center; justify-content: center">
  </div>
</div>


