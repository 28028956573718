import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {AbstractPageComponent} from '../../../interfaces/abstract-page.component';
import {ConfirmationService, MessageService, TreeNode} from 'primeng/api';
import {RightsService} from '../../../services/rights.service';
import {RightsGroup} from '../../../models/rights/rights-group';
import {RightsGroupHasRight} from '../../../models/rights/rights-group-has-right';
import {AdministrationService} from '../../../services/administration.service';
import {Administrative} from '../../../models/administration/administrative';
import {AuthenticatedUserService} from '../../../services/authenticated-user.service';
import {AutoComplete} from 'primeng/autocomplete';
import {HasRights} from '../../../models/rights/has-rights';
import {RightLogDTO} from '../../../models/rights/right-log-dto';
import {RightsGroupMemberLogDTO} from '../../../models/rights/rights-group-member-log-dto';
import {OrganizationChart} from 'primeng/organizationchart';
import {TreeComponent} from '../../../components/tree/tree.component';
import {MainLayoutComponent} from '../../../components/main-layout/main-layout.component';
import {UserHasRight} from '../../../models/rights/user_has_right';
import {Page} from '../../../annotations/page.annotation';

@Component({
  selector: 'app-rights',
  templateUrl: './rights.component.html',
  styleUrls: ['./rights.component.scss']
})
@Page('rights', 'Attribution des droits', undefined, { icon: 'pi pi-user' })
export class RightsComponent extends AbstractPageComponent implements OnInit {

  //#region tab
  GROUPS_TAB_INDEX = 0;
  USERS_DETAILS_TAB_INDEX = 1;
  SCHOOLS_RIGHTS_TAB_INDEX = 0;
  INTERFACES_RIGHTS_TAB_INDEX = 1;
  FUNCTIONS_RIGHTS_TAB_INDEX = 2;
  MEMBERS_RIGHTS_TAB_INDEX = 3;
  GROUPS_RIGHTS_TAB_INDEX = 3;
  //#endregion

  //#region rights
  SCHOOLS_RIGHTS_FAMILY_IDS = [1, 2, 3];
  APPLICATION_RIGHTS_FAMILY_IDS = [4, 5];
  SCHOOLS_RIGHTS_TYPE_ID = 4;
  INTERFACES_RIGHTS_TYPE_IDS = [2, 3];
  FUNCTIONS_RIGHTS_TYPE_ID = 1;
  //#endregion

  @ViewChild('schoolsTree') schoolsTree: TreeComponent;
  @ViewChild('interfacesTree') interfacesTree: TreeComponent;
  @ViewChild('functionsTree') functionsTree: TreeComponent;
  @ViewChild('userSearchInput') userSearchInput: AutoComplete;
  @ViewChild('chart') chart: OrganizationChart;

  userTypeTabActiveIndex = 0;
  rightsTabActiveIndex = 0;

  //#region filters
  rightsGroupSearchInput: string;
  memberSearchInput: string;
  rightsGroupLogsSearchInput: string;
  //#endregion

  loading = false;

  //#region users
  //#endregion

  //#region rights group
  loadingGroups = false;
  rightsGroups: TreeNode<RightsGroup>[] = [];
  allRightsGroupsTree: TreeNode<RightsGroup>[] = [];
  allRightsGroups: RightsGroup[] = [];
  selectedRightsGroup: any;

  loadingRightsGroupHasRights = false;
  selectedRightsGroupRights: TreeNode<RightsGroupHasRight>[] = [];
  flatRights: RightsGroupHasRight[];
  selectedRightsGroupHasRight: any;
  mSavedSelectedRightsGroupHasRight: any;
  private mSavedRightFamilies: string;

  hasAnyRightToSave = false;

  allRights = {
    can_read: null,
    can_update: null,
    can_delete: null,
    can_transmit: null
  };

  private currentUserRights: { [id: number]: RightsGroupHasRight[]; } = {};
  private currentUserRightsGroupIds: number[] = [];
  private userRightsGroupIds: number[] = [];
  //#endregion

  //#region dialogs
  displayRightsGroupOrganizationChartDialog = false;
  displayRightsGroupOrganizationChart = true;
  displaySelectedMemberRights = false;
  displayRightsGroupSaveOrUpdateDialog = false;
  displayRightsGroupDuplicationDialog = false;
  displayRightsGroupDeleteDialog = false;
  displayLogsDialog = false;
  displayMembersHistoryDialog = false;

  rightsGroupOrganizationChartSelectedNode: any;

  selectedMemberRightsLogs: TreeNode[] = [];
  selectedMemberUpperRights: TreeNode[] = [];

  rightsGroupToSaveOrUpdate: RightsGroup;
  rightsGroupToDuplicate: RightsGroup;
  rightsGroupToDuplicateRights: RightsGroupHasRight[];
  selectedRightsGroupsMembersToDuplicate: Administrative[];
  selectedRightsGroupsRightsToDuplicate: RightsGroupHasRight[];
  selectedUserToDisplay: Administrative;
  //#endregion

  //#region Logs
  rightsGroupHasRightHistories: RightLogDTO[] = [];
  filteredRightsGroupHasRightHistories: RightLogDTO[] = [];
  rightsGroupMemberLogs: RightsGroupMemberLogDTO[];
  contextMenuItems = [
    {label: 'Historique des membres', icon: 'pi pi-clock', command: () => this.showMembersHistoryDialog()}
  ];
  //#endregion

  //#region details/groups
  selectedGroup: string;
  filteredGroups: RightsGroup[] = [];
  rightsGroupsFromMember: RightsGroup[] = [];
  filteredRightsGroupsFromMember: RightsGroup[] = [];
  selectedRightsGroupIdsFromMemberToRemove: number[] = [];
  hasMemberRightsGroupsToSave = false;
  selectedMemberCurrentRightsGroupIds: number[] = [];
  //#endregion

  //#region groups/members
  areMembersLoading = false;
  selectedAdministrative: any;
  filteredAdministratives: any;
  membersFromRightsGroup: Administrative[] = [];
  filteredMembersFromRightsGroup: Administrative[] = [];
  selectedMemberIdsFromRightsGroupToRemove: number[] = [];
  hasRightsGroupMembersToSave = false;
  selectedRightsGroupsCurrentMemberIds: number[] = [];
  //#endregion

  hasRightToEdit = false;
  private rootParentRightsGroupId: number;

  //#region static methods
  private static affectRightType(cr: TreeNode): TreeNode {
    cr.type = cr.data.right_type_name === 'school' ? 'schoolRight' : 'right';
    return cr;
  }

  private static adaptRightWithNoRights(element: RightsGroupHasRight): RightsGroupHasRight {
    return {
      can_create: false,
      can_create_disabled: !element.can_create,
      can_read: false,
      can_read_disabled: !element.can_read,
      can_update: false,
      can_update_disabled: !element.can_update,
      can_delete: false,
      can_delete_disabled: !element.can_delete,
      can_transmit: false,
      can_transmit_disabled: !element.can_transmit,
      right_desc: element.right_desc,
      right_id: element.right_id,
      right_name: element.right_name,
      rights_family_id: element.rights_family_id,
      rights_family_name: element.rights_family_name,
      right_type_id: element.right_type_id,
      right_type_name: element.right_type_name,
      rights_group_id: element.rights_group_id,
      rights_group_name: element.rights_group_name
    };
  }

  private static disableRightsAccordingToCurrentUserRights(cr: TreeNode, currentUserRight: RightsGroupHasRight, hasRightToEdit: boolean): TreeNode<RightsGroupHasRight> {
    cr.data.can_create_disabled = !hasRightToEdit || !currentUserRight.can_create;
    cr.data.can_read_disabled = !hasRightToEdit || !currentUserRight.can_read;
    cr.data.can_update_disabled = !hasRightToEdit || !currentUserRight.can_update;
    cr.data.can_delete_disabled = !hasRightToEdit || !currentUserRight.can_delete;
    cr.data.can_transmit_disabled = !hasRightToEdit || !currentUserRight.can_transmit;
    return cr;
  }

  private static createHtmlList(elements: any[], property: string): string {
    let result = '<ul>';
    elements.forEach(el => result += `<li>${el[property]}</li>`);
    return result + '</ul>';
  }

  //#endregion

  constructor(private rightsService: RightsService,
              private authenticatedUserService: AuthenticatedUserService,
              private administrationService: AdministrationService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              @Inject(MainLayoutComponent) public mainLayout: MainLayoutComponent) {
    super();
  }

  ngOnInit(): void {
    this.initRightsGroupsTree(undefined);
  }

  parseRightsGroupHasRightLog(text: string): HasRights {

    const splitted = text
      .replace(') ', ' ')
      .split('RRightsGroupHasRightId(').join()
      .split(')').join()
      .split(' ').join()
      .split('RRightsGroupHasRight(id').join()
      .split('=').join()
      .split(',').filter(i => i);

    const map = [];

    for (let i = 0; i < splitted.length; i += 2) {
      map[splitted[i]] = splitted[i + 1];
    }

    return {
      can_create: map[`canCreate`] === 'true',
      can_read: map[`canRead`] === 'true',
      can_update: map[`canUpdate`] === 'true',
      can_delete: map[`canDelete`] === 'true',
      can_transmit: map[`canTransmit`] === 'true'
    };
  }

  getHelp(): string {
    return 'L\'aide de l\'attribution des droits';
  }

  //#region util methods
  private reduce(elements: any[], source: any[], property: string, childProperty: string) {
    source.forEach(el => {
      elements.push(el[property]);
      if (el[childProperty] !== undefined && el[childProperty] !== null && el[childProperty].length > 0) {
        this.reduce(elements, el[childProperty], property, childProperty);
      }
    });
  }

  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  private getRootParentRightsGroupId(node: any): number {
    if (node.parent === undefined) {
      return node.data.rights_group_id;
    } else {
      const parentId = node.parent.rights_group_id;
      if (parentId === node.data.rights_group_id) {
        return parentId;
      } else {
        return this.getRootParentRightsGroupId(node.parent);
      }
    }
  }

  private getRightsGroupsParents(allRightsGroups: RightsGroup[], filteredRightsGroups: RightsGroup[]) {
    allRightsGroups.forEach(
      o => filteredRightsGroups.map(rg => {
        if (o.rights_group_id === rg.rights_group_parent_id) {
          rg.rights_group_name = o.rights_group_name.concat(' > ', rg.rights_group_name);
        }
      }));
  }

  private getPropertyTriStateValue(property: string, selectedRights, length) {
    const canLength = selectedRights.filter(o => o.data[property] === true).length;
    return length > 0 && (canLength === 0 || canLength === length)
      ? canLength === length
      : null;
  }

  private extractCurrentRightsFromTree(tree): RightsGroupHasRight[] {
    return this
      .reduceRights(tree, 'data', 'children')
      .sort((a, b) => a.right_id > b.right_id ? 1 : -1);
  }

  //#endregion

  initRightsGroupsTree(rightsGroupIdToDisplay: number) {
    this.loading = true;

    this.resetArray(this.selectedRightsGroupRights);
    this.resetArray(this.membersFromRightsGroup);

    delete this.selectedRightsGroup;

    this.rightsService.getCurrentRightsGroup()
      .then((rgs: RightsGroup[]) => {

        // on stock les groupes de droits sur lesquels l'utilisateur n'a pas de droit de modifications
        this.currentUserRightsGroupIds = rgs
          .filter(r => !r.has_right_to_edit).map(r => r.rights_group_id);

        // on stock les ids des groupes de droits dans une variable
        rgs.forEach(rg => this.userRightsGroupIds.push(rg.rights_group_id));

        // récupération de tous les droits de l'utilisateur, quelque soient les groupes
        Promise.all(this.currentUserRightsGroupIds
          .map(rgId => this.rightsService.getRightsGroupRights(rgId)))
          .then((values) => {
            this.currentUserRights = [];

            values.forEach(o => {
              if (o && o.length > 0) {

                if (!this.rootParentRightsGroupId) {
                  this.rootParentRightsGroupId = o[0].rights_group_id;
                }

                this.currentUserRights[o[0].rights_group_id] = o;
              }
            });

            rgs.forEach(rg => rg[`key`] = rg.rights_group_id);
            this.rightsGroups = this.createRightsGroupsDataTree(rgs);

            if (rightsGroupIdToDisplay) {
              this.expandRecursiveToRightsGroup(this.rightsGroups, rightsGroupIdToDisplay, true);
            }
          })
          .then(() => this.loading = false);
      })
      .catch(() => {
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          detail: 'Erreur lors du chargement de votre groupe de droits. Si le problème persiste, Veuillez contacter le support informatique.',
          life: 20000
        });
      });
  }

  private resetArray(array: Array<any>) {
    if (array) {
      array.splice(0, array.length);
    }
  }

  onResetRightsGroupMembersSearchInput() {
    if (this.memberSearchInput) {
      this.memberSearchInput = '';
      this.filteredMembersFromRightsGroup = [...this.membersFromRightsGroup];
    }
  }

  onResetRightsGroupSearchInput() {
    if (this.rightsGroupSearchInput) {
      this.rightsGroupSearchInput = '';
      this.filteredRightsGroupsFromMember = [...this.rightsGroupsFromMember];
    }
  }

  onResetRightsGroupLogsSearchInput() {
    if (this.rightsGroupLogsSearchInput) {
      this.rightsGroupLogsSearchInput = '';
      this.filteredRightsGroupHasRightHistories = [...this.rightsGroupHasRightHistories];
    }
  }

  onRightsGroupNodeUnselect(event: any) {
    this.resetArray(this.selectedRightsGroupHasRight);
    this.messageService.add({
      severity: 'warn',
      summary: event.node.data.rights_group_name
    });
  }

  resetFilters() {
    this.schoolsTree.onResetFilter();
    this.interfacesTree.onResetFilter();
    this.functionsTree.onResetFilter();
    if (this.userTypeTabActiveIndex === this.GROUPS_TAB_INDEX) {
      this.onResetRightsGroupMembersSearchInput();
    }
  }

  createRightsDataTree(dataset, rights: TreeNode[], hasRightToEdit: boolean) {
    const currentUserRights = this.filterRightsBySelectedTab(this.currentUserRights[this.rootParentRightsGroupId]);

    const hashTable = Object.create(null);
    dataset.forEach(aData => {
      aData.data = aData;
      aData.type = 'family';
      aData.label = aData.rights_family_name;
      // aData.expanded = expanded;
      hashTable[aData.rights_family_id] = {...aData, children: []};

      const childRights = rights.filter(r => r.data.rights_family_id === aData.rights_family_id);

      if (childRights.length > 0) {
        childRights
          .map(cr => RightsComponent.disableRightsAccordingToCurrentUserRights(cr, currentUserRights.find(o => o.right_id === cr.data.right_id), hasRightToEdit))
          .map(cr => RightsComponent.affectRightType(cr))
          .map(cr => hashTable[aData.rights_family_id].children.push(cr));
      }
    });

    const dataTree = [];
    dataset.forEach(aData => {
      if (aData.rights_family_parent_id) {
        hashTable[aData.rights_family_parent_id].children.push(hashTable[aData.rights_family_id]);
      } else {
        dataTree.push(hashTable[aData.rights_family_id]);
      }
    });
    return this.filterRightFamiliesBySelectedTab(dataTree);
  }

  createRightsGroupsDataTree(dataset) {
    const hashTable = Object.create(null);
    dataset.forEach(aData => {
      aData.data = aData;

      const isRightsGroupMember = this.currentUserRightsGroupIds.findIndex(o => o === aData.data.rights_group_id) !== -1;
      aData.type = aData.has_right_to_edit
        ? 'rightsGroupWithRights'
        : isRightsGroupMember
          ? 'rightsGroupMember'
          : 'rightsGroupWithoutRights';

      aData.label = aData.rights_group_name;
      hashTable[aData.rights_group_id] = {...aData, children: []};
    });
    const dataTree = [];
    dataset.forEach(aData => {
      if (aData.rights_group_parent_id && hashTable[aData.rights_group_parent_id]) {
        hashTable[aData.rights_group_parent_id].children.push(hashTable[aData.rights_group_id]);
      } else {
        dataTree.push(hashTable[aData.rights_group_id]);
      }
    });
    return dataTree;
  }

  loadRightsGroupRights(rightsGroupId: number, rootRightsGroupId: number) {
    return new Promise((resolve => {
      this.loadingRightsGroupHasRights = true;

      this.rightsService.getRightsGroupRights(rightsGroupId)
        .then((nodes) => {
          const notOwnedRights: RightsGroupHasRight[] = [];

          const currentUserRights = this.currentUserRights[rootRightsGroupId];

          if (!currentUserRights || currentUserRights.length === 0) {
            this.messageService.add({
              severity: 'warn',
              detail: 'Ce groupe ne possède aucun droit, veuillez vous adresser à un membre d\'un groupe de droits supérieur.',
              life: 10000
            });

            this.resetArray(this.selectedRightsGroupRights);
            this.resetArray(this.membersFromRightsGroup);

            this.hasRightToEdit = this.currentUserRightsGroupIds.findIndex(o => o === rightsGroupId) === -1;

            return;
          }

          for (const cur of currentUserRights) {
            if (nodes.findIndex(r => r.right_id === cur.right_id) === -1) {
              notOwnedRights.push(cur);
            }
          }

          const allRightsToAdd: TreeNode<RightsGroupHasRight>[] = notOwnedRights
            .map(rg => RightsComponent.adaptRightWithNoRights(rg))
            .map(rg => ({label: rg.right_desc, data: rg, type: 'right', children: []}));

          this.hasRightToEdit = this.currentUserRightsGroupIds.findIndex(o => o === rightsGroupId) === -1;

          this.flatRights = nodes;

          this.rightsService.getRightsFamilies()
            .then((data) => {
              this.mSavedRightFamilies = JSON.stringify(data);

              const rights = (nodes !== undefined && nodes !== null && nodes.length > 0)
                ? nodes.map(rg => ({label: rg.right_desc, data: rg, type: 'right', children: []}))
                : [];

              rights.map(r => allRightsToAdd.push(r));

              allRightsToAdd
                .map(r => RightsComponent.disableRightsAccordingToCurrentUserRights(r, currentUserRights.find(o => o.right_id === r.data.right_id), this.hasRightToEdit))
                .map(r => r.data)
                .sort((a, b) => a.right_id > b.right_id ? 1 : -1);

              let sortedRights = allRightsToAdd.sort((a, b) => a.data.right_id > b.data.right_id ? 1 : -1);
              sortedRights.forEach(r => {
                r.key = r.data.right_id.toString();
                r[`right_type_id`] = r.data.right_type_id;
                r[`rights_family_id`] = r.data.rights_family_id;
              });
              sortedRights = this.filterRightsBySelectedTab(sortedRights);
              this.mSavedSelectedRightsGroupHasRight = JSON.stringify(sortedRights.map(r => r.data));

              data.forEach(rf => rf[`key`] = rf.rights_family_id);
              this.selectedRightsGroupRights = this.createRightsDataTree(data, sortedRights, this.hasRightToEdit)
                .sort((a, b) => a.rights_family_id > b.rights_family_id ? 1 : -1);

              resolve();
            });
        })
        .catch(() => {
          this.messageService.add({
            severity: 'error',
            detail: 'Erreur lors du chargement des droits de ce groupe. Si le problème persiste, Veuillez contacter le support informatique.',
            life: 20000
          });
        })
        .finally(() => {
          this.loadingRightsGroupHasRights = false;
        });
    }));
  }

  onUserSelect(adm: Administrative) {
    this.onResetRightsGroupSearchInput();
    this.resetArray(this.selectedRightsGroupHasRight);

    this.selectedUserToDisplay = adm;
    this.getAllRightsGroups();

    this.loadSelectedUserRights(adm.uid).then();
    this.loadRightsGroupsFromMember(adm.uid);
  }

  onRightsGroupNodeSelect(event: any) {
    this.resetArray(this.selectedRightsGroupHasRight);
    this.resetFilters();

    this.rootParentRightsGroupId = this.getRootParentRightsGroupId(event.node);

    this.loadRightsGroupRights(event.node.data.rights_group_id, this.rootParentRightsGroupId)
      .then(() => this.refreshRights());

    this.loadRightsGroupMembers(event.node.data.rights_group_id);
  }

  refreshSelectedRightsMultiSelectors() {
    if (!this.selectedRightsGroupHasRight) {
      return;
    }

    const selectedRights = this.selectedRightsGroupHasRight.filter(r => r.data !== undefined && r.data.right_id !== undefined);

    const length = selectedRights.length;

    const canRead = this.getPropertyTriStateValue('can_read', selectedRights, length);
    const canUpdate = this.getPropertyTriStateValue('can_update', selectedRights, length);
    const canDelete = this.getPropertyTriStateValue('can_delete', selectedRights, length);
    const canTransmit = this.getPropertyTriStateValue('can_transmit', selectedRights, length);

    this.allRights = {
      can_read: canRead,
      can_update: canUpdate,
      can_delete: canDelete,
      can_transmit: canTransmit
    };
  }

  private reduceRights(source: any[], property: string, childProperty: string): RightsGroupHasRight[] {
    const elements: any[] = [];
    this.reduce(elements, source, property, childProperty);
    return elements.filter(r => r.right_id !== undefined);
  }

  refreshRightsSaveButtons() {
    const currentStateRights = this.extractCurrentRightsFromTree(this.selectedRightsGroupRights)
      .map(o => this.adaptToVerySimpleRight(o));

    const savedRights = JSON.parse(this.mSavedSelectedRightsGroupHasRight)
      .map(savedRight => this.adaptToVerySimpleRight(savedRight));

    this.hasAnyRightToSave = JSON.stringify(savedRights) !== JSON.stringify(currentStateRights);
  }

  adaptToVerySimpleRight(element) {
    return {
      can_create: element.can_create,
      can_read: element.can_read,
      can_update: element.can_update,
      can_delete: element.can_delete,
      can_transmit: element.can_transmit,
      right_id: element.right_id
    };
  }

  expandAllRightsFamilies() {
    this.getTreeFromCurrentContext().forEach(node => {
      this.expandRecursive(node, true);
    });
  }

  collapseAllRightsFamilies() {
    this.getTreeFromCurrentContext().forEach(node => {
      this.expandRecursive(node, false);
    });
  }

  affectValueToAllSelectedValues(event: MouseEvent, property: string) {
    const selectedRights = this.selectedRightsGroupHasRight.filter(r => r.data !== undefined && r.data.right_id !== undefined);
    const can = this.getPropertyTriStateValue(property, selectedRights, selectedRights.length);
    this.selectedRightsGroupHasRight.forEach(o => {

      if (o.data[property + '_disabled'] === false) {
        o.data[property] = (can === null || can === false);
      }
    });
    this.refreshSelectedRightsMultiSelectors();
    this.refreshRightsSaveButtons();
  }

  refreshRights() {
    this.refreshSelectedRightsMultiSelectors();
    this.refreshRightsSaveButtons();
  }

  resetTreesRights() {
    if (this.selectedRightsGroupRights) {
      this.selectedRightsGroupRights.splice(0, this.selectedRightsGroupRights.length);
      this.resetArray(this.selectedRightsGroupHasRight);
    }

    this.resetArray(this.selectedMemberUpperRights);
    this.resetArray(this.selectedMemberRightsLogs);

    if (this.selectedRightsGroup) {
      this.loadRightsGroupRights(this.selectedRightsGroup.rights_group_id, this.rootParentRightsGroupId)
        .then(() => this.refreshRights());
    }
  }

  saveRights() {
    this.loading = true;
    const currentStateRights = this.extractCurrentRightsFromTree(this.selectedRightsGroupRights);
    const oldData = JSON.parse(this.mSavedSelectedRightsGroupHasRight);

    const rightsToSave = currentStateRights.filter(updated =>
      !!oldData.find(old => updated.right_id === old.right_id
        && (updated.can_read !== old.can_read
          || updated.can_update !== old.can_update
          || updated.can_delete !== old.can_delete
          || updated.can_transmit !== old.can_transmit)
      )
    );

    // Pour le moment, les drots create et update son fusionnés, mais on se laisse la possibilité de modifier ce comportement à l'avenir
    rightsToSave.forEach(r => r.can_create = r.can_update);

    const rightsGroupId = this.selectedRightsGroup.data.rights_group_id;

    this.rightsService.saveRightsGroupRights(rightsGroupId, rightsToSave)
      .then(() => {
        this.messageService.add({severity: 'success', summary: 'Droits mis à jour.'});

        this.loading = false;
        this.mSavedSelectedRightsGroupHasRight = JSON.stringify(currentStateRights);

        if (this.selectedRightsGroupHasRight) {
          this.selectedRightsGroupHasRight.splice(0, this.selectedRightsGroupHasRight.length);
        }

        this.refreshRights();
      })
      .catch((error) => {
        this.loading = false;
        if (error.status === 403) {
          this.messageService.add({
            severity: 'error',
            summary: 'Ces droits sont supérieurs à ceux attribuables à ce groupe de droits.',
            life: 20000
          });
        } else if (error.status === 409) {
          const result = error.error.result;
          const detailedInformation = RightsComponent.createHtmlList(result.data, 'rights_group_name');
          this.messageService.add({
            severity: 'error',
            summary: result.message,
            detail: detailedInformation,
            sticky: true
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Une erreur inattendue est survenue. Si le problème persiste, veuillez contacter le service informatique.',
            life: 20000
          });
        }
      });
  }

  showRightsGroupsOrganizationChart() {

    this.forceOrganizationChartFamiliesRefresh();

    this.rightsService.getAllRightsGroups()
      .then(rightsGroups => {

        this.allRightsGroupsTree = this.createRightsGroupsDataTree(rightsGroups);

        // ici on déplie les groupes de droits jusqu'à afficher les groupes dont l'utilisateur courant est membre
        const rightsGroupIdsToDisplay = this.rightsGroups.map(o => o.data.rights_group_id);
        if (rightsGroupIdsToDisplay && rightsGroupIdsToDisplay.length > 0) {
          rightsGroupIdsToDisplay.forEach(rightsGroupIdToDisplay => this.expandRecursiveToRightsGroup(this.allRightsGroupsTree, rightsGroupIdToDisplay, false));
        }

        // on affiche la dialog avec l'organigramme préparé
        this.displayRightsGroupOrganizationChartDialog = true;

        // this.allRightsGroupsTree.forEach(rg => this.expandRecursive(rg, true));
      })
      .catch(() => {
        this.messageService.add({
          severity: 'error',
          detail: 'Erreur lors du chargement de l\'arborescence des groupes de droits. Si le problème persiste, Veuillez contacter le support informatique.',
          life: 20000
        });
      });
  }

  getSaveOrUpdateDialogHeader(): string {
    let header = '';
    if (this.rightsGroupToSaveOrUpdate) {
      this.rightsGroupToSaveOrUpdate.rights_group_id
        ? header = 'Modification'
        : header = 'Création';
      header += ` d'un groupe de droits`;
    }
    return header;
  }

  //#region rights groups
  showRightsGroupsDuplicationDialog() {
    this.rightsGroupToDuplicate = {
      rights_group_name: '',
      rights_group_parent_id: this.selectedRightsGroup.rights_group_parent_id
    };

    this.rightsGroupToDuplicateRights = this
      .extractCurrentRightsFromTree(this.selectedRightsGroupRights)
      .filter(o => (o.can_create || o.can_read || o.can_update || o.can_delete || o.can_transmit));

    this.displayRightsGroupDuplicationDialog = true;
  }

  showRightsGroupsSaveOrUpdateDialog(addMode: boolean) {
    if (addMode) {
      this.rightsGroupToSaveOrUpdate = {
        rights_group_name: '',
        rights_group_parent_id: this.selectedRightsGroup.rights_group_id
      };
    } else {
      this.rightsGroupToSaveOrUpdate = {
        rights_group_name: this.selectedRightsGroup.rights_group_name,
        rights_group_parent_id: this.selectedRightsGroup.rights_group_parent_id,
        rights_group_id: this.selectedRightsGroup.rights_group_id
      };
    }

    this.displayRightsGroupSaveOrUpdateDialog = true;
  }

  showRightsGroupsDeleteDialog() {
    this.rightsGroupToSaveOrUpdate = {
      rights_group_name: this.selectedRightsGroup.rights_group_name,
      rights_group_parent_id: this.selectedRightsGroup.rights_group_parent_id,
      rights_group_id: this.selectedRightsGroup.rights_group_id
    };

    this.displayRightsGroupDeleteDialog = true;
  }

  createOrUpdateRightsGroup(rightsGroupToSave: RightsGroup) {
    const addMode = rightsGroupToSave.rights_group_id == null;

    this.rightsService.saveRightsGroup(rightsGroupToSave)
      .then((savedRightsGroup) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Le groupe de droits "' + savedRightsGroup.rights_group_name + '" a bien été ' + (addMode ? 'créé.' : 'modifié.'),
          life: 10000
        });
        this.displayRightsGroupSaveOrUpdateDialog = false;
        this.initRightsGroupsTree(savedRightsGroup.rights_group_id);
        this.resetFilters();

      })
      .catch((error) => {
        if (error.status === 409) {
          this.messageService.add({
            severity: 'error',
            summary: 'Conflit',
            detail: error.error.result.message,
            life: 20000
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de ' + (addMode ? 'création' : 'modification'),
            detail: 'La ' + (addMode ? 'création' : 'modification') + ' du groupe de droits a échouée. Si le problème persiste, merci de contacter le support.',
            life: 20000
          });
        }
      });

  }

  duplicateRightsGroup(rightsGroupToSave: RightsGroup) {
    this.rightsService.saveRightsGroup(rightsGroupToSave)
      .then((savedRightsGroup) => {
        if (this.selectedRightsGroupsMembersToDuplicate && this.selectedRightsGroupsMembersToDuplicate.length > 0) {
          this.rightsService.addRightsGroupMembers(savedRightsGroup.rights_group_id, this.selectedRightsGroupsMembersToDuplicate.map(o => o.uid))
            .then((users) => {
              this.messageService.add({
                severity: 'success',
                summary: users.length + ' membres ajoutés avec succès au nouveau groupe "' + savedRightsGroup.rights_group_name + '"',
                life: 10000
              });
            })
            .catch(() => {
              this.messageService.add({
                severity: 'warn',
                summary: 'duplication partielle',
                detail: 'La création du groupe de droits a aboutie, mais les droits sélectionnés n\'ont pas été ajoutés. Merci de les gérer manuellement en sélectionnant le groupe de droits nouvellement créé.',
                sticky: true
              });
            });
        }

        if (this.selectedRightsGroupsRightsToDuplicate && this.selectedRightsGroupsRightsToDuplicate.length > 0) {

          // Pour le moment, les drots create et update son fusionnés, mais on se laisse la possibilité de modifier ce comportement à l'avenir
          this.selectedRightsGroupsRightsToDuplicate.forEach(r => r.can_create = r.can_update);

          this.rightsService.saveRightsGroupRights(savedRightsGroup.rights_group_id, this.selectedRightsGroupsRightsToDuplicate)
            .then((rights) => {
              this.messageService.add({
                severity: 'success',
                summary: rights.length + ' droits ajoutés avec succès au nouveau groupe "' + savedRightsGroup.rights_group_name + '"',
                life: 10000
              });
            })
            .catch(() => {
              this.messageService.add({
                severity: 'warn',
                summary: 'duplication partielle',
                detail: 'La création du groupe de droits a aboutie, mais les droits sélectionnés n\'ont pas été ajoutés. Merci de les gérer manuellement en sélectionnant le groupe de droits nouvellement créé.',
                sticky: true
              });
            });
        }

        this.messageService.add({
          severity: 'success',
          summary: 'Le groupe de droits "' + savedRightsGroup.rights_group_name + '" a bien été créé.',
          life: 10000
        });
        this.displayRightsGroupDuplicationDialog = false;

        this.initRightsGroupsTree(savedRightsGroup.rights_group_id);
      })
      .catch((error) => {
        if (error.status === 409) {
          this.messageService.add({
            severity: 'error',
            detail: error.error.result,
            life: 20000
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de duplication',
            detail: 'La création du groupe de droits a échouée. Si le problème persiste, merci de contacter le support.',
            life: 20000
          });
        }
      });
  }

  onRightsGroupCreationDialogClose() {
    delete this.rightsGroupToSaveOrUpdate;
  }

  onRightsGroupDuplicationDialogClose() {
    delete this.rightsGroupToDuplicate;
    delete this.selectedRightsGroupsMembersToDuplicate;
    delete this.selectedRightsGroupsRightsToDuplicate;
  }

  onRightsGroupDeleteDialogClose() {
    delete this.rightsGroupToSaveOrUpdate;
    this.displayRightsGroupDeleteDialog = false;
  }

  //#endregion

  //#region users

  loadMembersFromMyRightsGroups() {
    this.areMembersLoading = true;
    this.rightsService.getMembersFromMyRightsGroups()
      .then((members) => {
        // suppression des doublons
        members = members.filter((adm, index, instance) => instance.findIndex(m => (m.uid === adm.uid)) === index);
        this.membersFromRightsGroup = members;
        this.filteredMembersFromRightsGroup = members;
      })
      .then(() => this.areMembersLoading = false)
      .catch(() => {
        this.messageService.add({
          severity: 'error',
          detail: 'Erreur lors du chargement des membres de mes groupes de droits. Si le problème persiste, Veuillez contacter le support informatique.',
          life: 20000
        });
      });
  }

  private loadRightsGroupsFromMember(memberId: number) {
    this.rightsService.getRightsGroupsFromMember(memberId)
      .then((rightsGroups) => {
        this.getRightsGroupsParents(this.allRightsGroups, rightsGroups);
        this.rightsGroupsFromMember = rightsGroups;
        this.filteredRightsGroupsFromMember = rightsGroups;

        this.selectedMemberCurrentRightsGroupIds = rightsGroups
          .map(rg => rg.rights_group_id)
          .sort((a, b) => a > b ? 1 : -1);

        this.resetArray(this.selectedRightsGroupIdsFromMemberToRemove);
        this.refreshRightsGroupsSaveButtons();
      })
      .catch(() => {
        this.messageService.add({
          severity: 'error',
          detail: 'Erreur lors du chargement des groupes de droits de l\'utilisateur. Si le problème persiste, Veuillez contacter le support informatique.',
          life: 20000
        });
      });
  }

  private loadRightsGroupMembers(rightsGroupId: number) {
    this.areMembersLoading = true;
    this.rightsService.getRightsGroupMembers(rightsGroupId)
      .then((members) => {
        this.membersFromRightsGroup = members;
        this.filteredMembersFromRightsGroup = members;

        this.selectedRightsGroupsCurrentMemberIds = members
          .map(m => m.uid)
          .sort((a, b) => a > b ? 1 : -1);

        this.resetArray(this.selectedMemberIdsFromRightsGroupToRemove);
        this.refreshMembersSaveButtons();
      })
      .then(() => this.areMembersLoading = false)
      .catch(() => {

        this.messageService.add({
          severity: 'error',
          detail: 'Erreur lors du chargement des membres du groupe de droits. Si le problème persiste, Veuillez contacter le support informatique.',
          life: 20000
        });
      });
  }

  private loadSelectedUserRights(uid: number) {
    return new Promise((resolve, reject) =>
      this.rightsService.getUserRights(uid)
        .then((rights) => {

          rights.forEach(r => r[`textColor`] = this.createColorFromString(r.rights_group_name));

          let upperRights = JSON.parse(JSON.stringify(rights));
          upperRights = this.getUpperRightsFromAllRightsGroup(upperRights);

          this.rightsService.getRightsFamilies()
            .then((data) => {

              const sortedRights = this.formatRightsProperties(rights);

              this.mSavedRightFamilies = JSON.stringify(data);
              this.mSavedSelectedRightsGroupHasRight = JSON.stringify(sortedRights.map(r => r.data));

              this.selectedMemberRightsLogs = this.createRightsDataTree(data, sortedRights, false)
                .sort((a, b) => a.rights_family_id > b.rights_family_id ? 1 : -1);
              this.selectedMemberUpperRights = this.createRightsDataTree(data, this.formatRightsProperties(upperRights), false)
                .sort((a, b) => a.rights_family_id > b.rights_family_id ? 1 : -1);

              resolve();
            });
          resolve();
        })
        .catch(reject)
    );
  }

  private getUpperRightsFromAllRightsGroup(rights: any[]): any[] {
    return rights.filter((right, index, instance) => instance
      .findIndex(o => {
        if (o.right_id === right.right_id && o.rights_group_id !== right.rights_group_id) {
          o.can_read = right.can_read || o.can_read;
          o.can_create = right.can_create || o.can_create;
          o.can_update = right.can_update || o.can_update;
          o.can_delete = right.can_delete || o.can_delete;
          o.can_transmit = right.can_transmit || o.can_transmit;
        }
        return (o.right_id === right.right_id);
      }) === index);
  }

  private formatRightsProperties(rights: UserHasRight[]): any[] {
    const theRights = (rights !== undefined && rights !== null && rights.length > 0)
      ? rights.map(rg => ({label: rg.right_desc, data: rg, type: 'right', children: []}))
      : [];

    theRights
      .map(r => RightsComponent.disableRightsAccordingToCurrentUserRights(r, undefined, false))
      .map(r => r.data)
      .sort((a, b) => a.right_id > b.right_id ? 1 : -1);

    let sortedRights = theRights.sort((a, b) => a.data.right_id > b.data.right_id ? 1 : -1);
    sortedRights.forEach(r => {
      r[`key`] = r.data.right_id.toString();
      r[`right_type_id`] = r.data.right_type_id;
      r[`rights_family_id`] = r.data.rights_family_id;
    });
    sortedRights = this.filterRightsBySelectedTab(sortedRights);

    return sortedRights;
  }

  private refreshMembersSaveButtons() {
    const memberIds = this.filteredMembersFromRightsGroup
      .map(m => m.uid)
      .sort((a, b) => a > b ? 1 : -1);

    this.hasRightsGroupMembersToSave = (JSON.stringify(memberIds) !== JSON.stringify(this.selectedRightsGroupsCurrentMemberIds)) || (this.selectedMemberIdsFromRightsGroupToRemove.length > 0);
  }

  private refreshRightsGroupsSaveButtons() {
    const rightsGroupIds = this.filteredRightsGroupsFromMember
      .map(rg => rg.rights_group_id)
      .sort((a, b) => a > b ? 1 : -1);

    this.hasMemberRightsGroupsToSave = (JSON.stringify(rightsGroupIds) !== JSON.stringify(this.selectedMemberCurrentRightsGroupIds)) || (this.selectedRightsGroupIdsFromMemberToRemove.length > 0);
  }

  searchAdministrative(event: any) {
    const query = event.query;
    const connectedUserUid = this.authenticatedUserService.getProfile().uid;

    this.administrationService.getAdministrativesByName(query)
      .then((administratives) => {
        this.filteredAdministratives = administratives.filter(adm => this.filteredMembersFromRightsGroup.findIndex(gm => gm.uid === adm.uid) === -1);
      })
      .catch(console.error);
  }

  searchRightsGroup(event: any) {
    const query = event.query;

    this.rightsService.getRightsGroupsByName(query)
      .then((rightsgroups) => {
        this.filteredGroups = rightsgroups.filter(rg => rg.has_right_to_edit && (this.filteredRightsGroupsFromMember.findIndex(o => o.rights_group_id === rg.rights_group_id) === -1));
        this.getRightsGroupsParents(this.allRightsGroups, this.filteredGroups);
      })
      .catch(console.error);
  }

  filterRightsGroupsFromMemberByName(event: any) {
    this.filteredRightsGroupsFromMember = [...this.rightsGroupsFromMember.filter(rg => rg.rights_group_name.toUpperCase().includes(event.toUpperCase()))];
  }

  filterAdministrativesFromRightsGroupByName(event: any) {
    this.filteredMembersFromRightsGroup = [...this.membersFromRightsGroup.filter(member => member.name.toUpperCase().includes(event.toUpperCase()))];
    this.selectedRightsGroupsCurrentMemberIds = this.filteredMembersFromRightsGroup.map(member => member.uid);
  }

  filterRightsGroupFromLogsByName(event: any) {
    this.filteredRightsGroupHasRightHistories = [...this.rightsGroupHasRightHistories.filter(rg => rg.rights_group_name.toUpperCase().includes(event.toUpperCase()))];
  }

  selectUser(event: any) {
    this.filteredMembersFromRightsGroup.push(event);
    this.selectedAdministrative = undefined;
    this.refreshMembersSaveButtons();
  }

  selectRightsGroup(event: any) {
    this.filteredRightsGroupsFromMember.push(event);
    this.selectedGroup = undefined;
    this.refreshRightsGroupsSaveButtons();
  }

  saveMembersFromRightsGroup() {
    const rightsGroupId = this.selectedRightsGroup.rights_group_id;
    const newIds = this.filteredMembersFromRightsGroup.map(m => m.uid);
    const toRemove = this.selectedMemberIdsFromRightsGroupToRemove;

    if (toRemove && toRemove.length > 0) {
      this.rightsService.removeRightsGroupMembers(rightsGroupId, toRemove)
        .then(() => this.messageService.add({
          severity: 'success',
          detail: toRemove.length > 1 ? toRemove.length + ' membres supprimés' : 'Membre supprimé',
          life: 5000
        }))
        .then(() => this.loadRightsGroupMembers(rightsGroupId))
        .catch(console.error);
    }

    const toAdd = newIds.filter(newId => this.selectedRightsGroupsCurrentMemberIds.findIndex(oldId => oldId === newId) === -1);
    if (toAdd && toAdd.length > 0) {
      this.rightsService.addRightsGroupMembers(rightsGroupId, toAdd)
        .then((addedAdministratives) => {
          let customDetail = '<ul>';

          addedAdministratives.forEach(adm => customDetail += '<li>' + adm.civility + ' ' + adm.name + ' ' + adm.firstname + ' ' + '</li>');

          this.messageService.add({
            severity: 'success',
            summary: toAdd.length > 1 ? toAdd.length + ' membres ajoutés : ' : 'Membre ajouté : ',
            detail: customDetail + '</ul>',
            life: 10000
          });
        })
        .then(() => this.loadRightsGroupMembers(rightsGroupId))
        .catch(console.error);
    }
  }

  saveRightsGroupsFromMember() {
    const uid = this.selectedUserToDisplay.uid;
    const newIds = this.filteredRightsGroupsFromMember.map(rg => rg.rights_group_id);
    const toRemove = this.selectedRightsGroupIdsFromMemberToRemove;

    if (toRemove && toRemove.length > 0) {
      this.rightsService.removeRightsGroupsFromMember(uid, toRemove)
        .then(() => this.messageService.add({
          severity: 'success',
          detail: toRemove.length > 1 ? toRemove.length + ' groupes supprimés' : 'Groupe supprimé',
          life: 5000
        }))
        .then(() => this.loadRightsGroupsFromMember(uid))
        .catch(console.error);
    }

    const toAdd = newIds.filter(newId => this.selectedMemberCurrentRightsGroupIds.findIndex(oldId => oldId === newId) === -1);
    if (toAdd && toAdd.length > 0) {
      this.rightsService.addRightsGroupsToMember(uid, toAdd)
        .then((addedRightsGroups) => {
          this.messageService.add({
            severity: 'success',
            summary: toAdd.length > 1 ? toAdd.length + ' groupes ajoutés' : 'Groupe ajouté',
            life: 10000
          });
        })
        .then(() => this.loadRightsGroupsFromMember(uid))
        .catch(console.error);
    }
  }

  removeMemberFromRightsGroup(adm: Administrative) {
    if (!this.selectedMemberIdsFromRightsGroupToRemove.includes(adm.uid)) {
      this.selectedMemberIdsFromRightsGroupToRemove.push(adm.uid);
    }

    this.refreshMembersSaveButtons();
  }

  removeRightsGroupFromMember(rg: RightsGroup) {
    if (!this.selectedRightsGroupIdsFromMemberToRemove.includes(rg.rights_group_id)) {
      this.selectedRightsGroupIdsFromMemberToRemove.push(rg.rights_group_id);
    }

    this.refreshRightsGroupsSaveButtons();
  }

  reloadSelectedRightsGroupMembers() {
    this.loadRightsGroupMembers(this.selectedRightsGroup.rights_group_id);
  }

  reloadSelectedMemberRightsGroups() {
    this.loadRightsGroupsFromMember(this.selectedUserToDisplay.uid);
  }

  showUserRightsDialog(user: Administrative) {

    this.selectedUserToDisplay = user;
    this.loadSelectedUserRights(user.uid)
      .then(() => this.displaySelectedMemberRights = true);
  }

  //#endregion

  isDuplicationRightsGroupNameInvalid() {
    return this.rightsGroupToDuplicate === undefined
      || this.selectedRightsGroup === undefined
      || this.rightsGroupToDuplicate.rights_group_name.trim().length === 0
      || this.selectedRightsGroup.parent.children.findIndex(o => o.rights_group_name === this.rightsGroupToDuplicate.rights_group_name) !== -1;
  }

  isCreationRightsGroupNameInvalid() {
    return this.rightsGroupToSaveOrUpdate.rights_group_name.trim().length === 0;
  }

  deactivateRightsGroup(rightsGroup: RightsGroup) {
    this.rightsService.removeRightsGroup(rightsGroup.rights_group_id)
      .then(() => {
        this.messageService.add({
          severity: 'success',
          detail: 'Le groupe de droits a été supprimé.',
          life: 10000
        });

        this.initRightsGroupsTree(undefined);
        this.onRightsGroupDeleteDialogClose();
      })
      .catch(() => {
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          detail: 'Erreur lors de la suppression du groupe de droits. Si le problème persiste, Veuillez contacter le support informatique.',
          life: 20000
        });
      });
  }

  private expandRecursiveToRightsGroup(rightsGroups: TreeNode<RightsGroup>[], rightsGroupIdToDisplay: number, shouldForceNodeSelection: boolean) {
    rightsGroups
      .map(rg => this.searchTree(rg, rightsGroupIdToDisplay))
      .forEach(elementToReach => this.expandAscRecursive(elementToReach, shouldForceNodeSelection));
  }

  private expandAscRecursive(elementToReach: any, shouldForceNodeSelection: boolean) {
    if (shouldForceNodeSelection && elementToReach) {
      this.selectedRightsGroup = elementToReach;
      this.hasRightToEdit = true;
      this.onRightsGroupNodeSelect({node: elementToReach});
    }

    if (elementToReach && elementToReach.parent) {
      elementToReach.parent.expanded = true;
      this.expandAscRecursive(elementToReach.parent, false);
    }
  }

  private searchTree(element: TreeNode<RightsGroup>, rightsGroupIdToDisplay: number) {
    if (element.data.rights_group_id === rightsGroupIdToDisplay) {
      return element;
    } else if (element.children != null) {
      let result = null;
      for (let i = 0; result == null && i < element.children.length; i++) {
        element.children[i].parent = element;

        result = this.searchTree(element.children[i], rightsGroupIdToDisplay);
      }
      return result;
    }
    return null;
  }

  expandSelectedMemberRightsFamilies() {
    this.selectedMemberRightsLogs.forEach(node => {
      this.expandRecursive(node, true);
    });
  }

  collapseSelectedMemberRightsFamilies() {
    this.selectedMemberRightsLogs.forEach(node => {
      this.expandRecursive(node, false);
    });
  }

  expandOrganizationChartFamilies() {
    this.allRightsGroupsTree.forEach(node => {
      this.expandRecursive(node, true);
    });

    this.forceOrganizationChartFamiliesRefresh();
  }

  collapseOrganizationChartFamilies() {
    this.allRightsGroupsTree.forEach(node => {
      this.expandRecursive(node, false);
    });
    this.forceOrganizationChartFamiliesRefresh();
  }

  forceOrganizationChartFamiliesRefresh() {
    this.displayRightsGroupOrganizationChart = false;
    setTimeout(() => {
      this.displayRightsGroupOrganizationChart = true;
    }, 0);
  }

  createColorFromString(str): string {

    const colors = [
      '#66ccff',
      '#feeb6a',
      '#7278ad',
      '#8ba5c0',
      '#7b0412',
      '#cc3433',
      '#003874',
      '#261d11',
      '#6e1010',
      '#535353',
      '#008169',
      '#f6ab49',
      '#ff4a4a',
      '#2b6896'
    ];

    let sum = 0;
    str.split('').forEach(c => sum += (c.charCodeAt()));

    const index = sum % colors.length;

    return colors[index];
  }

  showRightLogsDialog() {
    this.displayLogsDialog = true;
  }

  onMemberRightGroupSelected(rightsGroupId: number) {
    this.initRightsGroupsTree(rightsGroupId);
    this.displaySelectedMemberRights = false;
  }

  showRightHistoryDialog(event) {
    const rightsGroupId = event.data.rights_group_id;
    const rightId = event.data.right_id;

    const currentLogs = this.userTypeTabActiveIndex === this.GROUPS_TAB_INDEX
      ? this.rightsService.getRightsLogs(rightsGroupId, rightId)
      : this.rightsService.getRightsLogsByUser(rightId, this.selectedUserToDisplay.uid);

    currentLogs
      .then((data) => {

        data.forEach(d => {
          if (d.new_value) {
            d[`newValue`] = this.parseRightsGroupHasRightLog(d.new_value);
          }
          if (d.previous_value) {
            d[`previousValue`] = this.parseRightsGroupHasRightLog(d.previous_value);
          }

          return d;
        });

        this.rightsGroupHasRightHistories = data.sort((a, b) => a.update_date < b.update_date ? 1 : -1);
        this.filteredRightsGroupHasRightHistories = [...this.rightsGroupHasRightHistories];

        this.showRightLogsDialog();
      })
      .catch(console.error);

  }

  getAllRightsGroups() {
    this.rightsService.getAllRightsGroups()
      .then(rightsGroups => {
        this.allRightsGroups = rightsGroups;
      });
  }

  showMembersHistoryDialog() {

    if (!this.selectedRightsGroup || !this.selectedRightsGroup.data || !this.selectedRightsGroup.data.rights_group_id) {
      this.messageService.add({
        severity: 'warn',
        detail: 'Impossible de charger l\'historique des membres de ce groupe de droits. Si le problème persiste, veuillez contacter le support informatique.',
        life: 10000
      });
      return;
    }

    this.displayMembersHistoryDialog = true;

    this.rightsService.getRightsGroupMemberLogs(this.selectedRightsGroup.data.rights_group_id)
      .then((memberLogs) => {
        this.rightsGroupMemberLogs = memberLogs.sort((a, b) => a.upd_date < b.upd_date ? 1 : -1);
      })
      .catch(console.error);


  }

  onSelectedMemberRightsDialogHide() {
    delete this.selectedAdministrative;
  }

  onRightsGroupOrganizationChartNodeSelected(event: any) {
    this.initRightsGroupsTree(event.node.rights_group_id);
    this.displayRightsGroupOrganizationChartDialog = false;
  }

  onUserTypeTabChange(event: any) {
    this.userTypeTabActiveIndex = event.index;

    this.resetFilters();
    this.resetTreesRights();
    this.resetArray(this.selectedRightsGroupRights);
    this.resetArray(this.membersFromRightsGroup);
    this.resetArray(this.rightsGroupsFromMember);

    delete this.selectedUserToDisplay;
    delete this.selectedRightsGroup;

    if (this.userTypeTabActiveIndex === this.USERS_DETAILS_TAB_INDEX) {
      this.loadMembersFromMyRightsGroups();
    }
  }

  onRightsTabChange(event: any) {
    this.rightsTabActiveIndex = event.index;

    this.resetFilters();
    this.resetTreesRights();
    this.resetArray(this.selectedRightsGroupRights);

    if (this.selectedRightsGroup) {
      const rightsGroupId = this.selectedRightsGroup.data.rights_group_id;
      const rootParentRightsGroupId = this.getRootParentRightsGroupId(this.selectedRightsGroup);

      this.loadRightsGroupRights(rightsGroupId, rootParentRightsGroupId)
        .then(() => this.refreshRights());

      this.loadRightsGroupMembers(rightsGroupId);

    } else if (this.selectedUserToDisplay) {
      this.loadSelectedUserRights(this.selectedUserToDisplay.uid).then();
    }

    if (this.rightsTabActiveIndex === this.GROUPS_RIGHTS_TAB_INDEX) {
      this.getAllRightsGroups();
    }
  }

  private filterRightsBySelectedTab(rights: any[]): any[] {
    switch (this.rightsTabActiveIndex) {
      case this.SCHOOLS_RIGHTS_TAB_INDEX : rights = rights.filter(r => r.right_type_id === this.SCHOOLS_RIGHTS_TYPE_ID); break;
      case this.INTERFACES_RIGHTS_TAB_INDEX : rights = rights.filter(r => this.INTERFACES_RIGHTS_TYPE_IDS.indexOf(r.right_type_id) !== -1); break;
      case this.FUNCTIONS_RIGHTS_TAB_INDEX : rights = rights.filter(r => r.right_type_id === this.FUNCTIONS_RIGHTS_TYPE_ID); break;
      default : rights = rights.filter(r => r.right_type_id === this.SCHOOLS_RIGHTS_TYPE_ID); break;
    }
    return rights;
  }

  private filterRightFamiliesBySelectedTab(rightFamilies: any) {
    switch (this.rightsTabActiveIndex) {

      case this.SCHOOLS_RIGHTS_TAB_INDEX :
        rightFamilies = rightFamilies.filter(rf => this.SCHOOLS_RIGHTS_FAMILY_IDS.indexOf(rf.rights_family_id) !== -1);
        rightFamilies.forEach(rf => rf.expanded = true);
        break;

      case this.INTERFACES_RIGHTS_TAB_INDEX :
      case this.FUNCTIONS_RIGHTS_TAB_INDEX :
        rightFamilies = rightFamilies.filter(rf => this.APPLICATION_RIGHTS_FAMILY_IDS.indexOf(rf.rights_family_id) !== -1);
        break;

      default :
        rightFamilies = rightFamilies.filter(rf => this.SCHOOLS_RIGHTS_FAMILY_IDS.indexOf(rf.rights_family_id) !== -1);
        rightFamilies.forEach(rf => rf.expanded = true);
        break;
    }
    return rightFamilies;
  }

  // cette méthode n'est pas utilisée mais elle permet de récupérer le type de droit en fonction de l'onglet sélectionné
  private getRightsTypeBySelectedTab(): number {
    let selectedRightsType;
    switch (this.rightsTabActiveIndex) {
      case this.SCHOOLS_RIGHTS_TAB_INDEX : selectedRightsType = this.SCHOOLS_RIGHTS_TYPE_ID; break;
      case this.INTERFACES_RIGHTS_TAB_INDEX : selectedRightsType = this.INTERFACES_RIGHTS_TYPE_IDS; break;
      case this.FUNCTIONS_RIGHTS_TAB_INDEX : selectedRightsType = this.FUNCTIONS_RIGHTS_TYPE_ID; break;
      default : selectedRightsType = this.SCHOOLS_RIGHTS_TYPE_ID; break;
    }
    return selectedRightsType;
  }

  getTreeFromSelectedTab(): TreeNode[] {
    return this.userTypeTabActiveIndex === this.GROUPS_TAB_INDEX
      ? this.selectedRightsGroupRights
      : this.selectedMemberUpperRights;
  }

  getTreeFromCurrentContext(): TreeNode[] {
    let currentTree: TreeNode[];
    if (this.userTypeTabActiveIndex === this.GROUPS_TAB_INDEX) {
      currentTree = this.selectedRightsGroupRights;
    } else {
      currentTree = this.displaySelectedMemberRights ? this.selectedMemberRightsLogs : this.selectedMemberUpperRights;
    }
    return currentTree;
  }

  // cette méthode permet actuellement d'ajouter une checkbox à un noeud, la condition peut changer dans le futur
  getSelectionModeFromSelectedTab(): string {
    return this.userTypeTabActiveIndex === this.GROUPS_TAB_INDEX
      ? 'checkbox'
      : 'checkbox';
  }

  getEmptyMessageFromSelectedTab(): string {
    let message = '';
    if (this.userTypeTabActiveIndex === this.GROUPS_TAB_INDEX) {
      this.selectedRightsGroup
        ? message = 'Aucun droit pour le groupe sélectionné'
        : message = 'Veuillez sélectionner un groupe de droits';
    } else {
      this.selectedUserToDisplay
        ? message = 'Aucun droit pour l\'utilisateur sélectionné'
        : message = 'Veuillez sélectionner un utilisateur';
    }
    return message;
  }
}
