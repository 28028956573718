    <div class="centerContent softMarginTop">
      <div class="centerContent" style="width: 100px; height: 100px; border-radius: 50%; overflow: hidden;">
        <img src="{{this.getProfilePhoto()}}"
             style="max-width: 100%; justify-content: center; align-items: center;"
             alt="photo"/>
      </div>
    </div>
    <div class="centerContent">
      <p>{{currentProfile.firstname}} {{currentProfile.name}}</p>
    </div>

    <div>
      <button pButton type="button"
              class=" middleMarginBottom p-button-rounded p-button-secondary"
              icon="pi pi-question"
              iconPos="left"
              label="Aide"
              (click)="helpClicked.emit()"></button>
    </div>

    <div>
      <button pButton type="button"
              class=" middleMarginBottom p-button-rounded p-button-help"
              icon="pi pi-cog"
              label="Paramètres"
              (click)="paramsClicked.emit()"></button>
    </div>

    <div>
      <button pButton type="button"
              class=" middleMarginBottom p-button-rounded p-button-error"
              icon="pi pi-power-off"
              label="Déconnexion"
              (click)="disconnectionClicked.emit()"></button>
    </div>
