
<div #mainDiv id="mainDiv" class="fullHeight p-d-block" style="position: relative" (window:resize)="onResize(mainDiv, bottomPart)">
  <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 150px">
    <div #schoolYearSemesterSelector class="p-p-2">
      <app-school-year-semester-selector #selector
        (yearSelected)="onYearSelected($event)"
        (allSchoolsSelected)="onAllSchoolsSelected($event)"
        (semesterSelected)="onSemesterSelected($event)"
        [mandatoryFields]="['year','school','semester']"
      ></app-school-year-semester-selector>
    </div>

    <app-basic-grid #grid id="grid" dataKey="student_id" gridTitle="Liste des étudiants"
                    gridType="{{gridTypes.MAIN_GRID}}"
                    [data]="data" [customButtonsTemplate]="customButtonsTemplate" [scrollSizeInPx]="(gridScrollSizeInPx - gridBottomSize - schoolYearSemesterSelectorSizeInPx)" [bottomSize]="(gridBottomSize - 30 + schoolYearSemesterSelectorSizeInPx)"
                    [columns]="columns" [topColumns]="topColumns" [loading]="loadingData"
                    (cancel)="canceler.cancel()" [showAddButton]="false" [showDeleteButton]="false" [showShowButton]="true"
                    [exportTitle]="exportTitle"
                    [isTabActive]="isActive()"

                    [sendExportExcelVisible]="true"
                    [sendExportPDFVisible]="true"
                    [sendPublipostageVisible]="true"
                    [sendEmailingVisible]="false"
                    [sendSmsVisible]="true"
                    [sendNotificationVisible]="false"

                    (dataChanged)="onDataChanged($event)"
                    (refreshRequested)="onRefreshRequested()"
                    (rowsSelectionChanged)="onRowsSelectionChanged($event)"
                    (sendPublipostageClicked)="onSendPublipostageClicked()"
                    (sendEmailingClicked)="onSendEmailingClicked()"
                    (sendSmsClicked)="onSendSmsClicked()"
                    (sendNotificationClicked)="onSendNotificationClicked()"
                    (rowDblClicked)="onShowStudent($event)"
                    (showRequested)="onShowStudent($event)"
    >
      <ng-template #customButtonsTemplate>
        <div class="p-d-flex fullWidth centerVertical" style="height: 25px">
          <p-radioButton name="listmode" [ngStyle]="{'font-weight': 'normal', 'font-size': '0.9em'}" label="Etudiants inscrits" value="registered" [(ngModel)]="listMode" (onClick)="onListModeChanged()" class="p-mr-2"></p-radioButton>
          <p-radioButton name="listmode" [ngStyle]="{'font-weight': 'normal', 'font-size': '0.9em'}" label="Etudiants désistés" value="desisted" [(ngModel)]="listMode" (onClick)="onListModeChanged()"></p-radioButton>
        </div>
      </ng-template>

    </app-basic-grid>
  </div>

  <div #bottomPart style="height: 150px; position: absolute; bottom: 0; left: 0; right: 0;">

    <div *ngIf="selectedData" class="fullHeight">
      <div *ngIf="selectedData.length === 0; else hasData" class="centerContent fullHeight regionTitle">
        Veuillez sélectionner un ou plusieurs étudiants
      </div>
      <ng-template #hasData>
        <div *ngIf="selectedData.length === 1; else hasMultipleData" class="p-d-flex p-pt-2 fullHeight">
          <div class="p-col fullHeight p-mr-2 lightBorder10">
            <div class="regionTitle p-mb-2">Informations annuelles</div>
            <div>
              <p-table [value]="studentYearInfo"
                       [resizableColumns]="true"
                       scrollHeight="80px" [scrollable]="true"
                       [loading]="loadingStudentYearInfo">
                <ng-template pTemplate="header">
                  <tr>
                    <th pResizableColumn class="centerHorizontal">Ecole</th>
                    <th pResizableColumn class="centerHorizontal">Semestre</th>
                    <th pResizableColumn class="centerHorizontal">Promotion</th>
                    <th pResizableColumn class="centerHorizontal">Option</th>
                    <th pResizableColumn class="centerHorizontal">Classe</th>
                    <th pResizableColumn class="centerHorizontal">Session</th>
                    <th pResizableColumn class="centerHorizontal">Inscrit</th>
                    <th pResizableColumn class="centerHorizontal">Désisté</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-info>
                  <tr>
                    <td [pTooltip]="info.school">{{info.school}}</td>
                    <td [pTooltip]="info.description">{{info.description}}</td>
                    <td [pTooltip]="info.promo">{{info.promo}}</td>
                    <td [pTooltip]="info.option">{{info.option}}</td>
                    <td [pTooltip]="info.classe">{{info.classe}}</td>
                    <td [pTooltip]="info.session">{{info.session}}</td>
                    <td [pTooltip]="info.registered ? 'inscrit' : 'non inscrit'"><p-checkbox [(ngModel)]="info.registered" binary="true" [disabled]="true"></p-checkbox></td>
                    <td [pTooltip]="info.desisted ? 'désisté' : 'non désisté'"><p-checkbox [(ngModel)]="info.desisted" binary="true" [disabled]="true"></p-checkbox></td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
          <div class="p-col-2 fullHeight p-mr-2 lightBorder10 scrollable-y">
            <div class="regionTitle p-mb-2">Observations</div>
            <div [innerHTML]="selectedData[0].rsy_comments"></div>
          </div>
          <div class="p-col-2 fullHeight lightBorder10 scrollable-y">
            <div class="regionTitle p-mb-2">Message comptable</div>
            <div *ngIf="selectedData[0].rsy_reviews_accounting" [innerHTML]="selectedData[0].rsy_reviews_accounting.split('\n').join('<br>')"></div>
          </div>
        </div>

        <ng-template #hasMultipleData>
          <div class="fullHeight centerContent regionTitle">
            <div class="p-d-block">
              <div class="centerHorizontal">{{selectedData.length}} étudiants sélectionnés.</div>
              <div class="p-mt-3">Veuillez limiter votre sélection à un·e étudiant·e pour voir ses informations annuelles.</div>
            </div>
          </div>
        </ng-template>

      </ng-template>
    </div>
  </div>
</div>

<p-dialog header="Publipostage Word"
          [(visible)]="displayPublipostageDialog" [modal]="true" [style]="{width: '800px'}" [baseZIndex]="10"
          [draggable]="false" [resizable]="false" [closeOnEscape]="true">
  <div class="p-d-flex">
    <div class="p-col-5" style="text-align: right">Publipostage depuis un : </div>
    <div class="p-col-7">
      <p-radioButton name="publipostageType" label="Document existant" value="existingDocument" [(ngModel)]="publipostageMode" class=" p-mr-3"></p-radioButton>
      <p-radioButton name="publipostageType" label="Nouveau document"  value="newDocument"      [(ngModel)]="publipostageMode"></p-radioButton>
    </div>

  </div>
  <div *ngIf="publipostageMode === 'existingDocument'" class="p-d-flex centerVertical">
    <div class="p-col-5" style="text-align: right">Document (.docx) : </div>
    <div class="p-col-7 p-d-flex centerVertical">
      <p-fileUpload *ngIf="!publipostageSelectedFiles || publipostageSelectedFiles.length === 0; else hasWordModelFileSelected"
                    mode="basic" name="rendu" chooseIcon=""
                    class="upload-btn p-mr-3"
                    accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    (onSelect)="importOnClick($event)" [auto]="true" chooseLabel="Choisir">
      </p-fileUpload>
      <ng-template #hasWordModelFileSelected>
        <button pButton icon="pi pi-times" style="min-width: 35px" class="p-button-secondary p-mr-3" (click)="resetPublipostageSelectedFiles()"></button>
      </ng-template>
      <div class="primaryColor" style="font-weight: bold; overflow-x: scroll"
           [innerText]="publipostageSelectedFiles && publipostageSelectedFiles.length === 1 ? publipostageSelectedFiles[0].name : ''"></div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button pButton label="Annuler" icon="pi pi-times" class="p-button-danger" (click)="displayPublipostageDialog = false"></button>
    <button pButton label="Valider" icon="pi pi-check" class="p-button-success" (click)="onValidatePublipostage($event)"></button>
  </ng-template>
</p-dialog>
