import {Component, OnInit} from '@angular/core';
import {VersionService} from '../version.service';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {

  version = VersionService.getVersion();

  constructor() {
  }


  ngOnInit(): void {
  }

}
