import {TabLayoutComponent} from '../components/tab-layout/tab-layout.component';

export abstract class AbstractPageComponent {

  extraData: any;
  tabLayout: TabLayoutComponent;

  abstract getHelp(): string;

  isActive(): boolean {
    return this.tabLayout.tabs[this.tabLayout.currentTabIndex - 1]?.data[`pageId`] === this.extraData[`pageId`];
  }

}
