import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-chooser',
  templateUrl: './icon-chooser.component.html',
  styleUrls: ['./icon-chooser.component.scss', '../../styles/icons.scss']
})
export class IconChooserComponent implements OnInit {

  icons900 = ['\e900', '\e901', '\e902', '\e903', '\e904', '\e905', '\e906', '\e907', '\e908', '\e909', '\e90a', '\e90b', '\e90c', '\e90d', '\e90e', '\e90f'];
  icons910 = ['\e910', '\e911', '\e912', '\e913', '\e914', '\e915', '\e916', '\e917', '\e918', '\e919', '\e91a', '\e91b', '\e91c', '\e91d', '\e91e', '\e91f'];
  icons920 = ['\e920', '\e921', '\e922', '\e923', '\e924', '\e925', '\e926', '\e927', '\e928', '\e929', '\e92a', '\e92b', '\e92c', '\e92d', '\e92e', '\e92f'];
  icons930 = ['\e930', '\e931', '\e932', '\e933', '\e934', '\e935', '\e936', '\e937', '\e938', '\e939', '\e93a', '\e93b', '\e93c', '\e93d', '\e93e', '\e93f'];
  icons940 = ['\e940', '\e941', '\e942', '\e943', '\e944', '\e945', '\e946', '\e947', '\e948', '\e949', '\e94a', '\e94b', '\e94c', '\e94d', '\e94e', '\e94f'];
  icons950 = ['\e950', '\e951', '\e952', '\e953', '\e954', '\e955', '\e956', '\e957', '\e958', '\e959', '\e95a', '\e95b', '\e95c', '\e95d', '\e95e', '\e95f'];
  icons960 = ['\e960', '\e961', '\e962', '\e963', '\e964', '\e965', '\e966', '\e967', '\e968', '\e969', '\e96a', '\e96b', '\e96c', '\e96d', '\e96e', '\e96f'];
  icons970 = ['\e970', '\e971', '\e972', '\e973', '\e974', '\e975', '\e976', '\e977', '\e978', '\e979', '\e97a', '\e97b', '\e97c', '\e97d', '\e97e', '\e97f'];
  icons980 = ['\e980', '\e981', '\e982', '\e983', '\e984', '\e985', '\e986', '\e987', '\e988', '\e989', '\e98a', '\e98b', '\e98c', '\e98d', '\e98e', '\e98f'];
  icons990 = ['\e990', '\e991', '\e992', '\e993', '\e994', '\e995', '\e996', '\e997', '\e998', '\e999', '\e99a', '\e99b', '\e99c', '\e99d', '\e99e', '\e99f'];
  icons9a0 = ['\e9a0', '\e9a1', '\e9a2', '\e9a3', '\e9a4', '\e9a5', '\e9a6', '\e9a7', '\e9a8', '\e9a9', '\e9aa', '\e9ab', '\e9ac', '\e9ad', '\e9ae', '\e9af'];
  icons9b0 = ['\e9b0', '\e9b1', '\e9b2', '\e9b3', '\e9b4', '\e9b5', '\e9b6', '\e9b7', '\e9b8', '\e9b9', '\e9ba', '\e9bb', '\e9bc', '\e9bd', '\e9be', '\e9bf'];
  icons9c0 = ['\e9c0', '\e9c1', '\e9c2', '\e9c3', '\e9c4', '\e9c5', '\e9c6', '\e9c7', '\e9c8', '\e9c9', '\e9ca', ];

  iconsOthers = ['test', 'graduation-cap'];

  constructor() { }

  ngOnInit(): void {
  }

}
