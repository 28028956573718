import {Component, OnInit, ViewChild} from '@angular/core';
import {Page} from '../../annotations/page.annotation';
import {BasicGridComponent} from '../grid/basic-grid/basic-grid.component';
import {PagesService} from '../../services/pages.service';
import {EntryDTO} from '../../models/interfaces/entry-dto';

import {environment} from '../../../environments/environment';

import {AbstractRequestComponent} from '../../interfaces/abstract-request.component';
import {BaseService} from '../../services/base.service';
import {MessageService} from 'primeng/api';
import {FieldValue} from '../../models/query/field-value';
import {TableColumn} from '../../models/table-column';

@Component({
  selector: 'app-query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.scss']
})
@Page(PagesService.QUERY, 'Composant des requêtes', undefined, { icon: 'pi pi-users' })
export class QueryComponent extends AbstractRequestComponent implements OnInit {

  @ViewChild('grid') grid: BasicGridComponent;

  constructor(public baseService: BaseService,
              public messageService: MessageService) {
    super(baseService, messageService);
  }


  private entryValue: EntryDTO;

  get entry(): EntryDTO {
    return this.entryValue;
  }

  set entry(value) {
    this.entryValue = value;
    this.initQuery();
  }



  ngOnInit(): void {
    this.entry = this.extraData;
  }

  getHelp(): string {
    return 'Cette interface permet de réaliser une extraction des données de notre système (requête).' +
      '\n \n' +
      'Après paramètrage si nécessaire, il vous suffit de cliquer sur le bouton "Exécuter" pour afficher les données.';
  }

  initQuery() {
    this.isReady = false;
    this.baseService.getJson(`../assets/documents/queries/${this.entry.entry_id}.json`)
      .then(data => {
        this.requestData = data;
        this.requestData.columns = this.requestData.columns.map(column => new TableColumn(column.field, column.header, column.width, column.type, column.readonly, column.sortable, column.filterType) );
        this.setDefaultDataKeyIfNotExist();
        this.initSelectedParams();
        this.isReady = true;
      }).catch(() => {
        this.messageService.add({severity: 'error', summary: 'Cette requête n\'est pas disponible.'});
        if (environment.production) {
          this.tabLayout.closeCurrentTab();
        } else {
          this.baseService.getJson(`../assets/documents/queries/query_sample.json`)
            .then(data => {
              this.requestData = data;
              this.initSelectedParams();
              this.isReady = true;
            });
        }
      });
  }

  private setDefaultDataKeyIfNotExist() {
    if (this.requestData != null && (this.requestData.dataKey == null || this.requestData.dataKey.length <= 0)) {
      this.requestData.dataKey = this.ROW_INDEX;
    }
  }

  onUpdateDynamicParam(fieldValue: FieldValue) {
    this.updateSelectedValueParam(fieldValue.field, fieldValue.value, false);
  }
}
