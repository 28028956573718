import {Component, Input, OnInit} from '@angular/core';
import * as emailConstants from '../../constants/email.constant';
import {AuthenticatedUserService} from '../../services/authenticated-user.service';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {

  @Input()
  errorDialogDisplayed = false;

  @Input() errorMessage = '';
  @Input() title = 'Erreur';
  supportAddress = emailConstants.SUPPORT_ADDRESS;

  constructor(private authenticatedUserService: AuthenticatedUserService) { }

  ngOnInit(): void {
  }

  refreshPage(): void {
    window.location.reload();
  }

  disconnect(): void {
    this.authenticatedUserService.removeToken();
    this.authenticatedUserService.removeProfile();
    this.authenticatedUserService.logout();
  }
}
