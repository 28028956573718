import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SchoolYearSemesterValues} from '../../models/school-year-semester-values';
import {SchoolsService} from '../../services/schools.service';
import {PromotionDTO} from '../../models/schools/promotion-dto';
import {MessageService} from 'primeng/api';
import {ClassDTO} from '../../models/schools/class-dto';
import {OptionDTO} from '../../models/schools/option-dto';
import {SpecializationDTO} from '../../models/schools/specialization-dto';
import {Session} from '../../utils/sessionsList';

@Component({
  selector: 'app-application-params-selector',
  templateUrl: './application-params-selector.component.html',
  styleUrls: ['./application-params-selector.component.scss']
})
export class ApplicationParamsSelectorComponent implements OnInit {

  @Input() yearIsVisible = true;
  @Input() schoolIsVisible = true;
  @Input() allSchoolsIsVisible = true;
  @Input() semesterIsVisible = true;
  @Input() promoIsVisible = true;
  @Input() sessionIsVisible = true;
  @Input() classIsVisible = true;
  @Input() optionIsVisible = true;
  @Input() specializationIsVisible = true;
  @Input() mandatoryFields = [];

  //region emitters
  @Output() yearSelected        = new EventEmitter<SchoolYearSemesterValues>();
  @Output() schoolSelected      = new EventEmitter<SchoolYearSemesterValues>();
  @Output() allSchoolsSelected  = new EventEmitter<SchoolYearSemesterValues>();
  @Output() semesterSelected    = new EventEmitter<SchoolYearSemesterValues>();
  @Output() promotionSelected    = new EventEmitter<PromotionDTO>();
  @Output() sessionSelected    = new EventEmitter<string>();
  @Output() classSelected    = new EventEmitter<ClassDTO>();
  @Output() optionSelected    = new EventEmitter<OptionDTO>();
  @Output() specializationSelected    = new EventEmitter<SpecializationDTO>();
  //endregion

  //region loaders
  loadingPromotions = false;
  loadingSessions = false;
  loadingClasses = false;
  loadingOptions = false;
  loadingSpecializations = false;
  //endregion

  //region data
  schoolYearSemesterValues: SchoolYearSemesterValues = new SchoolYearSemesterValues();
  selectedPromotion: PromotionDTO;
  promoList: PromotionDTO[] = [];
  selectedSession: string;
  sessionsList: Session[] = [];
  selectedClass: ClassDTO;
  classesList: ClassDTO[] = [];
  selectedOption: OptionDTO;
  optionsList: OptionDTO[] = [];
  selectedSpecialization: SpecializationDTO;
  specializationsList: SpecializationDTO[] = [];
  //endregion
  PROMOTION = 'promotion';
  SESSION = 'session';
  OPTION = 'option';
  CLASS = 'class';
  SPECIALIZATION = 'specialization';

  constructor(private messageService: MessageService,
              private schoolsService: SchoolsService) { }

  ngOnInit(): void {
    if (this.sessionIsVisible) {
      this.loadSessions();
    }
  }

  checkIfPromoIsDisabled(): boolean {
    return this.schoolYearSemesterValues.selectedSchoolId === 0;
  }

  onYearSelected(schoolYearSemesterValues: SchoolYearSemesterValues) {
    this.schoolYearSemesterValues.selectedYear = schoolYearSemesterValues.selectedYear;
    this.yearSelected.emit(schoolYearSemesterValues);

    this.selectedPromotion = null;
    this.onPromotionSelected();
  }

  onSchoolSelected(schoolYearSemesterValues: SchoolYearSemesterValues) {
    this.schoolYearSemesterValues.selectedSchoolId = schoolYearSemesterValues.selectedSchoolId;
    this.schoolSelected.emit(schoolYearSemesterValues);
    if (this.promoIsVisible) {
      this.loadPromotions();
    }
  }

  onAllSchoolsSelected(schoolYearSemesterValues: SchoolYearSemesterValues) {
    this.selectedPromotion = null;
    this.onPromotionSelected();

    this.allSchoolsSelected.emit(schoolYearSemesterValues);
  }

  onSemesterSelected(schoolYearSemesterValues: SchoolYearSemesterValues) {
    this.schoolYearSemesterValues.selectedSemesterId = schoolYearSemesterValues.selectedSemesterId;
    this.semesterSelected.emit(schoolYearSemesterValues);
  }

  onPromotionSelected() {
    this.promotionSelected.emit(this.selectedPromotion);

    this.selectedClass = null;
    this.onClassSelected();
    this.selectedOption = null;
    this.onOptionSelected();
    if (this.selectedPromotion != null) {
      this.loadClasses();
      this.loadOptions();
    }
  }

  onSessionSelected() {
    this.sessionSelected.emit(this.selectedSession);
  }

  onClassSelected() {
    this.classSelected.emit(this.selectedClass);
  }

  onOptionSelected() {
    this.optionSelected.emit(this.selectedOption);
    this.selectedSpecialization = null;
    this.onSpecializationSelected();

    if (this.selectedOption != null) {
      this.loadSpecializations();
    }
  }

  onSpecializationSelected() {
    this.specializationSelected.emit(this.selectedSpecialization);
  }

  private loadPromotions() {
    this.loadingPromotions = true;

    this.schoolsService.getAllPromotionsByYearAndSchool(this.schoolYearSemesterValues.selectedSchoolId, this.schoolYearSemesterValues.selectedYear)
      .then(promotions => {
        this.promoList = promotions;
        if (this.promoList.length > 0) {
          this.selectedPromotion = this.promoList[0];
          this.onPromotionSelected();
        }
      })
      .catch(() => this.displayError('promotions'))
      .finally(() => this.loadingPromotions = false);
  }

  private loadSessions() {
    this.loadingSessions = true;

    this.sessionsList = this.schoolsService.getAllSessionsByPromotion();

    if (this.sessionsList.length > 0) {
      this.selectedSession = this.sessionsList[0].value;
      this.onSessionSelected();
    }
    this.loadingSessions = false;
  }

  private loadClasses() {
    this.loadingClasses = true;

    this.schoolsService.getAllClassesByYearAndPromotion(this.schoolYearSemesterValues.selectedYear, this.selectedPromotion.puid)
      .then(classes => {
        this.classesList = classes;
      })
      .catch(() => this.displayError('classes'))
      .finally(() => this.loadingClasses = false);
  }

  private loadOptions() {
    this.loadingOptions = true;

    this.schoolsService.getAllOptionsByYearAndPromotion(this.schoolYearSemesterValues.selectedYear, this.selectedPromotion.puid)
      .then(options => {
        this.optionsList = options;
      })
      .catch(() => this.displayError('options'))
      .finally(() => this.loadingOptions = false);
  }

  private loadSpecializations() {
    this.loadingSpecializations = true;

    this.schoolsService.getAllSpecializationsByOption(this.selectedOption.puid)
      .then(specializations => {
        this.specializationsList = specializations;
      })
      .catch(() => this.displayError('specialisations'))
      .finally(() => this.loadingSpecializations = false);
  }

  private displayError(name: string) {
    this.messageService.add({
      severity: 'error',
      summary: `Problème lors du chargement des ${name}.`,
      life: 2000
    });
  }

  isElementMandatory(currentField: string) {
    return this.mandatoryFields.some(field => field === currentField);
  }
}
