<div class="p-d-flex">
  <app-school-year-semester-selector [yearIsVisible]="yearIsVisible"
                                     [schoolIsVisible]="schoolIsVisible"
                                     [allSchoolsIsVisible]="allSchoolsIsVisible"
                                     [semesterIsVisible]="semesterIsVisible"
                                     [mandatoryFields]="mandatoryFields"
                                     (yearSelected)="onYearSelected($event)"
                                     (schoolSelected)="onSchoolSelected($event)"
                                     (allSchoolsSelected)="onAllSchoolsSelected($event)"
                                     (semesterSelected)="onSemesterSelected($event)"></app-school-year-semester-selector>

    <div *ngIf="promoIsVisible" class="p-field p-mr-3 p-pt-4" style="position: relative">
        <span class="p-float-label">
          <p-dropdown id="promotionDropdown" appendTo="body" styleClass="dropdownStyle"
                      optionLabel="name"
                      [autoDisplayFirst]="false"
                      [disabled]="promoList.length === 0 && checkIfPromoIsDisabled()"
                      [options]="promoList"
                      [(ngModel)]="selectedPromotion"
                      [showClear]="!isElementMandatory(PROMOTION)"
                      (onChange)="onPromotionSelected()">
            <ng-template let-element pTemplate="selectedItem">
              <div class="ellipsis-text">{{element.name}}</div>
            </ng-template>
          </p-dropdown>
          <label for="promotionDropdown">Promotion<span *ngIf="isElementMandatory(PROMOTION)" style="color: red"> * </span></label>
        </span>
      <div *ngIf="loadingPromotions" class="centerContent spinnerDivBackground"><app-spinner [spinnerWidth]="'25px'"></app-spinner></div>
    </div>

    <div *ngIf="sessionIsVisible" class="p-field p-mr-3 p-pt-4" style="position: relative">
          <span class="p-float-label">
            <p-dropdown id="sessionDropdown" appendTo="body" styleClass="dropdownStyle"
                        optionLabel="name" optionValue="value"
                        [autoDisplayFirst]="false"
                        [disabled]="sessionsList.length === 0"
                        [options]="sessionsList"
                        [(ngModel)]="selectedSession"
                        [showClear]="!isElementMandatory(SESSION)"
                        (onChange)="onSessionSelected()">
              <ng-template let-element pTemplate="selectedItem">
                <div class="ellipsis-text">{{element.name}}</div>
              </ng-template>
            </p-dropdown>
            <label for="sessionDropdown">Session<span *ngIf="isElementMandatory(SESSION)" style="color: red"> * </span></label>
          </span>
        <div *ngIf="loadingSessions" class="centerContent spinnerDivBackground"><app-spinner [spinnerWidth]="'25px'"></app-spinner></div>
      </div>
    <div *ngIf="optionIsVisible" class="p-field p-mr-3 p-pt-4" style="position: relative">
        <span class="p-float-label">
          <p-dropdown id="optionDropdown" appendTo="body" styleClass="dropdownStyle"
                      optionLabel="description"
                      [autoDisplayFirst]="false"
                      [disabled]="optionsList.length === 0"
                      [options]="optionsList"
                      [(ngModel)]="selectedOption"
                      [showClear]="!isElementMandatory(OPTION)"
                      (onChange)="onOptionSelected()">
            <ng-template let-element pTemplate="selectedItem">
              <div class="ellipsis-text">{{element.description}}</div>
            </ng-template>
          </p-dropdown>
          <label for="optionDropdown">Option / Filière<span *ngIf="isElementMandatory(OPTION)" style="color: red"> * </span></label>
        </span>
      <div *ngIf="loadingOptions" class="centerContent spinnerDivBackground"><app-spinner [spinnerWidth]="'25px'"></app-spinner></div>
    </div>
    <div *ngIf="classIsVisible" class="p-field p-mr-3 p-pt-4" style="position: relative">
        <span class="p-float-label">
          <p-dropdown id="classDropdown" appendTo="body" styleClass="dropdownStyle"
                      optionLabel="name"
                      [autoDisplayFirst]="false"
                      [disabled]="classesList.length === 0"
                      [options]="classesList"
                      [(ngModel)]="selectedClass"
                      [showClear]="!isElementMandatory(CLASS)"
                      (onChange)="onClassSelected()">
            <ng-template let-element pTemplate="selectedItem">
              <div class="ellipsis-text">{{element.name}}</div>
            </ng-template>
          </p-dropdown>
          <label for="classDropdown">Classe / Groupe<span *ngIf="isElementMandatory(CLASS)" style="color: red"> * </span></label>
        </span>
      <div *ngIf="loadingClasses" class="centerContent spinnerDivBackground"><app-spinner [spinnerWidth]="'25px'"></app-spinner></div>
    </div>
    <div *ngIf="specializationIsVisible" class="p-field p-mr-3 p-pt-4" style="position: relative">
        <span class="p-float-label">
          <p-dropdown id="specializationDropdown" appendTo="body" styleClass="dropdownStyle"
                      optionLabel="description"
                      [autoDisplayFirst]="false"
                      [disabled]="specializationsList.length === 0"
                      [options]="specializationsList"
                      [(ngModel)]="selectedSpecialization"
                      [showClear]="!isElementMandatory(SPECIALIZATION)"
                      (onChange)="onSpecializationSelected()">
            <ng-template let-element pTemplate="selectedItem">
              <div class="ellipsis-text">{{element.description}}</div>
            </ng-template>
          </p-dropdown>
          <label for="specializationDropdown">Spécialisation<span *ngIf="isElementMandatory(SPECIALIZATION)" style="color: red"> * </span></label>
        </span>
      <div *ngIf="loadingSpecializations" class="centerContent spinnerDivBackground"><app-spinner [spinnerWidth]="'25px'"></app-spinner></div>
    </div>

</div>
