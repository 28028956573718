<div #mainDiv id="mainDiv" class="p-d-block fullHeight" style="position: relative;">
  <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0px">
    <p-tabView>
      <p-tabPanel header="Droits" [selected]="true">
        <as-split direction="vertical">
            <as-split-area [size]="80">

            <div class="rightsTab fullHeight">
              <app-basic-grid #rightsGrid id="rightsGrid" gridTitle="Liste des droits" [customButtonsTemplate]="rightsGroupsButtons"
                              [columns]="rightsColumns" [data]="rightsData" dataKey="right_id" [loading]="loadingData"
                              [showRefreshButton]="true" [scrollable]="true"
                              [showGlobalSearch]="true" [showAddButton]="false" [showDeleteButton]="false" [showShowButton]="false"
                              [sendPublipostageVisible]="false" [sendEmailingVisible]="false" [sendSmsVisible]="false" [sendNotificationVisible]="false"
                              (rowsSelectionChanged)="onRightsRowsSelectionChanged($event)"
                              gridType="{{gridTypes.RIGHT_GRID}}"
                              [isTabActive]="isActive()"
                              [heightToKeep]="8"
                              (refreshRequested)="refreshRightsList()">
                <ng-template #rightsGroupsButtons>
                  <div class="p-ml-auto p-text-left preset-button">
                    <button pButton type="button" class="p-button-success p-mr-2" icon="pi pi-plus" pTooltip="Créer un droit" (click)="showRightDialog(true)"></button>
                    <button pButton type="button" class="p-button-success p-mr-2" icon="pi pi-pencil" pTooltip="Modifier le droit sélectionné"
                            (click)="showRightDialog(false)" [disabled]="rightsSelectedData.length != 1"></button>
                    <button pButton type="button" class="p-button-success p-mr-2" icon="far fa-check-square" pTooltip="Activer le(s) droit(s) sélectionné(s)"
                            (click)="showRightsActivationDialog(true)" [disabled]="rightsSelectedData.length === 0"></button>
                    <button pButton type="button" class="p-button-danger p-mr-2" icon="far fa-square" pTooltip="Désactiver le(s) droit(s) sélectionné(s)"
                            (click)="showRightsActivationDialog(false)" [disabled]="rightsSelectedData.length === 0"></button>
                    <button pButton type="button" class="p-button-danger p-mr-2" icon="pi pi-trash" pTooltip="Supprimer le droit sélectionné"
                            (click)="showRightsSuppressionDialog()" [disabled]="rightsSelectedData.length === 0"></button>
                  </div>
                </ng-template>
              </app-basic-grid>
            </div>
            </as-split-area>
            <as-split-area  [size]="20" class="lightBorder10">
            <div #bottomPart class="fullHeight">
              <div  class="p-d-flex p-pt-2 regionTitle">Utilisations</div>
              <div class="p-col fullHeight p-mr-2">
                <div *ngIf="rightsSelectedData.length === 0; else hasData" class="centerContent fullHeight regionTitle margin-top-25">
                  Veuillez sélectionner un droit
                </div>
                <ng-template #hasData>
                  <div *ngIf="rightsSelectedData.length === 1; else hasMultipleData" class="p-d-flex p-pt-2 fullHeight">

                    <div class="fullHeight">
                      <p-table id="usageGrid" [value]="rightUsages" [scrollable]="true" sortField="rights_group_name" sortOrder="1"
                               [loading]="loadingUsages" >
                        <ng-template pTemplate="header">
                          <tr>
                            <th pSortableColumn="rights_group_name" ><p-sortIcon field="rights_group_name"></p-sortIcon></th>
                            <th pSortableColumn="can_read" class="p-column-icon" class="p-column-icon">
                              <div class="p-d-flex p-jc-between p-ai-center p-usage-column">
                                <p-sortIcon field="can_read"></p-sortIcon>
                                <span class="centerHorizontal fullWidth"><i class="pi pi-eye"></i></span>
                              </div>
                            <th pSortableColumn="can_update" class="p-column-icon">
                              <div class="p-d-flex p-jc-between p-ai-center p-usage-column">
                                <p-sortIcon field="can_update"></p-sortIcon>
                                <span class="centerHorizontal fullWidth"><i class="pi pi-pencil"></i></span>
                              </div>
                            </th>
                            <th pSortableColumn="can_delete" class="p-column-icon">
                              <div class="p-d-flex p-jc-between p-ai-center p-usage-column">
                                <p-sortIcon field="can_delete"></p-sortIcon>
                                <span class="centerHorizontal fullWidth"><i class="pi pi-trash"></i></span>
                              </div>
                            </th>
                            <th pSortableColumn="can_transmit" class="p-column-icon">
                              <div class="p-d-flex p-jc-between p-ai-center p-usage-column">
                                <p-sortIcon field="can_transmit"></p-sortIcon>
                                <span class="centerHorizontal fullWidth"><i class="pi pi-directions"></i></span>
                              </div>
                            </th>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rightsGroup>
                          <tr>
                            <td>{{rightsGroup.rights_group_name}}</td>
                            <td class="p-column-icon">
                              <p-checkbox [(ngModel)]="rightsGroup.can_read" binary="true" [disabled]="true"></p-checkbox>
                            </td>
                            <td class="p-column-icon">
                              <p-checkbox [(ngModel)]="rightsGroup.can_update" binary="true" [disabled]="true"></p-checkbox>
                            </td>
                            <td class="p-column-icon">
                              <p-checkbox [(ngModel)]="rightsGroup.can_delete" binary="true" [disabled]="true"></p-checkbox>
                            </td>
                            <td class="p-column-icon">
                              <p-checkbox [(ngModel)]="rightsGroup.can_transmit" binary="true" [disabled]="true"></p-checkbox>
                            </td>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                          <div class="centerContent">
                            Le droit sélectionné n'est pas utilisé
                          </div>
                        </ng-template>
                      </p-table>
                    </div>
                  </div>
                  <ng-template #hasMultipleData>
                    <div class="fullHeight centerContent regionTitle">
                      <div class="p-d-block">
                        <div class="centerHorizontal">{{rightsSelectedData.length}} droits sélectionnés.</div>
                        <div class="p-mt-3">Veuillez limiter votre sélection à un droit afin de voir son utilisation.</div>
                      </div>
                    </div>
                  </ng-template>

                </ng-template>
              </div>
            </div>
            </as-split-area>
        </as-split>

      </p-tabPanel>

      <p-tabPanel header="Familles">
        <div class="rightsTab fullHeight">
          <app-basic-grid #familiesGrid id="familiesGrid" gridTitle="Liste des familles" [customButtonsTemplate]="familiesButtons"
                          [columns]="familiesColumns" [data]="familiesData" dataKey="rights_family_id" [loading]="loadingData"
                          [showAddButton]="false" [showDeleteButton]="false" [showShowButton]="false" [showGlobalSearch]="true"
                          [showRefreshButton]="true"
                          [sendPublipostageVisible]="false" [sendEmailingVisible]="false" [sendSmsVisible]="false"
                          [sendNotificationVisible]="false" [scrollable]="true"
                          (rowsSelectionChanged)="onFamiliesRowsSelectionChanged($event)"
                          [isTabActive]="isActive()"
                          gridType="{{gridTypes.RIGHT_GRID}}"
                          [rowToRemove]="6"

                          (refreshRequested)="refreshFamiliesList()">
            <ng-template #familiesButtons>
              <div class="p-ml-auto p-text-left">
                <button pButton type="button" class="p-button-success p-mr-2" icon="pi pi-plus" pTooltip="Ajouter un droit" (click)="showFamilyDialog(true)"></button>
                <button pButton type="button" class="p-button-success p-mr-2" icon="pi pi-pencil" pTooltip="Modifier le droit sélectionné"
                        [disabled]="familiesSelectedData.length != 1" (click)="showFamilyDialog(false)"></button>
                <button pButton type="button" class="p-button-danger p-mr-2" icon="pi pi-trash" pTooltip="Supprimer le droit sélectionné"
                        (click)="showFamilySuppressionDialog()" [disabled]="familiesSelectedData.length === 0"></button>
              </div>
            </ng-template>
          </app-basic-grid>
        </div>
      </p-tabPanel>
    </p-tabView>

  </div>
</div>


<p-dialog header="{{isCreateDialog ? 'Création' : 'Modification'}} d'un droit" #rightDialog
          [(visible)]="displayRightDialog" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000"
          [draggable]="false" [resizable]="false" [closeOnEscape]="true">
  <form [formGroup]="rightForm">
    <div class="p-fluid">
      <div class="p-field">
        <label for="rightType">Type de droit *</label>
        <p-dropdown id="rightType" formControlName="rightType" [options]="rightsTypes"
                    optionLabel="right_type_name" optionValue="right_type_id" [showClear]="true" placeholder="Choisir un type"></p-dropdown>
      </div>
      <div class="p-field">
        <label for="rightFamily">Famille *</label>
        <p-dropdown id="rightFamily" formControlName="rightFamily" [options]="familiesAllData"
                    optionLabel="rights_family_name" optionValue="rights_family_id" [showClear]="true" placeholder="Choisir une famille"></p-dropdown>
      </div>
      <div class="p-field">
        <label for="rightName">Nom *</label>
        <input id="rightName" formControlName="rightName" type="text" pInputText/>
      </div>
      <div class="p-field">
        <label for="rightDesc">Description *</label>
        <input id="rightDesc" formControlName="rightDesc" type="text" pInputText/>
      </div>
      <div class="p-field-checkbox">
        <p-checkbox binary="true" id="isActive" formControlName="isActive"></p-checkbox>
        <label for="isActive">Actif</label>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <button pButton icon="pi pi-save" label="Enregistrer" (click)="saveRight($event)" [disabled]="!rightForm.valid"></button>
    <button pButton icon="pi pi-ban" label="Annuler" (click)="reinitEditedRight();rightDialog.close($event)"></button>
  </ng-template>
</p-dialog>

<p-dialog header="{{isCreateDialog ? 'Création' : 'Modification'}} d'une famille" #familyDialog
          [(visible)]="displayFamilyDialog" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000"
          [draggable]="false" [resizable]="false" [closeOnEscape]="true">
  <form [formGroup]="familyForm">
  <div class="p-fluid">
    <div class="p-field">
      <label for="familyParent">Parent</label>
      <p-dropdown id="familyParent" [options]="familiesAllData"
                  formControlName="familyParent"
                  optionLabel="rights_family_name" optionValue="rights_family_id" [showClear]="true" scrollHeight="80px"
                  placeholder="Choisir un parent (non obligatoire)"></p-dropdown>
    </div>
    <div class="p-field">
      <label for="familyName">Nom *</label>
      <input id="familyName" formControlName="familyName" type="text" [required]="true" pInputText/>
    </div>
  </div>
  </form>

  <ng-template pTemplate="footer">
    <button pButton icon="pi pi-save" label="Enregistrer" (click)="saveFamily($event)"></button>
    <button pButton icon="pi pi-ban" label="Annuler" (click)="reinitEditedFamily();familyDialog.close($event)"></button>
  </ng-template>
</p-dialog>

<p-confirmDialog #deactivationDialog key="activationDialog" [style]="{width: '40vw'}">
</p-confirmDialog>

<p-confirmDialog #deleteDialog key="deleteRightDialog" [style]="{width: '40vw'}">
</p-confirmDialog>

<p-confirmDialog #deleteFamilyDialog key="deleteFamilyDialog" [style]="{width: '40vw'}">
</p-confirmDialog>
