import {Component, OnInit} from '@angular/core';
import {AbstractPageComponent} from '../../interfaces/abstract-page.component';
import {MessageService} from 'primeng/api';
import {ReloadService} from '../../services/reload.service';
import {Page} from '../../annotations/page.annotation';
import {PagesService} from '../../services/pages.service';

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.scss']
})
@Page('params', 'Paramètres', undefined,{ icon: 'pi pi-cog' })
export class ParametersComponent extends AbstractPageComponent implements OnInit {

  helpOptions = [
    {label: 'Non', value: {id: 1, name: 'Non'}},
    {label: 'Oui', value: {id: 2, name: 'Oui'}}
  ];
  selectedHelpOption = this.helpOptions[0].value;

  smallMenuOptions = [
    {label: 'Menu complet', value: {id: 1, name: 'Menu complet'}},
    {label: 'Juste les icônes', value: {id: 2, name: 'Juste les icônes'}}
  ];
  selectedSmallMenuOption = this.smallMenuOptions[1].value;

  constructor(private messageService: MessageService,
              private reloadService: ReloadService) {
    super();
  }

  ngOnInit(): void {

    setTimeout(() => {
      const helpDisplay = localStorage.getItem('helpDisplay');
      const smallMenuDisplay = localStorage.getItem('smallMenuDisplay');

      if (helpDisplay === null) {
        this.selectedHelpOption = this.helpOptions[0].value;
      } else {
        this.selectedHelpOption = JSON.parse(helpDisplay);
      }

      if (smallMenuDisplay === null) {
        this.selectedSmallMenuOption = this.smallMenuOptions[0].value;
      } else {
        this.selectedSmallMenuOption = JSON.parse(smallMenuDisplay);
      }

    }, 10);

  }

  getHelp(): string {
    return 'Aide des paramètres';
  }

  onSave() {
    setTimeout(() => {
      localStorage.setItem('helpDisplay', JSON.stringify(this.selectedHelpOption));
      localStorage.setItem('smallMenuDisplay', JSON.stringify(this.selectedSmallMenuOption));
      this.messageService.add({severity: 'success', summary: 'Paramètres enregistrés'});

      this.reloadService.shouldRefreshLayouts([
        {id: 'helpDisplay',      value: this.selectedHelpOption},
        {id: 'smallMenuDisplay', value: this.selectedSmallMenuOption},
      ]);
    }, 0);

  }

}
