import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AbstractPageComponent} from '../../../interfaces/abstract-page.component';
import {StudentCompleteDTO} from '../../../models/students/student-complete-dto';
import {Page} from '../../../annotations/page.annotation';
import {PagesService} from '../../../services/pages.service';

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.scss']
})
@Page(PagesService.STUDENT, 'Étudiant : ', undefined, { icon: 'pi pi-user' })
export class StudentComponent extends AbstractPageComponent implements OnInit, AfterViewInit {

  @ViewChild('bottomTabs') bottomTabs: ElementRef;

  student: StudentCompleteDTO;
  subTabs = [
    { value: 'profile',         label: 'Profil' },
    { value: 'formations',      label: 'Formations' },
    { value: 'disciplines',     label: 'Matières' },
    { value: 'proofs',          label: 'Justificatifs' },
    { value: 'compléments',     label: 'Compléments' },
    { value: 'documents',       label: 'Documents' },
    { value: 'gesTracking',     label: 'Suivi GES' },
    { value: 'pikicomTracking', label: 'Suivi Pikicom' },
    { value: 'accounting',      label: 'Comptabilité' },
    { value: 'billing',         label: 'Facturation' },
    { value: 'sepa',            label: 'SEPA' },
    { value: 'planning',        label: 'Planning étudiant' },
  ];

  selectedTab: string;
  bottomTabsHeight = 28;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.student = this.extraData;
    this.onSubTabClicked(this.subTabs[0]);
  }

  getHelp(): string {
    return '';
  }

  onSubTabClicked(subTab: { value: string, label: string }) {

    this.selectedTab = subTab.value;

    switch (this.selectedTab) {
      case 'profile':         this.loadProfile();         break;
      case 'formations':      this.loadFormations();      break;
      case 'disciplines':     this.loadDisciplines();     break;
      case 'proofs':          this.loadProofs();          break;
      case 'compléments':     this.loadComplements();     break;
      case 'documents':       this.loadDocuments();       break;
      case 'gesTracking':     this.loadGesTracking();     break;
      case 'pikicomTracking': this.loadPikicomTracking(); break;
      case 'accounting':      this.loadAccounting();      break;
      case 'billing':         this.loadBilling();         break;
      case 'sepa':            this.loadSepa();            break;
      case 'planning':        this.loadPlanning();        break;
    }
  }

  private loadProfile() {
    // TODO:
  }

  private loadFormations() {
    // TODO:
  }

  private loadDisciplines() {
    // TODO:
  }

  private loadProofs() {
    // TODO:
  }

  private loadComplements() {
    // TODO:
  }

  private loadDocuments() {
    // TODO:
  }

  private loadGesTracking() {
    // TODO:
  }

  private loadPikicomTracking() {
    // TODO:
  }

  private loadAccounting() {
    // TODO:
  }

  private loadBilling() {
    // TODO:
  }

  private loadSepa() {
    // TODO:
  }

  private loadPlanning() {
    // TODO:
  }

  ngAfterViewInit(): void {
    this.bottomTabsHeight = this.bottomTabs.nativeElement.offsetHeight;
  }
}
