import {Certifier} from './certifier';
import {CertifierDTO} from './certifierDTO';

export class CertifierAdapter{
  public static adapt(dto: CertifierDTO): Certifier {
    return {
      certifier_id: dto.certifier_id,
      certifier_name: dto.certifier_name,
      is_structure: dto.is_structure
    } as Certifier;
  }
}
