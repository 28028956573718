<div style="position: absolute; top: 0; bottom: 0; left: 0; right: 0; background-color: #343434; z-index: 100000">
  <div class="middleContainer" style="display: flex; align-items: center; justify-content: center; position: ">
    <div style="display: block; text-align: center">
      <img src="../../../assets/Kordis_logo_blanc.svg" />
      <div class="p-mt-6" style="height: 200px; display: block; text-align: center; color:white">
        <div style="height: 50px; font-size: 1.5rem">Chargement en cours, veuillez patienter</div>
        <app-spinner></app-spinner>
      </div>
    </div>
  </div>
</div>
