<div
  (mouseleave)="opened ? changeDisplayFavoriteEntries() : ''"
  (mouseenter)="opened ? changeDisplayFavoriteEntries() : ''"
  style="position: relative"
  [ngClass]="favoriteEntries && favoriteEntries.size ? 'hovering-button pointer' : 'disabled-favorite-button'"
>

  <div class="whiteColor expanded-button" *ngIf="!isMenuCollapsed" (click)="onButtonClicked();">
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 20 19" style="margin-right: 26px;">
      <path class="favorite-star"
            d="M10,0l2.7,6.791,7.3.467-5.631,4.663L16.18,19,10,15.091,3.82,19l1.812-7.079L0,7.257l7.3-.467Z"/>
    </svg>
    <span class="text">Favoris</span>
    <span class="arrow p-submenu-icon p-mr-5 pi"
          [ngClass]="{'pi-angle-right': fullEntries && fullEntries.size}"></span>
  </div>


  <div class="whiteColor collapsed-logo" *ngIf="isMenuCollapsed" (click)="onButtonClicked();">
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 20 19">
      <path class="favorite-star"
            d="M10,0l2.7,6.791,7.3.467-5.631,4.663L16.18,19,10,15.091,3.82,19l1.812-7.079L0,7.257l7.3-.467Z"/>
    </svg>

  </div>

  <div class="filter-selection-field">

    <app-formated-entries-menu *ngIf="displayFavoriteEntries"
                               (changeFavoriteStatus)="updateFavoriteStatus($event)"
                               (closePannel)="changeDisplayFavoriteEntries()"
                               [displayStar]="false"
                               [formattedEntries]="favoriteEntries"></app-formated-entries-menu>

  </div>
</div>

