import {Injectable} from '@angular/core';
import {UrlFactoryService} from '../factories/url-factory.service';
import {BaseService} from './base.service';
import {RightsGroup} from '../models/rights/rights-group';
import {Right} from '../models/rights/right';
import {UserHasRight} from '../models/rights/user_has_right';
import {RightsGroupHasRight} from '../models/rights/rights-group-has-right';
import {RightsFamily} from '../models/rights/rights_family';
import {Administrative} from '../models/administration/administrative';
import {RightsGroupMemberLogDTO} from '../models/rights/rights-group-member-log-dto';
import {RightLogDTO} from '../models/rights/right-log-dto';
import {RightType} from '../models/rights/right-type';
import {CancelablePromise} from '../common/promises/cancelable-promise';

@Injectable({
  providedIn: 'root'
})
export class RightsService {

  constructor(private baseService: BaseService,
              private urlFactory: UrlFactoryService) {
  }

  getRights(): CancelablePromise<Right[]> {
    return this.baseService.getAll<Right>(this.urlFactory.getRightsUrl());
  }

  saveRight(rightToSave: any): CancelablePromise<Right> {
    return this.baseService.save(this.urlFactory.getRightsUrl(), rightToSave, false);
  }

  activateRights(ids: number[], active: boolean): CancelablePromise<boolean> {
    return this.baseService.patch(this.urlFactory.getRightsActivationUrl() + '/' + active, ids);
  }

  deleteRights(rightsToDelete: Right[]): CancelablePromise<boolean> {
    return this.baseService.deleteAll(this.urlFactory.getRightsUrl(), rightsToDelete);
  }

  getAllRightsGroups(): CancelablePromise<RightsGroup[]> {
    return this.baseService.getAll<RightsGroup>(this.urlFactory.getAllRightsGroupsUrl());
  }

  getRightsGroupsByName(chunk: string): CancelablePromise<RightsGroup[]> {
    return this.baseService.getAll<RightsGroup>(this.urlFactory.getRightsGroupsByNameUrl(chunk));
  }

  getCurrentRightsGroup(): CancelablePromise<RightsGroup[]> {
    return this.baseService.getAll<RightsGroup>(this.urlFactory.getCurrentRightsGroupUrl());
  }

  getRightsGroupsFromMember(uid: number): CancelablePromise<RightsGroup[]> {
    return this.baseService.getAll<RightsGroup>(this.urlFactory.getRightsGroupsFromMemberUrl(uid));
  }

  getMembersFromMyRightsGroups(): CancelablePromise<Administrative[]> {
    return this.baseService.getAll<Administrative>(this.urlFactory.getMembersFromMyRightsGroupsUrl());
  }

  getRightsGroupChildren(rightsGroupId: number): CancelablePromise<RightsGroup[]> {
    return this.baseService.getAll<RightsGroup>(this.urlFactory.getRightsGroupChildrenUrl(rightsGroupId));
  }

  getRightsGroupMembers(rightsGroupId: number): CancelablePromise<Administrative[]> {
    return this.baseService.getAll<Administrative>(this.urlFactory.getRightsGroupMembersUrl(rightsGroupId));
  }

  getRightsGroupRights(rightsGroupId: number): CancelablePromise<RightsGroupHasRight[]> {
    return this.baseService.getAll<RightsGroupHasRight>(this.urlFactory.getRightsGroupRightsUrl(rightsGroupId));
  }

  getRightsGroupByRight(rightId: number): CancelablePromise<RightsGroupHasRight[]> {
    return this.baseService.getAll<RightsGroupHasRight>(this.urlFactory.getRightsGroupByRightUrl(rightId));
  }

  getRightsFamilies(): CancelablePromise<RightsFamily[]> {
    return this.baseService.getAll<RightsFamily>(this.urlFactory.getRightsFamiliesUrl());
  }

  getAllRightsFamilies(): CancelablePromise<RightsFamily[]> {
    return this.baseService.getAll<RightsFamily>(this.urlFactory.getAllRightsFamiliesUrl());
  }

  saveFamily(familyToSave: any): CancelablePromise<RightsFamily> {
    return this.baseService.save(this.urlFactory.getRightsFamiliesUrl(), familyToSave, false);
  }

  deleteFamilies(familiesToDelete: RightsFamily[]): CancelablePromise<boolean> {
    return this.baseService.deleteAll(this.urlFactory.getRightsFamiliesUrl(), familiesToDelete);
  }

  getRightTypes(): CancelablePromise<RightType[]> {
    return this.baseService.getAll<RightType>(this.urlFactory.getRightsTypesUrl());
  }

  saveRightsGroupRights(rightsGroupId: number, rightsToSave: any[]): CancelablePromise<RightsGroupHasRight[]> {
    return this.baseService.save(this.urlFactory.getRightsGroupRightsUrl(rightsGroupId), rightsToSave, false);
  }

  removeRightsGroupMembers(rightsGroupId: number, toRemove: number[]): CancelablePromise<boolean> {
    const elementsToRemove = toRemove.map(a => ({uid: a}));
    return this.baseService.deleteAll(this.urlFactory.getRightsGroupMembersUrl(rightsGroupId), elementsToRemove);
  }

  removeRightsGroupsFromMember(uid: number, toRemove: number[]): CancelablePromise<boolean> {
    const elementsToRemove = toRemove.map(rg => ({rights_group_id: rg}));
    return this.baseService.deleteAll(this.urlFactory.getRightsGroupsFromMemberUrl(uid), elementsToRemove);
  }

  addRightsGroupMembers(rightsGroupId: number, toAdd: number[]): CancelablePromise<Administrative[]> {
    const elementsToSave = toAdd.map(a => ({uid: a}));
    return this.baseService.save<any, Administrative[]>(this.urlFactory.getRightsGroupMembersUrl(rightsGroupId), elementsToSave, false);
  }

  addRightsGroupsToMember(uid: number, toAdd: number[]): CancelablePromise<Administrative[]> {
    const elementsToSave = toAdd.map(rg => ({rights_group_id: rg}));
    return this.baseService.save<any, Administrative[]>(this.urlFactory.getRightsGroupsFromMemberUrl(uid), elementsToSave, false);
  }

  getUserRights(uid: number): CancelablePromise<UserHasRight[]> {
    return this.baseService.getAll<UserHasRight>(this.urlFactory.getUserRightsUrl(uid));
  }

  saveRightsGroup(rightsGroupToSave: RightsGroup): CancelablePromise<RightsGroup> {
    return this.baseService.save(this.urlFactory.getAllRightsGroupsUrl(), rightsGroupToSave, false);
  }

  removeRightsGroup(rightsGroupId: number): CancelablePromise<boolean> {
    return this.baseService.deleteFromUrl(this.urlFactory.getRightsGroupUrl(rightsGroupId));
  }

  getRightsGroupMemberLogs(rightsGroupId: number): CancelablePromise<RightsGroupMemberLogDTO[]> {
    return this.baseService.getAll<RightsGroupMemberLogDTO>(this.urlFactory.getRightsGroupMemberLogsUrl(rightsGroupId));
  }

  getRightsLogs(rightsGroupId: number, rightId: number): CancelablePromise<RightLogDTO[]> {
    return this.baseService.getAll<RightLogDTO>(this.urlFactory.getRightsLogsUrl(rightsGroupId, rightId));
  }

  getRightsLogsByUser(rightId: number, userId: number): CancelablePromise<RightLogDTO[]> {
    return this.baseService.getAll<RightLogDTO>(this.urlFactory.getRightsLogsByUserUrl(rightId, userId));
  }
}
