
<div class="fullHeight" style="display: flex; justify-content: center; align-items: center;">

  <div>
    <div class="p-m-6" style="display: flex; justify-content: center; max-width: 850px">
      <div>
        <h3>Ceci est une version préliminaire de Kordis®.</h3>
        <p>Si vous remarquez un dysfonctionnement ou que vous avez une suggestion <u><i>en lien avec les écrans présents</i></u>, veuillez vous adresser au support à l'adresse <a href="mailto:service.informatique@geschool.fr" style="color: #FF0060">service.informatique@geschool.fr</a>.</p>
        <p>Ici prendra bientôt place un outil de recherche rapide.</p>
        <h4>En attendant, vous pouvez d'ores et déjà naviguer à l'aide du menu latéral gauche.</h4>
      </div>
    </div>
<!--    <app-search-box style="display: flex; justify-content: center"-->
<!--                    fieldName="student_firstname"-->
<!--                    placeHolder="Veuillez entrer une valeur à rechercher"-->
<!--                    [width]="600" [data]="filteredStudentData"-->
<!--                    (filterChanged)="onFilterChanged($event)"-->
<!--                    (dataSelected)="onDataSelected($event)">-->
<!--    </app-search-box>-->
  </div>
</div>
