import {Injectable} from '@angular/core';
import {SchoolDTO} from '../models/schools/school-dto';
import {SchoolYearSemesterValues} from '../models/school-year-semester-values';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private MAIN_FILTERS_KEY = 'kordisMainFilters';
  private USER_YEARS_KEY   = 'kordisUserYears';
  private USER_SCHOOLS_KEY = 'kordisUserSchools';

  mMainFilters: SchoolYearSemesterValues;
  mUserYears: number[];
  mUserSchools: SchoolDTO[];

  constructor() { }

  //region mainFilters
  getMainFilters(): Promise<SchoolYearSemesterValues> {
    return new Promise<SchoolYearSemesterValues>((resolve => {
      if (!this.mMainFilters) {
        const mainFilters = localStorage.getItem(this.MAIN_FILTERS_KEY);
        this.mMainFilters = JSON.parse(mainFilters) ?? {selectedYear: -1, selectedSchoolId: -1, selectedSemesterId: -1};
      }
      resolve(this.mMainFilters);
    }));
  }

  setMainFilters(mainFilters: SchoolYearSemesterValues) {
    this.mMainFilters = mainFilters;
    localStorage.setItem(this.MAIN_FILTERS_KEY, JSON.stringify(mainFilters));
  }
  //endregion

  //region userYears
  getUserYears(): Promise<number[]> {
    return new Promise<number[]>((resolve => {
      if (!this.mUserYears) {
        const years = localStorage.getItem(this.USER_YEARS_KEY);
        this.mUserYears = JSON.parse(years) ?? [];
      }
      resolve(this.mUserYears);
    }));
  }

  setUserYears(years: number[]) {
    this.mUserYears = years;
    localStorage.setItem(this.USER_YEARS_KEY, JSON.stringify(years));
  }
  //endregion

  //region userSchools
  getUserSchools(): Promise<SchoolDTO[]> {
    return new Promise<SchoolDTO[]>((resolve => {
      if (!this.mUserSchools) {
        const schools = localStorage.getItem(this.USER_SCHOOLS_KEY);
        this.mUserSchools = JSON.parse(schools) ?? [];
      }
      resolve(this.mUserSchools);
    }));
  }

  setUserSchools(schools: SchoolDTO[]) {
    this.mUserSchools = schools;
    localStorage.setItem(this.USER_SCHOOLS_KEY, JSON.stringify(schools));
  }
  //endregion
}
