import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginSuccessComponent} from './pages/users/login-success/login-success.component';
import {FailureScreenComponent} from "./components/failure-screen/failure-screen.component";


const routes: Routes = [
  { path: 'login-success', component: LoginSuccessComponent },
  { path: 'error', component: FailureScreenComponent },
  { path: '', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
