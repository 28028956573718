<div class="table-disabled">
  <p-table #dt id="dt" [columns]="columns" (window:resize)="calculateRows()"

           [value]="mData"
           [(selection)]="selectedData" [dataKey]="dataKey"
           (onRowSelect)="onRowSelect()" (onRowUnselect)="onRowUnselect()"
           (onHeaderCheckboxToggle)="onHeaderCheckboxToggle()"
           class="{{gridType}} isScrollable"
           styleClass="ui-table-customers p-datatable-striped"
           [paginator]="mData && mData.length > 0" [rowHover]="true" [rows]="rows"
           [showCurrentPageReport]="true"
           currentPageReportTemplate="Résultats {first} à {last} / {totalRecords} entrées"
           [resizableColumns]="true" columnResizeMode="fit"
           [scrollable]="true"
           [style]="{'min-width': '100%', 'position': 'relative', 'height' : 'auto'}"
           [scrollHeight]="'flex'"
           [filterDelay]="0"
           [globalFilterFields]="globalFilterFields">
    <ng-template pTemplate="caption">
      <div *ngIf="gridTitle && gridTitle.trim().length > 0" class="header-grid-title">{{ gridTitle }}</div>

      <div class="p-d-flex header-grid-buttons" *ngIf="showAddButton || showDeleteButton || showShowButton || customButtonsTemplate">
        <div class="p-mx-2 p-d-flex" *ngIf="showAddButton || showDeleteButton || showShowButton || customButtonsTemplate">

          <div *ngIf="showGlobalSearch" class="p-ml-auto p-text-left">
            <button pButton class="p-button-secondary globalSearch-button" icon="pi pi-search"></button>
            <span class="p-input-icon-right" style="bottom: 2px">
            <i *ngIf="searchInput.length > 0" class="pi pi-times clickable" style="color: red" (click)="onResetSearchInput()"></i>
            <input class="p-inputtext p-mr-1" style="width:auto" type="text" pInputText placeholder="Recherche globale" [(ngModel)]="searchInput" (ngModelChange)="searchGlobal($event)">
          </span>
          </div>

          <button *ngIf="showAddButton" type="button" pButton pRipple icon="pi pi-plus" (click)="add()"
                  class="p-button-success p-mx-1" pTooltip="Ajouter" tooltipPosition="bottom"></button>
          <button *ngIf="showDeleteButton" type="button" pButton pRipple icon="pi pi-trash" (click)="deleteSelected()"
                  class="p-button-danger p-mx-1" pTooltip="Supprimer la sélection"
                  tooltipPosition="bottom" [disabled]="!selectedData || selectedData.length === 0"></button>
          <button *ngIf="showShowButton" [disabled]="selectedData?.length !== 1" type="button" pButton pRipple icon="pi pi-eye" (click)="showSelectedElement()"
                  class="p-button-secondary p-mx-1" pTooltip="Visualiser"
                  tooltipPosition="bottom"></button>
          <p-splitButton *ngIf="moreActionItems.length > 0" icon="pi pi-ellipsis-h" [model]="moreActionItems" styleClass="p-button-secondary moreButton"></p-splitButton>
        </div>
        <hr style="height: 20px;margin: 0" *ngIf="showAddButton || showDeleteButton || showShowButton || customButtonsTemplate">
        <div class="p-ml-2 p-mx-2 p-d-flex p-ai-center">
          <ng-container
            *ngTemplateOutlet="customButtonsTemplate ? customButtonsTemplate : defaultCustomButtons"></ng-container>
          <ng-template #defaultCustomButtons></ng-template>
        </div>
        <span class="p-ml-2 p-mt-1 p-text-normal" *ngIf="selectedData != null && selectedData.length > 0"> {{selectedData.length}} élément(s) sélectionné(s)<app-remove-cross class="delete-selection-cross" (crossPressed)="removeAllSelection()"></app-remove-cross></span>

      </div>
    </ng-template>
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col [style]="{'width': '50px' }">
        <col *ngFor="let col of columns" [style]="{'width': col.width }">
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header">
      <tr *ngIf="topColumns && topColumns.length > 0">
        <th></th>
        <th *ngFor="let col of topColumns" [colSpan]="col.span">
          <div class="centerContent" style="min-height: 35px; font-size: 1.2em">{{ col.header }}</div>
        </th>
      </tr>
      <tr>
        <th>
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th *ngFor="let col of columns" [pSortableColumn]="col.sortable === true ? col.field : ''" [pTooltip]="col.header" pResizableColumn>
          <div class="p-d-flex p-jc-between p-ai-center" style="min-height: 35px">
            <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
            <p-columnFilter *ngIf="isFilterActivated(col, 'input')" type="text" [field]="col.field" display="menu"
                            [matchModeOptions]="matchModeOptions" [matchMode]="'contains'"
                            [showMatchModes]="true" [showOperator]="false" [showAddButton]="true"
                            [showApplyButton]="false" [showClearButton]="false">
            </p-columnFilter>
            <p-columnFilter *ngIf="isFilterActivated(col, 'dropdown')" [field]="col.field" matchMode="in" display="menu"
                            [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
              <ng-template pTemplate="filter" let-value  let-filter="filterCallback">
                <p-multiSelect optionLabel="label" placeholder="Sélectionnez..." selectedItemsLabel="{0} éléments sélectionnés"
                               [ngModel]="value"
                               [options]="options[col.field]"
                               (onChange)="filter($event.value)" >
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
            <p-columnFilter style="text-align: center; width: 100%" *ngIf="col.type === 'boolean'" display="menu" type="boolean" [field]="col.field"></p-columnFilter>
            <span style="width: 100%; text-align: center">{{col.header}}</span>
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr (click)="onRowClicked($event, rowData)" [pSelectableRowDisabled]="isDisabled" [pSelectableRowDblClick]="rowData" (dblclick)="onDblClick(rowData)"
          class="{{isDisabled ? 'disabled-row' : 'ui-selectable-row' }}"
      >
        <td>
          <p-tableCheckbox [disabled]="isDisabled" [value]="rowData"></p-tableCheckbox>
        </td>
        <td *ngFor="let col of columns">
          <p-checkbox *ngIf="data !== undefined && data !== null && data.length > 0 && col.type === 'boolean'"
                      [(ngModel)]="rowData[col.field]" binary="true" [disabled]="col.readonly"></p-checkbox>
          <span
            *ngIf="data !== undefined && data !== null && data.length > 0 && col.type === 'number' || col.type === 'string'">{{rowData[col.field]}}</span>
          <span
            *ngIf="data !== undefined && data !== null && data.length > 0 && col.type === 'date'">{{rowData[col.field] | date}}</span>
          <span
            *ngIf="data !== undefined && data !== null && data.length > 0 && col.type === 'link'"
            class="link-span"
            pTooltip="{{rowData[col.field]}}" tooltipPosition="bottom" tooltipEvent="hover"
            escape="false"
            tooltipStyleClass="link-tooltip">Afficher</span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <div class="emptyMessage" *ngIf="mData != null">
        Aucun résultat
      </div>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
      <div class="p-d-flex">
        <div class="p-d-inline-flex p-p-2 p-pb-0">
          <button type="button" *ngIf="showRefreshButton" pButton pRipple icon="pi pi-refresh" (click)="refresh()"
                  class="p-mr-2" pTooltip="Rafraîchir les données" tooltipPosition="bottom"></button>
        </div>

        <div class="centerVertical">
          <hr class="p-mr-2" style="height: 20px" *ngIf="showRefreshButton && hasAnySendButton()">
        </div>

        <div *ngIf="hasAnySendButton()" class="p-d-inline-flex p-p-2 p-pb-0">
          <p-splitButton #sendButtons (onClick)="sendButtons.onDropdownButtonClick($event)" icon="pi pi-share-alt" [model]="exportItems" styleClass="p-button-secondary exportButton"></p-splitButton>
        </div>
      </div>
    </ng-template>
  </p-table>
  <div *ngIf="isDisabled" class="disabled-card">
    <p-panel class="disabled-card-msg"  header="Attention">
      <p>Les paramêtres obligatoires ont changés.</p>
      <p>Si votre paramétrage est terminé, veuillez cliquer sur le bouton "Exécuter".</p>
    </p-panel>
  </div>
</div>

<p-dialog header="Modification modèle Word"
          [(visible)]="displayPublipostageDownloadDialog" [modal]="true" [style]="{width: '1000px'}" [baseZIndex]="10"
          [draggable]="false" [resizable]="false" [closeOnEscape]="true">

  <div class="publi-container">
    La génération de votre publipostage va nécessiter le télechargement de 2 documents:<br/>
    <span class="emphased-content">Un modèle Word</span> et
    <span class="emphased-content">une base de données Excel</span>
    <br/><br/>
    <b>Attention, pour que le publipostage fonctionne, vous devrez respecter les règles suivantes:</b><br/>
    <div class="precision-container">
      <div class="precision-container2">
        <div class="precision-container3">
          <span class="step-number"><b>1.</b></span> Le nom du fichier Excel téléchargé ne devra pas
          être modifié.<br/>
          Seul le nom du fichier Word pourra être changé.
        </div>
        <div style="background-image: url('assets/images/publipostage/Word_mod.png') !important" class="word-image"></div>
        <div  style="background-image: url('assets/images/publipostage/Excel_mod.png') !important" class="excel-image"></div>
      </div>
      <div>
        <div class="two-tier-width">
          <span class="step-number"><b>2.</b></span> Les deux fichiers devront se situer dans le <b>même
          répertoire</b>.
        </div>
      </div>
      <div style="position: relative; margin: 10px 0">
        <div class="two-tier-width" style=" min-width:50px;">
          <span class="step-number"><b>3.</b></span>Vous devrez fermer <b>TOUS</b> les logiciels de la
          suite Office ouverts avant d'ouvrir le document word de publipostage.
        </div>
        <div class="crossed-word-image"
          style=" background-image: url('assets/images/publipostage/Word_closed.png') !important;"></div>
        <div class="crossed-excel-image"
          style="background-image: url('assets/images/publipostage/Excel_closed.png') !important;"></div>
      </div>
    </div>
    Pour poursuivre la procédure de publipostage, veuillez cliquer sur le bouton <b>Télécharger les documents</b>.
  </div>

  <ng-template pTemplate="footer">
    <button pButton label="Annuler" icon="pi pi-times" class="p-button-danger" (click)="displayPublipostageDownloadDialog = false"></button>
    <button pButton label="Télécharger les documents" icon="pi pi-download" (click)="onDownloadPublipostageModelFiles()"></button>
  </ng-template>
</p-dialog>


<p-dialog header="Publipostage Word"
          [(visible)]="displayPublipostageDialog" [modal]="true" [style]="{width: '800px'}" [baseZIndex]="10"
          [draggable]="false" [resizable]="false" [closeOnEscape]="true">
  <div class="p-d-flex">
    <div class="p-col-5 align-right">Publipostage depuis un : </div>
    <div class="p-col-7">
      <p-radioButton name="publipostageType" label="Document existant" value="existingDocument" [(ngModel)]="publipostageMode" class=" p-mr-3"></p-radioButton>
      <p-radioButton name="publipostageType" label="Nouveau document"  value="newDocument"   (ngModelChange)="resetPublipostageFiles()"   [(ngModel)]="publipostageMode"></p-radioButton>
    </div>

  </div>
  <div *ngIf="publipostageMode === 'existingDocument'" class="p-d-flex centerVertical">
    <div class="p-col-5 align-right">Document (.docx) : </div>
    <div class="p-col-7 p-d-flex centerVertical">
      <p-fileUpload *ngIf="!publipostageSelectedFiles || publipostageSelectedFiles.length === 0; else hasWordModelFileSelected"
                    mode="basic" name="rendu" chooseIcon=""
                    class="upload-btn p-mr-3"
                    accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    (onSelect)="importOnClick($event)" [auto]="true" chooseLabel="Choisir">
      </p-fileUpload>
      <ng-template #hasWordModelFileSelected>
        <button pButton icon="pi pi-times" style="min-width: 35px" class="p-button-secondary p-mr-3" (click)="resetPublipostageSelectedFiles()"></button>
      </ng-template>
      <div class="primaryColor p-text-bold scrollable-x"
           [innerText]="publipostageSelectedFiles && publipostageSelectedFiles.length === 1 ? publipostageSelectedFiles[0].name : ''"></div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button pButton label="Annuler" icon="pi pi-times" class="p-button-danger" (click)="displayPublipostageDialog = false"></button>
    <button pButton [disabled]="!((publipostageSelectedFiles && publipostageSelectedFiles.length) || publipostageMode !== 'existingDocument')" label="Valider" icon="pi pi-check" class="p-button-success" (click)="onValidatePublipostage($event)"></button>
  </ng-template>
</p-dialog>

<app-field-loader [loadingCondition]="loading" (cancel)="this.cancelRequest()"></app-field-loader>
