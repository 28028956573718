export interface Session {
  name: string;
  value: string;
}

export const sessions = [
      {name: 'TOUTES', value: 'TOUTES'},
      {name: 'SEPTEMBRE', value: 'SEPTEMBRE'},
      {name: 'OCTOBRE', value: 'OCTOBRE'},
      {name: 'NOVEMBRE', value: 'NOVEMBRE'},
      {name: 'DECEMBRE', value: 'DECEMBRE'},
      {name: 'JANVIER', value: 'JANVIER'},
      {name: 'FEVRIER', value: 'FEVRIER'},
      {name: 'MARS', value: 'MARS'},
      {name: 'AVRIL', value: 'AVRIL'},
      {name: 'MAI', value: 'MAI'},
      {name: 'JUIN', value: 'JUIN'},
      {name: 'JUILLET', value: 'JUILLET'},
      {name: 'AOUT', value: 'AOUT'}
    ];
