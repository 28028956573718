<p-tabView class="fullHeight"  #closableTabView id="closableTabView" [activeIndex]="currentTabIndex" (onChange)="onTabChanged($event)" [controlClose]="true" (onClose)="handleClose($event)">
    <p-tabPanel header="Accueil" [selected]="true" >
      <app-welcome-page (tabAdded)="onAddTab($event)"></app-welcome-page>
    </p-tabPanel>
    <p-tabPanel *ngFor="let tab of tabs;let i = index" tab="tab.component" [closable]="true" [headerStyleClass]="tab.data.id === 'params' ? 'paramsHeader' : ''">
      <ng-template pTemplate="header"><i [class]="tab.data.icon" [style]="{'margin-right': tab.data.icon ? '5px': '0'}"></i>{{ tab.header + (tab.headerAddition ? tab.headerAddition : '') }}</ng-template>
      <ng-template appTab></ng-template>
    </p-tabPanel>
</p-tabView>

