<div [class]="isMenuCollapsed ? 'hover-style component-style' : 'component-style'">

  <div style="position: relative">

      <span class="p-d-inline" *ngIf="!isMenuCollapsed">
          <span class="p-input-icon-left rounded-search-field">
            <i class="p-text-bold icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="30.004" height="27.156" viewBox="0 0 30.004 27.156">
                <g transform="translate(-68.465 -171.922)">
                  <g class="a" transform="translate(95.621 182.861) rotate(124)"><ellipse
                    class="c" cx="9.781" cy="9.781" rx="9.781" ry="9.781"/>
                    <ellipse class="d" cx="9.781" cy="9.781" rx="8.531" ry="8.531"/></g>
                  <rect class="b" width="1.507" height="4.767" transform="translate(93.674 192.534) rotate(124)"/>
                  <rect class="b" width="2.985" height="7.773" transform="translate(98.469 194.858) rotate(124)"/>
                </g>
              </svg>
            </i>
            <input type="text"
                   pInputText placeholder="Rechercher"
                   (click)="interfaceFilter ? changeDisplayEntries() : ''"
                   (ngModelChange)="searchInterface($event)"
                   [(ngModel)]="interfaceFilter"
                   class="search-input"
            />
          </span>
      </span>

    <div *ngIf="isMenuCollapsed"
         (click)="changeCollapsedSearchFieldVisibility()"
         class="pointer collasped-search-button">

      <svg xmlns="http://www.w3.org/2000/svg" width="30.004" height="27.156" viewBox="0 0 30.004 27.156">
        <g transform="translate(-68.465 -171.922)">
          <g class="e" transform="translate(95.621 182.861) rotate(124)">
            <ellipse
              class="g" cx="9.781" cy="9.781" rx="9.781" ry="9.781"/>
            <ellipse class="h" cx="9.781" cy="9.781" rx="8.531" ry="8.531"/>
          </g>
          <rect class="f" width="1.507" height="4.767" transform="translate(93.674 192.534) rotate(124)"/>
          <rect class="f" width="2.985" height="7.773" transform="translate(98.469 194.858) rotate(124)"/>
        </g>
      </svg>

    </div>

    <div class="filter-selection-field">
      <div class="background-encapsulator" *ngIf="isMenuCollapsed && collapsedSearchFieldDisplayed">
        <input
          (click)="interfaceFilter ? changeDisplayEntries() : ''"
          (ngModelChange)="searchInterface($event)"
          class="standalone-search-field"
          type="text" pInputText
          placeholder="Rechercher"
          [(ngModel)]="interfaceFilter"/>
      </div>


      <app-formated-entries-menu *ngIf="displaySearchedEntries"
                                 (changeFavoriteStatus)="updateFavoriteStatus($event)"
                                 [filter]="interfaceFilter"
                                 (click)="changeDisplayEntries()"
                                 (closePannel)="hideDisplayedEntries($event, !isMenuCollapsed)"
                                 [formattedEntries]="displayedEntries"></app-formated-entries-menu>

    </div>
  </div>
</div>
